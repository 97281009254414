import api from '../api';

const ArtistApi = {};

const prefix = '/artists';

ArtistApi.getUsers = params => {
  return api.fire({
    url: '/users',
    method: 'GET',
    params
  });
};

ArtistApi.getArtist = params => {
  return api.fire({
    url: prefix,
    method: 'GET',
    params
  });
};

ArtistApi.getArtistById = userId => {
  return api.fire({
    url: `${prefix}/${userId}`,
    method: 'GET'
  });
};

ArtistApi.uploadBgImg = (userId, data) => {
  return api.fire({
    url: `${prefix}/${userId}/imageBackground`,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  });
};

ArtistApi.uploadThumbnailImg = (userId, data) => {
  return api.fire({
    url: `${prefix}/${userId}/imageThumbnail`,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  });
};

ArtistApi.uploadCV = (userId, data) => {
  return api.fire({
    url: `${prefix}/${userId}/cv`,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  });
};

ArtistApi.uploadVideo = (userId, data) => {
  return api.fire({
    url: `${prefix}/${userId}/promotionVideo`,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  });
};

ArtistApi.update = (userId, params) => {
  return api.fire({
    url: `${prefix}/${userId}`,
    method: 'PATCH',
    data: JSON.stringify(params)
  });
};

ArtistApi.addUser = userId => {
  return api.fire({
    url: prefix,
    method: 'POST',
    data: JSON.stringify({
      userId
    })
  });
};

ArtistApi.delete = userId => {
  return api.fire({
    url: `${prefix}/${userId}`,
    method: 'DELETE'
  });
};

export default ArtistApi;