import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showBackIcon: false,
  backDesi: '',
};

export const headerSlice = createSlice({
  name: "headerSlice",
  initialState,
  reducers: {
    showBackIcon: (state, action) => {
      state.showBackIcon = true;
      state.backDesi = action.payload;
    },
    hideBackIcon: state => {
      state.showBackIcon = false;
      state.backDesi = '';
    },
  }
});

export const { showBackIcon, hideBackIcon } = headerSlice.actions;
export default headerSlice.reducer;
