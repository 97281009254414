import { Link, useLocation } from "react-router-dom";

import { ROUTES } from 'components/layout/consts';

import styles from './style.module.scss';

export default function(props) {
  const location = useLocation();
  const curPathname = location.pathname.substring(1);
  
  const { title, icon, activeIcon, action } = props.data;
  const hasBorderTop = action === ROUTES.MINTING || action === ROUTES.USER;
  const isActive = curPathname === action;
  
  return (
    <Link to={action}>
      { hasBorderTop && <div className={styles.borderTop}/> }
      <div className={`${styles.navitem} ${isActive && styles.active}`}>
        <div className={styles.linkTextWrapper}>
          <img src={isActive ? activeIcon : icon} className={styles.itemlogo} />
          {
            (props.windowWidth >= 1200 || props.expanded) &&
              <span className={styles.linkText}>{title}</span>
          }
        </div>
      </div>
    </Link>
  );
}
