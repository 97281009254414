import TotalTokenCount from './TotalTokenCount';
import NewMintedTokenCount from './NewMintedTokenCount';
import TokenHighestSoldPrice from './ThirdSection';
import TokenSales from './TokenSales';
import TokenBidded from './TokenBidded';
import HighestBiddedTokens from './HighestBiddedTokens';
import MostOfferedToken from './MostOfferedToken';

import artistStyle from 'components/ArtistBoard/style.module.scss';

export default function TokenBoard() {
  return (
    <div className={artistStyle.container}>
      <div className={`${artistStyle.wrapper} ${artistStyle.marginBottomDis}`}>
        <TotalTokenCount />
      </div>

      <div className={`${artistStyle.wrapper} ${artistStyle.marginBottomDis}`}>
        <NewMintedTokenCount />
      </div>
      
      <div className={`${artistStyle.wrapper} ${artistStyle.marginBottomDis}`}>
        <TokenHighestSoldPrice />
      </div>

      <div className={`${artistStyle.wrapper} ${artistStyle.marginBottomDis}`}>
        <TokenSales />
      </div>

      <div className={`${artistStyle.wrapper} ${artistStyle.marginBottomDis}`}>
        <TokenBidded />
      </div>

      <div className={`${artistStyle.gridContainer}`}>
        <div className={artistStyle.wrapper}>
          <HighestBiddedTokens />
        </div>
        <div className={artistStyle.wrapper}>
          <MostOfferedToken />
        </div>
      </div>
    </div>
  );
}