import { useState, useEffect } from 'react';
import { startOfToday, subDays, getTime } from 'date-fns';

import TitleData from 'components/TitleData';
import Table from 'components/common/Table';
import LineChart from 'components/common/LineChart';
import DateRangeSelector from 'components/common/DateRangeSelector';
import Spinner from 'components/common/Spinner';

import DataStudioApi from 'utils/apicallers/datastudioapi';
import { formatNumber, formatTime } from 'utils/common';

import { TYPE as CHART_LINE_TYPE } from 'components/common/LineChart/const';
import { DEFAULT_RANGE_TIME } from 'components/OverviewBoard/const';

import style from '../style.module.scss';
import listingStyle from 'components/common/ListingPage/style.module.scss';

const cols = [
  {
    title: 'DATE',
    key: 'date',
    render: date => (
      <div className={listingStyle.id}>
        { formatTime(date, true) }
      </div>
    )
  },
  {
    title: 'USERS',
    key: 'counts',
    align:'right',
    style: {
      fontWeight: 500,
      color: 'var(--color--gray-600)'
    },
    render: content => {
      return (
        <div className={style.tableContent}>
          { formatNumber(content) }
        </div>
      );
    }
  },
];

export default function FirstSection() {
  const [newRegisterData, setNewRegisterData] = useState({
    titleData: {
      title: 'New Registered Users',
      content: 0,
      trend: 0,
    },
    chartData: null,
    tableData: []
  });
  const [apiLoading, setApiLoading] = useState(false);

  useEffect(() => {
    handleSelectRange({
      startDay: DEFAULT_RANGE_TIME.startDay,
      endDay: DEFAULT_RANGE_TIME.endDay
    });
  }, []);
  
  const handleSelectRange = range => {
    setApiLoading(true);
    DataStudioApi
      .getNewRegisteredUsers({start: range.startDay, end: range.endDay})
      .then(res => {
        const { data } = res.data;
        console.log('Userboard fistSection get newRegisteredUserss: ', data);

        const chartData = {
          time: [],
          data: [[]]
        };
        data.detailList.forEach(d => {
          chartData.time.push(d.date);
          chartData.data[0].push(d.counts);
        });
        setNewRegisterData({
          titleData: {
            title: 'New Registered Users',
            content: data.current,
            trend: data.compareToLast ? data.compareToLast*100 : 0,
          },
          chartData,
          tableData: data.detailList
        });
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setApiLoading(false);
      });
  };
  return (
    <>
      <div className={style.firstSection}>
        {newRegisterData && <TitleData data={newRegisterData} />}
        <div className={style.rightSection}>
          <DateRangeSelector handleSelectRange={handleSelectRange} />
        </div>
      </div>
      
      <div className={`${style.sectionRowWrapper} ${apiLoading && style.loading}`}>
        {
          apiLoading 
            ? <Spinner size="medium" />
            : (
              <>
              <div className={`${style.threeForthSection}`}>
                <LineChart
                  height="223px"
                  datasets={[{
                    type: CHART_LINE_TYPE.PRIMARY,
                    fill: true
                  }]}
                  rawDataList={newRegisterData?.chartData?.data}
                  rawTimeList={newRegisterData?.chartData?.time}
                />
              </div>

              <div className={style.verticalSeperator} />

              <div className={`${style.sectionItem} ${style.scrolling} ${style.rightSectionItem}`}>
                <Table
                  height="227px"
                  columns={cols}
                  dataSource={newRegisterData.tableData}
                  loading={apiLoading}
                  emptyContent={
                    <div className={style.empty}>
                      <div className={style.main}>No Data</div>
                    </div> 
                  }
                />
              </div>
              </>
            )
        }
      </div>
    </>
  );
}