import { useEffect, useState } from 'react';

import styles from './styles.module.scss';

export default function Pagination({ data, fetchAction }) {
  /**
   * data would looks like : 
   * data:
      pageNum: 1
      tokens: Array(1) => 0: {
        id: "6d982044-4707-487d-9a2e-de89eefa77fa",
        itemSerial: 8,
        owner: null,
        profileImage: null,
        eventType: "BID",
      }
      length: 1
      totalPages: 1
   */
  const { totalPages, pageNum }= data;
  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(pageNum);
  const [inputVal, setInputVal] = useState(pageNum);
  const handleInput = e => {
    setInputVal(e.currentTarget.value);
  };
  const fetchPage = page => {
    fetchAction(page);
    setCurrentPage(page);
    setInputVal(page);
  };
  const handleRenderMore = () => {
    fetchPage(pages[2] + 1);
  };
  const fetchSiblingPageData = direction => {
    const fetchPage = direction === 'prev'
      ? currentPage - 1
      : currentPage + 1;
    if (fetchPage <= 0 
      || fetchPage > totalPages
      || fetchPage == currentPage) {
      return;
    }
    fetchAction(fetchPage);
    if (direction === 'prev') {
      setInputVal(fetchPage);
    } else {
      setInputVal(fetchPage);
    }
    setCurrentPage(fetchPage);
  };

  const handleGoto = e => {
    if (e.keyCode === 13
        || e.keyCode === 32
        || e.type.toLowerCase() === 'blur') {
      e.currentTarget.blur();
      const gotopageValue = e.currentTarget.value;
      if (isNaN(gotopageValue)
        || gotopageValue > totalPages
        || gotopageValue <= 0) {
        setInputVal(currentPage);
        return;
      }
      if (gotopageValue !== currentPage) {
        fetchPage(parseInt(gotopageValue, 10));
      }
    }
  };

  const checkPages = () => {
    if (totalPages - pageNum >= 2) {
      setPages([pageNum, pageNum + 1, pageNum + 2]);
    } else if (totalPages - pageNum === 1){
      if (pageNum === 1) {
        setPages([pageNum, pageNum + 1]);
      } else {
        setPages([pageNum - 1, pageNum, pageNum + 1]);
      }
    } else if (pageNum === 2) {
        setPages([pageNum - 1, pageNum]);
      } else {
        setPages([pageNum - 2, pageNum - 1, pageNum]);
      }
  };

  useEffect(() => {
    checkPages();
  }, [data]);
  
  const renderMore = () => {
    return (
      <div className={styles.renderMoreContainer}>
        <div className={styles.morePages} onClick={ handleRenderMore }>...</div>
        <div className={styles.lastPage} onClick={ numOnClick }>
          { totalPages }
        </div>
      </div>
    );
  };

  const numOnClick = () => {
    fetchAction(totalPages);
    setCurrentPage(totalPages);
  };
  const handleFetchPN = e => {
    const action = e.currentTarget.dataset.action ?? 'next';
    fetchSiblingPageData(action);
    // switch(action) {
    //   case 'prev':
    //     fetchSiblingPageData('prev');
    //     break;
    //   default: 
    //     fetchSiblingPageData('next');
    //     break;
    // }
  };

  const renderpages = () => {
    if(data.totalPages <= 1) {
      return null;
    }
    return (
      <>
        <div
          data-action="prev"
          className={
            `${styles.prevIcon} ${currentPage === 1 && styles.disabled}`
          }
          onClick={ handleFetchPN }>
            &lt;
        </div>
        {
          pages.map(p => {
            return (
              <span key={ p }
                className={
                  `${styles.paginationIcon}
                    ${p === parseInt(currentPage, 10) && styles.active}`
                }
                onClick={() => fetchPage(p)}>
                { p }
              </span>
            );
          })
        }
        { !pages.includes(totalPages) && renderMore() }
        
        <div
          data-action="next"
          className={
            `${styles.nextIcon}
              ${currentPage === totalPages && styles.disabled}`
          }
          onClick={ handleFetchPN }>
            &gt;
        </div>
        <input
          className={styles.input}
          onChange={ handleInput }
          onKeyDown={ handleGoto }
          onBlur={ handleGoto }
          value={inputVal}
        />
        /
        <div className={styles.total}>
          { totalPages }
        </div>
      </>
    );
  };

  return (
    <div className={styles.container}>
      { renderpages() }
    </div>
  );
}