import styles from './styles.module.scss';

export default function CheckBox({
  type = 'checkbox',
  onChange = () => {},
  value,
  children
}) {
  // Children will be wrapped in label, which can be used to trigger checkbox onChange
  return (
    <label className={styles.container}>
      { children }
      <input type="checkbox" onChange={e => onChange(e)} checked={value} />
      <span
        className={type === 'checkbox' ? styles.checkmark : styles.radiomark}
      />
    </label>
  );
}