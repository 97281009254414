import { useState, useEffect } from 'react';

import TitleData from 'components/TitleData';
import DateRangeSelector from 'components/common/DateRangeSelector';
import Table from 'components/common/Table';
import LineChart from 'components/common/LineChart';
import Spinner from 'components/common/Spinner';
import MarketTab from 'components/TokenBoard/MarketTab';

import { TYPE as LINE_TYPE } from 'components/common/LineChart/const';
import { DEFAULT_RANGE_TIME } from 'components/OverviewBoard/const';

import DataStudioApi from 'utils/apicallers/datastudioapi';
import { formatNumber, formatTime, formatPercentage } from 'utils/common';

import artistStyle from 'components/ArtistBoard/style.module.scss';
import listingStyle from 'components/common/ListingPage/style.module.scss';
import userBoardStyle from 'components/UserBoard/style.module.scss';
import style from '../style.module.scss';

const cols = [
  {
    title: 'DATE',
    key: 'date',
    render: date => (
      <div className={listingStyle.id}>
        { formatTime(date, true) }
      </div>
    )
  },
  {
    title: 'AVERAGE SOLD PRICE (USD)',
    key: 'averageRevenue',
    align:'right',
    style: {
      fontWeight: 500,
      color: 'var(--color--gray-600)'
    },
    render: content => {
      return (
        <div className={artistStyle.tableContent}>
          { formatNumber(content) }
        </div>
      );
    }
  },
];

export default function FirstSection() {
  const [range, setRange] = useState(DEFAULT_RANGE_TIME);
  const [averageTokenData, setAverageTokenData] = useState({
    titleData: {
      title: 'Average Token Sold Price',
      content: 0,
      trend: 0,
    },
    current: {
      totalRevenue: 0,
      tokenSoldCount: 0,
      averageRevenue: 0
    },
    compareToLast: {
      totalRevenue: 0,
      tokenSoldCount: 0,
      averageRevenue: 0
    },
    chartData: null,
    detailList: [],
    tableData: []
  });
  const [apiLoading, setApiLoading] = useState(false);
  const [market, setMarket] = useState(0);

  useEffect(() => {
    setApiLoading(true);
    DataStudioApi
      .getDailyRevenue({
        type: market === 0 ? 'PRIMARY' : 'SECONDARY',
        start: range.startDay,
        end: range.endDay
      })
      .then(res => {
        const { data } = res.data;
        const chartData = {
          time: [],
          data: [[]]
        };
        data.detailList.forEach(d => {
          chartData.time.push(d.date);
          chartData.data[0].push(d.averageRevenue);
        });
        setAverageTokenData({
          titleData: {
            title: 'Average Token Sold Price',
            content: data.current.averageRevenue,
            trend: data.compareToLast.averageRevenue 
            ? formatPercentage(data.compareToLast.averageRevenue)
            : 0,
          },
          chartData,
          tableData: data.detailList
        });
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setApiLoading(false);
      });
  }, [range, market]);

  const handleSelectRange = range => {
    setRange(range);
  };

  const renderMarketTab = () => {
    return (
      <div className={style['market-wrapper']}>
        <MarketTab
          activeTab={market}
          setTab={setMarket}
        />
      </div>
    );
  };

  return (
    <>
      <div className={`${artistStyle.areaChartContent} ${artistStyle.center}`}>
        <TitleData 
          data={averageTokenData}
          component={renderMarketTab()}
        />
        <div className={artistStyle.rightSection}>
          <DateRangeSelector
            handleSelectRange={ handleSelectRange }
          />
        </div>
      </div>

      <div
        className={`${userBoardStyle.sectionRowWrapper} ${apiLoading && userBoardStyle.loading}`}
      >
        {
          apiLoading
            ? <Spinner size="medium" />
            : (
              <>
                <div className={userBoardStyle.threeForthSection}>
                  <LineChart
                    height="223px"
                    datasets={[{
                      type: LINE_TYPE.PRIMARY,
                      fill: true
                    }]}
                    rawTimeList={averageTokenData?.chartData?.time}
                    rawDataList={averageTokenData?.chartData?.data}
                    yTitle="USD"
                  />
                </div>

                <div className={userBoardStyle.verticalSeperator} />

                <div className={`${userBoardStyle.sectionItem} ${
                  userBoardStyle.scrolling} ${userBoardStyle.rightSectionItem}`}>
                  <Table
                    height="227px"
                    columns={cols}
                    dataSource={averageTokenData?.tableData}
                    emptyContent={
                      <div>No Data</div>
                    }
                  />
                </div>
              </>
            )
        }
      </div>
    </>
  );
}