import FirstSection from './FirstSection';
import SecondSection from './SecondSection';
import ThirdLeftSecton from './ThirdLeftSecton';
import ThirdRightSection from './ThirdRightSection';

import style from './style.module.scss';

export default function ArtistBoard() {
  return (
    <div className={style.container}>
      
      <div className={`${style.wrapper} ${style.marginBottomDis}`}>
        <FirstSection />
      </div>

      <div className={`${style.wrapper} ${style.marginTopDis}`}>
        <SecondSection />
      </div>

      <div className={`${style.gridContainer}`}>
        <div className={style.wrapper}>
          <ThirdLeftSecton />
        </div>

        <div className={style.wrapper}>
          <ThirdRightSection />
        </div>
      </div>

    </div>
  );
}