import { STATUS } from './const';

import styles from './styles.module.scss';

export default function StatusView({ status = '' }) {
  const _status = status ? status.toLowerCase() : '';
  
  const renderStatusColor = () => {
    switch (status) {
      case STATUS.ONLINE:
      case STATUS.NORMAL:
      case STATUS.PUBLISHED:
      case STATUS.FETURED:
        return styles.online;
      case STATUS.EXPIRED:
      case STATUS.SUSPEND:
      case STATUS.NOTFEATURED:
        return styles.expired;
      case STATUS.SCHEDULED:
        return styles.scheduled;
      default:
        return '';
    }
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.status} ${renderStatusColor()}`} />
      <span className={styles.captalText}>{_status}</span>
    </div>
  );
}