import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { createMintData } from 'reducers/mintingReducer';

import ProgressBar from 'components/common/ProgressBar';
// import Button from 'components/common/Button';

import MintApi from 'utils/apicallers/mintapi';
import { checkFileSize } from 'utils/common';

import { STATUS } from '../const';
// import { CREATE_MINTING_DATA } from '../const';
// import { HIDE_BACK_ICON } from 'components/universalheader/consts';

import uploadGreyImg from 'assets/imgs/ic-upload-grey.svg';
import uploadWhiteImg from 'assets/imgs/ic-upload-white.svg';
import styles from './style.module.scss';
import { hideBackIcon } from 'reducers/headerReducer';

export default function UploadSection({
  setStatus,
  errorHandle,
  acceptType = ''
}){
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  
  const [showFileOverSize, setShowFileOverSize] = useState(false);
  const [progress, setProgress] = useState(0);
  const [image, setImage] = useState('');

  useEffect(() => {
    dispatch(hideBackIcon());
  }, []);
  
  const id = 'image-input';

  const submitImage = e => {
    setImage(e);
    setUploading(true);
    
    const params = new FormData();
    params.append('content', e);
    MintApi
      .uploadArtwork(params, progressEvent => {
        const percentCompleted = 
          Math.round( (progressEvent.loaded * 100) / progressEvent.total );
        setProgress(percentCompleted);
      })
      .then(res => {
        const {
          itemContent,
          itemContentType,
          itemId,
          itemPreview,
          mintingId
        } = res.data.data;
        dispatch(
          createMintData({
            itemContent,
            itemContentType,
            itemId,
            itemPreview,
            mintingId
          })
        );
        // dispatch({
        //   type: CREATE_MINTING_DATA,
        //   payload: {
        //     itemContent,
        //     itemContentType,
        //     itemId,
        //     itemPreview,
        //     mintingId
        //   }
        // });
        setStatus(STATUS.DESCRIBE);
      })
      .catch(err => {
        console.error('error code:', err.response);
        const errMessage = 
          err?.response?.data?.msg || 'Something went wrong! Please try again.';
        errorHandle(errMessage);
        setProgress(0);
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrop = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (e.dataTransfer?.files.length > 0) {
      const imageEle = document.getElementById(`${id}_output`);
      URL.revokeObjectURL(imageEle.src);
      imageEle.src = URL.createObjectURL(e.dataTransfer.files[0]);
      e.dataTransfer.clearData();
      submitImage(e.dataTransfer.files[0]);
    }
  };

  const uploadImage = () => {
    const imgInputEle = document.getElementById(id);
    imgInputEle.value = null;
    imgInputEle.click();
  };
  const loadFile = e => {
    const imageEle = document.getElementById(`${id}_output`);
    URL.revokeObjectURL(imageEle.src);
    const file = e.target.files[0];
    const checkSizeResult = checkFileSize(file.size, 50);

    if(checkSizeResult){
      imageEle.src = URL.createObjectURL(file);
      submitImage(file);
    }else{
      setShowFileOverSize(true);
    }
  };

  return (
    <div
      className={styles.container}
      onDrop={handleDrop}
      onDragOver={handleDrag}
    >
      { uploading ? 'Uploading your NFT' : 'Upload your NFT' }
      {
        uploading ? (
          <div className={styles.loadingWrapper}>
            <div className={styles.uploadImgWrapper}>
              <div className={styles.innerWrapper}>
                <img src={uploadWhiteImg} />
              </div>
            </div> 
            <ProgressBar progress={progress} />
            <div className={styles.hint}>
              Wait for seconds, uploading soon!
            </div>
            {/* <Button
              type='simple'
              text='Cancel'
              onClick={() => setUploading(false)}
            /> */}
          </div>
        ) : (
          <div className={styles.uploadWrapper}>
            <div className={styles.subtitle}>
              Drag and drop art works here
            </div>
            <div className={styles.text}>
              or&nbsp;
              <span className={styles.highlight} onClick={uploadImage}>
                click
              </span>
              &nbsp;to browse
            </div>
            <img src={uploadGreyImg} />
            <div className={styles.hint}>
              JPEG, PNG, MP4 Videos accepted,<br/>50MB limit
            </div>
            {
              showFileOverSize && 
              <div className={styles.errorMsg}>Ur file is over the size limitation</div>
            }
          </div>
        )
      }
      <img id={`${id}_output`} className={styles.preview} />
      <input
        id={id}
        className={styles['img-input']}
        type="file"
        accept={acceptType || `image/*`}
        onChange={loadFile}
      />
    </div>
  );
}