export const ACTION = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE'
};

export const CTA_TYPE = {
  EVENT: 'Event',
  ARTIST: 'Artist',
  URL: 'Url'
};

export const DEDAULT_DATA = {
  bannerName: '',
  order: 1,
  startTime: 0,
  endTime: 0,
  background: '',
  mainTitle: '',
  subTitle: ['', ''],
  cta: '',
  ctaType: CTA_TYPE.EVENT,
  eventId: '',
  artistId: '',
  url: ''
};

export const SET_HEROBANNER_ID = 'herobanner/SET_HEROBANNER_ID';
export const FETCH_HEROBANNER_RESULT = 'herobanner/FETCH_HEROBANNER_RESULT'; 