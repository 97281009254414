import { useState } from 'react';

import FullScreenPreview from 'components/common/FullScreenPreview';
import Button from 'components/common/Button';

import VisibleIcon from 'assets/imgs/ic-visible.svg';

import styles from './styles.module.scss';

export default function ItemPreviewer({
  url = '',
  name = '',
  isEditDisable = false,
  isDeleteDisable = false,
  mode = 'edit',
  onSelect = () => {},
  onDelete = () => {}
}) {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div className={styles.container}>
        <div className={styles['image-container']}>
          <img src={url} alt="" width="100%" />
        </div>
        <div className={styles.detail}>
          <div className={styles.name}>
            {name}
          </div>
        </div>
        <img
          className={styles.visible}
          src={VisibleIcon}
          width="16px"
          height="16px"
          onClick={() => setVisible(true)}
        />
        {
          visible && (
            <FullScreenPreview
              onClose={() => setVisible(false)}
            >
              <img
                className={styles['img-wrapper']}
                src={url}
                width="100%"
              />
            </FullScreenPreview>
          )
        }
      </div>

      {
        mode === 'edit' && (
          <div className={styles['btn-area']}>
            <Button 
              text="Select"
              type="simple"
              disabled={isEditDisable}
              onClick={onSelect}
            />
            <span
              className={`${styles.delete} ${(isEditDisable || isDeleteDisable) && styles.disable}`}
              onClick={onDelete}
            >
              Delete
            </span>
          </div>
        )
      }
    </>
  );
}