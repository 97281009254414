import { createSlice } from '@reduxjs/toolkit';

import { DEFAULT_DETAIL_DATA } from 'components/event/consts';

const initialState = {
  eventId: '',
  detailData: DEFAULT_DETAIL_DATA
};

export const eventSlice = createSlice({
  name: "eventSlice",
  initialState,
  reducers: {
    setEventId: (state, action) => {
      state.eventId = action.payload;
    },
    fetchEventResult: (state, action) => {
      state.detailData = action.payload;
    },
    clearEventResult: state => {
      state.detailData = DEFAULT_DETAIL_DATA;
    },
  },
});

export const {
  setEventId,
  fetchEventResult,
  clearEventResult
} = eventSlice.actions;
export default eventSlice.reducer;

// const eventReducer = (state = initialState, actions) =>
//   produce(state, draft => {
//     const { type, payload } = actions;
//     switch (type) {
//       case SET_EVENT_ID:
//         draft.eventId = payload;
//         return draft;
//       case FETCH_EVENT_RESULT:
//         console.log('ready to trigger FETCH_EVENT_RESULT: ', payload);
//         draft.detailData = payload;
//         return draft;
//       case CLEAR_EVENT_RESULT:
//         draft.detailData = {...DEFAULT_DETAIL_DATA};
//         return draft;
//       default:
//         return draft;
//     }
//   });

// export default eventReducer;