export const STATUS = {
  UPLOAD: 0,
  DESCRIBE: 1,
  MINT: 2
};

export const DESCRIPTION_MAX_LEN = 500;

export const CREATE_MINTING_DATA = 'minting/CREATE_MINTING_DATA';

export const FETCH_MINTING_ARTIST_RESULT = 'minting/FETCH_MINTING_ARTIST_RESULT';