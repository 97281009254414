import { useState, useEffect, useRef } from 'react';
import { RangeDatePicker } from '@y0c/react-datepicker';
import { format, subDays, getTime } from 'date-fns';
import useClickModal from 'hooks/useClickModal';

import CalendarIcon from 'assets/imgs/ic-calendar-light.svg';

import dayjs from 'dayjs';

import styles from './style.module.scss';

const ninetyDays = 90 * 24 * 60 * 60 * 1000;

export default function DateRangeSelector({
  handleSelectRange,
  showTitle = true,
  alignLeft = false,
  verticalAlign=false
}) {
  const [selected, setSelected] = useState('7');

  const [range, setRange] = useState({
    startDay: dayjs(getTime(subDays(new Date(), 6))).startOf('day').valueOf(),
    endDay: dayjs(getTime(Date.now())).startOf('day').valueOf()
  });

  const [disableStartDay, setDisableStartDay] = useState(
    dayjs().startOf('day').valueOf()
  );

  const [showList, setShowList] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
  const ref = useRef(null);
  const wrapperRef = useRef(null);

  useEffect(() => {
    wrapperRef.current.getElementsByClassName('picker')[0].style.border = "none";
    wrapperRef.current.getElementsByClassName('range-picker-input')[0].style.border = "none";
  }, []);

  useEffect(() => {
    if (showPicker) {
      const calendar = wrapperRef.current.getElementsByClassName('calendar__item')[0];
      calendar.insertAdjacentHTML('afterend', '<div class="calendar__borderWrapper"><div class="calendar__border"/></div>');
    }
  }, [showPicker]);

  useClickModal(ref, () => { 
    const ele = wrapperRef.current.getElementsByClassName('rc-backdrop')[0];
    ele?.click();
    setShowPicker(false);
  }, e => {
    const ele = wrapperRef.current.getElementsByClassName('picker__container')[0];
    if (ele && !ele?.contains(e.target)) {
      setShowPicker(false);
    }
    setShowList(false);
  });

  const handleSelect = e => {
    const { key } = e.currentTarget.dataset;
    setSelected(key);
    const range = {
      startDay: dayjs(getTime(subDays(new Date(), parseInt(key, 10) - 1))).startOf('day').valueOf(),
      endDay: dayjs(getTime(Date.now())).startOf('day').valueOf()
    };
    setRange(range);
    setShowList(false);
    handleSelectRange(range);
  };

  const handleShowDatePicker = () => {
    const ele = wrapperRef.current.getElementsByClassName('picker__trigger')[0];
    ele.click();
    setShowList(false);
    setShowPicker(true);
  };

  const handleRangeDate = (start, end) => {
    setDisableStartDay(start.valueOf());
    if (start && end) {
      const endOnlyDateTimeStamp = dayjs(end.valueOf()).endOf('day').valueOf();

      const range = {
        startDay: start.valueOf(),
        endDay: endOnlyDateTimeStamp
      };
      setRange(range);
      handleSelectRange(range);
      setShowPicker(false);
      setSelected('other');
    }
  };

  const toggleList = () => {
    setShowList(prev => !prev);
  };

  const handleDisableDay = date => {
    const timestamp = date.valueOf();
    return (
      timestamp >= disableStartDay + ninetyDays || // range
      timestamp >= dayjs().startOf('end').valueOf() || // tomorrow
      timestamp < dayjs().startOf('day').valueOf() - ninetyDays + 1 // today - 90days
    );
  };

  const renderWrapper = content => {
    return (
      <div className={`${styles.pickerWrapper} ${!alignLeft && styles.alignRight}`}>
        {content}
      </div>
    );
  };

  return (
    <div ref={wrapperRef} className={`${styles.outer} ${verticalAlign && styles.vertical}`}>
      {
        showTitle && <span className={styles.title}>Show Data for</span>
      }
      <div className={styles.inner}>
        <div ref={ref}>
          <div className={styles.listContainer}>
            <div className={styles.listWrapper} onClick={toggleList}>
              <div className={styles.placeholder}>
                <img src={CalendarIcon} loading="lazy" />
                { format(range.startDay, 'yyyy-MM-dd') } - { format(range.endDay, 'yyyy-MM-dd') }
              </div>

              <div className={`${styles.arrow} ${(showList || showPicker) && styles.up}`}/>
            </div>
          </div>
          {
            showList && (
              <div className={styles.list}>
                <div className={styles.wrapper}>
                  <div
                    data-key={'7'}
                    className={`${styles.item} ${selected === '7' && styles['is-select']}`}
                    onClick={handleSelect}
                  >
                    Last 7 days
                  </div>
                  <div
                    data-key={'30'}
                    className={`${styles.item} ${selected === '30' && styles['is-select']}`}
                    onClick={handleSelect}
                  >
                    Last 30 days
                  </div>
                  <div
                    data-key={'60'}
                    className={`${styles.item} ${selected === '60' && styles['is-select']}`}
                    onClick={handleSelect}
                  >
                    Last 60 days
                  </div>

                  <div
                    className={`${styles.item} ${selected === 'other' && styles['is-select']}`}
                    onClick={handleShowDatePicker}
                  >
                    Select date range
                  </div>
                </div>
              </div>
            )
          }
        </div>
        <RangeDatePicker
          onChange={handleRangeDate}
          disableDay={handleDisableDay}
          initialStartDate={dayjs(range.startDay)}
          initialEndDate={dayjs(range.endDay)}
          wrapper={renderWrapper}
        />
      </div>
    </div>
  );
}