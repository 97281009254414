import DailyRevenuePrimaryMarket from './DailyRevenuePrimaryMarket';
import DailyRevenueSecondaryMarket from './DailyRevenueSecondaryMarket';
import AverageTokenSoldPrice from './AverageTokenSoldPrice';
import FeeEarning from './FeeEarning';

import style from 'components/ArtistBoard/style.module.scss';

export default function RevenueBoard() {
  return (
    <div className={style.container}>
      <div className={`${style.wrapper} ${style.marginBottomDis}`}>
        <DailyRevenuePrimaryMarket />
      </div>

      <div className={`${style.wrapper} ${style.marginBottomDis}`}>
        <DailyRevenueSecondaryMarket />
      </div>

      <div className={`${style.wrapper} ${style.marginBottomDis}`}>
        <AverageTokenSoldPrice />
      </div>

      <div className={style.wrapper}>
        <FeeEarning />
      </div>
    </div>
  );
}