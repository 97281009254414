import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { showBackIcon } from 'reducers/headerReducer';
import { setDetailArtistId } from 'reducers/artistReducer';
import { fetchHeroBanner } from 'reducers/heroBannerReducer';

import Button from 'components/common/Button';
import Spinner from 'components/common/Spinner';
import ErrorText from 'components/common/ErrorText';

import { copyShareLink, formatTime } from 'utils/common';
import heroBannerApi from 'utils/apicallers/herobannerapi';

import { 
  SHOW_HERO_BANNER_UPDATE,
  SHOW_EVENT_DETAIL,
  SHOW_ARTIST_DETAIL
} from 'components/universalheader/consts';
import { SET_EVENT_ID } from 'components/event/consts';
import { CTA_TYPE } from '../const';

import CopyImg from 'assets/imgs/copyclip.svg';

import styles from './styles.module.scss';

export default function Detail() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { herobannerId, detailData } = useSelector(state => {
    return state.HeroBannerReducer;
  });
  const [apiLoading, setApiLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const {
    bannerName,
    order,
    startTime,
    endTime,
    background,
    mainTitle,
    subTitle,
    cta,
    ctaType,
    eventId,
    artistId,
    url
  } = detailData;

  useEffect(() => {
    setApiLoading(true);
    setErrMsg('');
    heroBannerApi.getHeroBannerById(herobannerId)
      .then(res => {
        const { subTitle, ...rest } = res.data.data;
        dispatch(fetchHeroBanner({
          ...rest,
          subTitle: subTitle || [],
        }));
        // dispatch({
        //   type: FETCH_HEROBANNER_RESULT,
        //   payload: {
        //     ...rest,
        //     subTitle: subTitle || []
        //   }
        // });
      })
      .catch(err => {
        console.error(err);
        setErrMsg(err?.response?.data?.msg || 'fetch data fail');
      })
      .finally(() => {
        setApiLoading(false);
      });
  }, [herobannerId]);


  const handleUpdate = () => {
    dispatch(showBackIcon(SHOW_HERO_BANNER_UPDATE));
  };

  const handleCta = () => {
    switch (ctaType) {
      case CTA_TYPE.EVENT:
        history.push('/event');
        dispatch({
          type: SET_EVENT_ID,
          payload: eventId
        });
        dispatch(showBackIcon(SHOW_EVENT_DETAIL));
        break;
      case CTA_TYPE.ARTIST:
        history.push('/artist');
        dispatch(setDetailArtistId(artistId));
        dispatch(showBackIcon(SHOW_ARTIST_DETAIL));
        break;
      case CTA_TYPE.URL:
        window.open(url, '_blank', "noopener");
        break;
    }
  };

  const renderCta = () => {
    switch (ctaType) {
      case CTA_TYPE.EVENT:
        return `#${eventId}`;
      case CTA_TYPE.ARTIST:
        return artistId;
      case CTA_TYPE.URL:
      default:
        return url;
    }
  };

  if (apiLoading) {
    return (
      <Spinner size="medium" />
    );
  }

  if (errMsg) {
    return (
      <ErrorText text={errMsg} align="center" />
    );
  }

  return (
    <div className={styles['detail-container']}>
      <div className={styles['title-container']}>
        <div className={styles.title}>{bannerName}</div>
        <Button 
          text="Update"
          onClick={handleUpdate}
        />
      </div>

      <div className={styles.container}>
        <div className={styles['image-container']}>
          <div className={styles['img-wrapper']}>
            <img
              src={background}
              width="100%"
            />
          </div>
        </div>

        <div className={styles['info-container']}>
          <div className={styles.section}>
            <div className={styles.title}>Banner ID</div>
            <div className={styles.content}>
              #{herobannerId}
              <img
                className={styles.copyclip} 
                src={CopyImg}
                onClick={() => copyShareLink(herobannerId)}
              />
            </div>
          </div>

          <div className={styles.section}>
            <div className={styles.title}>Order</div>
            <div className={styles.content}>{order}</div>
          </div>

          <div className={styles.section}>
            <div className={styles.title}>Start Time</div>
            <div className={styles.content}>{formatTime(startTime)}</div>
          </div>

          <div className={styles.section}>
            <div className={styles.title}>End Time</div>
            <div className={styles.content}>{formatTime(endTime)}</div>
          </div>

          <div className={styles.section}>
            <div className={styles.title}>
              Main Title
            </div>
            <div className={styles.content}>
              {mainTitle}
            </div>
          </div>
          {
            subTitle.map((d, index) => (
              <div key={index} className={styles.section}>
                <div className={styles.title}>
                  {`Sub Title ${index}`}
                </div>
                <div className={styles.content}>{d}</div>
              </div>
            ))
          }

          <div className={styles.section}>
            <div className={styles.title}>
              CTA
            </div>
            <div className={styles.content}>{cta}</div>
          </div>

          <div className={styles.section}>
            <div className={styles.title}>
              CTA Type/ Action
            </div>
            <div className={styles['cta-container']}>
              {ctaType}
              <div className={styles['value']} onClick={handleCta}>
                {renderCta()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}