import { useEffect } from 'react';

import Button from 'components/common/Button';

import CloseIcon from 'assets/imgs/ic-cross.svg';
import styles from './styles.module.scss';

export default function NewModal({
  children,
  onOk = () => {},
  onCancel = () => {},
  okText = 'OK',
  cancelText = 'Cancel',
  visible = false,
  title = '',
  subTitle = '',
  hideCancel = false,
  hideOk = false,
  disableOk = false,
  disableCancel = false,
  showDivider = true,
}) {
  useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [visible]);

  return (
    <div className={`${styles.container} ${!visible && styles.hide}`}>
      <div className={`${styles['content-wrapper']} ${visible && styles.create}`}>
        <div
          className={styles['header-container']}
        >
          <div className={styles.title}>{title}</div>
          <img src={CloseIcon} loading="lazy" onClick={onCancel} />
        </div>
        {
          subTitle && (
            <div className={styles['sub-title']}>
              {subTitle}
            </div>
          )
        }

        <div className={`${styles.divider} ${!showDivider && styles.hide}`} />

        {visible && children}

        <div className={`${styles.divider} ${!showDivider && styles.hide}`} />

        <div className={styles['footer-container']}>
          {
            !hideCancel &&
            <Button
              text={cancelText}
              type="simple"
              onClick={onCancel}
              disabled={disableCancel}
            />
          }
          {
            !hideOk &&
            <Button
              text={okText}
              onClick={onOk}
              disabled={disableOk}
            />
          }
        </div>
      </div>
    </div>
  );
}