import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Prompt } from 'react-router-dom';
import { nanoid } from 'nanoid';

import { showBackIcon } from 'reducers/headerReducer';
import {
  fetchIteDetailResult,
  addItemAttrs,
  updateItemAttributeListAttribute,
  addListAttr,
  clearItemDetailResult,
} from 'reducers/itemReducer';

import useInputVerify, { STATUS } from 'hooks/useInputVerify';

import Description from '../Description';
import Input from 'components/common/BorderInput';
import Modal from 'components/common/Modal';
import SelectList from 'components/common/SelectList';
import AttributesCard from 'components/common/AttributeCard';
import Button from 'components/common/Button';
import Spinner from 'components/common/Spinner';
import ErrorText from 'components/common/ErrorText';

import itemAPI from 'utils/apicallers/itemapi';
import { copyShareLink } from 'utils/common';

import {
  SHOW_ITEM_DETAIL,
  SHOW_ITEM_UPDATE,
  SHOW_ERROR
} from 'components/universalheader/consts';
import { SELECTTYPE } from 'components/common/SelectList/consts';
import { ADD_ITEM_ATTRIBUTES } from 'components/item/consts';

import copyIcon from 'assets/imgs/copyicon.svg';
import addIcon from 'assets/imgs/ic-plus.svg';

import styles from './style.module.scss';
import detailStyles from 'components/event/eventDetail/style.module.scss';

export default function ItemDetail() {
  const dispatch = useDispatch();
  
  const itemId = useSelector(state => {
    return state.ItemReducer.itemId;
  });
  const backDesi = useSelector(state => {
    return state.HeaderReducer.backDesi;
  });
  const singleItemDetail = useSelector(state => {
    return state.ItemReducer.singleItemDetail;
  });
  const addAttributes = useSelector(state => {
    return state.ItemReducer.addAttributes;
  });
  const addListAttribute = useSelector(state => {
    return state.ItemReducer.addListAttribute;
  });
  const yearSelectOption = useSelector(state => {
    return state.ItemReducer.selectYears;
  });

  const [showSelectModal, setShowSelectModal] = useState(false);
  const [showSelectAttrubutes, setShowSelectAttrubutes] = useState(false);
  const [showListAttributes, setShowListAttributes] = useState(false);
  const [showViweAll, setShowViewAll] = useState(false);

  // for edit
  const [itemName, setItemName] = useState('');
  const [originAddAttributes, setOriginAttributes] = useState(addAttributes);
  const [attrListForEdit, setAttrListForEdit] = useState([]);
  const [selectedtArtist, setSelectedArtist] = useState({
    createTime: 0,
    userId: '',
    nickname: '',
    profileImage: ''
  });
  const [description, setDesc] = useState('');
  const [shortDesc, setShortDesc] = useState('');
  const [year, setYear] = useState(new Date().getFullYear());
  const [selectedYear, setSelectedYear] = useState(yearSelectOption);
  const [currentAttrMain, setCurrentAttrMain] = useState([]);
  const [readyToUpdate, setReadyToUpdate] = useState(true);

  const [apiLoading, setApiLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  
  // NOTE: not good implementation. tightly-coupled data structure.
  const [
    nameStatus,
    nameErrMsg,
    nameInputRef,
    setNameStatus,
    resetNameStatus
  ] = useInputVerify();

  const fetchItemApi = () => {
    setApiLoading(true);
    setErrMsg('');
    itemAPI
      .fetchItemDetail(itemId)
      .then(res => {
        const { name, attributes, ...rest } = res.data.data;
        const attrList = attributes || [];
        dispatch(fetchIteDetailResult({
          ...rest,
            name,
            attributes: attrList,
        }));
      setItemName(name);
      setAttrListForEdit(attrList);
      dispatch({
        type: ADD_ITEM_ATTRIBUTES,
        payload: attrList
      });
      setSelectedArtist({
        createTime: 0,
        userId: rest.userId,
        nickname: rest.nickname,
        profileImage: ''
      });
      setSelectedYear(res.data.data.year);
      setDesc(res.data.data.description);
      setShortDesc(res.data.data.shortDescription);
    })
    .catch(err => {
      console.error(err);
      dispatch(showBackIcon(SHOW_ERROR));
      // dispatch({
      //   type: SHOW_BACK_ICON,
      //   payload: SHOW_ERROR
      // });
    })
    .finally(() => {
      setApiLoading(false);
    });
  };

  useEffect(() => {
    dispatch(clearItemDetailResult());
    fetchItemApi();
  }, [itemId]);

  const verifyItemName = () => {
    if (!itemName) {
      setNameStatus(STATUS.INVALID, 'This field is required.');
      return false;
    } else {
      setNameStatus(STATUS.VALID, '');
      return true;
    }
  };

  const handleUpdate = e => { 
    const pass = verifyItemName();
    if (pass && readyToUpdate){
      const params = {
        name: itemName,
        userId: selectedtArtist.userId,
        attributes: attrListForEdit,
        description: description,
        listAttribute: addListAttribute,
        year: year,
        shortDescription: shortDesc,
      };
      setApiLoading(true);
      itemAPI
        .update(itemId, params)
        .then(res => {
          handleCancel();
          fetchItemApi();
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          setApiLoading(false);
        });
    }
  };

  const copyText = e => {
    const text = e.currentTarget.dataset.text;
    copyShareLink(text);
  };
  const goUpdate = e => {
    dispatch(showBackIcon(SHOW_ITEM_UPDATE));
  };
  const handleCancel = () => {
    dispatch(showBackIcon(SHOW_ITEM_DETAIL));
    dispatch(addItemAttrs(singleItemDetail.attributes));
    // dispatch({
    //   type: ADD_ITEM_ATTRIBUTES,
    //   payload: singleItemDetail.attributes
    // });
    setOriginAttributes(singleItemDetail.attributes);
    setAttrListForEdit(singleItemDetail.attributes);
    setSelectedArtist({
      createTime: 0,
      userId: singleItemDetail.userId,
      nickname: singleItemDetail.nickname,
      profileImage: ''
    });
    setDesc(singleItemDetail.description);
    setItemName(singleItemDetail.name);
    resetNameStatus();
  };

  const handleViewAll = e => {
    setShowViewAll(true);
  };
  const finishViewAll = e => {
    setShowViewAll(false);
  };

  const triggerSelectArtistModal = e => {
    setShowSelectModal(true);
  };
  const finishSelectArtist = data => {
    if (data) {
      setSelectedArtist(data);
    }
    setShowSelectModal(false);
  };

  const finishSelectAttributes = data => {
    // Handle cancel action + send previous addAttributes to store
    if (!data) {
      dispatch(dispatch(addItemAttrs(originAddAttributes)));
      // dispatch({
      //   type: ADD_ITEM_ATTRIBUTES,
      //   payload: originAddAttributes
      // });
    } else{
      setOriginAttributes(addAttributes);
      setAttrListForEdit(addAttributes);
    }
    setShowSelectAttrubutes(false);
  };
  const finishSelectListAttribute = dispatchFlag => {
    setShowListAttributes(false);
    if(dispatchFlag){
      dispatch(addListAttr(addListAttribute));
    }
  };
  const addNewAttributes = () => {
    setShowSelectAttrubutes(true);
  };
  const deleteAttribute = attr => {
    let newAddAttrList = [...addAttributes];
    let index = -1;
    if(addListAttribute) {
      if(addListAttribute.main == attr.main && addListAttribute.sub == attr.sub){
        index = 1;
      }
    }
    newAddAttrList = newAddAttrList.filter(
      d => !(d.main === attr.main && d.sub === attr.sub)
    );
    index !== -1 
      ? dispatch(updateItemAttributeListAttribute({
          addAttributes: newAddAttrList,
          addListAttribute: null,
        }))
      : dispatch(updateItemAttributeListAttribute({
          addAttributes: newAddAttrList,
          addListAttribute: addListAttribute,
        }));

    setOriginAttributes(newAddAttrList);
    setAttrListForEdit(newAddAttrList);
  };
  
  const handleYearSelect = selectedOption => {
    const value = selectedOption.value;
    setSelectedYear(selectedOption);
    setYear(value);
  };
  const updateListAttributes = () => {
    let currentAttributesMain = addAttributes.map(a => {
      return a.main;
    });
    if(addAttributes.length != 0){
      currentAttributesMain = addAttributes.map(a => {
        return a.main;
      });
    }
    setCurrentAttrMain(currentAttributesMain);
    setShowListAttributes(true);
  };

  const handleOpenListAttribute = () => {
    setShowListAttributes(false);
    setShowSelectAttrubutes(true);
  };
  const handleShortDesc = (value, overLimit) => {
    setShortDesc(value);
    setReadyToUpdate(!overLimit);
  };
  const handleLongDesc = (value, overLimit) => {
    setDesc(value);
    setReadyToUpdate(!overLimit);
  };
  const findYearDefault = () => {
    let result = null;
    if(selectedYear){
      result = yearSelectOption.find(y => {
        return y.value == selectedYear;
      });
    }
    return result;
  };

  const renderEditContent= () => {
    return (
    <>
      <Prompt message="" when={true} />
      <div className={styles.stickyArea}>
        <div className={styles.itemName}>{itemName}</div>
        <div className={`${styles['button-area']}`}>
          <Button 
            text="Cancel"
            type="simple"
            onClick={handleCancel}
          />
          <Button
            text="Save"
            onClick={handleUpdate}
          />
        </div>
      </div>

      <div className={detailStyles.contentWrapper}>
        <div className={detailStyles.leftPart}>
          <img src={singleItemDetail.preview} className={detailStyles.image} />
        </div>
        <div className={detailStyles.rightPart}>
          <div className={detailStyles.textTitle}>Item ID</div>
          <div className={`${detailStyles.text} ${detailStyles.withIcon}`}>
            #{singleItemDetail.itemId}
          </div>

          <div className={detailStyles.textTitle}>Item Title</div>
          <Input
            value={itemName}
            isError={nameStatus === STATUS.INVALID}
            errMsg={nameErrMsg}
            enableClearBtn={false}
            onInputChange={e => setItemName(e)}
          />
          <div className={detailStyles.textTitle}>
              Artist
          </div>
          <div
            className={
              `${detailStyles.text} ${styles.pointer}`
            }
            onClick={triggerSelectArtistModal}
          >
            {selectedtArtist.nickname} &#9998;
          </div>

          <div className={detailStyles.textTitle}>Contract / Token ID</div>
          <div className={`${detailStyles.text}`}>
            <span
              style={{color: '#3F7EF4', cursor: 'pointer'}}
              onClick={handleViewAll}
            >
              View All
            </span>
          </div>
          
          <div className={detailStyles.textTitle}>Year</div>
          <Select
            value={findYearDefault()}
            defaultValue={findYearDefault()}
            options={yearSelectOption}
            onChange={handleYearSelect}
            isSearchable={true}
          />

          <div className={detailStyles.textTitle}>List Attribute</div>
          <div
            className={
              `${detailStyles.text} ${styles.flexContainer} ${styles.columnFlex}`
            }
          >
            <div
              className={styles.addNewitem} onClick={updateListAttributes}>
              <span className={styles.addNewText}>
                {
                  addListAttribute ? 'Update' : 'Add New'
                }
              </span>
              <img src={addIcon} />
            </div>
            {
              <div className={styles.gridContainer}>
                {
                  <AttributesCard
                    key={nanoid()}
                    attr={addListAttribute}
                  />
                }
              </div>
            }
          </div>

          <div className={detailStyles.textTitle}>Attributes</div>
          <div
            className={
              `${detailStyles.text} ${styles.flexContainer} ${styles.columnFlex}`
            }
          >
            <div
              className={styles.addNewitem} onClick={addNewAttributes}>
              <span className={styles.addNewText}>Add New</span>
              <img src={addIcon} />
            </div>
            <div className={styles.gridContainer}>
              {
                attrListForEdit
                  .map(attr => {
                    return (
                      <AttributesCard
                        key={nanoid()}
                        attr={attr}
                        showEdit={true}
                        deleteHandler={deleteAttribute}
                      />
                    );
                  })
              }
            </div>
          </div>
          
        </div> 
      </div>
      <Description
        title="Short Description"
        desc={shortDesc}
        editMode={true}
        onChange={handleShortDesc}
        limit={200}
        words={shortDesc?.length}
      />
      <Description
        title="Description"
        desc={description} 
        editMode={true}
        onChange={handleLongDesc}
        limit={500}
        words={description?.length}
      />

      {
        showSelectModal &&
        <Modal>
          <SelectList
            title="Select Artist"
            handleCloseModal={finishSelectArtist}
            type={SELECTTYPE.ARTIST}
            specialExclude={true}
          />
        </Modal>
      }
      {
        showViweAll && 
        <Modal>
          <SelectList
            title="Contract / Token ID"
            handleCloseModal={finishViewAll}
            type={SELECTTYPE.CONTRACT}
            itemId={itemId}
          />
        </Modal>
      }
      {
        showSelectAttrubutes && 
          <Modal>
            <SelectList
              title="Add Attributes"
              handleCloseModal={finishSelectAttributes}
              type={SELECTTYPE.ATTRUBUTES}
            />
          </Modal>
      }
      {
        showListAttributes &&
          <Modal>
            <SelectList
              title="Add List Attribute"
              handleCloseModal={finishSelectListAttribute}
              type={SELECTTYPE.LISTATTRIBUTE}
              currentAttrMain={currentAttrMain}
              handleOpenListAttribute={handleOpenListAttribute}
            />
          </Modal>
      }
      </>
      );
  };
  const renderDetail = () => {
    return (
      <>
      <div className={detailStyles.titleSection}>
        <span className={styles.itemName}>{singleItemDetail.name}</span>
        <div className={detailStyles.updateBtn} onClick={goUpdate}>
          Update
        </div>
      </div>

      <div className={detailStyles.contentWrapper}>
        <div className={detailStyles.leftPart}>
          <img src={singleItemDetail.preview} className={detailStyles.image} />
        </div>
        <div className={detailStyles.rightPart}>
          <div className={detailStyles.textTitle}>Item ID</div>
          <div className={`${detailStyles.text} ${detailStyles.withIcon}`}>
            #{singleItemDetail.itemId}
            <img
              data-text={singleItemDetail.itemId}
              src={copyIcon}
              className={detailStyles.copyIcon}
              onClick={copyText}
            />
          </div>

          <div className={detailStyles.textTitle}>Artist</div>
          <div className={detailStyles.text}>{singleItemDetail.nickname}</div>

          <div className={detailStyles.textTitle}>Token Count</div>
          <div className={detailStyles.text}>{singleItemDetail.tokenCounts}</div>

          <div className={detailStyles.textTitle}>Contract / Token ID</div>
          <div 
            className={`${detailStyles.text}`}
          >
            <span
              style={{color: '#3F7EF4', cursor: 'pointer'}}
              onClick={handleViewAll}
            >
              View All
            </span>
          </div>

          <div className={detailStyles.textTitle}>Year</div>
          <div className={detailStyles.text}>
            {
              singleItemDetail.year || 
                <span style={{color: '#FF0000'}}>Not set yet</span>
            }
          </div>

          <div className={detailStyles.textTitle}>Listing Attribute</div>
          <div className={`${detailStyles.text} ${styles.gridContainer}`}>
            <AttributesCard key={nanoid()} attr={singleItemDetail.listAttribute} />
          </div>

          <div className={detailStyles.textTitle}>Attributes</div>
          <div className={`${detailStyles.text} ${styles.gridContainer}`}>
            {
              singleItemDetail.attributes
                .map(att => {
                  return <AttributesCard key={nanoid()} attr={att} />;
                })
            }
          </div>
          
        </div>
      </div>
      {
        showViweAll && 
        <Modal>
          <SelectList
            title="Contract / Token ID"
            handleCloseModal={finishViewAll}
            type={SELECTTYPE.CONTRACT}
            itemId={itemId}
          />
        </Modal>
      }

      <Description title='Short Descripton' desc={singleItemDetail.shortDescription} />
      <Description title='Descripton' desc={singleItemDetail.description} />
      </>
    );
  };

  const renderContent = () => {
    return (
      <div className={detailStyles.detailCotainer}>
        {
          backDesi === SHOW_ITEM_UPDATE
          ? renderEditContent()
          : renderDetail()
        }
      </div>
    );
  };

  // Rnder entry.
  if (apiLoading) {
    return (
      <Spinner size="medium" />
    );
  }
  if (errMsg) {
    return (
      <ErrorText text={errMsg} align="center" />
    );
  }
  return (singleItemDetail && !apiLoading) ? renderContent() : '';
}