import FirstSection from './FirstSection';
import SecondSection from './SecondSection';

import style from './style.module.scss';
export default function UserBoard() {
  return (
    <>
      <div className={style.container}>
        <FirstSection />
      </div>

      <div className={style.container}>
        <SecondSection />
      </div>
      {/* temparary comment out wait KYC process be confirmed */}
      {/* <div className={style.container}>
        <div className={style.sectionRowWrapper}>
          <NewUserVerification />
        </div>
      </div> */}
    </>
  );
}