import { useState, useEffect, useRef } from 'react';
import { Calendar, DatePicker } from '@y0c/react-datepicker';
import { format, addHours, addMinutes, getTime, isValid, parse } from 'date-fns';
import dayjs from 'dayjs';

import Modal from 'components/common/NewModal';
import Input from 'components/common/BorderInput';

import CalendarIcon from 'assets/imgs/ic-calendar.svg';

import style from './style.module.scss';

const DATE_FORMAT = 'yyyy-MM-dd';

export default function DateTimePicker({
  initUnixTime = null,
  visible = false,
  onSave = () => {},
  onClose = () => {},
}) {
  const [selected, setSelected] = useState([
    initUnixTime
      ? dayjs(initUnixTime).startOf('day')
      : dayjs().startOf('day')
  ]);
  const [dateText, setDateText] = useState(
    format(
      initUnixTime
        ? initUnixTime
        : dayjs().startOf('day').valueOf(),
      DATE_FORMAT
    )
  );
  const [dateTextErrMsg, setDateTextErrMsg] = useState('');

  const timeRef = useRef({
    hour: initUnixTime ? dayjs(initUnixTime).hour() : dayjs().startOf('day').hour(),
    min: initUnixTime ? dayjs(initUnixTime).minute() : dayjs().startOf('day').minute(),
  });

  const isValidDate = dateStr => {
    const d = parse(dateStr, DATE_FORMAT, new Date());
    return isValid(d);
  };

  const handleDateInput = dateStr => {
    if (isValidDate(dateStr)) {
      setSelected([dayjs(dateStr)]);
      setDateTextErrMsg('');
    } else {
      setSelected([]);
      setDateTextErrMsg('invalid date format');
      // TODO: show err msg
    }
    setDateText(dateStr);
  };

  const handleSelectDate = date => {
    const timestamp = date.valueOf();
    setSelected([date]);
    setDateText(format(timestamp, DATE_FORMAT));
  };

  const handleTimeChange = time => {
    const hour = time.hour();
    const min = time.minute();

    timeRef.current.hour = hour;
    timeRef.current.min = min;
  };

  const handleSave = () => {
    if (dateTextErrMsg) return;
    const dateUnix = selected[0].startOf('day').valueOf();
    const hour = timeRef.current.hour;
    const min = timeRef.current.min;
  
    let result = addHours(dateUnix, hour);
    result = addMinutes(result, min);
    console.log('result', result, getTime(result));
    onSave(getTime(result));
  };

  const disableDay = date => {
    return date.valueOf() < dayjs().startOf('day').valueOf();
  };

  return (
    <div>
      <Modal
        visible={visible}
        title="Schedule to Publish"
        okText="Save"
        onCancel={onClose}
        onOk={handleSave}
        disableOk={dateTextErrMsg}
        showDivider={false}
      >
        <div className={style.container}>
          <div className={style['input-wrapper']}>
            <Input 
              value={dateText}
              onInputChange={handleDateInput}
              enableClearBtn={false}
              prefix={
                <img src={CalendarIcon} />
              }
              isError={dateTextErrMsg}
              errMsg={dateTextErrMsg}
            />
          </div>

          <div className={style['time-picker']}>
            <DatePicker 
              showTimeOnly={true}
              showDefaultIcon={true}
              initialDate={
                initUnixTime
                  ? dayjs(initUnixTime)
                  : dayjs().startOf('day')
              }
              onChange={handleTimeChange}
            />
          </div>
          
          <Calendar
            className={style.calendar}
            showToday={false}
            selected={selected}
            onChange={handleSelectDate}
            disableDay={disableDay}
          />
        </div>
      </Modal>
    </div>
  );
}
