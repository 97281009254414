import { useState, useEffect } from 'react';

import FullScreenPreview from 'components/common/FullScreenPreview';

import { CONTENT_TYPE } from './const';

import ExpandIcon from 'assets/imgs/expand.svg';
import pdfIcon from 'assets/imgs/pdf.svg';

import styles from './styles.module.scss';

export default function Previewer({
  url = '',
  type = CONTENT_TYPE.PHOTO_MEDIA,
}) {
  const [isExpand, setIsExpand] = useState(false);
  const [size, setSize] = useState({
    height: 0,
    width: 0
  });

  const getResolution = indicator => {
    const newEle = document.createElement(indicator);
    newEle.src = url;
    switch(indicator){
      case 'img': 
        newEle.onload = () => {
          const { naturalWidth, naturalHeight } = newEle;
          setSize({
            height: naturalHeight,
            width: naturalWidth
          });
          newEle.remove();
        };
        break;
      case 'video': 
        newEle.onloadedmetadata = () => {
          const { videoHeight, videoWidth } = newEle;
          setSize({
            height: videoHeight,
            width: videoWidth
          });
          newEle.remove();
        };
        break;
      default: 
        return;
    }
  };
  
  const isPDF = type === CONTENT_TYPE.PDF_MEDIA;
  const isImg = type === CONTENT_TYPE.PHOTO_MEDIA;
  const isVideo = type === CONTENT_TYPE.VEDIO_MEDIA;
  
  useEffect(() => {
    isImg && getResolution('img');
    isVideo && getResolution('video');
  }, [url, type, getResolution]);

  const handleClick = () => {
    if (isPDF) {
      window.open(url, '_blank', '_noopener');
    } else {
      setIsExpand(true);
    }
  };

  const renderPreviewImg = () => {
    if (isPDF || isImg) {
      return (
        <img
          src={isPDF ? pdfIcon : url}
          width="100%"
        />
      );
    } else {
      return (
        <video
          muted
          width="100%"
          height="100%"
        >
          <source src={url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }
  };

  const renderImgContent = () => {
    return (
      <img className={styles['img-wrapper']} src={url} width="100%" />
    );
  };
  const renderVideoContent = () => {
    return (
      <video
        className={styles['img-wrapper']}
        controls
        autoPlay
        muted
      >
        <source src={url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    );
  };
  return (
    <div className={`${styles.container} ${isPDF && styles['is-pdf']}`}>
      <div className={`${styles['image-container']} ${isPDF && styles.pdf}`}>
        {renderPreviewImg()}
      </div>
      <div className={styles.info}>
        { !isPDF && <div className={styles.size}>{`${size.height} x ${size.width}`}</div> }
        <img
          className={styles.expand}
          src={ExpandIcon}
          onClick={handleClick}
        />
      </div>
      {
        isExpand && (
          <FullScreenPreview onClose={() => setIsExpand(false)}>
          { isImg && renderImgContent() }
          { isVideo && renderVideoContent() }
          </FullScreenPreview>
        )
      }
    </div>
  );
}