import { createSlice } from '@reduxjs/toolkit';

// import {
//   CREATE_MINTING_DATA,
//   FETCH_MINTING_ARTIST_RESULT
// } from 'components/Mint/const';

const initialState = {
  artistList: [],
  newMintingData: {
    itemContent: "",
    itemContentType: "",
    itemId: "",
    itemPreview: "",
    mintingId: ""
  }
};

export const mintSlice = createSlice({
  name: "mintSlice",
  initialState,
  reducers: {
    createMintData: (state, action) => {
      state.newMintingData = action.payload;
    },
    fetchMintArtist: (state, action) => {
      state.artistList = action.payload;
    },
  }
});

export const { createMintData, fetchMintArtist } = mintSlice.actions;
export default mintSlice.reducer;

// const mintingReducer = (state = initialState, actions) =>
//   produce(state, draft => {
//     const { type, payload } = actions;
//     switch (type) {
//       case CREATE_MINTING_DATA:
//         draft.newMintingData = payload;
//         return draft;
//       case FETCH_MINTING_ARTIST_RESULT:
//         draft.artistList = payload;
//         return draft;
//       default:
//         return draft;
//     }
//   });

// export default mintingReducer;