import { useState, useEffect } from 'react';
import useTableConfig from 'hooks/useTableConfig';

import GeneralRanking from 'components/GeneralRanking';
import NumberSelector from 'components/common/NumberSelector';
import Table from 'components/common/Table';
import DateRangeSelector from 'components/common/DateRangeSelector';
import Spinner from 'components/common/Spinner';

import { formatNumber } from 'utils/common';
import DataStudioApi from 'utils/apicallers/datastudioapi';

import { DEFAULT_RANGE_TIME } from 'components/OverviewBoard/const';

import style from '../style.module.scss';

const feeEarningCols = [
  {
    title: 'ARTIST NAME',
    key: 'name',
    render: name => name
  },
  {
    title: 'ROTALTY FEE (USD)',
    key: 'content',
    align: 'right',
    render: price => formatNumber(price)
  }
];
const TABLE_ACTION = {
  APPEND_DATA: 0,
  NEW_DATA: 1
};

export default function SecondSection() {
  const [feeEarningData, setFeeEarningData] = useState([]);
  const [pageSize, setPageSize] = useState(50);
  const [timeRange, setTimeRange] = useState(DEFAULT_RANGE_TIME);
  const [apiLoading, setApiLoading] = useState(false);
  const {
    pageRef,
    setPageData
  } = useTableConfig();

  const fetchData = tableAction => {
    tableAction === TABLE_ACTION.NEW_DATA && setApiLoading(true);

    DataStudioApi
      .getFeeEarning({
        start: timeRange.startDay,
        end: timeRange.endDay,
        pageSize: pageSize,
        page: pageRef.current.current
      })
      .then(res => {
        const { list, navigateLastPage } = res.data.data;
        const artistList = list.map(d => {
          return {
            name: d.artistNickname,
            img: d.artistImageThumbnail,
            content: d.fee,
            trend: d.compareToLast
          };
        });
        if (tableAction === TABLE_ACTION.APPEND_DATA) {
          setFeeEarningData(prev => [...prev, ...artistList]);
        } else {
          setFeeEarningData(artistList);
        }
        
        setPageData({
          total: navigateLastPage
        });
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setApiLoading(false);
      });
  };

  useEffect(() => {
    setPageData({
      current: 1
    });
    fetchData(TABLE_ACTION.NEW_DATA);
  }, [pageSize, timeRange]);

  const handleNextPageAction = () => {
    const { current, total } = pageRef.current;
    if (current < total) {
      setPageData({
        current: current + 1
      });
      fetchData(TABLE_ACTION.APPEND_DATA);
    }
  };

  const renderDataRanking = () => {
    return (
      <div className={style.rankingWrapper}>
        <div className={style.innerRankingWrapper}>
          <GeneralRanking
            data={feeEarningData.slice(0, 5)}
            noPadding={true}
          />
        </div>
        {
          feeEarningData.length > 5 &&
            <>
              <div className={style.verticalSeperator} />
              <div className={style.innerRankingWrapper}>
                <GeneralRanking
                  startIndex={6}
                  data={
                    feeEarningData.length > 10
                      ? feeEarningData?.slice(5, 10)
                      : feeEarningData?.slice(5)
                  }
                  noPadding={true}
                />
              </div>
              <div className={style.verticalSeperator} />
            </>
        }
      </div>
    );
  };
  return (
    <>
    <div className={style.areaChartContent}>
      <span className={style.title}>
        Artist Royalty Fee Earning
      </span>
      <div className={style.rightSection}>
        <NumberSelector onSelectChange={setPageSize} />
        <DateRangeSelector
          handleSelectRange={setTimeRange}
        />
      </div>
    </div>

    <div className={style.secondRow}>
      {
        apiLoading
          ? <Spinner size="medium" center={true} />
          : (
            <>
              <div className={style.rankingContainer}>
                <div className={style.rankingTitle}>Top Earning Artists</div>
                {
                  feeEarningData.length !== 0
                    ? renderDataRanking()
                    : <div className={style.empty}>
                        No Data
                      </div>
                }
              </div>

              <div className={style.verticalSeperator} />

              <div className={`${style.tableWrapper} ${style.secondSection}`}>
                <Table
                  height="381px"
                  columns={feeEarningCols}
                  dataSource={feeEarningData}
                  loading={apiLoading}
                  emptyContent={
                    <div>
                      <span>No Data</span>
                    </div>
                  }
                  fetchAction={handleNextPageAction} 
                />
              </div>
            </>
          )
      }
    </div>
    </>
  );
}