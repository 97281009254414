import { useState, useEffect } from 'react';
import useTableConfig from 'hooks/useTableConfig';

import Table from 'components/common/Table';
import DateRangeSelector from 'components/common/DateRangeSelector';
import MarketTab from '../MarketTab';
import NumberSelector from 'components/common/NumberSelector';

import { formatNumber } from 'utils/common';
import { shortenText } from 'utils';
import DataStudioApi from 'utils/apicallers/datastudioapi';

import { DEFAULT_RANGE_TIME } from 'components/OverviewBoard/const';

import style from 'components/ArtistBoard/style.module.scss';

const tokenSalesCols = [
  {
    title: 'TOKEN ID',
    key: 'tokenId',
    render: id => shortenText(id, 7)
  },
  {
    title: 'ITEM NAME',
    key: 'itemName'
  },
  {
    title: 'PRICE',
    key: 'price',
    align: 'right',
    render: price => formatNumber(price)
  },
];

const TABLE_ACTION = {
  APPEND_DATA: 0,
  NEW_DATA: 1
};

export default function HighestBiddedTokens() {
  const [apiLoading, setApiLoading] = useState(false);
  const [biddedTokenData, setBiddedTokenData] = useState([]);
  const [market, setMarket] = useState(0);
  const [timeRange, setTimeRange] = useState(DEFAULT_RANGE_TIME);
  const [pageSize, setPageSize] = useState(50);
  const {
    pageRef,
    setPageData
  } = useTableConfig();

  const fetchData = tableAction => {
    if (tableAction === TABLE_ACTION.NEW_DATA) {
      setApiLoading(true);
    }
    
    DataStudioApi
      .getHighestBiddedTokens({
        type: market === 0 ? 'PRIMARY' : 'SECONDARY',
        start: timeRange.startDay,
        end: timeRange.endDay,
        page: pageRef.current.current,
        pageSize: pageSize
      })
      .then(res => {
        const { list, navigateLastPage } = res.data.data;

        if (tableAction === TABLE_ACTION.APPEND_DATA) {
          setBiddedTokenData(prev => [...prev, ...list]);
        } else {
          setBiddedTokenData(list);
        }
        
        setPageData({
          total: navigateLastPage
        });
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setApiLoading(false);
      });
  };

  useEffect(() => {
    setPageData({
      current: 1
    });
    fetchData(TABLE_ACTION.NEW_DATA);
  }, [market, timeRange, pageSize]);

  const handleNextPageAction = () => {
    const { current, total } = pageRef.current;
    if (current < total) {
      setPageData({
        current: current + 1
      });
      fetchData(TABLE_ACTION.APPEND_DATA);
    }
  };

  return (
    <>
      <div className={`${style.title} ${style.containTab}`}>
        Highest Bidded Tokens
        <MarketTab
          activeTab={market}
          setTab={setMarket}
          noMargin={true}
        />
      </div>

      <div className={`${style.selectDateSection} ${style['flex-row']}`}>
        <DateRangeSelector
          alignLeft={true}
          handleSelectRange={setTimeRange}
          verticalAlign={true}
        />
        <NumberSelector verticalAlign={true} onSelectChange={setPageSize} />
      </div>
      
      <div className={`${style.marginBottomDis} ${style.tableWrapper}`}>
        <Table
          height="381px"
          columns={tokenSalesCols}
          dataSource={biddedTokenData}
          loading={apiLoading}
          emptyContent={
            <div>No Data</div>
          }
          fetchAction={handleNextPageAction}
        />
      </div>
    </>
  );
}