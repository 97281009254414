import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { showBackIcon } from 'reducers/headerReducer';
import { fetchEventResult } from 'reducers/eventReducer';

import Button from 'components/common/Button';
import ErrorText from 'components/common/ErrorText';
import Spinner from 'components/common/Spinner';

import { copyShareLink, formatTime } from 'utils/common';
import EventApi from 'utils/apicallers/eventapi';

import { STATUS_ENUM } from '../consts';
import { 
  SHOW_EVENT_UPDATE
} from 'components/universalheader/consts';

import demo from 'assets/imgs/eventDemo.png';
import copyIcon from 'assets/imgs/copyicon.svg';

import styles from './style.module.scss';
import '@y0c/react-datepicker/assets/styles/calendar.scss';

const EVENT_TYPE = {
  BID: 'BID',
  SALE: 'SALE'
};

export default function EventDetail() {
  const dispatch = useDispatch();
  
  const eventId = useSelector(state => {
    return state.EventReducer.eventId;
  });
  const eventDetail = useSelector(state => {
    return state.EventReducer.detailData;
  });
  
  const [errMsg, setErrMsg] = useState('');
  const [isApiLoading, setIsApiloading] = useState(false);

  const fetchApi = () => {
    setIsApiloading(true);
    setErrMsg('');
    
    EventApi
      .getEventsById(eventId)
      .then(res => {
        dispatch(fetchEventResult(res.data.data));
        // dispatch({
        //   type: FETCH_EVENT_RESULT,
        //   payload: res.data.data,
        // });
      })
      .catch(err => {
        setErrMsg(err?.response?.data?.msg || 'fetch data fail');
      })
      .finally(() => {
        setIsApiloading(false);
      });
  };
  useEffect(() => {
    if(eventId){
      fetchApi();
    }
  }, [eventId]);

  const copyText = e => {
    const text = e.currentTarget.dataset.text;
    copyShareLink(text);
  };

  const goUpdate = e => {
    dispatch(showBackIcon(SHOW_EVENT_UPDATE));
    // dispatch({
    //   type: SHOW_BACK_ICON,
    //   payload: SHOW_EVENT_UPDATE
    // });
  };

  const renderDetail = () => {
    return (
      <>
      <div className={styles.titleSection}>
        <span className={styles.title}>{eventDetail.title}</span>
        {
          eventDetail.status !== STATUS_ENUM.EXPIRED && (
            <Button
              text="Update"
              onClick={goUpdate}
            />
          )
        }
      </div>

      <div className={styles.contentWrapper}>
        <div className={styles.leftPart}>
          <img src={demo} className={styles.image} />
        </div>
        <div className={styles.rightPart}>
          <div className={styles.textTitle}>Event ID</div>
          <div className={`${styles.text} ${styles.withIcon}`}>
            #{eventDetail.eventId}
            <img
              data-text={eventDetail.eventId}
              src={copyIcon}
              className={styles.copyIcon}
              onClick={copyText}
            />
          </div>

          <div className={styles.textTitle}>Start Time</div>
          <div className={styles.text}>
            {formatTime(eventDetail.scheduleStartTime)}
          </div>

          <div className={styles.textTitle}>End Time</div>
          <div className={styles.text}>
            {formatTime(eventDetail.scheduleEndTime)}
          </div>
          
          <div className={styles.textTitle}>Event Item</div>
          <div className={styles.evnetItemWrapper}>
            <img src={eventDetail.itemContent} className={styles.itemImg} />
            <div className={styles.itemDes}>
              <div className={styles.text}>{eventDetail.itemName}</div>
            </div>
          </div>

          <div className={styles.textTitle}>Model</div>
          <div className={styles.text}>
            {eventDetail.eventType === EVENT_TYPE.BID ? 'Auction' : 'Sale'}
          </div>
          
          {
            eventDetail.eventType === EVENT_TYPE.BID
            ? (
                <>
                  <div className={styles.textTitle}>Start Bidding Price</div>
                  <div className={styles.text}>
                    <div className={styles.price}>
                      {eventDetail.lowerBound}
                      <div className={styles.currency}>{eventDetail.currency}</div>
                    </div>
                  </div>

                  <div className={styles.textTitle}>Bid Increment Price</div>
                  <div className={styles.text}>
                  <div className={styles.price}>
                      {eventDetail.minBidDiff}
                      <div className={styles.currency}>{eventDetail.currency}</div>
                    </div>
                  </div>
                </>
              )
            : (
                <>
                  <div className={styles.textTitle}>Sale Price</div>
                  <div className={styles.text}>
                    <div className={styles.price}>
                      {eventDetail.price}
                      <div className={styles.currency}>
                        {eventDetail.currency}
                      </div>
                    </div>
                  </div>
                </>
              )
          }

          {
            eventDetail.reservePrice && (
              <>
                <div className={styles.textTitle}>Reserve Price</div>
                <div className={styles.text}>
                  <div className={styles.price}>
                    {eventDetail.reservePrice}
                    <div className={styles.currency}>
                      {eventDetail.currency}
                    </div>
                  </div>
                </div>
              </>
            )
          }
            
        </div>
      </div>
      </>
    );
  };
  if (errMsg) {
    return (
      <ErrorText text={errMsg} align="center" />
    );
  }

  if (isApiLoading) {
    return (
      <Spinner size="medium" />
    );
  }

  return (
    <div className={styles.detailCotainer}>
      { renderDetail() }
    </div>
  );
}