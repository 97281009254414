import api from '../api';

const EventApi = {};

const prefix = '/events';

EventApi.getEvents = (params) => {
  return api.fire({
    url: prefix,
    method: 'GET',
    params
  });
};

EventApi.getEventsById = (eventId) => {
  return api.fire({
    url: `${prefix}/${eventId}`,
    method: 'GET'
  });
};

EventApi.create = (params) => {
  return api.fire({
    url: `${prefix}`,
    method: 'POST',
    data: JSON.stringify(params)
  });
};

EventApi.update = (eventId, params) => {
  return api.fire({
    url: `${prefix}/${eventId}`,
    method: 'PATCH',
    data: JSON.stringify(params)
  });
};

EventApi.delete = id => {
  return api.fire({
    url: `${prefix}/${id}`,
    method: 'DELETE'
  });
};

export default EventApi;