import { createSlice } from '@reduxjs/toolkit';

import { SESSION } from 'consts/storageConsts';

const initialState = {
  isOnline: localStorage.getItem(SESSION) || false,
};

export const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    setUserOnlineStatus: (state, action) => {
      state.isOnline = action.payload;
    },
  }
});

export const { setUserOnlineStatus } = userSlice.actions;
export default userSlice.reducer;
