import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { showBackIcon } from 'reducers/headerReducer';
import { setHeroBannerId } from 'reducers/heroBannerReducer';

import useTableConfig from 'hooks/useTableConfig';

import Banner from './Banner';
import Detail from './Detail';
import ListingPage from 'components/common/ListingPage';
import StatusView from 'components/common/StatusView';
import DeleteModal from 'components/common/DeleteModal';
import ErrorView from 'components/common/ErrorView';

import { shortenText } from 'utils';
import { formatTime } from 'utils/common';
import heroBannerApi from 'utils/apicallers/herobannerapi';

import { SORT } from '../common/Table/const';
import {
  SHOW_HERO_BANNER_ADD,
  SHOW_HERO_BANNER_UPDATE,
  SHOW_HERO_BANNER_DETAIL,
  SHOW_ERROR
} from 'components/universalheader/consts';
import { API_TYPE } from 'components/common/DeleteModal/const';
import { ACTION } from './const';

import HeroIcon from 'assets/imgs/heroimge.svg';
import ViewIcon from 'assets/imgs/view.svg';
import ViewBlackIcon from 'assets/imgs/view-black.svg';
import EditIcon from 'assets/imgs/edit.svg';
import EditBlackIcon from 'assets/imgs/edit-black.svg';
import TrashIcon from 'assets/imgs/trash.svg';
import TrashBlackIcon from 'assets/imgs/trash-black.svg';

import listingStyle from 'components/common/ListingPage/style.module.scss';
import styles from './styles.module.scss';

export default function HeroBanner() {
  const [tableData, setTableData] = useState([]);
  const [delVisible, setDelVisible] = useState(false);
  const [id, setId] = useState('');
  const [apiLoading, setApiLoading] = useState(false);
  const { backDesi } = useSelector(state => state.HeaderReducer);
  const {
    currentPage,
    pagData,
    sort,
    pageRef,
    setSortData,
    setPageData
  } = useTableConfig();
  const dispatch = useDispatch();

  const fetchApi = () => {
    const sorting = [SORT.NONE, SORT.DESC].includes(sort.type) ? 'DESC' : 'ASC';
    let orderBy = 'update_time';
    switch (sort.key) {
      case 'bannerName':
        orderBy = 'banner_name';
        break;
      case 'startTime':
        orderBy = 'start_time';
        break;
      case 'endTime':
        orderBy = 'end_time';
        break;
      case 'updateTime':
        orderBy = 'update_time';
        break;
      case 'order': 
        orderBy = 'order';
        break;
      case 'status':
        orderBy = 'status';
        break;
      default:
        break;
    }

    setApiLoading(true);
    heroBannerApi
      .getHeroBanner({
        orderBy: orderBy,
        page: currentPage,
        pageSize: pageRef.current.size,
        sorting
      })
      .then(res => {
        const { list, navigateLastPage } = res.data.data;
        setTableData(list);
        setPageData({
          total: navigateLastPage
        });
      })
      .catch(err => {
        console.log('err', err);
      })
      .finally(() => {
        setApiLoading(false);
      });
  };

  useEffect(() => {
    const destList = [
      SHOW_HERO_BANNER_ADD,
      SHOW_HERO_BANNER_UPDATE,
      SHOW_HERO_BANNER_DETAIL
    ];
    !destList.includes(backDesi) && fetchApi();

  }, [sort, currentPage, backDesi]);

  const gotoDetail = id => {
    dispatch(setHeroBannerId(id));
    dispatch(showBackIcon(SHOW_HERO_BANNER_DETAIL));
  };
  const gotoEdit = id => {
    dispatch(setHeroBannerId(id));
    dispatch(showBackIcon(SHOW_HERO_BANNER_UPDATE));
  };

  const handlePagFetchAction = (page) => {
    setPageData({
      current: page
    });
  };
  const handleSort = (key, type) => {
    setSortData({
      key,
      type
    });
  };

  const handleDelete = id => {
    setId(id);
    setDelVisible(true);
  };
  const handleDeleteComplete = isSuccess => {
    setDelVisible(false);
    isSuccess && fetchApi();
  };
  const handleAddNew = () => {
    dispatch(showBackIcon(SHOW_HERO_BANNER_ADD));
  };
  
  const cols = [
    {
      title: 'BANNER ID',
      key: 'bannerId',
      fixed: 'left',
      render: text => (
        <div className={listingStyle.id}>{shortenText(text, 4)}</div>
      )
    },
    {
      title: 'BANNER NAME',
      key: 'bannerName',
      fixed: 'left',
      sorter: true
    },
    {
      title: 'ORDER',
      key: 'order',
      align: 'center',
      sorter: true
    },
    {
      title: 'START TIME',
      key: 'startTime',
      align: 'left',
      sorter: true,
      render: text => formatTime(text)
    },
    {
      title: 'END TIME',
      key: 'endTime',
      align: 'left',
      sorter: true,
      render: text => formatTime(text)
    },
    {
      title: 'LAST UPDATE TIME',
      key: 'updateTime',
      align: 'right',
      width: '200px',
      sorter: true,
      render: text => formatTime(text)
    },
    {
      title: 'STATUS',
      key: 'status',
      align: 'center',
      sorter: true,
      render: text => <StatusView status={text.toUpperCase()} />
    },
    {
      title: 'VIEW',
      key: 'view',
      align: 'center',
      width: '5%',
      clickable: true,
      render: (text, row, _, { isHover }) => {
        return (
          <img 
            src={isHover ? ViewBlackIcon : ViewIcon}
            onClick={() => gotoDetail(row.bannerId)}
          />
        );
      }
    },
    {
      title: 'EDIT',
      key: 'edit',
      align: 'center',
      width: '5%',
      clickable: true,
      render: (text, row, _, { isHover }) => {
        return (
          <img 
            src={isHover ? EditBlackIcon : EditIcon}
            onClick={() => gotoEdit(row.bannerId)}
          />
        );
      }
    },
    {
      title: 'DELETE',
      key: 'delete',
      align: 'center',
      width: '5%',
      clickable: true,
      render: (text, row, _, { isHover }) => {
        return (
          <img 
            src={isHover ? TrashBlackIcon : TrashIcon}
            onClick={() => handleDelete(row.bannerId)}
          />
        );
      }
    }
  ];

  const emptyView = () => {
    return (
      <div className={styles.empty}>
        <div className={styles.main}>You don&apos;t have any banners yet.</div>
        <p className={styles.sub}>Click &apos;Add new&apos; to start</p>
      </div>
    );
  };
  const renderContent = () => {
    switch (backDesi) {
      case SHOW_HERO_BANNER_ADD:
        return (
          <Banner action={ACTION.CREATE} />
        );
      case SHOW_HERO_BANNER_UPDATE:
        return (
          <Banner action={ACTION.UPDATE} />
        );
      case SHOW_HERO_BANNER_DETAIL:
        return (
          <Detail />
        );
      case SHOW_ERROR:
        return (
          <ErrorView />
        );
      default:
        return (
          <>
            <DeleteModal
              id={id}
              visible={delVisible}
              apiType={API_TYPE.HEROBANNER}
              title="Delete Banner"
              errTitle="Not Able to Delete Main Attribute"
              content="Are you sure to delete this banner?"
              errContent="This banner can not be deleted."
              onComplete={handleDeleteComplete}
            />
            <ListingPage
              title={{
                text: 'Hero Banner',
                icon: HeroIcon
              }}
              cols={cols}
              data={tableData}
              pagData={pagData}
              sort={sort}
              addNewFn={handleAddNew}
              pagFetchAction={handlePagFetchAction}
              onSortClick={handleSort}
              emptyView={emptyView()}
              loading={apiLoading}
            />
          </>
      );
    }
  };
  return (
    <div>
      { renderContent() }
    </div>
  );
}