import { useState, useEffect } from 'react';

import TitleCard from 'components/TitleCard';
import TitleData from 'components/TitleData';
import GeneralRanking from 'components/GeneralRanking';
import DailyRevenuSoldCount from 'components/DailyRevenuSoldCount';
import LineChart from 'components/common/LineChart';
import DateRangeSelector from 'components/common/DateRangeSelector';
import Spinner from 'components/common/Spinner';

import { TYPE as CHART_TYPE } from 'components/common/LineChart/const';
import { DEFAULT_RANGE_TIME } from 'components/OverviewBoard/const';
import DataStudioApi from 'utils/apicallers/datastudioapi';
import { formatPercentage } from 'utils/common';

import style from './style.module.scss';

export default function OverviewBoard() {
  const [apiLoading, setApiLoading] = useState(false);
  const [timeRange, setTimeRange] = useState(DEFAULT_RANGE_TIME);
  const [overViewData, setOverViewData] = useState({
    current: {
      loggedInUsers: 0,
      newArtists: 0,
      soldTokens: 0,
      revenue: 0,
      fee: 0
    },
    compareToLast: {
      loggedInUsers: 0,
      newArtists: 0,
      soldTokens: 0,
      revenue: 0,
      fee: 0
    }
  });
  const [newRegisteredUserData, setNewRegisteredUserData] = useState({
    current: 0,
    compareToLast: 0,
    detailList: [],
    chartData: null
  });
  const [loggedInUserData, setLoggedInUserData] = useState({
    current: 0,
    compareToLast: 0,
    detailList: [],
    chartData: null
  });
  const [dailyRevenue, setDailyRevenue] = useState({
    primary: {
      compareToLast: {
        averageRevenue: 0,
        tokenSoldCount: 0,
        totalRevenue: 0
      },
      current: {
        averageRevenue: 0,
        tokenSoldCount: 0,
        totalRevenue: 0
      },
      // detailList: []
      chartData: null
    },
    secondary: {
      compareToLast: {
        averageRevenue: 0,
        tokenSoldCount: 0,
        totalRevenue: 0
      },
      current: {
        averageRevenue: 0,
        tokenSoldCount: 0,
        totalRevenue: 0
      },
      // detailList: [],
      chartData: null
    }
  });

  const [feeEarningList, setFeeEarningList] = useState([]);
  const [tokenHighestSoldData, setTokenHighestSoldData] = useState([
    {
      category: 'Primary Market',
      data: []
    },
    {
      category: 'Secondary Market',
      data: []
    }
  ]);
  const [highestBiddedTokensData, setHighestBiddedTokensData] = useState([
    {
      category: 'Primary Market',
      data: []
    },
    {
      category: 'Secondary Market',
      data: []
    }
  ]);
  const [mostOfferedList, setMostOfferedList] = useState([]);

  const titleCards = [
    {
      title: 'Logged in Users',
      content: overViewData.current.loggedInUsers,
      trend: formatPercentage(overViewData.compareToLast.loggedInUsers)
    },
    {
      title: 'Artists',
      content: overViewData.current.newArtists,
      trend: formatPercentage(overViewData.compareToLast.newArtists)
    },
    {
      title: 'Tokens Sold',
      content: overViewData.current.soldTokens,
      trend: formatPercentage(overViewData.compareToLast.soldTokens)
    },
    {
      title: 'Revenue (USD)',
      content: overViewData.current.revenue,
      trend: formatPercentage(overViewData.compareToLast.revenue)
    },
    {
      title: 'Fee (USD)',
      content: overViewData.current.fee,
      trend: formatPercentage(overViewData.compareToLast.fee)
    },
  ];

  useEffect(() => {
    setApiLoading(true);
    DataStudioApi
      .getDashboard({
        start: timeRange.startDay,
        end: timeRange.endDay
      })
      .then(res => {
        const { 
          overview, 
          newRegisteredUsers, 
          loggedInUsers, 
          dailyRevenue, 
          feeEarning,
          highestSoldPrice,
          highestBiddedToken,
          mostOfferedToken
        } = res.data.data;

        setOverViewData(overview);

        let newRegisterData = {
          time: [],
          data: [[]]
        };

        newRegisteredUsers.detailList.forEach(d => {
          newRegisterData.time.push(d.date);
          newRegisterData.data[0].push(d.counts);
        });

        setNewRegisteredUserData({
          ...newRegisteredUsers,
          chartData: newRegisterData
        });

        let loggedInData = {
          time: [],
          data: [[]]
        };

        loggedInUsers.detailList.forEach(d => {
          loggedInData.time.push(d.date);
          loggedInData.data[0].push(d.counts);
        });

        setLoggedInUserData({
          ...loggedInUsers,
          chartData: loggedInData
        });

        const primaryDailyRevenueRawTime = [];
        const secondaryDailyRevenueRawTime = [];
        const primaryDailyRevenueRawData = [[], []];
        const secondaryDailyRevenueRawData = [[], []];

        dailyRevenue.primary.detailList.forEach(d => {
          primaryDailyRevenueRawTime.push(d.date);
          primaryDailyRevenueRawData[0].push(d.totalRevenue);
          primaryDailyRevenueRawData[1].push(d.tokenSoldCount);
        });
        dailyRevenue.secondary.detailList.forEach(d => {
          secondaryDailyRevenueRawTime.push(d.date);
          secondaryDailyRevenueRawData[0].push(d.totalRevenue);
          secondaryDailyRevenueRawData[1].push(d.tokenSoldCount);
        });
        setDailyRevenue({
          primary: {
            ...dailyRevenue.primary,
            chartData: {
              time: primaryDailyRevenueRawTime,
              data: primaryDailyRevenueRawData
            }
          },
          secondary: {
            ...dailyRevenue.secondary,
            chartData: {
              time: secondaryDailyRevenueRawTime,
              data: secondaryDailyRevenueRawData
            }
          }
        });

        const feeEarningData = feeEarning.map(d => ({
          img: d.artistImageThumbnail,
          name: d.artistNickname,
          content: d.fee,
          trend: d.compareToLast
        }));
        setFeeEarningList(feeEarningData);

        const primaryHighestSoldList = highestSoldPrice.primary.map(d => ({
          img: d.itemPreview,
          name: d.itemName,
          content: d.price,  
        }));
        const secondaryHighestSoldList = highestSoldPrice.secondary.map(d => ({
          img: d.itemPreview,
          name: d.itemName,
          content: d.price,  
        }));

        setTokenHighestSoldData([
          {
            category: 'Primary Market',
            data: primaryHighestSoldList
          },
          {
            category: 'Secondary Market',
            data: secondaryHighestSoldList
          }
        ]);

        const primaryHighestBiddedList = highestBiddedToken.primary.map(d => ({
          img: d.itemPreview,
          name: d.itemName,
          content: d.price,
        }));
        const secondaryHighestBiddedList = highestBiddedToken.secondary.map(d => ({
          img: d.itemPreview,
          name: d.itemName,
          content: d.price,
        }));
        
        setHighestBiddedTokensData([
          {
            category: 'Primary Market',
            data: primaryHighestBiddedList
          },
          {
            category: 'Secondary Market',
            data: secondaryHighestBiddedList
          }
        ]);
        
        const mostOfferedData = mostOfferedToken.map(d => ({
          img: d.itemPreview,
          name: d.itemName,
          content: d.count,
        }));
        setMostOfferedList(mostOfferedData);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => setApiLoading(false));
  }, [timeRange]);

  const handleSelectRange = range => {
    setTimeRange(range);
  };

  return (
    <div className={style.container}>
      <div className={style.titleWrapper}>
        <span className={style.leftSection}>OverView</span>
        <div className={style.rightSection}>
          <DateRangeSelector handleSelectRange={handleSelectRange}/>
        </div>
      </div>

      <div className={style.titleCrads}>
        {
          titleCards.map(card => {
            return (
              <TitleCard key={card.title} data={card} />
            );
          })
        }
      </div>

      <div className={style.areaChartContent}>
        <div className={style.wrapper}>
          <TitleData
            data={{
              titleData: {
                title: 'New Register User',
                content: newRegisteredUserData.current,
                trend: newRegisteredUserData.compareToLast
                        ? formatPercentage(newRegisteredUserData.compareToLast)
                        : 0
              }
            }}
          />
          {
            apiLoading
              ? <Spinner size="medium" />
              : <LineChart
                  rawTimeList={newRegisteredUserData?.chartData?.time}
                  rawDataList={newRegisteredUserData?.chartData?.data}
                  datasets={[{
                    label: '',
                    fill: true,
                    type: CHART_TYPE.PRIMARY
                  }]}
                />
          }
        </div>
        <div className={style.wrapper}>
          <TitleData
            data={{
              titleData: {
                title: 'Logged In User',
                content: loggedInUserData.current,
                trend: loggedInUserData.compareToLast
                        ? formatPercentage(loggedInUserData.compareToLast)
                        : 0
              }
            }} 
          />
          {
            apiLoading
              ? <Spinner size="medium" />
              : <LineChart
                  rawTimeList={loggedInUserData?.chartData?.time}
                  rawDataList={loggedInUserData?.chartData?.data}
                  datasets={[{
                    label: '',
                    fill: true,
                    type: CHART_TYPE.THIRD
                  }]}
                />
          }
        </div>
      </div>

      <div className={style.areaChatwithListing}>
        <div className={`${style.contentWrapper} ${style.twoColumn}`}>
          <DailyRevenuSoldCount data={dailyRevenue} loading={apiLoading} />
        </div>
        <div className={style.oneColumn}>
          <GeneralRanking
            data={feeEarningList}
            titleText="Artist Royalty Fee Earning"
            smallPadding={true}
            loading={apiLoading}
          />
        </div>
      </div>

      <div className={style.areaChatwithListing}>
        <GeneralRanking
          data={tokenHighestSoldData}
          titleText="Token Highest Sold Price"
          smallPadding={true}
          loading={apiLoading}
        />
        <GeneralRanking
          data={highestBiddedTokensData}
          titleText="Highest Bidded Tokens"
          smallPadding={true}
          loading={apiLoading}
        />
        <GeneralRanking
          data={mostOfferedList}
          titleText="Most Offered Tokens"
          smallPadding={true}
          loading={apiLoading}
        />
      </div>
    </div>
  );
}