import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { hideBackIcon, showBackIcon } from 'reducers/headerReducer';
import { fetchArtistResult } from 'reducers/artistReducer';

import FeatureButton from '../FeatureButton';
import Button from "components/common/Button";
import Previewer from 'components/common/Previewer';
import Collapse from "components/common/Collapse";
import DropdownButton from 'components/common/DropdownButton';
import DeleteModal from 'components/common/DeleteModal';
import ErrorText from 'components/common/ErrorText';
import Spinner from 'components/common/Spinner';

import { copyShareLink } from 'utils/common';
import ArtistApi from "utils/apicallers/artistapi";

import { delList } from "../const";
import { SHOW_ARTIST_UPDATE } from "components/universalheader/consts";
import { CONTENT_TYPE } from 'components/common/Previewer/const';
import { API_TYPE } from 'components/common/DeleteModal/const';

import CopyIcon from "../../../assets/imgs/copyclip.svg";

import styles from "./styles.module.scss";

export default function Detail() {
  const [delVisible, setDelVisible] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  
  const dispatch = useDispatch();
  const { detailArtistId, detailData } = useSelector((state) => {
    return state.ArtistReducer;
  });

  const {
    nickname,
    itemCount,
    featured,
    imageBackground,
    imageThumbnail,
    country,
    linkDiscord,
    linkTwitter,
    linkIg,
    description,
    slogan,
    cv,
    video,
    videoDescription,
    userId,
    cvTitle,
  } = detailData;

  const fetchApi = () => {
    setApiLoading(true);
    setErrMsg('');
    ArtistApi
      .getArtistById(detailArtistId)
      .then((res) => {
        dispatch(fetchArtistResult(res.data.data));
      })
      .catch((err) => {
        console.log("err", err);
        setErrMsg(err?.response?.data?.msg || 'fetch data fail');
      })
      .finally(() => {
        setApiLoading(false);
      });
  };
  useEffect(() => {
    fetchApi();
  }, [detailArtistId]);

  const openPreviewSite = () => {
    const clientDomain = process.env.REACT_APP_CLIENT_DOMAIN;
    //NOTE: Internal link never added noreffer as it affects SEO
    window.open(
      `${clientDomain}/profile/${detailArtistId}`,
      "_blank",
      "noopener"
    );
  };

  const handleCopy = () => {
    copyShareLink(detailArtistId);
  };

  const handleUpdate = () => {
    dispatch(showBackIcon(SHOW_ARTIST_UPDATE));
  };

  const handleDeleteComplete = isSuccess => {
    setDelVisible(false);
    isSuccess && dispatch(hideBackIcon());
    // dispatch({
    //   type: HIDE_BACK_ICON
    // });
  };

  if (apiLoading) {
    return (
      <Spinner size="medium" />
    );
  }

  if (errMsg) {
    return (
      <ErrorText text={errMsg} align="center" />
    );
  }

  const renderNotSetMessage = () => {
    return (
      <div className={styles['not-set']}>not set yet</div>
    );
  };

  return (
    <div className={styles.container}>
      <DeleteModal 
        id={detailArtistId}
        visible={delVisible}
        apiType={API_TYPE.ARTIST}
        title="Delete Artist"
        errTitle="Not Able to Delete Artist"
        content="Are you sure to delete this artist?"
        errContent="This artist can not be deleted because he/ she has items."
        onComplete={handleDeleteComplete}
      />

      <div className={styles["name-container"]}>
        <div className={styles.name}>{nickname}</div>

        <div className={styles["btn-container"]}>
          <Button text="Preview" type="simple" onClick={openPreviewSite} />
          <Button text="Update" type="normal" onClick={handleUpdate} />
          <DropdownButton
            list={delList}
            onSelect={() => setDelVisible(true)}
          />
        </div>
      </div>

      <div className={styles["profile-container"]}>
        <div className={styles["artist-image-wrapper"]}>
          {
            imageThumbnail
             ? <div className={styles["image-wrapper"]}>
                <img
                  src={imageThumbnail} width="100%"
                  loading="lazy"
                />
              </div>
             : renderNotSetMessage()
          }
        </div>

        <div className={styles["profile-wrapper"]}>
          <div className={styles.section}>
            <div className={styles.title}>Artist ID</div>
            <div className={styles.content}>
              <div>@{detailArtistId}</div>
              <img
                className={styles.copy}
                src={CopyIcon}
                onClick={handleCopy}
              />
            </div>
          </div>
          
          <div className={styles.section}>
            <div className={styles.title}>Featured</div>
            <div className={styles.content}>
              <FeatureButton isFeatured={featured} />
            </div>
          </div>

          <div className={styles.section}>
            <div className={styles.title}>Hero Image</div>
            {
              imageBackground
                ? <Previewer
                    url={imageBackground}
                  />
                : renderNotSetMessage()
            }
          </div>

          <div className={styles.section}>
            <div className={styles.title}>Country</div>
            {
              country
                ? <div className={styles.content}>{country}</div>
                : renderNotSetMessage()
            }
          </div>

          <div className={styles.section}>
            <div className={styles.title}>Twitter</div>
            {
              linkTwitter
                ? <a
                    className={`${styles.content} ${styles.link}`}
                    href={linkTwitter}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {linkTwitter}
                  </a>
                : renderNotSetMessage()
            }
          </div>

          <div className={styles.section}>
            <div className={styles.title}>Instagram</div>
            {
              linkIg
                ? <a
                    className={`${styles.content} ${styles.link}`}
                    href={linkIg}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {linkIg}
                  </a>
                : renderNotSetMessage()
            }
          </div>

          <div className={styles.section}>
            <div className={styles.title}>Discord</div>
            {
              linkDiscord
                ? <a
                    className={`${styles.content} ${styles.link}`}
                    href={linkDiscord}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {linkDiscord}
                  </a>
                : renderNotSetMessage()
            }
          </div>
        </div>
      </div>

      <Collapse header="Artist Description" defaultCollapse={false}>
        <div className={styles.section}>
          <div className={styles.title}>Short Description</div>
          {
            slogan
             ? <p className={`${styles.content} ${styles.bio}`}>{slogan}</p>
             : renderNotSetMessage()
          }
        </div>

        <div className={styles.section}>
          <div className={styles.title}>Long Bio</div>
          {
            description
             ? <pre className={`${styles.content} ${styles.bio}`}>
                {description}
              </pre>
             : <div className={styles['not-set']}>not set yet</div>
          }
        </div>

        <div className={styles.section}>
          <div className={styles.title}>
            { cvTitle || 'CV'}
          </div>
          {
            cv ? (
              <Previewer
                url={cv}
                type={CONTENT_TYPE.PDF_MEDIA}
              />
            )
            : renderNotSetMessage()
          }
        </div>
      </Collapse>

      <Collapse header="Video Promotion" defaultCollapse={false}>
        <div className={styles.section}>
          <div className={styles.title}>Video</div>
          {
            video ? (
              <Previewer
                url={video}
                type={CONTENT_TYPE.VEDIO_MEDIA}
                thumbnailImgUrl={video}
              />
            )
            : renderNotSetMessage()
          }
        </div>
        <div className={styles.section}>
          <div className={styles.title}>Video description</div>
          {
            videoDescription
            ? <p className={`${styles.content} ${styles.bio}`}>
                {videoDescription}
              </p>
            : renderNotSetMessage()
          }
        </div>
      </Collapse>
    </div>
  );
}
