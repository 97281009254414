import { formatNumber, checkArrow } from 'utils/common';

import style from './style.module.scss';

export default function ThreeLabel({ data, fontSize='normal' }) {
  const renderTrend = d => {
    return (
      <div className={ `${style.trendNum} ${
        (!d.trend || d.trend === 0)
          ? style.noTrend
          : d.trend > 0
            ? style.greenColor
            : style.redColor
        }`}
      >
        <span>
          { checkArrow(d.trend) }
        </span>
        <span className={style.rankTrend}>
          { d.trend !== 0 && `${Math.abs(d.trend)}%`}
        </span>
      </div>
    );
  };
  return (
    <div className={style.container}>
      {
        data.map(d => {
          return (
            <div key={d.name} className={style[fontSize]}>
              <div className={style.title}>
                {
                  d.color && 
                    <div
                      className={`${style.titleColorSquare} ${style[d.color]}`}
                    />
                }
                <span>{d.name}</span>
              </div>
              <div className={style.content}>
                <span className={`${style.totalNum}`}>
                  { formatNumber(d.content) }
                </span>
                { renderTrend(d) }
              </div>
            </div>
          );
        })
      }
    </div>
  );
}
