import { useState, useEffect } from 'react';
import useTableConfig from 'hooks/useTableConfig';

import Table from 'components/common/Table';
import DateRangeSelector from 'components/common/DateRangeSelector';

import { formatNumber } from 'utils/common';
import DataStudioApi from 'utils/apicallers/datastudioapi';

import { DEFAULT_RANGE_TIME } from 'components/OverviewBoard/const';
import { default as SECTION_NAME } from '../const';

import style from '../style.module.scss';

const tokenSalesCols = [
  {
    title: 'ARTIST NAME',
    key: 'artistNickname'
  },
  {
    title: 'TOKEN SALES',
    key: 'sales',
    align: 'right',
    render: token => formatNumber(token)
  },
  {
    title: 'REVENUE (USD)',
    key: 'revenue',
    align: 'right',
    render: price => formatNumber(price)
  }
];

const TABLE_ACTION = {
  APPEND_DATA: 0,
  NEW_DATA: 1
};

export default function ThirdLeftSecton() {
  const [apiLoading, setApiLoading] = useState(false);
  const [tokenPSaleData, setTokenPSaleData] = useState([]);
  const [timeRange, setTimeRange] = useState(DEFAULT_RANGE_TIME);
  const {
    pageRef,
    setPageData
  } = useTableConfig();

  useEffect(() => {
    setPageData({
      current: 1
    });
    fetchData(TABLE_ACTION.NEW_DATA);
  }, [timeRange]);

  const fetchData = tableAction => {
    tableAction === TABLE_ACTION.NEW_DATA && setApiLoading(true);
    DataStudioApi
      .getArtistTokenSales({
        start: timeRange.startDay,
        end: timeRange.endDay,
        page: pageRef.current.current
      })
      .then(res => {
        const { list, navigateLastPage } = res.data.data;
        // console.log('artistBoard thirdLeftSection get artist tokenn primary market: ', list);

        if (tableAction === TABLE_ACTION.APPEND_DATA) {
          setTokenPSaleData(prev => [...prev, ...list]);
        } else {
          setTokenPSaleData(list);
        }

        setPageData({
          total: navigateLastPage
        });
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setApiLoading(false);
      });
  };

  const handleNextPageAction = () => {
    const { current, total } = pageRef.current;
    if (current < total) {
      setPageData({
        current: current + 1
      });
      fetchData(TABLE_ACTION.APPEND_DATA);
    }
  };

  return (
    <>
      <div className={style.title}>
        <span>Artist Token Sales - Primary Market</span>
      </div>

      <div className={style.selectDateSection}>
        <DateRangeSelector
          alignLeft={true}
          handleSelectRange={setTimeRange}
          verticalAlign={true}
        />
      </div>
      
      <div className={`${style.marginBottomDis} ${style.tableWrapper}`}>
        <Table
          height="381px"
          columns={tokenSalesCols}
          dataSource={tokenPSaleData}
          loading={apiLoading}
          emptyContent={
            <div>No Data</div>
          }
          fetchAction={handleNextPageAction}
        />
      </div>
    </>
  );
}