import Modal from 'components/common/NewModal';

import styles from './styles.module.scss';

export default function ConfirmPrompt({
  visible = false,
  message = '',
  onOk = () => {},
  onCancel = () => {}
}) {
  return (
    <Modal
      visible={visible}
      title="Navigation Popup"
      onOk={onOk}
      onCancel={onCancel}
      okText="Leave this page"
      cancelText="Stay on this page"
    >
      {
        message
        ? <p className={styles.content}>
            {message}
          </p>
        : <p className={styles.content}>
            Your content has not been saved yet!<br/>
            Are you sure you want to leave this page?
          </p>
      }
    </Modal>
  );
}