export const SET_ARTICLE_ID = 'editorial/SET_ARTICLE_ID';

export const FETCH_ARTICLE_RESULT = 'editorial/FETCH_ARTICLE_RESULT';

export const ARTICLE_IMAGE_SIZE_IN_MB = 5;
export const ARTICLE_IMAGE_DIMENSION = {
  height: 584,
  width: 584
};

export const REQUIRED_MSG = 'This field is reqiured.';

export const FIELD_KEY = {
  ID: 'id',
  STATUS: 'status',
  ARTICLE_TITLE: 'title',
  AUTHOR_NAME: 'author',
  PUBLISH_TIME: 'publishTime',
  ARTICLE_IMAGE: 'image',
  IMAGE_DESCRIPTION: 'imageDescription',
  EXCERPT: 'excerpt',
  CONTENT: 'context',
  ARTISTS: 'artists',
  TAG: 'tag'
};

export const MODE = {
  NEW: 0,
  UPDATE: 1
};

export const SUBMIT_TYPE = {
  PUBLISH_NOW: 'PUBLISH_NOW',
  SCHEDULE_TO_PUBLISH: 'SCHEDULE_TO_PUBLISH',
  SAVE_DRAFT: 'SAVE_DRAFT',
  UPDATE_DRAFT: 'UPDATE_DRAFT',
  DELETE_DRAFT: 'DELETE_DRAFT',
  REVERT_TO_DRAFT: 'REVERT_TO_DRAFT',
  CHANGE_PUBLISH_SCHEDULE: 'CHANGE_PUBLISH_SCHEDULE',
  DELETE_ARTICLE: 'DELETE_ARTICLE',
  // for Update button
  UPDATE_SCHEDULED: 'UPDATE_SCHEDULED',
  UPDATE_PUBLISH: 'UPDATE_PUBLISH'
};

export const SUBMIT_TYPE_TEXT = {
  [SUBMIT_TYPE.PUBLISH_NOW]: 'Publish Now',
  [SUBMIT_TYPE.SCHEDULE_TO_PUBLISH]: 'Schedule to Publish',
  [SUBMIT_TYPE.SAVE_DRAFT]: 'Save Draft',
  [SUBMIT_TYPE.UPDATE_DRAFT]: 'Update Draft',
  [SUBMIT_TYPE.DELETE_DRAFT]: 'Delete Draft',
  [SUBMIT_TYPE.REVERT_TO_DRAFT]: 'Revert to Draft',
  [SUBMIT_TYPE.CHANGE_PUBLISH_SCHEDULE]: 'Change Publish Schedule',
  [SUBMIT_TYPE.DELETE_ARTICLE]: 'Delete Article'
};

export const ARTICLE_STATUS = {
  DRAFT: 'DRAFT',
  SCHEDULED: 'SCHEDULED',
  PUBLISHED: 'PUBLISHED'
};

export const DROPDOWN_BUTTON_LIST = {
  [MODE.NEW]: {
    [ARTICLE_STATUS.DRAFT]: [
      SUBMIT_TYPE.PUBLISH_NOW,
      SUBMIT_TYPE.SCHEDULE_TO_PUBLISH
    ]
  },
  [MODE.UPDATE]: {
    [ARTICLE_STATUS.DRAFT]: [
      SUBMIT_TYPE.PUBLISH_NOW,
      SUBMIT_TYPE.SCHEDULE_TO_PUBLISH
    ],
    [ARTICLE_STATUS.SCHEDULED]: [
      SUBMIT_TYPE.REVERT_TO_DRAFT,
      SUBMIT_TYPE.CHANGE_PUBLISH_SCHEDULE,
      SUBMIT_TYPE.DELETE_ARTICLE
    ],
    [ARTICLE_STATUS.PUBLISHED]: [
      SUBMIT_TYPE.REVERT_TO_DRAFT,
      SUBMIT_TYPE.CHANGE_PUBLISH_SCHEDULE,
      SUBMIT_TYPE.DELETE_ARTICLE
    ]
  }
};

export const OTHERS_BUTTON_LIST = {
  [MODE.NEW]: {
    [ARTICLE_STATUS.DRAFT]: [
      SUBMIT_TYPE.SAVE_DRAFT
    ]
  },
  [MODE.UPDATE]: {
    [ARTICLE_STATUS.DRAFT]: [
      SUBMIT_TYPE.UPDATE_DRAFT,
      SUBMIT_TYPE.DELETE_DRAFT
    ],
    [ARTICLE_STATUS.SCHEDULED]: [
      SUBMIT_TYPE.REVERT_TO_DRAFT,
      SUBMIT_TYPE.CHANGE_PUBLISH_SCHEDULE,
      SUBMIT_TYPE.DELETE_ARTICLE
    ],
    [ARTICLE_STATUS.PUBLISHED]: [
      SUBMIT_TYPE.REVERT_TO_DRAFT,
      SUBMIT_TYPE.DELETE_ARTICLE
    ]
  }
};

export const TAG_CHAR_LIMIT = 20;
