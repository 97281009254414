import { createSlice } from '@reduxjs/toolkit';

import {
  ACTION,
  // SET_ACTION,
  // SET_ITEM_DETAIL_ID,
  // FETCH_ITEM_DETAIL_RESULT,
  // UPTDATE_ITEM_ATTRIBUTES,
  // REMOVE_LIST_ADD_ATTRIBUTE,
  // ADD_ITEM_ATTRIBUTES,
  // UPDATE_ITEM_ATTRIBUTES_LISTATTRIBUTES,
  // ADD_LIST_ATTRIBUTE,
  // FETCH_ITEM_LIST_RESULT,
  // CLEAR_ITEM_DETAIL_RESULT,
  // FETCH_TOKEN_LIST_RESULT,
  // CLEAR_TOKEN_LIST_RESULT,
  // SELECT_YEARS,
  // CLEAN_ADDLISTATTRIBUTE
} 
from 'components/item/consts';

// const singleItemDetail = {
//   id: 'at34213789',
//     itemTitle: 'Beautiful Eyes in the forest',
//     authorNickName: 'Samuel R. Harvey',
//     tokenCount: 100,
//     attributes: [
//       {main: 'Visual', sub: 'Branding', checked: false},
//       {main: 'Visual', sub: 'Poster', checked: false},
//       {main: 'Color', sub: 'Pink', checked: false},
//       {main: 'Material', sub: 'Digital', checked: false},
//     ],
//     desc: "Pearl\'s Girl is the title of several 1996 single releases and a song by Underworld, from their album Second Toughest in the Infants; where the song is titled \"Pearls Girl\". The single peaked on the UK Singles Chart at number 22. The song title comes from the name of a racing greyhound\. I spent a few nights in Hamburg,\" recalled Karl Hyde, \"and it actually came from a night on the Rioja down by the docks. I'm actually shouting, Rioja, Rioja… I'd come from this really great club on the Reeperba.line. He is a member of the America modern architect committee, and he is also a freelance photographer taking photos while traveling."
// };

const DEFAULT_DETAIL_DATA = {
  itemId: '',
  name: '',
  nickname: '',
  tokenCounts: 0,
  preview: '',
  description: '',
  attributes: []
};

const initialState = {
  itemList: [],
  itemId: '',
  contractTokenList: [],
  contractTokenCount: 0,
  action: ACTION.DETAIL,
  singleItemDetail: {
    ...DEFAULT_DETAIL_DATA
  },
  addAttributes: [],
  addListAttributePrev: null,
  addListAttribute: null,
  selectYears: [],
};
export const itemSlice = createSlice({
  name: "itemSlice",
  initialState,
  reducers: {
    selectYears: (state, action) => {
      state.selectYears = action.payload;
    },
    // setAction: (state, action) => {
    //   state.action = action.paylod;
    // },
    setItemDetailId: (state, action) => {
      state.itemId = action.payload;
    },
    fetchIteDetailResult: (state, action) => {
      state.singleItemDetail = action.payload;
      state.addListAttribute = action.payload.listAttribute;
    },
    fetchItemListResult: (state, action) => {
      state.itemList = action.payload; 
    },
    addItemAttrs: (state, action) => {
      state.addAttributes = action.payload;
    },
    updateItemAttributeListAttribute: (state, action) => {
      state.addAttributes = action.payload.addAttributes;
      state.addListAttribute = action.payload.addListAttribute;
    },
    addListAttr: (state, action) => {
      state.addListAttributePrev = state.addListAttribute;
      state.addListAttribute = action.payload;
    },
    removeListAddAttr: (state, action) => {
      state.addListAttributePrev = state.addListAttribute;
      state.addListAttribute = action.payload;
    },
    updateItemAttr: (state, action) => {
      const mainAttribute = action.payload.attribute.main;
      const subAttribute = action.payload.attribute.sub;

      const attributesArray = state.singleItemDetail.attributes;
      const attributesArrayLength = attributesArray.length;
      for(let i = 0 ; i < attributesArrayLength ; i++){
        if(attributesArray[i].main === mainAttribute 
            && attributesArray[i].sub === subAttribute){
              attributesArray[i].checked = action.payload.checked;
        }
      }
    },
    fetchTokenListResult: (state, action) => {
      state.contractTokenList = action.payload.list;
      state.contractTokenCount = action.payload.total;
    },
    clearTokenListResult: (state) => {
      state.contractTokenList = [];
      state.contractTokenCount = 0;
    },
    clearItemDetailResult: (state) => {
      state.singleItemDetail = {...DEFAULT_DETAIL_DATA};
    },
    cleanAddListAttr: (state) => {
      state.addListAttribute = state.addListAttributePrev;
      state.addListAttributePrev = null;
    },
  }
});

export const { 
  selectYears,
  setItemDetailId,
  fetchIteDetailResult,
  fetchItemListResult,
  addItemAttrs,
  updateItemAttributeListAttribute,
  addListAttr,
  removeListAddAttr,
  updateItemAttr,
  fetchTokenListResult,
  clearTokenListResult,
  clearItemDetailResult,
  cleanAddListAttr,
} = itemSlice.actions;
export default itemSlice.reducer;

// const itemReducer = (state = initialState, actions) =>
//   produce(state, draft => {
//     const { type, payload } = actions;
//     switch (type) {
//       case SELECT_YEARS: 
//         draft.selectYears = payload;
//         return draft;
//       case SET_ACTION:
//         draft.action = payload;
//         return draft;
//       case SET_ITEM_DETAIL_ID:
//         draft.itemId = payload;
//         return draft;
//       case FETCH_ITEM_DETAIL_RESULT:
//         draft.singleItemDetail = payload;
//         draft.addListAttribute = payload.listAttribute;
//         return draft;
//       case FETCH_ITEM_LIST_RESULT:
//         draft.itemList = payload;
//         return draft;
//       case ADD_ITEM_ATTRIBUTES: 
//         draft.addAttributes = payload;
//         return draft;
//       case UPDATE_ITEM_ATTRIBUTES_LISTATTRIBUTES: 
//         draft.addAttributes = payload.addAttributes;
//         draft.addListAttribute = payload.addListAttribute;
//         return draft;
//       case ADD_LIST_ATTRIBUTE:
//         draft.addListAttributePrev = draft.addListAttribute;
//         draft.addListAttribute = payload;
//         return draft;
//       case REMOVE_LIST_ADD_ATTRIBUTE:
//         draft.addListAttributePrev = draft.addListAttribute;
//         draft.addListAttribute = payload;
//         return draft;
//       case UPTDATE_ITEM_ATTRIBUTES: {
//         const mainAttribute = payload.attribute.main;
//         const subAttribute = payload.attribute.sub;

//         const attributesArray = draft.singleItemDetail.attributes;
//         const attributesArrayLength = attributesArray.length;
//         for(let i = 0 ; i < attributesArrayLength ; i++){
//           if(attributesArray[i].main === mainAttribute 
//               && attributesArray[i].sub === subAttribute){
//                 attributesArray[i].checked = payload.checked;
//           }
//         }
//         return draft;
//       }
//       case FETCH_TOKEN_LIST_RESULT:
//         draft.contractTokenList = payload.list;
//         draft.contractTokenCount = payload.total;
//         return draft;
//       case CLEAR_TOKEN_LIST_RESULT:
//         draft.contractTokenList = [];
//         draft.contractTokenCount = 0;
//         return draft;
//       case CLEAR_ITEM_DETAIL_RESULT:
//         draft.singleItemDetail = {...DEFAULT_DETAIL_DATA};
//         return draft;
//       case CLEAN_ADDLISTATTRIBUTE:
//         draft.addListAttribute = draft.addListAttributePrev;
//         draft.addListAttributePrev = null;
//         return draft;
//       default:
//         return draft;
//     }
//   });

// export default itemReducer;