import { useEffect, useRef } from 'react';
import { format, isSunday } from 'date-fns';

import Chart from 'chart.js/auto';

import { TYPE, BORDER_COLOR, BG_COLOR } from './const';

import style from './style.module.scss';

const yConfig = {
  type: 'linear',
  ticks: {
    maxTicksLimit: 30,
    precision: 0
  },
};

/**
 * 
 * @param {Object[]} datasets - data config
 * @param {Number[]} rawTimeList - timestamp array
 * @param {Number[][]} rawDataList - raw data array
 */

export default function LineChart({
    height = "290px",
    yTitle = 'USERS',
    y1Title = '',
    datasets = [],
    rawTimeList = [],
    rawDataList = []
  }) {
  const chartRef = useRef();
  const chartInstRef = useRef();

  useEffect(() => {
    chartInstRef.current = new Chart(chartRef.current, {
      type: 'line',
      data: {
        labels: [],
        datasets: datasets.map(d => ({
          label: d.label,
          fill: d.fill,
          backgroundColor: BG_COLOR[d.type],
          borderColor: BORDER_COLOR[d.type],
          data: [],
          ...(d?.yAxisID && { yAxisID: d?.yAxisID })
        }))
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        // interaction: {
        //   mode: 'index',
        //   intersect: false,
        // },
        plugins: {
          title: {
            display: false
          },
          legend: {
            display: false
          },
          tooltip: {
            displayColors: false,
            titleFont: {
              size: 12,
              weight: 400,
              lineHeight: 1.4
            },
            bodyFont: {
              size: 14,
              weight: 700,
              lineHeight: 1.4
            },
            padding: {
              left: 12,
              right: 12,
              top: 8,
              bottom: 8
            },
            callbacks: {
              title: (itemList) => {
                const item = itemList[0];
                return format(Number(item.label), 'yyyy-MM-dd');
              }
            }
          }
        },
        scales: {
          x: {
            ticks: {
              maxTicksLimit: 90
            },
            // font: {
            //   style: 'normal'
            // },
            grid: {
              display: false
            }
          },
          y: {
            ...yConfig,
            beginAtZero: true,
            position: 'left'
          },
          y1: {
            ...yConfig,
            display: datasets.some(d => d.yAxisID === 'y1'),
            position: 'right',
          }
        }
      }
    });

    return () => chartInstRef.current.destroy();
  }, []);

  useEffect(() => {
    if (!chartInstRef.current)
      return;

    const chartInstance = chartInstRef.current;

    chartInstance.data.labels = rawTimeList;
    chartInstance.options.scales.x.ticks.callback = (val, index) => {
      if (rawTimeList[val]) {
        const day = rawTimeList[val];
        if (
          (rawTimeList.length > 7 && isSunday(day)) ||
          rawTimeList.length <= 7
          ) {
          return format(day, 'MM/dd');
        } else {
          return '';
        }
      }
      return '';
    };

    chartInstance.data.datasets.forEach((dataset, index) => {
      dataset.data = rawDataList[index];
    });

    chartInstance.update();
  }, [rawTimeList, rawDataList]);

  return (
    <div className={style.container}>
      <div>
        <div className={style['title-wrapper']}>
          <span>{yTitle}</span>
          <span>{y1Title}</span>
        </div>
        <div className={style['canvas-wrapper']}>
          <canvas
            ref={chartRef}
            height={height}
          >
          </canvas>
        </div>
      </div>
    </div>
  );
}
