import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";

import ProgressBar from 'components/common/ProgressBar';
import Button from 'components/common/Button';

import { ROUTES } from 'components/layout/consts';
import { STATUS } from '../const';

import uploadWhiteImg from 'assets/imgs/ic-upload-white.svg';
import styles from './style.module.scss';

export default function MintSection({ setStatus }) {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <div className={styles.container}>
      Minting your NFT
      <div className={styles.loadingWrapper}>
        <div className={styles.uploadImgWrapper}>
          <img className={styles.uploadImg} src={uploadWhiteImg} />
        </div> 
        <ProgressBar progress={100}/>
        <div className={styles.hint}>
          This might take 10-15 mins, once the minting finished,<br />
          it will display in the &nbsp;
          <span
            className={styles.highlight}
            onClick={() => history.push(ROUTES.ITEM)}
          >
            Item lists
          </span>.
        </div>
        <Button
          text='Mint another'
          onClick={() => setStatus(STATUS.UPLOAD)}
        />
      </div>
    </div>
  );
}