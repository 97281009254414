export const SET_USER_ONLINESTATUS = 'userReducer/SET_USER_ONLINESTATUS';
export const RESET_USER_ONLINESTATUS = 'userReducer/RESET_USER_ONLINESTATUS';
export const SET_USER_PERF = 'userReducer/SET_USER_PERF';
export const FETCH_USEROWN_PROFILE_SUCCESS =
  'userReducer/FETCH_USEROWN_PROFILE_SUCCESS';

export const SET_ITEM_LIST = 'productReducer/SET_ITEM_LIST';

export const SET_HOME_DRAWER = 'userReducer/SET_HOME_DRAWER';
export const SET_DIALOG_VISIBLE = 'userReducer/SET_DIALOG_VISIBLE';

export const OPEN_REMIND_VERIFY_DIALOG =
  'userReducer/OPEN_REMIND_VERIFY_DIALOG';
export const CLOSE_REMIND_VERIFY_DIALOG = 
  'userReducer/CLOSE_REMIND_VERIFY_DIALOG';

export const SET_INIT_BALANCE = 'walletReducer/SET_INIT_BALANCE';

export const SET_MESSAGE = 'messageReducer/SET_MESSAGE';
export const CLEAR_MESSAGE = 'messageReducer/CLEAR_MESSAGE';

export const PROFILE_DATA_ISLOADING = 'profileReducer/PROFILE_DATA_ISLOADING';
export const FETCH_PROFILE_SUCCESS = 'profileReducer/FETCH_PROFILE_SUCCESS';
export const UPDATE_PROFILE_SUCCESS = 'profileReducer/UPDATE_PROFILE_SUCCESS';
export const FETCH_PROFILE_COLLECTION_SUCCESS = 
  'profileReducer/FETCH_PROFILE_COLLECTION_SUCCESS';
export const FETCH_PROFILE_CREATIONS_SUCCESS = 
  'profileReducer/FETCH_PROFILE_CREATIONS_SUCCESS';
export const SET_PROFILE_FETCH_ERROR = 'profileReducer/SET_PROFILE_FETCH_ERROR';
export const FETCH_BIDDING_MADE_SUCCESS = 
  'profileReducer/FETCH_BIDDING_MADE_SUCCESS';
export const FETCH_BIDDING_RECEIVE_SUCCESS = 
  'profileReducer/FETCH_BIDDING_RECEIVE_SUCCESS';
export const FETCH_SELLINGS_SUCCESS = 'profileReducer/FETCH_SELLINGS_SUCCESS';
export const FETCH_ACTIVITIES_SUCCESS = 
  'profileReducer/FETCH_ACTIVITIES_SUCCESS';
export const RESET_PROFILE_STORE = 'profileReducer/RESET_PROFILE_STORE';

export const SET_FILTER = 'FILTERDRAWER/SET_FILTER';
export const SET_SEARCH_STR = 'FILTERDRAWER/SET_SEARCH_STR';
