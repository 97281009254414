import api from '../api';

const TokenApi = {};

const url = '/tokens';

TokenApi.getTokens = params => {
  return api.fire({
    url: url,
    method: 'GET',
    params
  });
};

export default TokenApi;
