import { useEffect } from 'react';

import styles from './styles.module.scss';

export default function Modal(props) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);
  return (
    <div className={styles.container}>
      {props.children}
    </div>
  );
}