export const MODE = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE'
};

export const DEFAULT_DATA = {
  attributeName: "",
  subAttributes: [],
};
export const SET_DETAIL_MAIN_ATTR_ID = 'attribute/SET_MAIN_ATTR_ID';
export const FETCH_ATTR_DETAIL_RESULT = 'attribute/FETCH_ATTR_DETAIL_RESULT';
export const CLEAR_ATTR_DETAIL_RESULT = 'attribute/CLEAR_ATTR_DETAIL_RESULT';
export const FETCH_MAIN_ATTRIBUTES_RESULT = 
  'attribute/FETCH_MAIN_ATTRIBUTES_RESULT';
export const FETCH_MAIN_ATTRIBUTES_COUNT = 
  'attribute/FETCH_MAIN_ATTRIBUTES_COUNT';