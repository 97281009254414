import api from '../api';

const prefix = '/items';

const itemApi = {};

/**
 * payload: 
 *  attributes: null
    content: "https://btse-nft.s3-ap-northeast-1.amazonaws.com/staging/item/9d8cd440-4265-4381-86da-1ac283dfeaa6.png"
    contentType: null
    counter: 0
    createTime: null
    creator: null
    description: null
    itemId: "83492087-cd12-45b9-b372-341c93625e10"
    name: null
    preview: "https://btse-nft.s3-ap-northeast-1.amazonaws.com/staging/item/9d8cd440-4265-4381-86da-1ac283dfeaa6.png"
    status: "CMS_WIP"
    tokenCounts: null
    updateTime: null
    whitelabel: null
 * 
 */

itemApi.fetchItemDetail = itemId => {
  return api.fire({
    url: `${prefix}/${itemId}`,
    method: 'GET',
  });
};

itemApi.getItemStatus = () => {
  return api.fire({
    url: prefix,
    method: 'GET',
    params: {
      type: 'MINTING'
    }
  });
};

/**
 * "data": {
      [
        {
            "itemId": "it0001",
            "name": "4 seasons",
            "tokenCount": 76,
            "itemPreview": "https://btse-nft.s3-ap-northeast-1.amazonaws.com/testnet/item/d29e5af2-8248-4656-b79d-89e5edbf357e.png"
        },
        ...
      ]
    },
 */
itemApi.getItems = (params) => {
  return api.fire({
    url: prefix,
    method: 'GET',
    params
  });
};

itemApi.update = (itemId, params) => {
  return api.fire({
    url: `${prefix}/${itemId}`,
    method: 'PATCH',
    data: JSON.stringify(params)
  });
};

export default itemApi;

