import Cookies from 'js-cookie';

const cookieUtils = {};

/**
 * Default cookieUtils set cookie as session cookie.
 * Which means ookie will be rmeoved after user close session.
 */
cookieUtils.set = ({name, value, expires}) => {
  if(!expires){
    Cookies.set(`${name}`, `${value}`, {expires: expires});
  }
  Cookies.set(`${name}`, `${value}`);
};

cookieUtils.get = name => {
  return Cookies.get(name);
};

cookieUtils.remove = name => {
  Cookies.remove(name);
};

export default cookieUtils;
