import dashboardlogo from '../../assets/imgs/dashboardicon.svg';
import heroimgelogo from '../../assets/imgs/heroimge.svg';
import mintinglogo from '../../assets/imgs/minting.svg';
import artistlogo from '../../assets/imgs/artist.svg';
import itemlogo from '../../assets/imgs/item.svg';
import attributelogo from '../../assets/imgs/attribute.svg';
import eventlogo from '../../assets/imgs/event.svg';
import userlogo from '../../assets/imgs/user.svg';
import datastudiologo from '../../assets/imgs/datastudio.svg';
import editoriallogo from '../../assets/imgs/edit-grey.svg';

import dashboardWhitelogo from '../../assets/imgs/dashboardicon-white.svg';
import heroimgeWhitelogo from '../../assets/imgs/heroimge-white.svg';
import mintingWhitelogo from '../../assets/imgs/minting-white.svg';
import artistWhitelogo from '../../assets/imgs/artist-white.svg';
import itemWhitelogo from '../../assets/imgs/item-white.svg';
import attributeWhitelogo from '../../assets/imgs/attribute-white.svg';
import eventWhitelogo from '../../assets/imgs/event-white.svg';
import userWhitelogo from '../../assets/imgs/user-white.svg';
import datastudioWhitelogo from '../../assets/imgs/datastudio-white.svg';
import editorialWhitelogo from '../../assets/imgs/edit-white.svg';

export const ROUTES = {
  DASHBOARD: 'dashboard',
  HEROBANNER: 'herobanner',
  MINTING: 'mint',
  ARTIST: 'artist',
  ITEM: 'item',
  ATTRIBUTE: 'attribute',
  EVENT: 'event',
  USER: 'user',
  DATABOARD: 'dataBoard',
  LOGIN: 'login',
  EDITORIAL: 'editorial',
};

export const navItems = [
  {
    title: 'Hero Banner',
    icon: heroimgelogo,
    activeIcon: heroimgeWhitelogo,
    action: ROUTES.HEROBANNER
  },
  {
    title: 'NFT Minting',
    icon: mintinglogo,
    activeIcon: mintingWhitelogo,
    action: ROUTES.MINTING
  },
  {
    title: 'Artist',
    icon: artistlogo,
    activeIcon: artistWhitelogo,
    action: ROUTES.ARTIST
  },
  {
    title: 'Item',
    icon: itemlogo,
    activeIcon: itemWhitelogo,
    action: ROUTES.ITEM
  },
  {
    title: 'Attribute',
    icon: attributelogo,
    activeIcon: attributeWhitelogo,
    action: ROUTES.ATTRIBUTE
  },
  {
    title: 'Event',
    icon: eventlogo,
    activeIcon: eventWhitelogo,
    action: ROUTES.EVENT
  },
  {
    title: 'Editorial',
    icon: editoriallogo,
    activeIcon: editorialWhitelogo,
    action: ROUTES.EDITORIAL
  },
  {
    title: 'User',
    icon: userlogo,
    activeIcon: userWhitelogo,
    action: ROUTES.USER
  },
  {
    title: 'Data Studio',
    icon: datastudiologo,
    activeIcon: datastudioWhitelogo,
    action: ROUTES.DATABOARD
  },
]; 
  