import { useState } from 'react';

import DropdownList from 'components/common/DropdownList';

import style from './style.module.scss';

export default function NumberSelector({
  label = 'Number',
  onSelectChange = () => {},
  verticalAlign = false
}) {
  const options = [
    {
      key: 50,
      text: 50,
    },
    {
      key: 100,
      text: 100,
    },
    {
      key: 150,
      text: 150,
    },
    {
      key: -1,
      text: 'all',
    },
  ];

  const [selectedItem, setSelectedItem] = useState(options[0]);

  const handleSelect = data => {
    setSelectedItem(data);
    onSelectChange(data.key);
  };

  return (
    <div className={`${style.container} ${verticalAlign && style['column-direction']}`}>
      <div className={style.label}>{label}</div>
      <div className={style['list-wrapper']}>
        <DropdownList 
          list={options}
          selectAction={handleSelect}
          content={selectedItem.text}
        />
      </div>
    </div>
  );
}
