import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useClickModal from 'hooks/useClickModal';

import { setUserOnlineStatus } from 'reducers/userReducer';
import { hideBackIcon } from 'reducers/headerReducer';
import ComfirmPrompt from 'components/common/Prompt';

import AuthApi from 'utils/apicallers/authapi';

import { SESSION, USERNAME } from 'consts/storageConsts';
import {
  SHOW_ARTIST_DETAIL,
  SHOW_ARTIST_UPDATE,
  SHOW_EVENT_DETAIL,
  SHOW_ADD_NEW_EVENT,
  SHOW_EVENT_UPDATE,
  SHOW_ATTRIBUTE_DETAIL_UPDATE,
  SHOW_ATTRIBUTE_ADD_NEW_ATTR,
  SHOW_HERO_BANNER_UPDATE,
  SHOW_HERO_BANNER_ADD,
  SHOW_ITEM_UPDATE,
  SHOW_EDITORIAL_UPDATE,
  SHOW_EDITORIAL_ADD
} from 'components/universalheader/consts';

import leftArror from 'assets/imgs/left-arrow.svg';
import bell from 'assets/imgs/universalheader-bell.svg';

import styles from './style.module.scss';

export default function Universalheader(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  
  const iconRef = useRef(null);
  const logoutRef = useRef(null);

  const { showBackIcon, backDesi } = useSelector(state => {
    return state.HeaderReducer;
  });

  const [showMenu, setShowMenu] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);

  useClickModal(iconRef, () => { 
    setShowMenu(f => !f);
  }, e => {
    if (logoutRef?.current?.contains(e.target)) {
      logout();
    }
    setShowMenu(false);
  });

  useEffect(() => {
    const pathname = location.pathname;
    const remainBackIcon = (
      (pathname === '/event' && backDesi === SHOW_EVENT_DETAIL) ||
        (pathname === '/artist' && backDesi === SHOW_ARTIST_DETAIL)
    );
    showBackIcon && !remainBackIcon && executeHideBack();
  }, [location]);
  
  const handleBackClick = () => {
    const editPageList = [
      SHOW_ADD_NEW_EVENT,
      SHOW_EVENT_UPDATE,
      SHOW_ATTRIBUTE_DETAIL_UPDATE,
      SHOW_ATTRIBUTE_ADD_NEW_ATTR,
      SHOW_ARTIST_UPDATE,
      SHOW_HERO_BANNER_UPDATE,
      SHOW_HERO_BANNER_ADD,
      SHOW_ITEM_UPDATE,
      SHOW_EDITORIAL_UPDATE,
      SHOW_EDITORIAL_ADD
    ];
    if (editPageList.includes(backDesi)) {
      setShowPrompt(true);
    } else {
      executeHideBack();
    }
  };

  const handlePromptOk = () => {
    setShowPrompt(false);
    executeHideBack();
  };

  const executeHideBack = () => {
    dispatch(hideBackIcon());
  };

  const logout = () => {
    AuthApi
      .logout()
      .then(res => {
        localStorage.removeItem(SESSION);
        localStorage.removeItem(USERNAME);
        dispatch(setUserOnlineStatus(false));
      })
      .catch(err => {
        console.error(err);
      });
  };

  return (
    <>
      <ComfirmPrompt
        visible={showPrompt}
        onOk={handlePromptOk}
        onCancel={() => setShowPrompt(false)}
      />
      <div className={styles.uheaderContainer}>
        <div className={styles.rightItem} onClick={handleBackClick}>
          {
            showBackIcon && 
              <div className={styles.rightWrapper}>
                <img src={leftArror} className={styles.icon} />
                <span className={styles.backText}>Back</span>
              </div>
          }
        </div>
        <div className={styles.leftItems}>
          <img src={bell} className={styles.icon} />
          <div ref={iconRef} className={styles.avatar}>
            A
          </div>
          {
            showMenu &&
              <div ref={logoutRef} className={styles.menu}>Logout</div>
          }
        </div>
      </div>
    </>
  );
}