import { useEffect } from 'react';

export default function useClickModal(ref, insideClick, outsideClick) {
  useEffect(() => {
    const handleClick = e => {
      if (ref.current) {
        if (ref.current.contains(e.target)) {
          insideClick && insideClick(e);
        } else {
          outsideClick && outsideClick(e);
        }
      }
    };
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [ref, insideClick, outsideClick]);
}