import { useState, useEffect } from "react";

import DateRangeSelector from 'components/common/DateRangeSelector';
import ThreeLabel from 'components/common/ThreeLabel';
import LineChart from 'components/common/LineChart';
import Table from 'components/common/Table';
import Spinner from 'components/common/Spinner';

import { DEFAULT_RANGE_TIME } from 'components/OverviewBoard/const';
import { TYPE as LINE_TYPE } from 'components/common/LineChart/const';

import { formatTime, formatNumber, formatPercentage } from 'utils/common';
import DataStudioApi from 'utils/apicallers/datastudioapi';

import style from '../style.module.scss';

const cols = [
  {
    title: 'DATE',
    key: 'date',
    fixed: 'left',
    render: time => formatTime(time, true)
  },
  {
    title: 'TOTAL TOKENS',
    key: 'total',
    align: 'right',
    fixed: 'left',
    render: tokenCount => formatNumber(tokenCount)
  },
  {
    title: 'TOKENS ON PRIMARY MARKET',
    key: 'primary',
    align: 'right',
    render: tokenCount => formatNumber(tokenCount)
  },
  {
    title: 'TOKENS ON SECONDARY MARKET',
    key: 'secondary',
    align: 'right',
    render: tokenCount => formatNumber(tokenCount)
  }
];

export default function TotalTokenCount() {
  const [apiLoading, setApiLoading] = useState(false);
  const [totalTokenData, setTotalTokenData] = useState({
    current: {
      total: 0,
      primary: 0,
      secondary: 0
    },
    compareToLast: {
      total: 0,
      primary: 0,
      secondary: 0
    },
    detailList: [],
    chartData: null
  });

  useEffect(() => {
    handleSelectRange({
      startDay: DEFAULT_RANGE_TIME.startDay,
      endDay: DEFAULT_RANGE_TIME.endDay
    });
  }, []);

  const handleSelectRange = range => {
    setApiLoading(true);
    DataStudioApi
      .getTotalToken({
        start: range.startDay,
        end: range.endDay
      })
      .then(res => {
        const { data } = res.data;
        let chartData = {
          time: [],
          data: [[], [], []]
        };

        data.detailList.forEach(d => {
          chartData.time.push(d.date);
          chartData.data[0].push(d.total);
          chartData.data[1].push(d.primary);
          chartData.data[2].push(d.secondary);
        });

        setTotalTokenData({
          ...data,
          chartData
        });
      })
      .catch(err => {})
      .finally(() => setApiLoading(false));
  };

  return (
    <div className={style.container}>
      <div className={style.titleWrapper}>
        <div className={style.title}>Total Token Count</div>
        <div className={style.rightSection}>
          <DateRangeSelector
            handleSelectRange={handleSelectRange}
          />
        </div>
      </div>

      {
        apiLoading
          ? <Spinner size="medium" />
          : (
              <>
                <ThreeLabel
                  data={[
                    {
                      name: "Total Tokens",
                      color: "primary",
                      content: totalTokenData.current.total,
                      trend: formatPercentage(totalTokenData.compareToLast.total),
                    },
                    {
                      name: "Tokens on Primary Market",
                      color: "secondary",
                      content: totalTokenData.current.primary,
                      trend: formatPercentage(totalTokenData.compareToLast.primary),
                    },
                    {
                      name: "Tokens on Secondary Market",
                      color: "third",
                      content: totalTokenData.current.secondary,
                      trend: formatPercentage(totalTokenData.compareToLast.secondary),
                    },
                  ]}
                />

                <LineChart
                  datasets={[
                    {
                      type: LINE_TYPE.PRIMARY,
                      fill: true
                    },
                    {
                      type: LINE_TYPE.SECONDARY,
                      fill: true
                    },
                    {
                      type: LINE_TYPE.THIRD,
                      fill: true
                    }
                  ]}
                  rawTimeList={totalTokenData?.chartData?.time}
                  rawDataList={totalTokenData?.chartData?.data}
                />
                <div className={style.tableWrapper}>
                  <Table
                    height="276px"
                    columns={cols}
                    dataSource={totalTokenData.detailList}
                  />
                </div>
              </>
            )
      }
    </div>
  );
}
