import api from '../api';

const DataStudioApi = {};

const prefix = '/dataStudio';

const userField = 'userSummary';

const artistField = 'artistSummary';

const tokenField = 'tokenSummary';

const revenueField = 'revenueSummary';

DataStudioApi.getDashboard = ({ start, end }) => {
  const params = {
    start,
    end
  };

  return api.fire({
    url: `${prefix}/dashboard`,
    method: 'GET',
    params
  });
};

DataStudioApi.getNewRegisteredUsers = ({ start, end }) => {
  const params = {
    // sorting: 'ASC',
    start,
    end
  };
  
  return api.fire({
    url: `${prefix}/${userField}/newRegisteredUsers`,
    method: 'GET',
    params
  });
};

DataStudioApi.getLoggedInUsers = ({ start, end }) => {
  const params = {
    orderBy: 'DATE',
    sorting: 'ASC',
    start,
    end
  };

  return api.fire({
    url: `${prefix}/${userField}/loggedInUsers`,
    method: 'GET',
    params
  });
};

DataStudioApi.getNewArtists = ({ start, end }) => {
  const params = {
    orderBy: 'DATE',
    sorting: 'ASC',
    start,
    end
  };

  return api.fire({
    url: `${prefix}/${artistField}/newArtists`,
    method: 'GET',
    params
  });
};

DataStudioApi.getFeeEarning = ({ page = 1, pageSize = 50, start, end }) => {
  const params = {
    orderBy: 'FEE',
    sorting: 'ASC',
    pageSize,
    page,
    start,
    end
  };

  return api.fire({
    url: `${prefix}/${artistField}/feeEarning`,
    method: 'GET',
    params
  });
};

DataStudioApi.getArtistTokenSales = 
  ({ type = 'PRIMARY', start, end, page = 1 }) => {
    const params = {
      type,
      start,
      end,
      page,
      sorting: 'DESC',
    };
    return api.fire({
      url: `${prefix}/${artistField}/tokenSales`,
      method: 'GET',
      params
    });
  };

DataStudioApi.getTotalToken = ({ start, end }) => {
  const params = {
    orderBy: 'DATE',
    sorting: 'ASC',
    start,
    end
  };

  return api.fire({
    url: `${prefix}/${tokenField}/countAll`,
    method: 'GET',
    params
  });
};

DataStudioApi.getNewMinted = ({ start, end }) => {
  const params = {
    orderBy: 'DATE',
    sorting: 'ASC',
    start,
    end
  };

  return api.fire({
    url: `${prefix}/${tokenField}/newMinted`,
    method: 'GET',
    params
  });
};

DataStudioApi.getHighestSoldPrice = ({ type = 'PRIMARY', start, end, page = 1, pageSize = 10 }) => {
  const params = {
    type,
    orderBy: 'PRICE_HIGHEST',
    sorting: 'DESC',
    start,
    end,
    page,
    pageSize
  };

  return api.fire({
    url: `${prefix}/${tokenField}/highestSoldPrice`,
    method: 'GET',
    params
  });
};

DataStudioApi.getTokenSales = ({ type = 'PRIMARY', start, end, page = 1 }) => {
  const params = {
    type,
    orderBy: 'DATE',
    page,
    // pageSize: 50,
    sorting: 'DESC',
    start,
    end
  };

  return api.fire({
    url: `${prefix}/${tokenField}/transactions`,
    method: 'GET',
    params
  });
};

DataStudioApi.getTokenBidded = ({ type = 'PRIMARY', start, end, page }) => {
  const params = {
    type,
    orderBy: 'BID_PRICE',
    page,
    // pageSize: 10,
    sorting: 'DESC',
    start,
    end
  };

  return api.fire({
    url: `${prefix}/${tokenField}/bidded`,
    method: 'GET',
    params
  });
};

DataStudioApi.getHighestBiddedTokens = 
  ({ type = 'PRIMARY', start, end, page, pageSize = 50 }) => {
  const params = {
    type,
    orderBy: 'PRICE',
    page,
    pageSize,
    sorting: 'DESC',
    start,
    end,
    withImage: false
  };

  return api.fire({
    url: `${prefix}/${tokenField}/highestBidded`,
    method: 'GET',
    params
  });
};

DataStudioApi.getMostOfferedTokens = ({ start, end, page, pageSize = 50 }) => {
  const params = {
    orderBy: 'COUNT',
    page,
    pageSize,
    sorting: 'DESC',
    start,
    end
  };

  return api.fire({
    url: `${prefix}/${tokenField}/mostOffered`,
    method: 'GET',
    params
  });
};

DataStudioApi.getDailyRevenue = ({ type, start, end }) => {
  const params = {
    type,
    start,
    end,
    orderBy: 'DATE',
    sorting: 'ASC'
  };

  return api.fire({
    url: `${prefix}/${revenueField}/dailyRevenue`,
    method: 'GET',
    params
  });
};

DataStudioApi.getRevenueFeeEarning = ({ start, end }) => {
  const params = {
    orderBy: 'DATE',
    sorting: 'ASC',
    start,
    end
  };

  return api.fire({
    url: `${prefix}/${revenueField}/feeEarning`,
    method: 'GET',
    params
  });
};

DataStudioApi.getSummaryCSV = (currentTab, { start, end }) => {
  const params = {
    start,
    end
  };
  return api.fire({
    url: `${prefix}/${currentTab}Summary/export`,
    method: 'GET',
    params,
    responseType: 'blob'
  });
};

export default DataStudioApi;