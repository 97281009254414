import { useState, useEffect } from "react";

import DateRangeSelector from 'components/common/DateRangeSelector';
import ThreeLabel from 'components/common/ThreeLabel';
import LineChart from 'components/common/LineChart';
import Table from 'components/common/Table';
import Spinner from 'components/common/Spinner';

import { DEFAULT_RANGE_TIME } from 'components/OverviewBoard/const';
import { TYPE as LINE_TYPE } from 'components/common/LineChart/const';

import { formatTime, formatNumber, formatPercentage } from 'utils/common';
import DataStudioApi from 'utils/apicallers/datastudioapi';

import style from 'components/TokenBoard/style.module.scss';

const cols = [
  {
    title: 'DATE',
    key: 'date',
    render: time => formatTime(time, true)
  },
  {
    title: 'TOTAL TOKENS',
    key: 'totalRevenue',
    align: 'right',
    render: tokenCount => formatNumber(tokenCount)
  },
  {
    title: 'TOKENS SOLD COUNT',
    key: 'tokenSoldCount',
    align: 'right',
    render: count => formatNumber(count)
  },
  {
    title: 'AVERAGE TOKEN REVENUE (USD)',
    key: 'averageRevenue',
    align: 'right',
    render: price => formatNumber(price)
  }
];

export default function TotalTokenCount() {
  const [apiLoading, setApiLoading] = useState(false);
  const [totalSoldCountData, setTotalSoldCountData] = useState({
    current: {
      totalRevenue: 0,
      tokenSoldCount: 0,
      averageRevenue: 0
    },
    compareToLast: {
      totalRevenue: 0,
      tokenSoldCount: 0,
      averageRevenue: 0
    },
    chartData: {
      time: [],
      data: [[], []]
    },
    detailList: []
  });

  useEffect(() => {
    handleSelectRange({
      startDay: DEFAULT_RANGE_TIME.startDay,
      endDay: DEFAULT_RANGE_TIME.endDay
    });
  }, []);

  const handleSelectRange = range => {
    setApiLoading(true);
    DataStudioApi
      .getDailyRevenue({
        type: 'PRIMARY',
        start: range.startDay,
        end: range.endDay
      })
      .then(res => {
        const { data } = res.data;
        let chartData = {
          time: [],
          data: [[], []]
        };

        data.detailList.forEach(d => {
          chartData.time.push(d.date);
          chartData.data[0].push(d.totalRevenue);
          chartData.data[1].push(d.tokenSoldCount);
        });

        setTotalSoldCountData({
          ...data,
          chartData
        });
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => setApiLoading(false));
  };

  return (
    <div className={style.container}>
      <div className={style.titleWrapper}>
        <div className={style.title}>
          Daily Revenue & Token Sold Count - Primary Market
        </div>
        <div className={style.rightSection}>
          <DateRangeSelector
            handleSelectRange={handleSelectRange}
          />
        </div>
      </div>

      {
        apiLoading
          ? <Spinner size="medium" />
          : (
              <>
                <ThreeLabel
                  data={[
                    {
                      name: "Total Revenue (USD)",
                      color: "primary",
                      content: totalSoldCountData.current.totalRevenue,
                      trend: formatPercentage(totalSoldCountData.compareToLast.totalRevenue),
                    },
                    {
                      name: "Token Sold Count",
                      color: "secondary",
                      content: totalSoldCountData.current.tokenSoldCount,
                      trend: formatPercentage(totalSoldCountData.compareToLast.tokenSoldCount),
                    },
                    {
                      name: "Average Token Revenue (USD)",
                      color: "third",
                      content: totalSoldCountData.current.averageRevenue,
                      trend: formatPercentage(totalSoldCountData.compareToLast.averageRevenue),
                    },
                  ]}
                />

                <LineChart
                  datasets={[
                    {
                      type: LINE_TYPE.PRIMARY,
                      fill: true,
                      yAxisID: 'y'
                    },
                    {
                      type: LINE_TYPE.SECONDARY,
                      fill: false,
                      yAxisID: 'y1'
                    }
                  ]}
                  yTitle="REVENUE"
                  y1Title="TOKEN SOLD"
                  rawTimeList={totalSoldCountData.chartData.time}
                  rawDataList={totalSoldCountData.chartData.data}
                />
                <div className={style.tableWrapper}>
                  <Table
                    height="276px"
                    columns={cols}
                    dataSource={totalSoldCountData.detailList}
                  />
                </div>
              </>
            )
      }
    </div>
  );
}
