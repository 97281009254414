import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchUsersResult } from 'reducers/artistReducer';
import { 
  fetchMainAttrsResult,
  fetchMainAttrsCount
} from 'reducers/attributeReducer';
import {
  fetchItemListResult,
  fetchTokenListResult,
  clearTokenListResult,
  cleanAddListAttr,
} from 'reducers/itemReducer';
import { fetchMintArtist } from 'reducers/mintingReducer';

import Skeleton from './Skeleton';
import BorderInput from 'components/common/BorderInput';
import Button from 'components/common/Button';
import CheckBox from 'components/common/CheckBox';
import GeneralAccordion from 'components/common/GeneralAccordion';

import { shortenText } from 'utils';
import AttributeApi from 'utils/apicallers/attributesapi';
import ArtistApi from 'utils/apicallers/artistapi';
import ItemApi from 'utils/apicallers/itemapi';
import TokenApi from 'utils/apicallers/tokenapi';

import { SELECTTYPE } from './consts';

import magnifierIcon from 'assets/imgs/ic-magnifier.svg';
import crossIcon from 'assets/imgs/ic-cross.svg';
import contractIcon from 'assets/imgs/contractIcon.svg';
import contractLinkIcon from 'assets/imgs/contractLink.svg';

import styles from './style.module.scss';

const FETCH_ACTION = {
  SCROLL: 0,
  SEARCH: 1
};

const SKELETON_COUNT = 5;

export default function SelectList({
  type = SELECTTYPE.ARTIST,
  itemId = '',
  specialExclude = false,
  title,
  handleCloseModal,
  currentAttrMain,
  handleOpenListAttribute,
}) {
  const dispatch = useDispatch();
  
  const mainAttributes = useSelector(state => {
    return state.AttributeReducer.mainAttributes;
  });
  const mainAttributesCount = useSelector(state => {
    return state.AttributeReducer.mainAttributesCount;
  });
  const itemAddAttributes = useSelector(state => {
    return state.ItemReducer.addAttributes;
  });
  const userList = useSelector(state => {
    return state.ArtistReducer.userList;
  });
  const itemList = useSelector(state => {
    return state.ItemReducer.itemList;
  });
  const artistList = useSelector(state => {
    return state.MintingReducer.artistList;
  });
  const contractTokenList = useSelector(state => {
    return state.ItemReducer.contractTokenList;
  });
  const contractTokenCount = useSelector(state => {
    return state.ItemReducer.contractTokenCount;
  });

  const [search, setSearch] = useState('');
  const [selectedArtist, setSelectedArtist] = useState({
    username: '',
    userId: ''
  });
  const [selectedItem, setSelectedItem] = useState({
    itemId: '',
    name: '',
    preview: ''
  });
  
  const [texts, setTexts] = useState({
    searchInputPlaceHolder: '',
    buttonText: '',
    subTitle: '',
  });
  const pageRef = useRef({
    total: 1,
    current: 1,
    size: 10
  });
  const [isSearchLoading, setIsSearchLoading] = useState(false);

  const inputPrefix = (
    <img
      className={styles.magnifier}
      src={magnifierIcon} 
      height="16px"
      width="16px"
    />
  );

  const onSearchChange = e => {
    setSearch(e);
  };
  const onCheckboxChange = (e, event) => {
    event.preventDefault();
    if (e.userId === selectedArtist.userId) {
      setSelectedArtist({
        username: '',
        userId: ''
      });
    } else {
      setSelectedArtist(e);
    }
  };
  const onItemCheckboxChange = (e, event) => {
    event.preventDefault();
    if (e.itemId === selectedItem.itemId) {
      setSelectedItem({
        itemId: '',
        name: '',
        preview: ''
      });
    } else {
      setSelectedItem(e);
    }
  };

  const fetchArtistApi = (action = FETCH_ACTION.SEARCH) => {
    const { current, size } = pageRef.current;
    const params = {
      search,
      orderBy: 'create_time',
      page: current,
      pageSize: size,
      sorting: 'DESC',
    };
    action === FETCH_ACTION.SEARCH && setIsSearchLoading(true);
    ArtistApi
      .getArtist(params)
      .then(res => {
        const { list, navigateLastPage } = res.data.data;
        if (action === FETCH_ACTION.SCROLL) {
          const newList = [...userList, ...list];
          dispatch(fetchMintArtist(newList));
          // dispatch({
          //   type: FETCH_MINTING_ARTIST_RESULT,
          //   payload: newList
          // });
        } else {
          dispatch(fetchMintArtist(list));
          // dispatch({
          //   type: FETCH_MINTING_ARTIST_RESULT,
          //   payload: list
          // });
        }
        pageRef.current.total = navigateLastPage;
      })
      .catch(err => {console.error(err);})
      .finally(() => {
        action === FETCH_ACTION.SEARCH && setIsSearchLoading(false);
      });
  };
  const fetchUserApi = (action = FETCH_ACTION.SEARCH) => {
    const { current, size } = pageRef.current;
    const params = {
      search,
      orderBy: 'create_time',
      page: current,
      pageSize: size,
      sorting: 'DESC',
      excludeArtist: true
    };
    action === FETCH_ACTION.SEARCH && setIsSearchLoading(true);
    ArtistApi.getUsers(params)
      .then(res => {
        const { list, navigateLastPage } = res.data.data;
        if (action === FETCH_ACTION.SCROLL) {
          const newList = [...userList, ...list];
          dispatch(fetchUsersResult(newList));
          // dispatch({
          //   type: FETCH_USERS_RESULT,
          //   payload: newList
          // });
        } else {
          dispatch(fetchUsersResult(list));
          // dispatch({
          //   type: FETCH_USERS_RESULT,
          //   payload: list
          // });
        }
        pageRef.current.total = navigateLastPage;
      })
      .catch(err => {})
      .finally(() => {
        action === FETCH_ACTION.SEARCH && setIsSearchLoading(false);
      });
  };
  const fetchItemApi = () => {
    const params = {
      ...(search && { search }),
      type: 'UNLISTED'
    };
    setIsSearchLoading(true);
    ItemApi
      .getItems(params)
      .then(res => {
        const { list } = res.data.data;
        dispatch(fetchItemListResult(list));
        // dispatch({
        //   type: FETCH_ITEM_LIST_RESULT,
        //   payload: list
        // });
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setIsSearchLoading(false);
      });
  };
  const fetchTokenApi = () => {
    const { current, size } = pageRef.current;
    const params = {
      itemId: itemId,
      page: current,
      pageSize: size
    };
    setIsSearchLoading(true);
    TokenApi
      .getTokens(params)
      .then(res => {
        const { list, total, navigateLastPage } = res.data.data;
        const newList = [...contractTokenList, ...list];
        dispatch(fetchTokenListResult({
          list: newList,
          total
        }));
        // dispatch({
        //   type: FETCH_TOKEN_LIST_RESULT,
        //   payload: {
        //     list: newList,
        //     total
        //   }
        // });
        pageRef.current.total = navigateLastPage;
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setIsSearchLoading(false);
      });
  };
  const fetchAttributesApi = (action = FETCH_ACTION.SEARCH) => {
    const { current, size } = pageRef.current;
    const params = {
      search,
      page: current,
      pageSize: size,
      orderBy: 'MAIN_ATTRIBUTE_NAME',
      sorting: 'DESC'
    };
    action === FETCH_ACTION.SEARCH && setIsSearchLoading(true);
    AttributeApi.getAttribute(params)
      .then(res => {
        const { list, navigateLastPage, total } = res.data.data;
        if (action === FETCH_ACTION.SCROLL) {
          dispatch(fetchMainAttrsResult([...mainAttributes, ...list]));
          // dispatch({
          //   type: FETCH_MAIN_ATTRIBUTES_RESULT,
          //   payload: newList
          // });
        } else {
          dispatch(fetchMainAttrsResult(list));
          // dispatch({
          //   type: FETCH_MAIN_ATTRIBUTES_RESULT,
          //   payload: list
          // });
        }
        dispatch(fetchMainAttrsCount(total));
        // dispatch({
        //   type: FETCH_MAIN_ATTRIBUTES_COUNT,
        //   payload: total
        // });
        pageRef.current.total = navigateLastPage;
      })
      .catch(err => {console.error(err);})
      .finally(() => {
        action === FETCH_ACTION.SEARCH && setIsSearchLoading(false);
      });
  };

  useEffect(() =>{
    switch(type){
      case SELECTTYPE.ITEM:
        setTexts({
          buttonText: 'Add Item',
          subTitle: 'Latest Items',
          searchInputPlaceHolder: 'Search Item id, Item name',
        });
        break;
      case SELECTTYPE.LISTATTRIBUTE:
      case SELECTTYPE.ATTRUBUTES:
        setTexts({
          buttonText: 'Add Attrubutes',
          subTitle: `Total ${mainAttributesCount} Attributes`,
          searchInputPlaceHolder: 'Search Attrubute',
        });
        break;
      case SELECTTYPE.CONTRACT:
        setTexts({
          buttonText: '',
          searchInputPlaceHolder: '',
          subTitle:
            `Total ${contractTokenCount} Token${contractTokenCount > 1
              ? 's'
              : ''}`,
        });
        break;
      case SELECTTYPE.MINTING:
        setTexts({
          buttonText: 'Set Artist',
          subTitle: `Latest Artist`,
          searchInputPlaceHolder: 'Search Artist',
        });
        break;
      case SELECTTYPE.ARTIST:
      default: 
        setTexts({
          buttonText: 'Add as Artist',
          subTitle: 'Latest User',
          searchInputPlaceHolder: 'Search Username',
        });
        break;
    }
  }, [contractTokenCount, mainAttributesCount]);

  useEffect(() => {
    switch (type) {
      case SELECTTYPE.ARTIST:
        pageRef.current = {
          current: 1,
          total: 1,
          size: 10
        };
        if(specialExclude){
          fetchArtistApi(FETCH_ACTION.SEARCH);
        }else{
          fetchUserApi(FETCH_ACTION.SEARCH);
        }
        break;
      case SELECTTYPE.MINTING:
        pageRef.current = {
          current: 1,
          total: 1,
          size: 10
        };
        fetchArtistApi(FETCH_ACTION.SEARCH);
        break;
      case SELECTTYPE.ITEM:
        fetchItemApi();
        break;
      case SELECTTYPE.ATTRUBUTES:
        pageRef.current = {
          current: 1,
          total: 1,
          size: 10
        };
        fetchAttributesApi(FETCH_ACTION.SEARCH);
        break;
      case SELECTTYPE.LISTATTRIBUTE:
        pageRef.current = {
          current: 1,
          total: 1,
          size: 10
        };
        fetchAttributesApi(FETCH_ACTION.SEARCH);
        break;
      default:
        break;
    }
  }, [search]);

  useEffect(() => {
    dispatch(clearTokenListResult());
    // dispatch({
    //   type: CLEAR_TOKEN_LIST_RESULT
    // });
    if (type === SELECTTYPE.CONTRACT) {
      pageRef.current = {
        current: 1,
        total: 1,
        size: 10
      };
      fetchTokenApi();
    }
    return () => {
      dispatch(clearTokenListResult());
      // dispatch({
      //   type: CLEAR_TOKEN_LIST_RESULT
      // });
    };
  }, [itemId]);

  /** get artist and user only */
  const handleScroll = (e) => {
    if (![SELECTTYPE.ARTIST, SELECTTYPE.MINTING].includes(type)) {
      return;
    }

    const { scrollTop, offsetHeight, scrollHeight } = e.target;
    if (scrollHeight - offsetHeight - scrollTop < 1) {
      ++pageRef.current.current;
      if (pageRef.current.current <= pageRef.current.total) {
        if (type === SELECTTYPE.ARTIST) {
          fetchUserApi(FETCH_ACTION.SCROLL);
        } else {
          fetchArtistApi(FETCH_ACTION.SCROLL);
        }   
      }
    }
  };
  const handleTokenScroll = e => {
    const { scrollTop, offsetHeight, scrollHeight } = e.target;
    if (scrollHeight - offsetHeight - scrollTop < 1) {
      ++pageRef.current.current;
      if (pageRef.current.current <= pageRef.current.total) {
        fetchTokenApi();
      }
    }
  };
  const handleAttrScroll = e => {
    const { scrollTop, offsetHeight, scrollHeight } = e.target;
    if (scrollHeight - offsetHeight - scrollTop < 1) {
      ++pageRef.current.current;
      if (pageRef.current.current <= pageRef.current.total) {
        fetchAttributesApi(FETCH_ACTION.SCROLL);
      }
    }
  };

  const renderListItem = (e, index) => {
    return (
      <div
        key={index}
        className={styles.itemWrapper}
        onClick={event => onCheckboxChange(e, event)}
      >
        <div className={styles.left}>
          <img className={styles.headImg} src={e.profileImage} />
          <div className={styles.nameWrapper}>
            <div className={styles.username}>
              { e.nickname }
            </div>
            <div className={styles.userId}>
              @{ e.userId }
            </div>
          </div>
        </div>
        <div className={styles.checkbox}>
          <CheckBox
            type={'radio'}
            value={selectedArtist.userId === e.userId}
          />
        </div>
      </div>
    );
  };
  const renderContractItem = item => {
    return (
      <div
        key={item.coinTokenId}
        className={styles.itemWrapper}
      >
        <div className={`${styles.left} ${styles.spaceBetween}`}>
          <div className={styles.flex}>
            <img className={styles.headImg} src={contractIcon} />
            <div className={styles.nameWrapper}>
              <div className={styles.username}>
                { `#${item.coinTokenId}` }
              </div>
              <div className={`${styles.username} ${styles.tokenHash}`}>
                { item.tokenHash && shortenText(item.tokenHash, 35)}
              </div>
            </div>
          </div>

          <div className={styles.contractLink}>
            <a
              href={`https://etherscan.io/tx/${item.tokenHash}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src={contractLinkIcon} width="16px" height="16px" />
            </a>
          </div>
        </div>
      </div>
    );
  };

  const handleCancel = () => {
    setSelectedArtist({
      username: '',
      userId: ''
    });
    handleCloseModal();
  };

  const renderSkeleton = () => {
    const list = [];
    for (let i = 0; i < SKELETON_COUNT; i++) {
      list.push(
        <Skeleton key={i} />
      );
    }
    return list;
  };
  
  const renderGeneralSelectItems = () => {
    return (
      <>
        <div
          className={styles.listWrapper}
          onScroll={handleScroll}
        >
          {
            isSearchLoading
              ? renderSkeleton()
              : type === SELECTTYPE.ARTIST && !specialExclude
                ? userList.map((item, index) => renderListItem(item, index))
                : artistList.map((item, index) => renderListItem(item, index))
          }
        </div>
        <div className={styles.btnArea}>
          <div className={styles.btnWrapper}>
            <Button
              type='simple'
              text='Cancel'
              onClick={handleCancel}
            />
          </div>
          <div className={styles.btnWrapper}>
            <Button
              text={texts.buttonText}
              onClick={() => handleCloseModal(selectedArtist)}
              disabled={!selectedArtist.userId}
            />
          </div>
        </div>
      </>
    );
  };

  const renderItems = () => {
    return (
      <>
        <div
          className={styles.listWrapper}
        >
          { 
            itemList.map((e, index) => (
              <div
                key={index}
                className={styles.itemWrapper}
                onClick={event => onItemCheckboxChange(e, event)}
              >
                <div className={styles.left}>
                  <img className={styles.headImg} src={e.preview} />
                  <div className={styles.nameWrapper}>
                    <div className={styles.username}>
                      { e.name }
                    </div>
                    <div className={styles.userId}>
                      @{ e.nickname }
                    </div>
                  </div>
                </div>
                <div className={styles.checkbox}>
                  <CheckBox
                    type={'radio'}
                    value={selectedItem.itemId === e.itemId}
                  />
                </div>
              </div>
            ))
          }
        </div>
        <div className={styles.btnArea}>
          <div className={styles.btnWrapper}>
            <Button
              type='simple'
              text='Cancel'
              onClick={handleCancel} />
          </div>
          <div className={styles.btnWrapper}>
            <Button
              text={texts.buttonText}
              onClick={() => handleCloseModal(selectedItem)}
              disabled={!selectedItem.itemId}
            />
          </div>
        </div>
      </>
    );
  };

  const renderAttributesSelectItem = () => {
    return mainAttributes
      ? (
          <>
            <div className={styles.listWrapper} onScroll={handleAttrScroll}>
              <div className={styles.accordion}>
                {
                  mainAttributes.map(d => {
                    return (
                      <GeneralAccordion data={d} key={d.attributeId} />
                    );
                  })
                }
              </div>
            </div>
            
            <div className={styles.btnArea}>
              <div className={styles.btnWrapper}>
                <Button
                  type='simple'
                  text='Cancel'
                  onClick={handleCancel}
                />
              </div>
              <div className={styles.btnWrapper}>
                <Button
                  text={texts.buttonText}
                  onClick={() => handleCloseModal(selectedItem)}
                  disabled={itemAddAttributes.length == 0 }
                />
              </div>
            </div>
          </>
        )
      : renderSkeleton() ;
  };

  const handleCancelListAttribute = () => { 
    dispatch(cleanAddListAttr(null));
    // dispatch({
    //   type: CLEAN_ADDLISTATTRIBUTE,
    //   payload: null,
    // });
    handleCloseModal(false);
  };
  const renderListAttributesSelectItem = () => {
    const result = new Set();
    if(currentAttrMain.length !== 0 && mainAttributes.length) {
      mainAttributes.forEach(m => {
        currentAttrMain.forEach(c => {
          if(c == m.attributeName) {
            result.add(m);
          }
        });
      });
    }
    return result.size
      ? (
          <>
            <div className={styles.listWrapper} onScroll={handleAttrScroll}>
              <div className={styles.accordion}>
                {
                  [...result].map(d => {
                    return (
                      <GeneralAccordion
                        data={d}
                        key={d.attributeId}
                        type='listattributes'
                      />
                    );
                  })
                }
              </div>
            </div>
            
            <div className={styles.btnArea}>
              <div className={styles.btnWrapper}>
                <Button
                  type='simple'
                  text='Cancel'
                  onClick={handleCancelListAttribute}
                />
              </div>
              <div className={styles.btnWrapper}>
                <Button
                  text={texts.buttonText}
                  onClick={() => handleCloseModal()}
                />
              </div>
            </div>
          </>
        )
      : <div
          className={styles.noAttributesAlert}
          onClick={handleOpenListAttribute}
        >
          You don’t have any attributes yet <br />
          Add attribute from <a>Attribute</a>.
        </div>;
  };
  const renderContractSelectors = () => {
    return (
      <div className={styles.listWrapper} onScroll={handleTokenScroll}>
        { 
          contractTokenList.map(item => renderContractItem(item))
        }
      </div>
    );
  };

  const renderContent = () => {
    switch(type){
      case SELECTTYPE.LISTATTRIBUTE:
        return renderListAttributesSelectItem();
      case SELECTTYPE.ATTRUBUTES:
        return renderAttributesSelectItem();
      case SELECTTYPE.CONTRACT:
        return renderContractSelectors();
      case SELECTTYPE.ITEM:
        return renderItems();
      case SELECTTYPE.ARTIST:
      case SELECTTYPE.MINTING:
      default:
        return renderGeneralSelectItems();
    }
  };
  return (
    <div className={styles.container}>
      <img
        className={styles.cross}
        src={crossIcon}
        onClick={() => handleCloseModal()}
      />
      <div className={styles.upper}>
        <div className={styles.title}>
          {title}
        </div>
        
        {
          type !== SELECTTYPE.CONTRACT &&
            <BorderInput
              value={search}
              prefix={inputPrefix}
              placeholder={texts.searchInputPlaceHolder}
              onInputChange={onSearchChange}
            />
        }
      </div>

      <div className={styles.subtitle}>
        {
          currentAttrMain?.length == 0
            ? ''
            : texts.subTitle
        }
      </div>
      { renderContent() }
    </div>
  );
}