import { formatDistanceToNow, format } from 'date-fns';
import curry from 'lodash/curry';

import { default as SHARING_TITLE }from '../components/event/consts';

export const formatNumber = num => {
  /**
   * @param {number | string}
   * @returns {string}
   * ex. 3000 => 3,000
   */
  if (typeof num === 'undefined' || typeof num === 'object') {
    return '';
  }
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatFloatNumber = num => {
  const splitResult = num.toString().split('.');
  let result = splitResult[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if(splitResult[1]){
    result = `${result}.${splitResult[1]}`;
  }
  return result;
};

/**
   * @param {number}
   * @returns {number}
   * ex. 0.33 => 33
   */
export const formatPercentage = num => {
  return Math.floor(num * 10000) / 100;
};

export const formatCurrency = (number, option = {
  style: 'currency',
  currency: 'USD'
}) => {
  return number.toLocaleString('en-US', option);
};

export const formatDigital = (num, decimalPlace) => {
  return formatNumber(Number.parseFloat(num).toFixed(decimalPlace));
};

export const getLeftTime = (milliSeconds, isLeft = true) => {
  if(!milliSeconds) {
    return 'On Going';
  }
  let diff = milliSeconds - Date.now();
  if( diff < 0 ) {
    return isLeft ? 'Expired' : 'Now';
  }
  return formatDistanceToNow(milliSeconds, {addSuffix: isLeft });
};

export const formatCountdown = (sec) => {
  // get count down format ex. 7d 12:34:05
  if (sec < 0) {
    return '';
  }
  let output = '';
  const d = Math.floor(sec / 3600 / 24);
  const h = Math.floor((sec - d * 3600 * 24) / 3600);
  const m = Math.floor(sec % 3600 / 60);
  const s = Math.floor(sec % 3600 % 60);
  if (d) {
    output += `${d}d `;
  }
  output += `${h > 9 ? '' : '0'}${h}:`;
  output += `${m > 9 ? '' : '0'}${m}:`;
  output += `${s > 9 ? '' : '0'}${s}`;
  return output;
};

export const formatDateFromNormal = input => {
  if (!input) {
    return '';
  }
  const date = new Date(input);
  const monthMapping = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  return `${monthMapping[date.getMonth()]}.${date.getDate()}`;
};

export const formatTime = (timestamp, onlyDate=false) => {
  return onlyDate
    ? format(new Date(timestamp), 'yyyy-MM-dd')
    : format(new Date(timestamp), 'yyyy-MM-dd HH:mm');
};

export const validator = {
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\s\S]{8,}$/,
  // eslint-disable-next-line
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  englishText: /^[a-zA-Z0-9-'",_//-@#&]+[a-zA-Z0-9-'",_//-@#&\s]*$/,
  // eslint-disable-next-line
  ip: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
  htmlTag: /<[^>]*>/
};

/**
 * Usage:
 * let timer = new Timer(function() {
 *   your function here
 * }, 5000)
 */
export function Timer (fn, t) {
  let timerObj = setInterval(fn, t);

  this.stop = function() {
    if (timerObj) {
      clearInterval(timerObj);
      timerObj = null;
    }
    return this;
  };

  // start timer using current settings (if it's not already running)
  this.start = function() {
    if (!timerObj) {
      this.stop();
      timerObj = setInterval(fn, t);
    }
    return this;
  };

  // start with new or original interval, stop current interval
  this.reset = function() {
    return this.stop().start();
  };
}

// export const getAPIDomain = () => {
//   if(process.browser){
//     switch(window.__ENV.REACT_APP_API_ENVIRONMENT) {
//       case 'BTSE':
//         return window.__ENV.REACT_APP_SPOT_BTSE_API_HOST;
//       default:
//         return window.__ENV.REACT_APP_SPOT_API_HOST;
//     }
//   }else{
//     switch(process.env.REACT_APP_API_ENVIRONMENT) {
//       case 'BTSE':
//         return process.env.REACT_APP_SPOT_BTSE_API_HOST;
//       default:
//         return process.env.REACT_APP_SPOT_API_HOST;
//     }
//   }
// };

export const copyShareLink = (text = '') => {
  let copyText = '';
  if(typeof text !== 'string'){
    copyText = window.location.href;
  } else {
    copyText = text || window.location.href;
  }
  const el = document.createElement('textarea');
  el.value = copyText;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const handleShare = async (data, eventHandleFn) => {
  const handleNativeShare = async config => {
    await navigator.share(config);
  };
  try {
    navigator.share
      ? handleNativeShare({
          title: SHARING_TITLE,
          text: data.name,
          url: window.location.href,
        })
      : eventHandleFn();
  } catch(err) {
    console.error('have error: ', err);
  }
};

export const modifyUrlQuery = checkName => {
  if(new URLSearchParams(window.location.search).get('type') !== checkName){
    window.history.pushState({}, '', `?type=${checkName}`);
  }
};

export const cutLongParagrath = (content, limitation = 200) => {
  if(content){
    const clength = content.length;
    if(clength > limitation) {
      return content.slice(0, 200);
    }
  }
  return '';
};

export const formatInputNum = num => {
  let splitArr = num.toString().split('.');
  let [integer, decimal] = splitArr;
  integer = formatNumber(integer);
  if (splitArr.length === 1) {
    return integer;
  } else {
    return integer + '.' + decimal;
  }
};

export const keyPressOnlyNum = e => {
  // Accept only number, delete and backspace
  const regex = /^[ 0-9_.]*$/;
  if (regex.test(e.key)) {
    return true;
  } else {
    if (e.keyCode !== 48 && e.keyCode !== 8) {
      e.preventDefault();
    }
    return false;
  }
};

export const fetchMatchPattern = curry((pattern, str) => {
  return pattern.exec(str)[1];
});
export const checkMatchPattern = curry((pattern, str) => {
  if (pattern.test(str)) {
    return true;
  }
  return false;
});

export const checkFileSize = (bytes, limitationInMB) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));

  if(i > 2 ){
    return false;
  }
  if(i <= 1) {
    return true;
  }
  
  const result = parseFloat((bytes / Math.pow(1024, i)).toFixed(2));
  // console.log('we have file size: ', `${result} ${sizes[i]}` );
  return (result < limitationInMB);
};

export const checkArrow = trend => {
  if(trend == 0) {
    return <span>&#x02212;</span>;
  }
  return (
    trend > 0 ? <span>&#8593;</span> : <span>&#8595;</span>
  );
};

export const handleMouseEnter = curry((callback, e) => {
    const mType = e.type;
    switch(mType) {
      case 'mouseenter':
        callback(true);
        break;
      case 'mouseleave':
        callback(false);
        break;
      default: 
        callback(false);
    }
  });