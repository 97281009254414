import { useState, useRef, useEffect } from 'react';

import Modal from 'components/common/NewModal';
import Input from 'components/common/BorderInput';
import Skeleton from 'components/common/SelectList/Skeleton';
import CheckBox from 'components/common/CheckBox';

import ArtistApi from 'utils/apicallers/artistapi';

import magnifierIcon from 'assets/imgs/ic-magnifier.svg';

import style from './style.module.scss';

const FETCH_ACTION = {
  SEARCH: 'SEARCH',
  SCROLL: 'SCROLL'
};

export default function MultiSelectListModal({
  originSelectedList = [], // userId list
  visible = false,
  onSelect = () => {},
  onClose = () => {}
}) {
  const [searchText, setSearchText] = useState('');
  const [selectedArtistList, setSelectedArtistList] = useState(
    originSelectedList
  );
  const [selectedIdList, setSelectedIdList] = useState(
    originSelectedList.map(d => d.id)
  );
  const [list, setList] = useState([]);
  const [isSearcgLoading, setIsSearchLoading] = useState(false);
  const pageRef = useRef({
    total: 1,
    current: 1,
    size: 10
  });

  const fetchArtist = (action = FETCH_ACTION.SEARCH) => {
    const { current, size } = pageRef.current;
    const params = {
      search: searchText,
      orderBy: 'create_time',
      page: current,
      pageSize: size,
      sorting: 'DESC',
    };
    action === FETCH_ACTION.SEARCH &&
      setIsSearchLoading(true);

    ArtistApi
      .getArtist(params)
      .then(res => {
        const { list, navigateLastPage } = res.data.data;
        if (action === FETCH_ACTION.SCROLL) {
          setList(prev => [...prev, ...list]);
        } else {
          setList(list);
        }
        pageRef.current.total = navigateLastPage;
      })
      .catch(err => {})
      .finally(() => {
        action === FETCH_ACTION.SEARCH &&
          setIsSearchLoading(false);
      });
  };

  useEffect(() => {
    pageRef.current = {
      total: 1,
      current: 1,
      size: 10
    };
    fetchArtist(FETCH_ACTION.SEARCH);
  }, [searchText]);

  const handleSearch = text => {
    setSearchText(text);
  };

  const handleScroll = e => {
    const { scrollTop, offsetHeight, scrollHeight } = e.target;
    if (scrollHeight - offsetHeight - scrollTop < 1) {
      ++pageRef.current.current;
      if (pageRef.current.current <= pageRef.current.total) {
         fetchArtist(FETCH_ACTION.SCROLL);
      }
    }
  };

  const handleSelect = (e, data) => {
    e.preventDefault();
    if (selectedIdList.includes(data.userId)) {
      // remove
      const newIdList = selectedIdList.filter(id => id !== data.userId);
      const newArtistList = selectedArtistList.filter(
        d => d.id !== data.userId
      );
      setSelectedIdList(newIdList);
      setSelectedArtistList(newArtistList);
    } else {
      // add
      setSelectedIdList(prev => [
        ...prev,
        data.userId
      ]);
      setSelectedArtistList(prev => [
        ...prev,
        {
          nickname: data.nickname,
          id: data.userId
        }
      ]);
    }
  };

  const renderSearchInput = () => {
    return (
      <Input
        value={searchText}
        prefix={
          <img src={magnifierIcon} />
        }
        placeholder="Search Artist"
        onInputChange={handleSearch}
      />
    );
  };

  const renderLoading = () => {
    const skeleton = [];
    for (let i = 0; i < 6; i++) {
      skeleton.push(
        <Skeleton key={i} />
      );
    }
    return skeleton;
  };

  const handleFinishSelect = () => {
    onSelect(selectedArtistList);
  };

  const renderList = () => {
    return (
      <>
        {
          list.map((d, index) => {
            return (
              <div
                key={index}
                className={style['item-wrapper']}
                onClick={e => handleSelect(e, d)}
              >
                <div className={style['left-section']}>
                  <img src={d.imageThumbnail} width="48px" height="48px" />
                  <div className={style.nameWrapper}>
                    <div className={style.username}>
                      { d.nickname }
                    </div>
                    <div className={style.userId}>
                      @{ d.userId }
                    </div>
                  </div>
                </div>
                <CheckBox
                  type={'radio'}
                  value={selectedIdList.includes(d.userId)}
                />
              </div>
            );
          })
        }
      </>
    );
  };

  return (
    <Modal
      title="Select Artist"
      subTitle={renderSearchInput()}
      visible={visible}
      okText="Set Artist"
      onCancel={onClose}
      onOk={handleFinishSelect}
    >
      <>
        <div className={style['sub-title']}>Latest Artist</div>
        <div
          className={style['list-wrapper']}
          onScroll={handleScroll}
        >
          {
            isSearcgLoading
              ? renderLoading()
              : renderList()
          }
        </div>
      </>
    </Modal>
  );
}
