import { useState, useEffect } from 'react';
import { addHours, addMinutes, format, subDays, getTime } from 'date-fns';

import TitleData from 'components/TitleData';
import Table from 'components/common/Table';
import LineChart from 'components/common/LineChart';
import DateRangeSelector from 'components/common/DateRangeSelector';
import Spinner from 'components/common/Spinner';

import DataStudioApi from 'utils/apicallers/datastudioapi';
import { formatNumber, formatTime } from 'utils/common';

import { TYPE as CHART_LINE_TYPE } from 'components/common/LineChart/const';
import { DEFAULT_RANGE_TIME } from 'components/OverviewBoard/const';

import style from '../style.module.scss';
import listingStyle from 'components/common/ListingPage/style.module.scss';

const cols = [
  {
    title: 'DATE',
    key: 'date',
    render: date => (
      <div className={listingStyle.id}>
        { formatTime(date, true) }
      </div>
    )
  },
  {
    title: 'USERS',
    key: 'counts',
    align:'right',
    render: content => {
      return (
        <div className={style.tableContent}>
          { formatNumber(content) }
        </div>
      );
    }
  },
];

export default function SecondSection() {
  const [logedInUserData, setLogedInUserData] = useState({
    titleData: {
      title: 'Logged In Users',
      content: 0,
      trend: 0,
    },
    chartData: null,
    tableData: []
  });
  const [apiLoading, setApiLoading] = useState(false);

  useEffect(() => {
    handleSelectRange({
      startDay: DEFAULT_RANGE_TIME.startDay,
      endDay: DEFAULT_RANGE_TIME.endDay
    });
  }, []);

  const handleSelectRange = range => {
    console.log("second section for Select date range: ", range);
    
    setApiLoading(true);
    DataStudioApi
      .getLoggedInUsers({start: range.startDay, end: range.endDay})
      .then(res => {
        const { data } = res.data;
        
        console.log('second secton get loggedInUsers: ', data);

        const chartData = {
          time: [],
          data: [[]]
        };

        data.detailList.forEach(d => {
          chartData.time.push(d.date);
          chartData.data[0].push(d.counts);
        });
        
        setLogedInUserData({
          titleData: {
            title: 'Logged In Users',
            content: data.current,
            trend: data.compareToLast ? data.compareToLast*100 : 0,
          },
          chartData,
          tableData: data.detailList
        });
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setApiLoading(false);
      });
  };
  return (
    <>
    <div className={style.firstSection}>
      { logedInUserData && <TitleData data={logedInUserData} /> }
      <div className={style.rightSection}>
        <DateRangeSelector handleSelectRange={handleSelectRange} />
      </div>
    </div>

    <div className={`${style.sectionRowWrapper} ${apiLoading && style.loading}`}>
      {
        apiLoading 
        ? <Spinner size="medium" />
        : (
          <>
            <div className={`${style.threeForthSection}`}>
              <LineChart
                height="223px"
                datasets={[{
                  type: CHART_LINE_TYPE.THIRD,
                  fill: true
                }]}
                rawDataList={logedInUserData?.chartData?.data}
                rawTimeList={logedInUserData?.chartData?.time}
              />
            </div>

            <div className={style.verticalSeperator} />

            <div className={`${style.sectionItem} ${style.scrolling} ${style.rightSectionItem}`}>
              <Table
                height="227px"
                columns={cols}
                dataSource={logedInUserData.tableData}
                loading={apiLoading}
                emptyContent={
                  <div className={style.empty}>
                    <div className={style.main}>No Data</div>
                  </div>
                }
              />
            </div>
          </>
        )
      }
      </div>
    </>
  );
}