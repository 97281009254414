import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  mainAttributes: [],
  mainAttributesCount: 0,
  detailMainAttrId: "",
  detailData: {
    attributeName: "",
    subAttributes: []
  },
};
export const attributeSlice = createSlice({
  name: 'attributeSlice',
  initialState,
  reducers: {
    setDefaultMainAttrId: (state, action) => {
      state.detailMainAttrId = action.payload;
    },
    fetchMainAttrsResult: (state, action) => {
      state.mainAttributes = action.payload;
    },
    fetchMainAttrsCount: (state, action) => {
      state.mainAttributesCount = action.payload;
    },
    fetchAttrDetailResult: (state, action) => {
      state.detailData = action.payload;
    },
    clearAttrDetailResult: state => {
      state.detailData = {
        attributeName: "",
        subAttributes: []
      };
    }
  }
});

export const { 
  setDefaultMainAttrId,
  fetchMainAttrsResult,
  fetchMainAttrsCount,
  fetchAttrDetailResult,
  clearAttrDetailResult
} = attributeSlice.actions;

export default attributeSlice.reducer;

// const AttributeReducer = (state = initialState, actions) =>
//   produce(state, draft => {
//     const { type, payload } = actions;
//     switch (type) {
//       case SET_DETAIL_MAIN_ATTR_ID:
//         draft.detailMainAttrId = payload;
//         return draft;
//       case FETCH_MAIN_ATTRIBUTES_RESULT:
//         draft.mainAttributes = payload;
//         return draft;
//       case FETCH_MAIN_ATTRIBUTES_COUNT:
//         draft.mainAttributesCount = payload;
//         return draft;
//       case FETCH_ATTR_DETAIL_RESULT:
//         draft.detailData = payload;
//         return draft;
//       case CLEAR_ATTR_DETAIL_RESULT:
//         draft.detailData = {
//           attributeName: "",
//           subAttributes: []
//         };
//         return draft;
//       default:
//         return draft;
//     }
//   });

// export default AttributeReducer;