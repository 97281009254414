import style from './style.module.scss';
import gstyle from 'components/App/style.module.scss';

export default function DashboardTab({ data, handleChangeTab, activeTab, noMargin = false }) {
  return (
    <div className={`${style.tabWrapper} ${noMargin && style['no-margin']}`}>
      {
        data.map((d, index)=> {
          return (
            <span
              data-key={d.category}
              className={`${style.tab} ${index === 1 ? style.longWidth : style.shorWidth} ${index === activeTab ? gstyle.innerTabActive : gstyle.innnerNonActive}`}
              key={d.category}
              onClick={handleChangeTab}
            >
              {d.category}
            </span>
          );
        })
      }
    </div>
  );
}
