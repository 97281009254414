import styles from './styles.module.scss';

export default function ErrorText({ text = '', align = 'left' }) {
  return (
    text
    ? (
      <div className={`${styles.err} ${styles[align] || styles.left}`}>
        {text}
      </div>
      )
    : null
  );
}