import api from '../api';

const AttributeApi = {};

const prefix = '/attributes';

AttributeApi.getAttribute = params => {
  return api.fire({
    url: prefix,
    method: 'GET',
    params
  });
};

AttributeApi.create = params => {
  return api.fire({
    url: prefix,
    method: 'POST',
    data: JSON.stringify(params)
  });
};

AttributeApi.add = (attributeId, params) => {
  return api.fire({
    url: `${prefix}/${attributeId}`,
    method: 'PUT',
    data: JSON.stringify(params) // same as create params
  });
};

AttributeApi.delete = (attributeId) => {
  return api.fire({
    url: `${prefix}/${attributeId}`,
    method: 'DELETE'
  });
};

AttributeApi.getAttributeById = (attributeId, params) => {
  return api.fire({
    url: `${prefix}/${attributeId}`,
    method: 'GET',
    params
  });
};

export default AttributeApi;