import { useState } from 'react';
import { useSelector } from 'react-redux';

import UploadSection from './UploadSection';
import DescribeSection from './DescribeSection';
import MintSection from './MintSection';

import { STATUS } from './const';
import { SHOW_MINT_HOME } from 'components/universalheader/consts';

import checkIcon from 'assets/imgs/ic-check.svg';
import uploadIcon from 'assets/imgs/ic-upload.svg';
import describeIcon from 'assets/imgs/ic-describe.svg';
import mintIcon from 'assets/imgs/ic-mint.svg';
import mintGreyIcon from 'assets/imgs/ic-mint-grey.svg';
import cautionIcon from 'assets/imgs/caution.svg';

import styles from './style.module.scss';

export default function Mint() {
  const backDesi = useSelector(state => {
    return state.HeaderReducer.backDesi;
  });
  
  const [status, setStatus] = useState(STATUS.UPLOAD);
  const [error, setError] = useState('');

  const renderContent = () => {
    switch(status) {
      case STATUS.UPLOAD:
        return (
          <UploadSection
            setStatus={setStatus}
            errorHandle={setError}
            error={error}
            acceptType={`image/jpg, image/jpeg, image/png, image/gif, .mp4`}
          />
        );
      case STATUS.DESCRIBE:
        return <DescribeSection setStatus={setStatus} errorHandle={setError} />;
      case STATUS.MINT:
        return <MintSection setStatus={setStatus} />;
      default:
        return <div>Something went wrong! Please try again.</div>;
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <img src={mintGreyIcon}/>
        NFT Minting
      </div>
      {
        error && 
        <div className={styles.errorMsg}>
          <img src={cautionIcon} />
          <span>{error}</span>
        </div>
      }
      <div className={styles.progressBar}>
        <div className={styles.wrapper}>
          <div className={`${styles.item} ${styles.active}`}>
            <img src={status > STATUS.UPLOAD ? checkIcon : uploadIcon} />
          </div>
          <div className={styles.name}>Upload</div>
        </div>
        
        <div className={`${styles.line} ${status > STATUS.UPLOAD && styles.active}`} />

        <div className={styles.wrapper}>
          <div className={`${styles.item} ${status > STATUS.UPLOAD && styles.active}`}>
            <img src={status > STATUS.DESCRIBE ? checkIcon : describeIcon} />
          </div>
          <div className={styles.name}>Describe</div>  
        </div>
        
        <div className={`${styles.line} ${status > STATUS.DESCRIBE && styles.active}`} />

        <div className={styles.wrapper}>
          <div className={`${styles.item} ${status > STATUS.DESCRIBE && styles.active}`}>
            <img src={mintIcon} />
          </div>
          <div className={styles.name}>Mint</div>
        </div>
      </div>
      {
        backDesi === SHOW_MINT_HOME
          ? (
              <UploadSection
                setStatus={setStatus}
                errorHandle={setError}
                error={error}
                acceptType={`image/png, image/jpg, image/jpeg, .mp4`}
              />
            )
          : renderContent()
      }
    </div>
  );
}