import UserReducer from './userReducer';
import HeaderReducer from './headerReducer';
import HeroBannerReducer from './heroBannerReducer';
import ItemReducer from './itemReducer';
import AttributeReducer from './attributeReducer';
import ArtistReducer from './artistReducer';
import EventReducer from './eventReducer';
import MintingReducer from './mintingReducer';
import EditorialReducer from './editorialReducer';
import dashboardReducer from './dashboardReducer';

const rootReducer = {
  UserReducer,
  HeaderReducer,
  HeroBannerReducer,
  ItemReducer,
  AttributeReducer,
  ArtistReducer,
  EventReducer,
  MintingReducer,
  EditorialReducer,
  dashboardReducer
};

export default rootReducer;