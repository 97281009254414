import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import useTableConfig from 'hooks/useTableConfig';

import { showBackIcon } from 'reducers/headerReducer';
import { setDefaultMainAttrId } from 'reducers/attributeReducer';

import Detail from './Detail';
import AttributeNew from './AttributeNew';
import DeleteModal from 'components/common/DeleteModal';
import ListingPage from 'components/common/ListingPage';
import ErrorView from 'components/common/ErrorView';

import AttributeApi from 'utils/apicallers/attributesapi';

import { MODE } from './const';
import { 
  SHOW_ATTRIBUTE_DETAIL,
  SHOW_BACK_ICON,
  SHOW_ATTRIBUTE_ADD_NEW_ATTR,
  SHOW_ATTRIBUTE_DETAIL_UPDATE,
  SHOW_ERROR
} from "components/universalheader/consts";
import { SORT } from "components/common/Table/const";
import { shortenText } from 'utils';
import { API_TYPE } from 'components/common/DeleteModal/const';

import styles from "./styles.module.scss";
import listingStyle from 'components/common/ListingPage/style.module.scss';

import ViewIcon from 'assets/imgs/view.svg';
import ViewBlackIcon from 'assets/imgs/view-black.svg';
import EditIcon from 'assets/imgs/edit.svg';
import EditBlackIcon from 'assets/imgs/edit-black.svg';
import TrashIcon from 'assets/imgs/trash.svg';
import TrashBlackIcon from 'assets/imgs/trash-black.svg';
import AttributeIcon from "assets/imgs/attribute.svg";

export default function Attribute() {
  const {
    currentPage,
    pagData,
    sort,
    pageRef,
    setSortData,
    setPageData
  } = useTableConfig();

  const dispatch = useDispatch();
  
  const [tableData, setTableData] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);
  const [id, setId] = useState('');
  const[delModalVisible, setDelModdalVisible] = useState(false);
  
  const { backDesi } = useSelector(state => state.HeaderReducer);
  
  const fetchAttribute = () => {
    const orderBy = sort.key === 'subAttributeCount'
      ? 'SUB_ATTRIBUTE_COUNT'
      : 'MAIN_ATTRIBUTE_NAME';
    const sorting = sort.type === SORT.ASC
      ? 'ASC'
      : 'DESC';

    const params = {
      page: currentPage,
      pageSize: pageRef.current.size,
      sorting,
      orderBy
    };
    setApiLoading(true);
    AttributeApi.getAttribute(params)
      .then(res => {
        const { list, navigateLastPage } = res.data.data;
        setTableData(list);
        setPageData({
          total: navigateLastPage
        });
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setApiLoading(false);
      });
  };
  useEffect(() => {
    const destList = [
      SHOW_ATTRIBUTE_DETAIL,
      SHOW_ATTRIBUTE_ADD_NEW_ATTR,
      SHOW_ATTRIBUTE_DETAIL_UPDATE
    ];
    !destList.includes(backDesi) && fetchAttribute();
  }, [sort, currentPage, backDesi]);

  const handleDelete = id => {
    setId(id);
    setDelModdalVisible(true);
  };

  const goToDetail = id => {
    dispatch(setDefaultMainAttrId(id));
    dispatch(showBackIcon(SHOW_ATTRIBUTE_DETAIL));
  };
  const goToUpdate = id => {
    dispatch(setDefaultMainAttrId(id));
    dispatch(showBackIcon(SHOW_ATTRIBUTE_DETAIL_UPDATE));
  };

  const handleShowAddNew = () => {
    dispatch(showBackIcon(SHOW_ATTRIBUTE_ADD_NEW_ATTR));
    dispatch({
      type: SHOW_BACK_ICON,
      payload: SHOW_ATTRIBUTE_ADD_NEW_ATTR
    });
  };

  const handleSort = (key, type) => {
    setSortData({
      key,
      type
    });
  };
  const handlePagAction = page => {
    setPageData({
      current: page
    });
  };
  const handleDelComplete = isSuccess => {
    setDelModdalVisible(false);
    isSuccess && fetchAttribute();
  };

  const cols = [
    {
      title: "ID",
      key: "attributeId",
      width: '173px',
      render: (text) => (
        <div className={listingStyle.id}>{`#${shortenText(text, 6)}`}</div>
      ),
    },
    {
      title: "MAIN ATTRIBUTE NAME",
      key: "attributeName",
      sorter: true,
    },
    {
      title: "SUB ATTRIBUTE COUNT",
      key: "subAttributeCount",
      width: '175px',
      sorter: true,
      align: 'left',
    },
    {
      title: 'VIEW',
      key: 'view',
      align: 'center',
      width: '5%',
      clickable: true,
      render: (text, row, _, { isHover }) => {
        return (
          <img
            src={isHover ? ViewBlackIcon : ViewIcon }
            loading="lazy"
            onClick={() => goToDetail(row.attributeId)}
          />
        );
      }
    },
    {
      title: 'EDIT',
      key: 'edit',
      align: 'center',
      width: '5%',
      clickable: true,
      render: (text, row, _, { isHover }) => {
        return (
          <img
            src={isHover ? EditBlackIcon : EditIcon }
            loading="lazy"
            onClick={() => goToUpdate(row.attributeId)}
          />
        );
      }
    },
    {
      title: 'DELETE',
      key: 'delete',
      align: 'center',
      width: '5%',
      clickable: true,
      render: (text, row, _, { isHover }) => {
        return (
          <img
            src={isHover ? TrashBlackIcon : TrashIcon }
            loading="lazy"
            onClick={() => handleDelete(row.attributeId)}
          />
        );
      }
    }
  ];
  const renderContent = () => {
    switch (backDesi) {
      case SHOW_ATTRIBUTE_DETAIL:
        return <Detail />;
      case SHOW_ATTRIBUTE_ADD_NEW_ATTR:
        return (
          <AttributeNew mode={MODE.CREATE} />
        );
      case SHOW_ATTRIBUTE_DETAIL_UPDATE:
        return (
          <AttributeNew mode={MODE.UPDATE} />
        );
      case SHOW_ERROR:
        return (
          <ErrorView />
        );
      default:
        return (
          <>
            <ListingPage 
              addNewFn={handleShowAddNew}
              data={ tableData }
              cols={ cols }
              title={{
                text: 'Attribute',
                icon: AttributeIcon
              }}
              sort={sort}
              onSortClick={handleSort}
              pagData={pagData}
              pagFetchAction={handlePagAction}
              loading={apiLoading}
            />

            <DeleteModal 
              id={id}
              visible={delModalVisible}
              apiType={API_TYPE.ATTRIBUTE}
              title="Delete Main Attribute"
              errTitle="Not Able to Delete Main Attribute"
              content="Are you sure to delete this main attribute and its all sub attributes?"
              errContent="This main attribute can not be deleted."
              onComplete={handleDelComplete}
            />
          </>
        );
    }
  };
  return (
    <div className={styles.container}>
      { renderContent() }
    </div>
  );
}
