import { useState } from 'react';

import ArrowUp from 'assets/imgs/arrow-up-grey.svg';

import styles from './styles.module.scss';

export default function Collapse({ header, children, defaultCollapse = true }) {
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapse);
  
  const handleCollpsed = () => {
    setIsCollapsed(prev => !prev);
  };
  return (
    <div>
      <div className={styles.header} onClick={handleCollpsed}>
        <div>{header}</div>
        <img
          className={`${styles['arrow-icon']} ${isCollapsed && styles['is-collapsed']}`}
          src={ArrowUp}
          loading="lazy"
          height="16px"
          width="16px"
        />
      </div>
      {
        !isCollapsed &&
          <div className={styles.content}>
            {children}
          </div>
      }
    </div>
  );
}