import { useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { subDays, getTime } from 'date-fns';

import UserBoard from 'components/UserBoard';
import ArtistBoard from 'components/ArtistBoard';
import TokenBoard from 'components/TokenBoard';
import RevenueBoard from 'components/RevenueBoard';
import OverviewBoard from 'components/OverviewBoard';
import Tab from 'components/common/Tab';
import ExportCSV from 'components/common/ExportCSV';
import DateRangeSelector from 'components/common/DateRangeSelector';

import { tabs } from './consts';

import datastudioBlacklogo from '../../assets/imgs/datastudio.svg';
import DataStudioApi from 'utils/apicallers/datastudioapi';

import style from './style.module.scss';

export default function DataBoard() {
  const [range, setRange] = useState({
    start: dayjs(getTime(subDays(new Date(), 6))).startOf('day').valueOf(),
    end: dayjs(getTime(Date.now())).startOf('day').valueOf(),
  });

  const currentTab = useSelector(state => {
    return state.dashboardReducer.currentTab;
  });

  const exportAction = () => {
    DataStudioApi
      .getSummaryCSV(currentTab.toLowerCase(), range)
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'data.zip');
        
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  };
  
  const renderContent = () => {
    switch(currentTab){
      case tabs[1].key:
        return <UserBoard />;
      case tabs[2].key: 
        return <ArtistBoard />;
      case tabs[3].key:
        return <TokenBoard />;
      case tabs[4].key: 
        return <RevenueBoard />;
      default:
        return <OverviewBoard />;
    }
  };

  const handleSelectRange = range => {
    setRange({
      start: range.startDay,
      end: range.endDay
    });
  };

  return (
    <div className={style.contentArea}>
      <div className={style.titleWrapper}>
        <img src={datastudioBlacklogo} className={style.titleIcon} />
        <span>Data Studio</span>
      </div>
      <div className={style.tabWrapper}>
        <Tab tabs={tabs} />
        {
          currentTab !== tabs[0].key &&
            (
              <div className={style.rightSection}>
                Export Data for
                <DateRangeSelector
                  handleSelectRange={handleSelectRange}
                  showTitle={false}
                />
                <ExportCSV exportAction={exportAction}/>
              </div>
            )
        }
      </div>
      <div className={style.seperator} />
      { renderContent() }
    </div>
  );
}