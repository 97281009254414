import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { showBackIcon } from 'reducers/headerReducer';
import { 
  fetchAttrDetailResult,
  clearAttrDetailResult
} from 'reducers/attributeReducer';

import Button from 'components/common/Button';
import DropdownList from 'components/common/DropdownList';
import Table from 'components/common/Table';
import Spinner from 'components/common/Spinner';
import ErrorText from 'components/common/ErrorText';

import { shortenText } from 'utils';
import AttributeApi from 'utils/apicallers/attributesapi';

import { SORT } from 'components/common/Table/const';
import { SHOW_ATTRIBUTE_DETAIL_UPDATE } from 'components/universalheader/consts';

import styles from './styles.module.scss';
import listingStyle from 'components/common/ListingPage/style.module.scss';

const SUB_ATTR_LIMIT = 6;

export default function AttributeDetail() {
  const dispatch = useDispatch();
  
  const { 
    detailMainAttrId,
    detailData,
  } = useSelector(state => {
    return state.AttributeReducer;
  });

  const [showAll, setShowAll] = useState(false);
  const [selectedSubAttr, setSelectedSubAttr] = useState({
    subAttributeName: '',
    items: []
  });
  const [sort, setSort] = useState({
    key: '',
    type: 0,
  });
  const [apiLoading, setApiLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  

  const cols = [
    {
      title: 'ITEM ID',
      key: 'itemId',
      render: text => (
        <div className={listingStyle.id}>{`#${shortenText(text, 6)}`}</div>
      )
    },
    {
      title: 'ITEM NAME',
      key: 'itemName',
      sorter: true,
      render: (text, row) => {
        return (
          <div className={styles['item-name-wrapper']}>
            <img src={row.itemPreview} width={40} height={40}/>
            {/** TODO: handle long string */}
            <div>{text}</div>
          </div>
        );
      }
    },
    {
      title: 'TOKEN COUNT',
      key: 'tokenCount',
      sorter: true,
      align: 'right'
    },
  ];

  const fetchData = () => {
    setErrMsg('');
    setApiLoading(true);
    
    AttributeApi.getAttributeById(detailMainAttrId)
      .then(res => {
        const { attributeName, subAttributes = [] } = res.data.data;
        dispatch(fetchAttrDetailResult({
          attributeId: detailMainAttrId,
          attributeName,
          subAttributes
        }));
        // dispatch({
        //   type: FETCH_ATTR_DETAIL_RESULT,
        //   payload: {
        //     attributeId: detailMainAttrId,
        //     attributeName,
        //     subAttributes
        //   }
        // });
      })
      .catch(err => {
        console.error(err);
        setErrMsg(err?.response?.data?.msg || 'fetch data fail');
      })
      .finally(() => {
        setApiLoading(false);
      });
  };

  useEffect(() => {
    dispatch(clearAttrDetailResult());
    fetchData();
  }, [detailMainAttrId]);

  const renderSubAttr = () => {
    let list = [];
    const subAttributes = detailData.subAttributes;

    if (showAll || subAttributes.length <= SUB_ATTR_LIMIT) {
      list = subAttributes;
    } else {
      list = subAttributes.slice(0, SUB_ATTR_LIMIT);
    }
    return list.map((d, index) => (
      <div key={index} className={styles['sub-attr']}>
        { d.subAttributeName }
      </div>
    ));
  };

  const dropList = () => {
    const subAttributes = detailData.subAttributes;
    return subAttributes.map((d, index) => ({
      ...d,
      key: index,
      text: `${d.subAttributeName} (${d.items.length})`
    }));
  };

  const showDropdownName = () => {
    if (!selectedSubAttr.subAttributeName) {
      return '';
    } else {
      return `${selectedSubAttr.subAttributeName} (${selectedSubAttr.items.length})`;
    }
  };

  const handleSelectAttr = attrData => {
    setSelectedSubAttr(attrData);
    setSort({
      key: '',
      type: 0
    });
  };

  const handleSort = (key, sortType) => {
    setSort({
      key,
      type: sortType
    });
  };

  const renderTableData = () => {
    // related by selectedSubAttr and sortType
    if (!selectedSubAttr.subAttributeName) {
      return [];
    }

    let items = [...selectedSubAttr.items];
    if (sort.type !== SORT.NONE) {
      return items.sort((a, b) => {
        // string
        if (typeof a[sort.key] === 'string') {
          const stringA = a[sort.key].toUpperCase();
          const stringB = b[sort.key].toUpperCase();

          if (sort.type === SORT.ASC) {
            if (stringA < stringB) {
              return -1;
            } else if (stringA > stringB) {
              return 1;
            } else {
              return 0;
            }
          } else if (stringA < stringB) {
              return 1;
            } else if (stringA > stringB) {
              return -1;
            } else {
              return 0;
            }
        } else { // number
          if (sort.type === SORT.ASC) {
            return a[sort.key] - b[sort.key];
          } else {
            return b[sort.key] - a[sort.key];
          }
        }
      });
    }

    return items;
  };

  const handleUpdate = () => {
    dispatch(showBackIcon(SHOW_ATTRIBUTE_DETAIL_UPDATE));
  };

  if (errMsg) {
    return (
      <ErrorText text={errMsg} align="center" />
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles['title-container']}>
        <div>Main Attribute Details</div>
        <Button
          text="Update"
          onClick={handleUpdate}
          disabled={apiLoading}
        />
      </div>

      {
        apiLoading
          ? <Spinner size="medium" />
          : (
              <div className={styles['content-contaner']}>
                <div className={styles.section}>
                  <div className={styles.title}>Attribute ID</div>
                  <div className={styles.content}>
                    #{detailMainAttrId}
                  </div>
                </div>

                <div className={styles.section}>
                  <div className={styles.title}>Main Attribute Name</div>
                  <div className={styles.content}>
                    {detailData.attributeName}
                  </div>
                </div>

                <div className={styles.section}>
                  <div className={styles.title}>
                    <div>Sub Attributes</div>
                    {
                      detailData.subAttributes.length > SUB_ATTR_LIMIT &&
                      <div className={styles['show-more-less']} onClick={() => setShowAll(!showAll)}>
                        {
                          showAll
                          ? "See less"
                          : "See all"
                        }
                      </div>
                    }
                  </div>
                  <div className={styles['sub-attr-container']}>
                    { renderSubAttr() }
                  </div>
                </div>

                <div className={styles.section}>
                  <div className={styles.title}>
                    Item List
                  </div>
                  <div className={styles['item-list-container']}>
                    <DropdownList
                      placeholder="Select sub attributte"
                      list={dropList()}
                      content={showDropdownName()}
                      selectAction={handleSelectAttr}
                    />
                    <div className={styles['table-wrapper']}>
                      <Table
                        columns={cols}
                        sort={sort}
                        dataSource={renderTableData()}
                        onSortClick={handleSort}
                      />
                    </div>
                  </div>
                </div>
              </div>
          )
      }      
    </div>
  );
}
