import styles from './styles.module.scss';

export default function Skeleton() {
  return (
    <div className={styles.container}>
      <div className={styles.avatar} />
      <div>
        <div className={styles['rectangle--1']} />
        <div className={styles['rectangle--2']} />
      </div>
    </div>
  );
}