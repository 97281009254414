export const shortenText = (str, limit, instreadText = '...') => {
  if(str.length <= limit){
    return str;
  }
  return `${str.substr(0, limit + 1)}${instreadText}`;
};

export const checkCharacters = (paragraph, limit) => {
  if(paragraph.length > limit){
    return false;
  }
  return true;
};