import React, { useState, useRef } from "react";

import clearImg from "assets/imgs/ic-clear.svg";
import styles from "./styles.module.scss";

export const BORDER_TYPE = {
  AROUND: 0,
  UNDERLINE: 1,
};

const BorderInput = ({
  id = '',
  name = null,
  required = null,
  pattern = null,
  type = "text",
  value,
  onInputChange = null,
  onKeyDown = null,
  onBlur = null,
  placeholder = "",
  isDisabled = false,
  isError = false,
  prefix = null,
  postfix = null,
  errMsg = null,
  maxLength = null,
  borderType = BORDER_TYPE.AROUND,
}) => {
  const ref = useRef();
  
  const [targetValue, setTargetValue] = useState('');
  const valueRef = useRef();

  const keyPress = (e) => {
    if (type === "tel") {
      // Accept only number, delete and backspace
      const regex = /^\d+$/;
      if (regex.test(e.key)) {
        return true;
      } else {
        if (e.keyCode !== 48 && e.keyCode !== 8) {
          e.preventDefault();
        }
        return false;
      }
    }
    onKeyDown && onKeyDown(e);
  };
  const onChange = e => {
    const targetValue = e.target.value;
    if( targetValue !== valueRef.current ){
      onInputChange && onInputChange(targetValue);
    }
    valueRef.current = targetValue;
  };

  const handleClear = () => {
    ref.current.focus();
    onInputChange && onInputChange('');
  };
  const handleOnBlur = e => {
    onBlur && onBlur(e);
  };

  const setClass = () => {
    let classStr = `${styles['input-wrapper']} ${
      (borderType === BORDER_TYPE.AROUND
        ? styles["border-around"]
        : styles["border-underline"])}`;

    return errMsg
      ? `${classStr} ${styles.err}`
      : classStr;
  };
  return (
    <>
      <div className={setClass()}>
        {
          prefix && (
            <div className={styles['prefix-wrapper']}>
              {prefix}
            </div>
          )
        }
        <input
          id={id}
          name={name}
          required={required}
          pattern={pattern}
          ref={ref}
          type={type}
          placeholder={placeholder}
          disabled={isDisabled}
          maxLength={maxLength}
          value={value || ''}
          onChange={onChange}
          onBlur={handleOnBlur}
          onKeyDown={keyPress}
        />
          {postfix}
          {
            value && (
              <img
                className={styles.clearBtn}
                src={clearImg}
                loading="lazy"
                onClick={handleClear}
              />
          )}
      </div>
      {
        errMsg &&
          <div className={styles.err}>
            {errMsg}
          </div>
      }
    </>
  );
};
const areEqual = (prevProps, nextProps) => {
  if(prevProps.value == nextProps.value){
    return true;
  }
  return false;
};

export default React.memo(BorderInput, areEqual);
