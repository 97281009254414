import { useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useSelector } from 'react-redux';
import ReactDOM from 'react-dom';

import NavItem from 'components/navItem';
import Login from 'components/Login';
import HeroBanner from 'components/HeroBanner';
import Event from 'components/event';
import Artist from '../Artist';
import Attribute from 'components/Attribute';
import Mint from 'components/Mint';
import Item from 'components/item';
import User from 'components/User';
import Editorial from 'components/Editorial';
import UniversalHeader from 'components/universalheader';
import ConfirmPrompt from 'components/common/Prompt';
import PageNotFound from 'components/PageNotFound';
import DataBoard from 'components/DataBoard';

import useResizeWidth from 'hooks/useResizeWidth';

import { handleMouseEnter } from 'utils/common';

import nftlogo from 'assets/imgs/nft.svg';
import btselogo from 'assets/imgs/btse.svg';

import { ROUTES, navItems } from './consts';

import styles from './style.module.scss';

export default function(props) {
  const [expanded, setExpanded] = useState(false);
  const isOnline = useSelector(state => state.UserReducer.isOnline);
  const [windowWidth] = useResizeWidth();

  const renderNavItems = () => {
    return navItems.map(item => {
      return (
        <NavItem
          key={item.title}
          data={item}
          windowWidth={windowWidth}
          expanded={expanded}
        />
      );
    });
  };

  const getUserConfirmation = (message, callback) => {
    const modalContainer = document.getElementById('modalContainer');
    const closeModal = isConfirm => {
      ReactDOM.unmountComponentAtNode(modalContainer);
      callback(isConfirm);
    };
    ReactDOM.render(
      <ConfirmPrompt
        visible={true}
        message={message}
        onOk={() => closeModal(true)}
        onCancel={() => closeModal(false)}
      />,
      modalContainer
    );
  };
  
  const handleMouseMove = handleMouseEnter(setExpanded);
  return isOnline
    ? (
        <Router getUserConfirmation={getUserConfirmation}>
          <div className={styles.container}>
            
            {/* nav bar section */}
            <aside
              className={`${styles.navWrapper} ${windowWidth < 1200 && styles.expanded}`}
              onMouseEnter={handleMouseMove}
              onMouseLeave={handleMouseMove}
            >
              <div className={styles.navitem}>
                <img src={btselogo} className={styles.logoImg} />
                <img src={nftlogo} className={styles.nftImg} />
              </div>
              { renderNavItems() }
            </aside>

            {/* main content */}
            <main className={styles.mainContentWrapper}>
                <div id="modalContainer" />
                <div className={styles.mainWrapper}>
                  <UniversalHeader />
                  <Switch>
                    <Route path={`/${ROUTES.HEROBANNER}`}>
                      <HeroBanner />
                    </Route>
                    <Route path={`/${ROUTES.MINTING}`}>
                      <Mint />
                    </Route>
                    <Route path={`/${ROUTES.ARTIST}`}>
                      <Artist />
                    </Route>
                    <Route path={`/${ROUTES.ITEM}`}>
                      <Item />
                    </Route>
                    <Route path={`/${ROUTES.ATTRIBUTE}`}>
                      <Attribute />
                    </Route>
                    <Route path={`/${ROUTES.EVENT}`}>
                      <Event />
                    </Route>
                    <Route path={`/${ROUTES.EDITORIAL}`}>
                      <Editorial />
                    </Route>
                    <Route path={`/${ROUTES.USER}`}>
                      <User />
                    </Route>
                    <Route path={`/${ROUTES.DATABOARD}`}>
                      <DataBoard />
                    </Route>
                    <Route path={`/404`}>
                      <PageNotFound />
                    </Route>
                    
                    {/* general routing rules */}
                    <Route exact path="/">
                      <Redirect to={`/${ROUTES.HEROBANNER}`} />
                    </Route>
                    <Route path={`/${ROUTES.LOGIN}`}>
                      <Redirect to={`/${ROUTES.HEROBANNER}`} />
                    </Route>
                    <Route path="*">
                      <Redirect to="/404" />
                    </Route>

                  </Switch>
                </div>
              </main>
          </div>
        </Router>
      )
    : <Login />;
}