import { useEffect } from 'react';
import CloseIcon from 'assets/imgs/close.svg';

import styles from "./styles.module.scss";

export default function FullScreenPreview({
  children,
  onClose = () => {}
}) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <div className={styles["full-image-container"]}>
      <img
        className={styles.close}
        src={CloseIcon}
        loading="lazy"
        height="19.2px"
        width="19.2px"
        onClick={onClose}
      />
      <div className={styles.wrapper}>
        {children}
      </div>
    </div>
  );
}
