import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { showBackIcon } from 'reducers/headerReducer';

import Button from 'components/common/Button';
import StatusView from 'components/common/StatusView';
import Previewer from 'components/common/Previewer';
import Spinner from 'components/common/Spinner';

import EditorialApi from 'utils/apicallers/editorial';
import { formatTime } from 'utils/common';
import { FIELD_KEY } from '../consts';
import {
  SHOW_EDITORIAL_UPDATE,
  SHOW_ERROR
} from 'components/universalheader/consts';

import style from './style.module.scss';

const NOT_SET_YET = 'Not set yet';

export default function Detail() {
  const { articleId } = useSelector(state => {
    return state.EditorialReducer;
  });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [article, setArticle] = useState({
    [FIELD_KEY.ID]: "",
    [FIELD_KEY.STATUS]: "",
    [FIELD_KEY.ARTICLE_TITLE]: "",
    [FIELD_KEY.AUTHOR_NAME]: "",
    [FIELD_KEY.PUBLISH_TIME]: null,
    [FIELD_KEY.ARTICLE_IMAGE]: "",
    [FIELD_KEY.IMAGE_DESCRIPTION]: "",
    [FIELD_KEY.EXCERPT]: "",
    [FIELD_KEY.CONTENT]: "",
    [FIELD_KEY.ARTISTS]: [],
    [FIELD_KEY.TAG]: "",
  });

  useEffect(() => {
    setLoading(true);
    EditorialApi
      .getArticleById(articleId)
      .then(res => {
        console.info('res', res);
        const { data } = res.data;
        setArticle(data);
      })
      .catch(err => {
        dispatch(showBackIcon(SHOW_ERROR));
        // dispatch({
        //   type: SHOW_BACK_ICON,
        //   payload: SHOW_ERROR
        // });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [articleId]);

  const gotoUpdate = () => {
    dispatch(showBackIcon(SHOW_EDITORIAL_UPDATE));
    // dispatch({
    //   type: SHOW_BACK_ICON,
    //   payload: SHOW_EDITORIAL_UPDATE
    // });
  };

  if (loading) {
    return (
      <Spinner size="medium" />
    );
  }
  return (
    <>
      <div className={style['title-container']}>
        <div className={style.title}>
          Preview Article
        </div>
        <Button
          text="Update"
          onClick={gotoUpdate}
        />
      </div>

      <div className={style['content-wrapper']}>
        <div className={style.wrapper}>
          <div className={style.title}>
            Article ID
          </div>
          <div className={style.content}>
            {article[FIELD_KEY.ID]}
          </div>
        </div>

        <div className={style.wrapper}>
          <div className={style.title}>
            Status
          </div>
          <div className={style.content}>
            <StatusView status={article[FIELD_KEY.STATUS]} />
          </div>
        </div>

        <div className={style.wrapper}>
          <div className={style.title}>
            Article Title
          </div>
          <div className={style.content}>
            {
              article[FIELD_KEY.ARTICLE_TITLE]
                ? article[FIELD_KEY.ARTICLE_TITLE]
                : NOT_SET_YET 
            }
          </div>
        </div>

        <div className={style.wrapper}>
          <div className={style.title}>
            Author Name
          </div>
          <div className={style.content}>
          {
            article[FIELD_KEY.AUTHOR_NAME]
              ? article[FIELD_KEY.AUTHOR_NAME]
              : NOT_SET_YET
          }
          </div>
        </div>

        <div className={style.wrapper}>
          <div className={style.title}>
            Publish Time
          </div>
          <div className={style.content}>
            {
              article[FIELD_KEY.PUBLISH_TIME]
                ? formatTime(article[FIELD_KEY.PUBLISH_TIME])
                : 'Not scheduled yet'
            }
          </div>
        </div>

        <div className={style.wrapper}>
          <div className={style.title}>
            Article Image
          </div>
          <div className={style.content}>
            {
              article[FIELD_KEY.ARTICLE_IMAGE]
                ? <Previewer url={article[FIELD_KEY.ARTICLE_IMAGE]} />
                : NOT_SET_YET
            }
          </div>
        </div>

        <div className={style.wrapper}>
          <div className={style.title}>
            Image Description
          </div>
          <div className={style.content}>
            {
              article[FIELD_KEY.IMAGE_DESCRIPTION]
                ? article[FIELD_KEY.IMAGE_DESCRIPTION]
                : NOT_SET_YET
            }
          </div>
        </div>

        <div className={style.wrapper}>
          <div className={style.title}>
            Excerpt
          </div>
          <pre className={style.content}>
            {
              article[FIELD_KEY.EXCERPT]
                ? article[FIELD_KEY.EXCERPT]
                : NOT_SET_YET
            }
          </pre>
        </div>

        <div className={`${style.wrapper} ${style.contentArea}`}>
          <div className={style.title}>
            Content
          </div>
          <pre className={style.content}>
            {
              article[FIELD_KEY.CONTENT]
                ? article[FIELD_KEY.CONTENT]
                : NOT_SET_YET
            }
          </pre>
        </div>

        <div className={`${style.wrapper} ${style.artists}`}>
          <div className={style.title}>
            Assign to Artist
          </div>
          <div className={style.content}>
            <div className={style['list']}>
              {
                article[FIELD_KEY.ARTISTS].length !== 0
                  ? article[FIELD_KEY.ARTISTS].map(artist => (
                      <span key={artist.id}>{artist.nickname}</span>
                    ))
                  : NOT_SET_YET
              }
            </div>
          </div>
        </div>

        <div className={style.wrapper}>
          <div className={style.title}>
            Tag
          </div>
          <div className={style.content}>
            {
              article[FIELD_KEY.TAG]
                ? article[FIELD_KEY.TAG]
                : NOT_SET_YET
            }
          </div>
        </div>
      </div>
    </>
  );
}
