export const API_ERROR_BTSE_TOKEN_EXPIRED = 10002;
export const API_ERROR_TOKEN_EXPIRED = 40000;


/** http 500 error message */
export const SERVER_ERROR = 'Service error! Please try again later.';

/** http 400 error message */
export const TOKEN_EXPIRED = 'not login or token expired';
export const INCORRECT_RESOURCE_ID = 'incorrect resource id';
