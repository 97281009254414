import { createSlice } from '@reduxjs/toolkit';

// import {
//   SET_ARTICLE_ID,
//   FETCH_ARTICLE_RESULT
// } from 'components/Editorial/consts';

const initialState = {
  articleId: '',
  articleDetail: {
    title: '',
    articleId: '',
    status: '',
    articleTitle: '',
    authorName: '',
    publishTime: '',
    articleImage: '',
    imageDescription: '',
    excerpt: '',
    content: '',
    assignToArtists: [],
    tag: ''
  }
};
export const editorialSlice = createSlice({
  name: "editorialSlice",
  initialState,
  reducers: {
    setArticleId: (state, action) => {
      state.articleId = action.payload;
    },
    fetchAritilceResult: (state, action) => {
      state.articleDetail = action.payload;
    },
  }
});

export const { setArticleId, fetchAritilceResult } = editorialSlice.actions;
export default editorialSlice.reducer;

// const editorialReducer = (state = initialState, actions) =>
//   produce(state, draft => {
//     const { type, payload } = actions;
//     switch(type) {
//       case SET_ARTICLE_ID:
//         draft.articleId = payload;
//         return draft;
//       case FETCH_ARTICLE_RESULT:
//         draft.articleDetail = payload;
//         return draft;
//     }
//   });

//   export default editorialReducer;