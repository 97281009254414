import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { showBackIcon } from 'reducers/headerReducer';
import { selectYears, setItemDetailId } from 'reducers/itemReducer';

import useTableConfig from 'hooks/useTableConfig';

import ItemDetail from './itemDetail';
import ListingPage from 'components/common/ListingPage';
import ErrorView from 'components/common/ErrorView';

import { shortenText } from 'utils';
import ItemApi from 'utils/apicallers/itemapi';

import { default as startYear } from './itemDetail/consts';
import { 
  SHOW_ITEM_DETAIL,
  SHOW_ITEM_UPDATE,
  SHOW_ERROR
} from 'components/universalheader/consts';
import { SORT } from 'components/common/Table/const';

import titleLeftIcon from 'assets/imgs/itemTitleIcon.svg';
import ViewIcon from 'assets/imgs/view.svg';
import ViewBlackIcon from 'assets/imgs/view-black.svg';
import EditIcon from 'assets/imgs/edit.svg';
import EditBlackIcon from 'assets/imgs/edit-black.svg';

import itemStyles from './style.module.scss';
import styles from 'components/common/ListingPage/style.module.scss';

export default function Item() {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);
  const [isFetchingMint, setIsFetchingMint] = useState(false);
  const [mintingCount, setMintingCount] = useState(0);
  const { backDesi } = useSelector(state => {
    return state.HeaderReducer;
  });
  const {
    currentPage,
    pagData,
    sort,
    pageRef,
    setSortData,
    setPageData
  } = useTableConfig();

  const cols = [
    {
      title: 'ID',
      key: 'itemId',
      render: text => (
        <div className={styles.id}>{`#${shortenText(text, 5)}`}</div>
      )
    },
    {
      title: 'ITEM NAME',
      key: 'name',
      sorter: true,
      render: (text, row) => {
        return (
          <div className={itemStyles.itemNameWrapper}>
            <img 
              src={row.preview}
              height={40}
              width={40}
            />
            <span>{text}</span>
          </div>
        );
      }
    },
    {
      title: 'TOKEN COUNT',
      key: 'tokenCounts',
      align: 'left',
      sorter: true
    },
    {
      title: 'ARTIST',
      key: 'nickname',
      align: 'left',
      sorter: true
    },
    {
      title: 'VIEW',
      key: 'view',
      align: 'center',
      width: '5%',
      clickable: true,
      render: (text, row, router, { isHover }) => {
        return (
          <img
            className={itemStyles.clickable}
            src={isHover ? ViewBlackIcon : ViewIcon}
            onClick={() => gotoDetail(row.itemId)}
          />
        );
      }
    },
    {
      title: 'EDIT',
      key: 'edit',
      align: 'center',
      width: '5%',
      clickable: true,
      render: (text, row, router, { isHover }) => {
        return (
          <img
            className={itemStyles.clickable}
            src={isHover ? EditBlackIcon : EditIcon}
            onClick={() => gotoUpdate(row.itemId)}
          />
        );
      }
    }
  ];

  const fetchItemsStatus = () => {
    setIsFetchingMint(true);
    ItemApi
      .getItemStatus()
      .then(res => {
        const total = res.data.data.total;
        setMintingCount(total);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setIsFetchingMint(false);
      });
  };
  const fetchApi = () => {
    let orderBy = 'create_time';
    switch(sort.key) {
      case 'name':
      case 'nickname':
        orderBy = sort.key;
        break;
      case 'tokenCounts':
        orderBy = 'TOKEN_COUNTS';
        break;
      default:
        break;
    }

    const sorting = sort.type === SORT.ASC ? 'ASC' : 'DESC';
    const params = {
      orderBy: orderBy,
      page: currentPage,
      pageSize: pageRef.current.size,
      sorting
    };
    setApiLoading(true);
    ItemApi
      .getItems(params)
      .then(res => {
        const { list, navigateLastPage } = res.data.data;
        setTableData(list);
        setPageData({
          total: navigateLastPage
        });
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setApiLoading(false);
      });
  };
  useEffect(() => {
    const destList = [
      SHOW_ITEM_DETAIL,
      SHOW_ITEM_UPDATE
    ];
    if (!destList.includes(backDesi)) {
      fetchApi();
      fetchItemsStatus();
    }
  }, [sort, currentPage, backDesi]);
  useEffect(() => {
      const currentYear = new Date().getFullYear();
      let result = [];
      for(let i = currentYear ; i >= startYear ; i-- ){
        result.push({value: i, label: i});
      }
      dispatch(selectYears(result));
      // dispatch({
      //   type: SELECT_YEARS,
      //   payload: result,
      // });
  }, []);
  
  const gotoDetail = id => {
    dispatch(setItemDetailId(id));
    // dispatch({
    //   type: SET_ITEM_DETAIL_ID,
    //   payload: id
    // });
    dispatch(showBackIcon(SHOW_ITEM_DETAIL));
  };

  const gotoUpdate = id => {
    dispatch(setItemDetailId(id));
    // dispatch({
    //   type: SET_ITEM_DETAIL_ID,
    //   payload: id
    // });
    dispatch(showBackIcon(SHOW_ITEM_UPDATE));
  };

  const handleSort = (key, type) => {
    setSortData({ key, type });
  };
  const handlePagAction = (page) => {
    setPageData({
      current: page
    });
  };
  
  const renderContent = () => {
    switch(backDesi){
      case SHOW_ITEM_DETAIL:
      case SHOW_ITEM_UPDATE:
        return <ItemDetail />;
      case SHOW_ERROR:
        return (
          <ErrorView />
        );
      default:
        return (
          <ListingPage
            data={tableData}
            cols={cols}
            title={{
              text: "Item",
              icon: titleLeftIcon
            }}
            sort={sort}
            onSortClick={handleSort}
            pagData={pagData}
            pagFetchAction={handlePagAction}
            messageComponent={
              isFetchingMint
              ? null
              : mintingCount >= 1
                ? (
                    <div className={itemStyles.mintingStatus}>
                      {`${mintingCount} items are minting.`}
                    </div>
                  )
                : (
                    <div className={itemStyles.mintingStatus}>
                      {`${mintingCount} item is minting.`}
                    </div>
                  )
            }
            loading={apiLoading}
          />
        );
    }
  };
  return (
    <div>
      <div className={styles.contentArea}>
        { renderContent() }
      </div>
    </div>
  );
}