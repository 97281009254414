import React from 'react';

import styles from './styles.module.scss';

const Switch = ({ checked, onChange, inputkey }) => {
  const handleChecked = e => {
    const valObj = {};
    valObj[inputkey] = e.target.checked;
    onChange(valObj);
  };

  return (
    <label className={styles.container}>
      <input
        type="checkbox"
        checked={checked}
        onChange={handleChecked}
      />
      <span className={styles.slider} />
    </label>
  );
};
const areEqual = (prevProps, nextProps) => {
  if(prevProps.checked !== nextProps.checked || prevProps.inputkey !== nextProps.inputkey){
    return false;
  }
  return true;
};

export default React.memo(Switch, areEqual);
