import React, { useState, useRef } from 'react';
import useClickModal from 'hooks/useClickModal';

import Button from 'components/common/Button';

import ArrowDownIcon from 'assets/imgs/ic-arrow-down-white.svg';

import styles from './styles.module.scss';

const DropdownButton = ({
  text = '...',
  list = [],
  showArrow = false,
  onSelect = () => {},
  disabled = false
}) => {
  const [listVisible, setListVisible] = useState(false);
  const ref = useRef(null);

  useClickModal(ref, null, () => setListVisible(false));

  const handleSelect = data => {
    onSelect(data);
    setListVisible(false);
  };

  const renderBottunText = () => {
    if (!showArrow) {
      return text;
    } else {
      return (
        <div className={styles.buttonText}>
          <div className={styles.text}>{text}</div>
          <img
            className={`${styles.chroven} ${listVisible && styles['chroven--click']}`}
            src={ArrowDownIcon}
            loading="lazy"
          />
        </div>
      );
    }
  };

  return (
    <div
      ref={ref}
      className={styles.container}
    >
      <Button
        disabled={disabled}
        text={renderBottunText()}
        onClick={() => setListVisible(prev => !prev)}
      />
      {
        listVisible && (
          <div className={styles.list}>
            {
              list.map((d, index) => {
                return (
                  <div
                    key={index}
                    className={styles.item}
                    onClick={() => handleSelect(d)}
                  >
                    {
                      d?.icon && 
                        <img 
                          src={d.icon}
                          loading="lazy"
                          height="14px"
                          width="14px"
                        />
                    }
                    {d.text}
                  </div>
                );
              })
            }
          </div>
        )
      }
    </div>
  );
};

export default React.memo(DropdownButton);
