
import closeIcon from 'assets/imgs/closeIcon.svg';
import styles from './style.module.scss';

export default function AttributesCard({attr, showEdit, deleteHandler}) {
  const triggerShowDelete = e => {
    document.getElementById(`${attr.main}${attr.sub}`).style.display= 'block';
  };
  const triggerHideDelete = e => {
    document.getElementById(`${attr.main}${attr.sub}`).style.display= 'none';
  };

  return (
    <div
      className={styles.cardContainer}
      onMouseEnter={showEdit && triggerShowDelete}
      onMouseLeave={showEdit && triggerHideDelete}
    >
      {
        showEdit &&
          <img
            id={`${attr.main}${attr.sub}`}
            className={styles.closeIcon}
            src={closeIcon}
            width="16px"
            height="16px"
            onClick={() => {deleteHandler(attr);}}
          />
      }
      {
        attr?.main 
          ? (
              <>
                <div className={styles.mainText}>{attr.main}</div>
                <div>{attr.sub}</div>
              </>
            )
          : <span style={{color: '#FF0000'}}>Not set yet</span>
      }
      
    </div>
  );
}