export const TYPE = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  THIRD: 'THIRD'
};

export const BORDER_COLOR = {
  [TYPE.PRIMARY]: '#3F7EF4',
  [TYPE.SECONDARY]: '#FC9536',
  [TYPE.THIRD]: '#45A973'
};

export const BG_COLOR = {
  [TYPE.PRIMARY]: 'rgba(62, 126, 244, 0.3)',
  [TYPE.SECONDARY]: 'rgba(252, 149, 54, 0.3)',
  [TYPE.THIRD]: 'rgba(69, 169, 115, 0.3)'
};