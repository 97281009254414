import { useHistory } from 'react-router-dom';
import { useState, useRef, useEffect, useMemo } from 'react';
import _ from 'lodash';

import styles from './styles.module.scss';
import Empty from './Empty';
import ExpendIcon from './ExpendIcon';

import { SORT } from './const';
import SortIcon from './SortIcon';
import Spinner from 'components/common/Spinner';

const DEFAULT_COLUMN_SIZE = 150;

const ALIGN_MAP = {
  left: 'align-left',
  center: 'align-center',
  right: 'align-right'
};

// for expend icon position
const EXPEND_POSITION = {
  left: 'left',
  right: 'right',
};

/**
 * 1. columns: a array of object we can defined specific render function as call-back.
 *    if no render function will use key field to fetch data
 * 2. datasource: a array of objects where data comes from
 * 3. expandable: indicate if we will make one drop-arrow to expand the column or not
 */
export default function Table({
  columns = [],
  dataSource = [],
  expandable,
  emptyContent,
  sort = {
    key: '',
    type: SORT.DESC
  },
  loading = false,
  height = null,
  onSortClick = (key, sortType) => {},
  fetchAction = () => {},
}) {
  const [expandedList, setExpandedList] = useState([]); // store expend key
  const [showScroll, setShowScroll] = useState(false);
  const [hoverId, setHoverId] = useState('');
  const tableRef = useRef();
  const scrollLeftRef = useRef(0);
  const router = useHistory();

  const handleAlignPosition = (align) => {
    return align && ALIGN_MAP[align] || ALIGN_MAP.left;
  };

  useEffect(() => {
    let timer = null;
    if (showScroll) {
      timer = setTimeout(() => {
        setShowScroll(false);
      }, 500);
    }
    return () => {
      timer && clearTimeout(timer);
    };
  }, [showScroll]);

  const handleScroll = e => {
    const { scrollTop, scrollLeft, offsetHeight, scrollHeight } = e.target;
    const isVerticalScroll = scrollLeft === scrollLeftRef.current;
    
    if (scrollHeight - offsetHeight - scrollTop < 1 && isVerticalScroll) {
      fetchAction();
    }
    scrollLeftRef.current = scrollLeft;
    setShowScroll(true);
  };

  /** trigger API to get new dataSource */
  const handleSort = (key, sort) => {
    setExpandedList([]);
    onSortClick(key, sort);
  };
  
  const generateHeader = (columns) => {
    return (
      <thead>
        <tr>
          {
            columns.map((col, index) => {
              return (
                <th
                  key={col.key}
                  className={`${styles[handleAlignPosition(col.align)]} ${col.borderRight && styles['border-right']}`}
                  style={{
                    width: col.width ? col.width : DEFAULT_COLUMN_SIZE,
                  }}
                >
                  { 
                    col.sorter ?
                      <SortIcon
                        prefix={col.title}
                        align={col.align}
                        onSortClick={(sort) => handleSort(col.key, sort)}
                        sortType={sort.key === col.key && sort.type}
                      />
                      :
                      col.title
                  }
                </th>
              );
            })
          }
          {
            expandable && (
              <th 
                className={styles[handleAlignPosition('right')]}
                key="expend-right">
              </th>
            )
          }
        </tr>
      </thead>
    );
  };

  const handleExpendRowClick = (key, data) => {
    if (expandedList.includes(key)) {
      setExpandedList(expandedList => expandedList.filter(d => d !== key));
    } else {
      setExpandedList(expandedList => [...expandedList, key]);
    }
  };

  const handleDataRowStyle = col => {
    let style = {
      ...(col.style && {...col.style}),
      ...(col.width && { width: col.width }),
      ...(col.width && { minWidth: col.width })
    };
    return style;
  };

  const handleTdClass = col => {
    const classList = [styles[handleAlignPosition(col.align)]];
    if (col.fontStyle && styles[col.fontStyle]) {
      classList.push(styles[col.fontStyle]);
    }
    if (col.clickable === true) {
      classList.push(styles.clickable);
    }
    if (col.borderRight) {
      classList.push(styles['border-right']);
    }

    return classList.join(' ');
  };

  const renderDataRow = (columns, dataSource) => {
    const trList = [];
    dataSource.forEach((data, key) => {
      const isHover = key === hoverId;
      trList.push(
        <tr
          className={isHover ? styles.hover : ''}
          key={`tr-${key}`}
          onMouseEnter={() => setHoverId(key)}
          onMouseLeave={() => setHoverId('')}
        >
          {
            columns.map((col, index) => {
              if (col.render) {
                return (
                  <td
                    key={`${key}-${index}`}
                    className={handleTdClass(col, index)}
                    style={handleDataRowStyle(col)}
                  >
                    <div>
                      {
                        col.render(data[col.key], data, router, {
                          isHover: isHover
                        })
                      }
                    </div>
                  </td>
                );
              } else {
                return (
                  <td
                    key={`${key}-${index}`}
                    className={handleTdClass(col, index)}
                    style={handleDataRowStyle(col)}
                  >
                    <div>
                      { data[col.key] }
                    </div>
                  </td>
                );
              }
            })
          }
          {
            expandable && (
              <td
                key={`expend-icon-${key}`}
                className={`${styles[handleAlignPosition('right')]} ${styles.clickable}`}
                onClick={() => handleExpendRowClick(key)}>
                <ExpendIcon
                  isExpended={expandedList.includes(key)}
                />
              </td>
            )
          }
        </tr>
      );
      if (expandable) {
        trList.push(
          <tr 
            key={`expend-tr-${key}`}
            className={`${expandedList.includes(key) ? styles.show : styles.hide}`}
          >
            <td
              className={styles.expand}
              key={`expend-td-${key}`}
              colSpan="100%"
            >
              {expandable.expandedRowRender(data)}
            </td>
          </tr>
        );
      }
    });

    return (
      <tbody>
        { trList }
      </tbody>
    );
  };

  const renderEmpty = () => {
    return (
      <div className={styles['empty-container']}>
        <div className={styles.header}>
          {
            columns.map(col => {
              return (
                <div
                  key={col.key}
                  className={`${styles.item} ${styles[handleAlignPosition([col.align])]}`}
                >
                  { col.title }
                </div>
              );
            })
          }
        </div>
        <Empty content={emptyContent} />
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <div className={styles['empty-container']}>
        <div className={styles.header}>
          {
            columns.map(col => {
              return (
                <div
                  key={col.key}
                  className={`${styles.item} ${styles[handleAlignPosition([col.align])]}`}
                >
                  { col.title }
                </div>
              );
            })
          }
        </div>
        <div className={styles.loading}>
          <Spinner size="medium" />
        </div>
      </div>
    );
  };

  const leftCols = useMemo(() => {
    return columns.filter(d => d.fixed === 'left');
  }, [columns]);

  const middleCols = useMemo(() => {
    return columns.filter(d => !d.fixed );
  }, [columns]);

  const rightCols = useMemo(() => {
    return columns.filter(d => d.fixed === 'right');
  }, [columns]);

  const hasLeftTable = leftCols.length > 0;
  const hasMiddleTable = middleCols.length > 0;
  const hasRightTable = rightCols.length > 0;

  const multiTableClass = () => {
    if ( hasLeftTable && hasMiddleTable ) {
      return hasRightTable ? styles['three-table'] : styles['left-middle-table'];
    } else {
      return !hasLeftTable && hasMiddleTable && hasRightTable
        ? styles['middle-right-table']
        : styles['middle-table-only'];
    }
  };

  const multiTableStyle = () => {
    let style = {};
    if (height) {
      style.maxHeight = height;
    }

    return style;
  };

  return (
    <>
      {
        loading
          ? renderLoading()
          : dataSource.length === 0
              ? renderEmpty()
              : <div
                  className={`${styles['multi-fixed-table']} ${
                    showScroll && styles.scroll} ${multiTableClass()}`
                  }
                  style={multiTableStyle()}
                  onScroll={_.throttle(handleScroll, 400)}
                >
                  {
                    hasLeftTable && (
                      <table className={`${styles['table-wrapper']} ${styles['left-table']}`}>
                        { generateHeader(leftCols) }
                        { renderDataRow(leftCols, dataSource) }
                      </table> 
                    )
                  }
                    <table
                      className={`${styles['table-wrapper']} ${styles['middle-table']}`}
                      ref={tableRef}
                      onScroll={_.throttle(handleScroll, 400)}
                    >
                      { generateHeader(middleCols) }
                      { renderDataRow(middleCols, dataSource) }
                    </table>
                  {
                    hasRightTable && (
                      <table className={`${styles['table-wrapper']} ${styles['right-table']}`}>
                        { generateHeader(rightCols) }
                        { renderDataRow(rightCols, dataSource) }
                      </table>
                    )
                  }
                </div>
      }
    </>
  );
}