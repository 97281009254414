import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useTableConfig from 'hooks/useTableConfig';

import { showBackIcon } from 'reducers/headerReducer';
import { setArticleId } from 'reducers/editorialReducer';

import ListingPage from 'components/common/ListingPage';
import ErrorView from 'components/common/ErrorView';
import StatusView from 'components/common/StatusView';
import DeleteModal from 'components/common/DeleteModal';
import Article from './Article';
import Detail from './Detail';

import { formatTime } from 'utils/common';
import EditorialApi from 'utils/apicallers/editorial';

import { SORT } from 'components/common/Table/const';
// import { ARTICLE_STATUS } from './consts';
import { API_TYPE } from 'components/common/DeleteModal/const';

import EditLogo from 'assets/imgs/edit-grey.svg';
import TrashIcon from 'assets/imgs/trash.svg';
import TrashBlackIcon from 'assets/imgs/trash-black.svg';
import ViewIcon from 'assets/imgs/view.svg';
import ViewBlackIcon from 'assets/imgs/view-black.svg';
import EditIcon from 'assets/imgs/edit.svg';
import EditBlackIcon from 'assets/imgs/edit-black.svg';

import {
  SHOW_EDITORIAL_DETAIL,
  SHOW_EDITORIAL_UPDATE,
  SHOW_EDITORIAL_ADD,
  SHOW_ERROR
} from 'components/universalheader/consts';

const COL_KEYS = {
  ARTICLE_ID: 'id',
  TITLE: 'title',
  PUBLISH_TIME: 'publishTime',
  UPDATE_TIME: 'updateTime',
  STATUS: 'status',
};

export default function Editorial() {
  const { backDesi } = useSelector(state => state.HeaderReducer);
  const [tableData, setTableData] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);
  const [delVisible, setDelVisible] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const {
    currentPage,
    pagData,
    sort,
    pageRef,
    setSortData,
    setPageData
  } = useTableConfig();
  const dispatch = useDispatch();

  const fetchData = () => {
    const sorting = sort.type === SORT.ASC ? 'ASC' : 'DESC';    
    const orderBy = COL_KEYS[sort.key] ?? 'update_time';
    
    const params = {
      sorting,
      orderBy,
      page: currentPage,
      pageSize: pageRef.current.size
    };
    setApiLoading(true);
    EditorialApi
      .getArticles(params)
      .then(res => {
        const { navigateLastPage, list } = res.data.data;
        setTableData(list);
        setPageData({
          total: navigateLastPage
        });
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => setApiLoading(false));
  };

  useEffect(() => {
    const dest = [
      SHOW_EDITORIAL_DETAIL,
      SHOW_EDITORIAL_ADD,
      SHOW_EDITORIAL_UPDATE,
      SHOW_ERROR
    ];
    !dest.includes(backDesi) && fetchData();
  }, [sort, currentPage, backDesi]);
  
  const gotoDetail = id => {
    dispatch(showBackIcon(SHOW_EDITORIAL_DETAIL));
    dispatch(setArticleId(id));
  };

  const gotoUpdate = id => {
    dispatch(showBackIcon(SHOW_EDITORIAL_UPDATE));
    dispatch(setArticleId(id));
  };

  const handleDelete = id => {
    setSelectedId(id);
    setDelVisible(true);
  };

  const cols = [
    {
      title: 'ARTICLE ID',
      key: COL_KEYS.ARTICLE_ID
    },
    {
      title: 'TITLE',
      key: COL_KEYS.TITLE,
      sorter: true
    },
    {
      title: 'PUBLISH TIME',
      key: COL_KEYS.PUBLISH_TIME,
      sorter: true,
      render: (time, row) => {
        return time ? formatTime(time) : '-';
      }
    },
    {
      title: 'LAST UPDATE TIME',
      key: COL_KEYS.UPDATE_TIME,
      sorter: true,
      render: time => formatTime(time)
    },
    {
      title: 'STATUS',
      key: COL_KEYS.STATUS,
      sorter: true,
      render: (text) => {
        return (
          <StatusView
            status={text}
          />
        );
      }
    },
    {
      title: 'VIEW',
      key: 'view',
      align: 'center',
      width: '3%',
      clickable: true,
      render: (text, row, _, { isHover }) => {
        return (
          <img
            src={isHover ? ViewBlackIcon : ViewIcon}
            onClick={() => gotoDetail(row.id)}
          />
        );
      }
    },
    {
      title: 'EDIT',
      key: 'edit',
      align: 'center',
      width: '3%',
      clickable: true,
      render: (text, row, _, { isHover }) => {
        return (
          <img
            src={isHover ? EditBlackIcon : EditIcon}
            onClick={() => gotoUpdate(row.id)}
          />
        );
      }
    },
    {
      title: 'DELETE',
      key: 'delete',
      align: 'center',
      width: '3%',
      clickable: true,
      render: (text, row, _, { isHover }) => {
        return (
          <img
            src={isHover ? TrashBlackIcon : TrashIcon}
            onClick={() => handleDelete(row.id)}
          />
        );
      }
    }
  ];

  const goAddNew = () => {
    dispatch(showBackIcon(SHOW_EDITORIAL_ADD));
  };

  const handleSort = (key, type) => {
    setSortData({ key, type });
  };
  const handlePagAction = page => {
    setPageData({
      current: page
    });
  };

  const handleDeleteComplete = isSuccess => {
    setDelVisible(false);
    isSuccess && fetchData();
  };

  const renderContent = () => {
    switch(backDesi) {
      case SHOW_EDITORIAL_DETAIL:
        return (
          <Detail />
        );
      case SHOW_EDITORIAL_UPDATE:
      case SHOW_EDITORIAL_ADD:
        return (
          <Article />
        );
      case SHOW_ERROR:
        return (
          <ErrorView />
        );
      default:
        return (
          <>
            <DeleteModal 
              id={selectedId}
              visible={delVisible}
              apiType={API_TYPE.EDITORIAL}
              title="Delete Article"
              errTitle="No Able to Delete Article"
              content="Are you sure to delete this article?"
              errContent="This article cannot be deleted."
              onComplete={handleDeleteComplete}
            />
            <ListingPage
              data={tableData}
              cols={cols}
              title={{
                text: 'Editorial Articles',
                icon: EditLogo
              }}
              sort={sort}
              loading={apiLoading}
              pagData={pagData}
              addNewFn={goAddNew}
              onSortClick={handleSort}
              pagFetchAction={handlePagAction}
            />
          </>
        );
    }
  };

  return (
    <div>
      { renderContent() }
    </div>
  );
}
