import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';

import BorderInput from 'components/common/BorderInput';
import Modal from 'components/common/Modal';
import Button from 'components/common/Button';
import SelectList from 'components/common/SelectList';

import MintApi from 'utils/apicallers/mintapi';

import { validator } from 'utils/common';
import { STATUS, DESCRIPTION_MAX_LEN } from '../const';
import { SELECTTYPE } from 'components/common/SelectList/consts';

import arrowDownIcon from 'assets/imgs/ic-arrow-down.svg';
import styles from './style.module.scss';

const TOKEN_LIMIT = 20;

export default function DescribeSection({ setStatus, errorHandle }) {
  const [edition, setEdition] = useState(null);
  const [selectedArtist, setSelectedArtist] = useState({
    nickname: '',
    userId: ''
  });
  const [editionErr, setEditionErr] = useState(false);
  const [showArtistList, setShowArtistList] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionErr, setDescriptionErr] = useState(false);

  const isSubmittable = edition && title && selectedArtist.userId && description;
  
  const newMintingData = useSelector(state => {
    return state.MintingReducer.newMintingData;
  }); 

  const onEditionChange = e => {
    const val = parseInt(e, 10);
    if (!val) {
      setEdition(null);
      setEditionErr(false);
      return;
    }
    setEdition(val);
    if (val > 0 && val <= TOKEN_LIMIT) {
      setEditionErr(false);
    } else {
      setEditionErr(true);
    }
  };
  const handleSelectArtist = data => {
    setShowArtistList(false);
    if (!data) {
      return;
    }
    setSelectedArtist(data);
  };

  const stripHTML = text => {
    return text.replace(validator.htmlTag, "");
  };

  const onTitleChange = value => {
    setTitle(value);
  };
  const onDescriptionChange = e => {
    const s = e.target.value;
    if (s.length > DESCRIPTION_MAX_LEN) {
      setDescriptionErr(true);
    } else {
      setDescription(e.target.value);
      setDescriptionErr(false);
    }
  };
  const handleMint = () => {
    errorHandle('');
    const mintingId = newMintingData.mintingId;
    const params = {
      tokenCounts: edition,
      name: stripHTML(title),
      description: stripHTML(description),
      artistId: selectedArtist.userId,
      status: 'READY_TO_MINT'
    };

    MintApi.update(mintingId, params)
      .then(res => {
        setStatus(STATUS.MINT);
      })
      .catch(err => {
        console.error(err);
        errorHandle(err?.response?.data?.msg);
      });
  };

  return (
    <div className={styles.container}>
      Describe your NFT
      <Prompt message="" when={true} />
      
      <div className={styles.content}>
        <div className={styles.coverWrapper}>
          <img src={newMintingData.itemPreview}/>
        </div>
        <div className={styles.formWrapper}>
          <div className={styles.title}>
            Edition
          </div>
          <BorderInput
            type='tel'
            value={edition}
            onInputChange={onEditionChange}
            placeholder={`1-${TOKEN_LIMIT}`}
            isError={editionErr}
            errMsg={editionErr && "Not available"}
          />
          <div className={`${styles.title} ${editionErr && styles.err}`}>
            Title
          </div>
          <BorderInput
            value={title}
            onInputChange={onTitleChange}
            placeholder={'Title of NFT'} />
          <div className={styles.title}>
            Artist
          </div>
          <div
            className={`${styles.dropdown} ${selectedArtist.nickname && styles.active}`}
            onClick={() => setShowArtistList(true)}
          >
            { 
              selectedArtist.nickname
                ? selectedArtist.nickname
                : 'Select Artist'
            }
            <img className={showArtistList && styles.up} src={arrowDownIcon} />
          </div>
          <div className={styles.title}>
            Description
          </div>
          <textarea
            className={`${styles.description} ${descriptionErr && styles.error}`}
            placeholder='Descripe your NFT'
            value={description}
            onChange={onDescriptionChange} />
          <div className={styles.remain}>
            { DESCRIPTION_MAX_LEN - description.length}
          </div>
        </div>
      </div>
      <div className={styles.btnArea}>
        <div className={styles.btn}>
          <Button
            type='simple'
            text='Cancel'
            onClick={() => setStatus(STATUS.UPLOAD)} />
        </div>
        <div className={styles.btn}>
        <Button
          text='Mint NFT'
          onClick={handleMint} 
          disabled={!isSubmittable} />
        </div>
      </div>
      {
        showArtistList && (
          <Modal>
            <SelectList
              title={'Select Artist'}
              handleCloseModal={handleSelectArtist}
              type={SELECTTYPE.MINTING}
            />
          </Modal>
        )
      }
    </div>
  );
}