import DashboardTab from 'components/common/DashboardTab';

export default function MarketTab({
  activeTab,
  setTab,
  noMargin = false
}) {
  const MARKET_LIST = [
    {
      category: 'Primary Market',
    },
    {
      category: 'Secondary Market',
    },
  ];

  const handleTab = e => {
    const tabCategory = e.currentTarget.dataset.key;
    const tabIndex = MARKET_LIST[0].category == tabCategory ? 0 : 1;
    setTab(tabIndex);
  };

  return (
    <DashboardTab 
      data={MARKET_LIST}
      activeTab={activeTab}
      handleChangeTab={handleTab}
      noMargin={noMargin}
    />
  );
}
