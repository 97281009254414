import React, { useState } from 'react';

import { formatNumber, formatPercentage, checkArrow } from 'utils/common';
import DashboardTab from 'components/common/DashboardTab';
import Spinner from 'components/common/Spinner';

import demo from '../../assets/imgs/rankDemoImg.jpg';

import style from './style.module.scss';
import gstyle from 'components/App/style.module.scss';

export default function GeneralRanking({
  data, 
  titleText, 
  smallPadding=false, 
  noPadding=false,
  loading=false,
  startIndex = 1
}) {  
  const [activeTab, setActiveTab] = useState( data.length > 0 && data[0].category ? 0 : 1);

  const handleChangeTab = e => {
    const tabCategory = e.currentTarget.dataset.key;
    const tabIndex = data[0].category == tabCategory ? 0 : 1;
    setActiveTab(tabIndex);
  };
  const renderTrend = trend => {
    return (
      <div className={ trend > 0 ? gstyle.greenColor : trend === 0 ? gstyle.greyColor : gstyle.redColor}>
        <span>
          { checkArrow(trend) }
        </span>
        <span className={style.rankTrend}>
          { trend !== 0 && `${Math.abs(formatPercentage(trend))}%`}
        </span>
      </div>
    );
  };
  const renderTab = () => {
    return (
      <DashboardTab
        data={ data }
        activeTab={ activeTab }
        handleChangeTab={handleChangeTab}
      />
    );

    // return (
    //   <div className={style.tabWrapper}>
    //     {
    //       data.map((d, index)=> {
    //         return (
    //           <span
    //             data-key={d.category}
    //             className={`${style.tab} ${index === activeTab ? gstyle.innerTabActive : ''}`}
    //             key={d.category}
    //             onClick={handleChangeTab}
    //           >
    //             {d.category}
    //           </span>
    //         );
    //       })
    //     }
    //   </div>
    // );
  };
  const renderCatetoryData = data => {
    if (data.length === 0) {
      return (
        <div className={style['no-data']}>No Data</div>
      );
    }

    return (
      data.map((d, index) => {
        return (
          <React.Fragment key={d.name}>
            <div className={style.rankItem}>
              <span className={style.rankNum}>
                { startIndex++ }
              </span>
              <img
                className={style.rankImg}
                src={ d.img || demo }
                width="48px"
                height="48px"
              />
              
              <div className={style.rankContent}>
                <span>{d.name}</span>
                <div className={style.rankDetail}>
                  <span className={style.rankNumber}>
                    ${ formatNumber(d.content) }
                  </span>
                  <span>{d.trend !== undefined && renderTrend(d.trend)}</span>
                </div>
              </div>
            </div>
            <div className={style.seperator} />
          </React.Fragment>
        );
      })
    );
  };
  const checkWhichTabToRender = () => {
    return renderCatetoryData(data[activeTab].data);
  };

  return (
    <div className={style.container}>
      {/* As design spec have two different padding */}
      <div
        className={`${style.flexColumnWrapper} ${
          noPadding 
            ? style.noPadding
            : smallPadding
              ? style.smallPaddingContentWrapper 
              : style.contentWrapper
        }`}
      >
        { 
          titleText &&
            <div className={style.title}>
              { titleText }
            </div>
        }

        { data.length > 0 && data[0].category && renderTab() }

        {
          loading
            ? <div className={style['loading-wrapper']}>
                <Spinner size="medium" />
              </div>
            : <div className={style.listContent}>
                {
                  data.length > 0 && data[0].category
                    ? checkWhichTabToRender()
                    : renderCatetoryData(data)
                }
              </div>
        }
      </div>
    </div>
  );
}