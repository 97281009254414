import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { hideBackIcon } from 'reducers/headerReducer';

import Button from 'components/common/Button';
import DropdownButton from 'components/common/DropdownButton';
import StatusView from 'components/common/StatusView';
import Input from 'components/common/BorderInput';
import TextArea from 'components/common/TextArea';
import DragDrop from 'components/common/DragDrop';
import Spinner from 'components/common/Spinner';
import UploadButton from 'components/common/UploadButton';
import Previewer from 'components/common/Previewer';
import ErrorText from 'components/common/ErrorText';
import DateTimePicker from '../DateTimePickerModal';
import DeleteModal from 'components/common/DeleteModal';
import MultiSelectListModal from '../MultiSelectListModal';

import { formatTime, checkFileSize, fetchMatchPattern } from 'utils/common';

import {
  SHOW_EDITORIAL_ADD,
} from 'components/universalheader/consts';
import {
  MODE,
  ARTICLE_IMAGE_DIMENSION,
  ARTICLE_IMAGE_SIZE_IN_MB,
  FIELD_KEY,
  SUBMIT_TYPE,
  REQUIRED_MSG,
  SUBMIT_TYPE_TEXT,
  ARTICLE_STATUS,
  DROPDOWN_BUTTON_LIST,
  OTHERS_BUTTON_LIST,
  TAG_CHAR_LIMIT
} from '../consts';
import { API_TYPE } from 'components/common/DeleteModal/const';
import { fileExtensions } from 'consts/appConsts';

import AddIcon from 'assets/imgs/ic-add.svg';
import TrashIcon from 'assets/imgs/trash-red.svg';

import style from './style.module.scss';
import EditorialApi from 'utils/apicallers/editorial';

const INIT_ERR_MSG = {
  [FIELD_KEY.STATUS]: '',
  [FIELD_KEY.ARTICLE_TITLE]: '',
  [FIELD_KEY.AUTHOR_NAME]: '',
  [FIELD_KEY.PUBLISH_TIME]: '',
  [FIELD_KEY.ARTICLE_IMAGE]: '',
  [FIELD_KEY.IMAGE_DESCRIPTION]: '',
  [FIELD_KEY.EXCERPT]: '',
  [FIELD_KEY.CONTENT]: '',
  [FIELD_KEY.ARTISTS]: '',
  [FIELD_KEY.TAG]: ''
};
const patternMatch = fetchMatchPattern(/(?:\.([^.]+))?$/);

export default function Article() {
  const dispatch = useDispatch();
  const { backDesi } = useSelector(state => {
    return state.HeaderReducer;
  });
  const { articleId } = useSelector(state => {
    return state.EditorialReducer;
  });
  const mode = backDesi === SHOW_EDITORIAL_ADD
    ? MODE.NEW
    : MODE.UPDATE;
  const [artistVisible, setArtistVisible] = useState(false);
  const [article, setArticle] = useState({
    [FIELD_KEY.STATUS]: 'DRAFT',
    [FIELD_KEY.ARTICLE_TITLE]: '',
    [FIELD_KEY.AUTHOR_NAME]: '',
    [FIELD_KEY.PUBLISH_TIME]: 0,
    [FIELD_KEY.ARTICLE_IMAGE]: '',
    [FIELD_KEY.IMAGE_DESCRIPTION]: '',
    [FIELD_KEY.EXCERPT]: '',
    [FIELD_KEY.CONTENT]: '',
    [FIELD_KEY.ARTISTS]: [],
    [FIELD_KEY.TAG]: ''
  });
  const [scheduleVisible, setScheduleVisible] = useState(false);
  const [errMsg, setErrMsg] = useState(INIT_ERR_MSG);
  const [apiErrMsg, setApiErrMsg] = useState('');
  const [apiLoading, setApiLoading] = useState(false);
  const [imgUploading, setImgUploading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [delVisible, setDelVisible] = useState(false);
  
  const formRef = useRef();
  const submitTypeRef = useRef();
  const submitDataRef = useRef(article);

  useEffect(() => {
    // always fetch new data on mode = update
    /**
     * listing -> detail
     * listing -> update
     * detail -> update
     */
    if (mode === MODE.UPDATE) {
      setApiLoading(true);
      EditorialApi
        .getArticleById(articleId)
        .then(res => {
          const { data } = res.data;
          console.log('data', data);
          
          setArticle(data);
          submitDataRef.current = data;
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          setApiLoading(false);
        });
    }
  }, [mode, articleId]);

  const gotoListing = () => {
    dispatch(hideBackIcon());
  };

  const handleInput = (key, value) => { // TODO: all input field has word limit.
    setArticle(prev => ({
      ...prev,
      [key]: value
    }));
    submitDataRef.current[key] = value;
    if (errMsg[key] === REQUIRED_MSG && value) {
      setErrMsg(prev => ({
        ...prev,
        [key]: ''
      }));
    }
  };

  const handleSubmitImage = file => {
    const setImgErrMsg = msg => {
      setErrMsg(prev => ({
        ...prev,
        [FIELD_KEY.ARTICLE_IMAGE]: msg
      }));
    };
    setImgErrMsg('');
    
    const fileExtension = patternMatch(file.name);
    if(!fileExtensions.img.includes(fileExtension)){
      setImgErrMsg('file type not supported');
      return;
    }

    if (!checkFileSize(file.size, 5)) {
      setImgErrMsg('File size is larger than 5 MB.');
      return;
    }

    const img = new Image();
    const imgUrl = URL.createObjectURL(file);
    img.src = imgUrl;
    img.onload = function() {
      const { height, width } = img;
      URL.revokeObjectURL(imgUrl);
      if (
        height < ARTICLE_IMAGE_DIMENSION.height || 
        width < ARTICLE_IMAGE_DIMENSION.width
      ) {
        setImgErrMsg('image dimension is incorrect.');
        return;
      }

      const params = new FormData();
      params.append('image', file);

      setImgUploading(true);
      EditorialApi
        .uploadImg(params)
        .then(res => {
          const { image } = res.data.data;
          handleInput(FIELD_KEY.ARTICLE_IMAGE, image);
        })
        .catch(err => {
          setImgErrMsg(`upload fail: ${err?.response?.data?.message}`);
        })
        .finally(() => setImgUploading(false));
    };
  };

  const handleOpenArtist = () => {
    setArtistVisible(true);
  };

  const handleSelectArtist = newList => {
    setArtistVisible(false);
    handleInput(FIELD_KEY.ARTISTS, newList);
  };

  const handleDeleteArtist = artist => {
    const preArtists = [...article[FIELD_KEY.ARTISTS]];
    const newArtists = preArtists.filter(d => d.id !== artist.id);
    setArticle(prev => ({
      ...prev,
      [FIELD_KEY.ARTISTS]: newArtists
    }));
  };

  const validateInput = () => {
    const form = formRef.current;

    if (form.checkValidity()) {
      setErrMsg(INIT_ERR_MSG);
      return true;
    } else {
      const elements = form.elements;
      const errField = {...INIT_ERR_MSG};
      for (let i = 0; i < elements.length; i++) {
        const ele = elements[i];
        if (!ele.validity.valid) {
          if (ele.validity.valueMissing) {
            errField[ele.name] = REQUIRED_MSG;
          }
          // maybe handle others err situation like validity.tooLong
        }
      }
      setErrMsg(errField);
      return false;
    }
  };

  const handleUpdate = () => {
    // ARTICLE_STATUS.SCHEDULED and ARTICLE_STATUS.PUBLISHED
    submitTypeRef.current = article[FIELD_KEY.STATUS] === ARTICLE_STATUS.SCHEDULED
      ? SUBMIT_TYPE.UPDATE_SCHEDULED
      : SUBMIT_TYPE.UPDATE_PUBLISH;
    validateInput() && handleSubmitType();
  };

  const handlePublish = action => {
    const ignoreValidateList = [
      SUBMIT_TYPE.SAVE_DRAFT,
      SUBMIT_TYPE.UPDATE_DRAFT,
      SUBMIT_TYPE.DELETE_ARTICLE,
      SUBMIT_TYPE.DELETE_DRAFT
    ];
    submitTypeRef.current = action.key;
    if (ignoreValidateList.includes(action.key)) {
      setErrMsg(INIT_ERR_MSG);
      handleSubmitType();
    } else {
      validateInput() && handleSubmitType();
    }
  };

  const handleSubmitType = () => {
    const submitType = submitTypeRef.current;
    switch (submitType) {
      case SUBMIT_TYPE.SCHEDULE_TO_PUBLISH:
      case SUBMIT_TYPE.CHANGE_PUBLISH_SCHEDULE:
        setScheduleVisible(true);
        break;
      case SUBMIT_TYPE.DELETE_ARTICLE:
      case SUBMIT_TYPE.DELETE_DRAFT:
        setDelVisible(true);
        break;
      default:
        handleFireApi();
        break;
    }
  };

  const handleSubmitWithTime = unixTime => {
    handleInput(FIELD_KEY.PUBLISH_TIME, unixTime);
    setScheduleVisible(false);
    handleFireApi();
  };

  const executeCreateApi = () => {
    const data = {...submitDataRef.current};
    const submitType = submitTypeRef.current;

    switch (submitType) {
      case SUBMIT_TYPE.PUBLISH_NOW:
        data[FIELD_KEY.STATUS] = ARTICLE_STATUS.PUBLISHED;
        data[FIELD_KEY.PUBLISH_TIME] = Date.now();
        break;
      case SUBMIT_TYPE.SCHEDULE_TO_PUBLISH:
        data[FIELD_KEY.STATUS] = ARTICLE_STATUS.SCHEDULED;
        break;
      default:
        data[FIELD_KEY.STATUS] = ARTICLE_STATUS.DRAFT;
        data[FIELD_KEY.PUBLISH_TIME] = null;
        break;
    }

    data[FIELD_KEY.ARTISTS] = data[FIELD_KEY.ARTISTS].map(d => d.id);
    console.log('trigger post api payload: ', data);
    setIsSubmitting(true);
    setApiErrMsg('');
    EditorialApi
      .createArticle(data)
      .then(res => {
        setIsSubmitting(false);
        gotoListing();
      })
      .catch(err => {
        setIsSubmitting(false);
        setApiErrMsg(`create fail: ${err?.response?.data?.msg}`);
      });
  };

  const executePatchApi = () => {
    const data = {...submitDataRef.current};
    const submitType = submitTypeRef.current;

    switch (submitType) {
      case SUBMIT_TYPE.REVERT_TO_DRAFT:
        data[FIELD_KEY.STATUS] = ARTICLE_STATUS.DRAFT;
        break;
      case SUBMIT_TYPE.PUBLISH_NOW:
        data[FIELD_KEY.STATUS] = ARTICLE_STATUS.PUBLISHED;
        data[FIELD_KEY.PUBLISH_TIME] = Date.now();
        break;
      case SUBMIT_TYPE.SCHEDULE_TO_PUBLISH:
        data[FIELD_KEY.STATUS] = ARTICLE_STATUS.SCHEDULED;
        break;
      case SUBMIT_TYPE.UPDATE_SCHEDULED:
      case SUBMIT_TYPE.UPDATE_DRAFT:
      case SUBMIT_TYPE.UPDATE_PUBLISH:
      case SUBMIT_TYPE.CHANGE_PUBLISH_SCHEDULE:
      default:
        break;
    }

    data[FIELD_KEY.ARTISTS] = data[FIELD_KEY.ARTISTS].map(d => d.id);
    console.log('trigger patch api payload: ', data);

    setIsSubmitting(true);
    setApiErrMsg('');
    EditorialApi
      .updateArticle(articleId, data)
      .then(res => {
        setIsSubmitting(false);
        // TODO: show success UI although without design
        gotoListing();
      })
      .catch(err => {
        setIsSubmitting(false);
        setApiErrMsg(`update fail: ${err?.response?.data?.msg}`);
      });
  };

  const handleFireApi = () => {
    const submitType = submitTypeRef.current;
    console.log('submitType: ', submitType);

    if (mode === MODE.NEW) {
      executeCreateApi();
    } else {
      executePatchApi();
    }
  };

  const handleDeleteComplete = isSuccess => {
    console.log('delete status: ', isSuccess);
    setDelVisible(false);
    isSuccess && gotoListing();
  };

  const isSubmitDisable = (
    isSubmitting ||
    apiLoading ||
    imgUploading
  );

  if (apiLoading) {
    return (
      <Spinner size="medium" />
    );
  }

  return (
    <>
      {
        artistVisible && (
          <MultiSelectListModal 
            visible={artistVisible}
            originSelectedList={article[FIELD_KEY.ARTISTS]}
            onClose={() => setArtistVisible(false)}
            onSelect={handleSelectArtist}
          />
        )
      }

      <DeleteModal 
        id={articleId}
        visible={delVisible}
        apiType={API_TYPE.EDITORIAL}
        title="Delete Article"
        errorTitle="Not Able to Delete Article"
        content="Are you sure to delete this article?"
        errContent="This article can not be deleted because of server error."
        onComplete={handleDeleteComplete}
      />
      <DateTimePicker 
        visible={scheduleVisible}
        initUnixTime={article[FIELD_KEY.PUBLISH_TIME]}
        onSave={handleSubmitWithTime}
        onClose={() => setScheduleVisible(false)}
      />
      <div className={style['title-container']}>
        <div className={style.title}>
          {
            mode === MODE.NEW
              ? 'New Article'
              : 'Edit Article'
          }
        </div>
        <div className={style['action-container']}>
          <Button
            text="Cancel"
            type="simple"
            onClick={gotoListing}
          />
          {
            article[FIELD_KEY.STATUS] === ARTICLE_STATUS.DRAFT
              ? (
                  <DropdownButton 
                    text="Publish"
                    showArrow={true}
                    disabled={isSubmitDisable}
                    list={
                      DROPDOWN_BUTTON_LIST[mode][article[FIELD_KEY.STATUS]]
                        .map(d => ({
                          key: d,
                          text: SUBMIT_TYPE_TEXT[d]
                        }))
                    }
                    onSelect={handlePublish}
                  />
                )
              : (
                  <Button 
                    text="Save"
                    onClick={handleUpdate}
                    disabled={isSubmitDisable}
                  />
                )
          }
          <DropdownButton
            disabled={isSubmitDisable}
            list={
              OTHERS_BUTTON_LIST[mode][article[FIELD_KEY.STATUS]]
                .map(d => ({
                  key: d,
                  text: SUBMIT_TYPE_TEXT[d]
                }))
            }
            onSelect={handlePublish}
          />
        </div>
      </div>

      {/** NOTE: no design below */}
      {
        (isSubmitting || apiErrMsg ) && (
          <div className={`${style['status-container']} ${apiErrMsg && style.err}`}>
            {
              isSubmitting
                ? 'Submitting ...'
                : apiErrMsg
            }
          </div>
        )
      }

      <form
        ref={formRef}
        className={style['content-wrapper']}
        // onSubmit={handleSubmit}
        noValidate={true} // use customize validate
      >
        {
          mode === MODE.UPDATE && (
            <div className={style.wrapper}>
              <div className={style.title}>Article ID</div>
              <div className={style.content}>
                {article[FIELD_KEY.ID]}
              </div>
            </div>
          )
        }
          <div className={style.wrapper}>
            <div className={style.title}>Status</div>
            <div className={style.content}>
              <StatusView status={article[FIELD_KEY.STATUS]} />
            </div>
          </div>

          <div className={style.wrapper}>
            <div className={`${style.title} ${style.required}`}>Article Title</div>
            <div className={style.content}>
              <Input
                name={FIELD_KEY.ARTICLE_TITLE}
                required={true}
                value={article[FIELD_KEY.ARTICLE_TITLE]}
                onInputChange={e => handleInput(FIELD_KEY.ARTICLE_TITLE, e)}
                isError={errMsg[FIELD_KEY.ARTICLE_TITLE]}
                errMsg={errMsg[FIELD_KEY.ARTICLE_TITLE]}
                enableClearBtn={false}
              />
            </div>
          </div>

          <div className={style.wrapper}>
            <div
              className={`${style.title} ${style.required}`}
            >
              Author Name
            </div>
            <div className={style.content}>
              <div className={style.shortWidthInput}>
                <Input
                  name={FIELD_KEY.AUTHOR_NAME}
                  required={true}
                  value={article[FIELD_KEY.AUTHOR_NAME]}
                  onInputChange={e => handleInput(FIELD_KEY.AUTHOR_NAME, e)}
                  isError={errMsg[FIELD_KEY.AUTHOR_NAME]}
                  errMsg={errMsg[FIELD_KEY.AUTHOR_NAME]}
                  enableClearBtn={false}
                />
              </div>
            </div>
          </div>

          {
            mode === MODE.UPDATE && (
              <div className={style.wrapper}>
                <div 
                  className={`${style.title} ${style.required}`}
                >
                  Publish Time
                </div>
                <div className={style.content}>
                  {
                    article[FIELD_KEY.PUBLISH_TIME]
                      ? formatTime(article[FIELD_KEY.PUBLISH_TIME])
                      : 'Not set yet'
                  }
                </div>
              </div>
            )
          }

          <div className={style.wrapper}>
            <div className={style.title}>Article Image</div>
            <div className={style.content}>
              {
                imgUploading
                  ? <Spinner size="medium" />
                  : article[FIELD_KEY.ARTICLE_IMAGE]
                    ? <>
                        <Previewer url={article[FIELD_KEY.ARTICLE_IMAGE]} />
                        <div className={style['upload-btn-area']}>
                          <div className={style.btn}>
                            <UploadButton
                              text="Replace"
                              acceptType="image/png, image/jpeg"
                              handleUpload={handleSubmitImage}
                            />
                          </div>
                          <div
                            className={style.delete}
                            onClick={() => handleInput(FIELD_KEY.ARTICLE_IMAGE, '')}
                          >
                            Delete
                          </div>
                        </div>
                      </>
                    : (
                        <div className={style['dragdrop-section']}>
                          <DragDrop
                            title={(
                              <div className={style['dragdrop-title']}>
                                Drag and drop image here
                              </div>
                            )}
                            showClick={true}
                            limitText={(
                              <>
                                <div className={style['dragdrop-hint']}>
                                  {`Recommended image size is no smaller than ${ARTICLE_IMAGE_DIMENSION.height}*${ARTICLE_IMAGE_DIMENSION.height}`}
                                </div>
                                <div className={style['dragdrop-hint']}>
                                  {`JPEG, PNG accepted, ${ARTICLE_IMAGE_SIZE_IN_MB}MB limit`}
                                </div>
                              </>
                            )}
                            acceptType="image/jpeg, image/png"
                            submitImage={handleSubmitImage}
                          />
                        </div>
                      )
              }
              {
                errMsg[FIELD_KEY.ARTICLE_IMAGE] && (
                  <ErrorText text={errMsg[FIELD_KEY.ARTICLE_IMAGE]} />
                )
              }
            </div>
          </div>

          <div className={style.wrapper}>
            <div className={style.title}>Image Description</div>
            <div className={style.content}>
              <div className={style.shortWidthInput}>
                <Input
                  value={article[FIELD_KEY.IMAGE_DESCRIPTION]}
                  onInputChange={
                    e => handleInput(FIELD_KEY.IMAGE_DESCRIPTION, e)
                  }
                  enableClearBtn={false}
                />
              </div>
            </div>
          </div>

          <div className={style.wrapper}>
            <div className={`${style.title} ${style.required}`}>Excerpt</div>
            <div className={style.content}>
              <TextArea
                name={FIELD_KEY.EXCERPT}
                required={true}
                value={article[FIELD_KEY.EXCERPT]}
                onInputChange={e => handleInput(FIELD_KEY.EXCERPT, e)}
                maxLength={300}
                isError={errMsg[FIELD_KEY.EXCERPT]}
                errMsg={errMsg[FIELD_KEY.EXCERPT]}
              />
            </div>
          </div>

          <div className={style.wrapper}>
            <div className={`${style.title} ${style.required}`}>Content</div>
            <div className={style.content}>
              <TextArea
                name={FIELD_KEY.CONTENT}
                required={true}
                value={article[FIELD_KEY.CONTENT]}
                onInputChange={e => handleInput(FIELD_KEY.CONTENT, e)}
                showCount={false}
                isError={errMsg[FIELD_KEY.CONTENT]}
                errMsg={errMsg[FIELD_KEY.CONTENT]}
              />
            </div>
          </div>

          <div className={style.wrapper}>
            <div className={style.title}>Assign to Artist</div>
            <div className={style.content}>
              <div className={style['artist-list']}>
                {
                  article[FIELD_KEY.ARTISTS]
                    .map((d, index) => (
                      <div key={index} className={style.artist}>
                        <div className={style.item}>
                          {d.nickname}
                        </div>
                        <img
                          src={TrashIcon} 
                          onClick={() => handleDeleteArtist(d)}
                        />
                      </div>
                    ))
                }
              </div>
              <div
                className={style['add-new-artist-btn']}
                onClick={handleOpenArtist}
              >
                <span>Assign New Artist</span>
                <img
                  src={AddIcon}
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className={style.wrapper}>
            <div className={style.title}>Tag</div>
            <div className={style.content}>
              <div className={style.shortWidthInput}>
                <Input
                  value={article[FIELD_KEY.TAG]}
                  onInputChange={e => handleInput(FIELD_KEY.TAG, e)}
                  maxLength={TAG_CHAR_LIMIT}
                  enableClearBtn={false}
                />
                <div className={style['charactor-hint']}>
                  { TAG_CHAR_LIMIT - (article[FIELD_KEY.TAG]?.length || 0)}
                </div>
              </div>
            </div>
          </div>
      </form>
    </>
  );
}
