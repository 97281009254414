import { useRef } from 'react';
import Button from 'components/common/Button';

import styles from './style.module.scss';

export default function UploadButton({
  text = 'Upload',
  handleUpload,
  cClass,
  accept = ''
}) {
  const inputRef = useRef();

  const uploadImage = () => {
    const imgInputEle = inputRef.current;
    imgInputEle.value = null;
    imgInputEle.click();
  };

  const loadFile = e => {
    // Use below code in handleUpload to set image to <img />
    // const imageEle = document.getElementById(`${id}_output`);
    // URL.revokeObjectURL(imageEle.src);
    // imageEle.src = URL.createObjectURL(e.target.files[0]);
    handleUpload(e.target.files[0]);
  };

  return (
    <>
      <Button
        type='simple'
        text={text}
        cClass={cClass}
        onClick={uploadImage}
      />
      <input
        ref={inputRef}
        className={styles['img-input']} 
        type="file"
        accept={accept || 'image/*'}
        onChange={loadFile}
      />
    </>
  );
}