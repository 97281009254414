import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setDashboardTab } from 'reducers/dashboardReducer';

import style from './style.module.scss';
import gstyle from 'components/App/style.module.scss';

export default function Tab({ tabs }) {
  const dispatch = useDispatch();
  const currentTab= useSelector(state => {
    return state.dashboardReducer.currentTab;
  });
  
  const handleTabContent = e => {
    const activeTabName = e.currentTarget.dataset.key;
    dispatch(setDashboardTab(activeTabName));
  };
  return (
    <div className={style.container}>
      {
        tabs.map(tab => {
          return (
            <div
              data-key={tab.key}
              key={tab.key}
              className={`${style.tab} ${currentTab === tab.key ? gstyle.tabActive : ''}`}
              onClick={handleTabContent}
            >
              {tab.key}
            </div>
          );
        })
      }
    </div>
  );
}