import api from '../api';

const EditorialApi = {};

const prefix = '/articles';

EditorialApi.getArticles = params => {
  return api.fire({
    url: prefix,
    method: 'GET',
    params
  });
};

EditorialApi.getArticleById = articleId => {
  return api.fire({
    url: `${prefix}/${articleId}`,
    method: 'GET'
  });
};

EditorialApi.createArticle = data => {
  return api.fire({
    url: prefix,
    method: 'POST',
    data: JSON.stringify(data)
  });
};

EditorialApi.updateArticle = (articleId, data) => {
  return api.fire({
    url: `${prefix}/${articleId}`,
    method: 'PATCH',
    data: JSON.stringify(data)
  });
};

EditorialApi.delete = articleId => {
  return api.fire({
    url: `${prefix}/${articleId}`,
    method: 'DELETE'
  });
};

EditorialApi.uploadImg = data => {
  return api.fire({
    url: `${prefix}/image`,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  });
};

export default EditorialApi;