import { useState, useEffect } from 'react';
import useTableConfig from 'hooks/useTableConfig';

import GeneralRanking from 'components/GeneralRanking';
// import DashboardTab from 'components/common/DashboardTab';
import NumberSelector from 'components/common/NumberSelector';
import Table from 'components/common/Table';
import DateRangeSelector from 'components/common/DateRangeSelector';
import Spinner from 'components/common/Spinner';
import MarketTab from '../MarketTab';

import { formatNumber } from 'utils/common';
import DataStudioApi from 'utils/apicallers/datastudioapi';

import { DEFAULT_RANGE_TIME } from 'components/OverviewBoard/const';

import style from './style.module.scss';
import artistStyle from 'components/ArtistBoard/style.module.scss';

const highestSoldCols = [
  {
    title: 'ITEM NAME',
    key: 'itemName'
  },
  {
    title: 'LOWEST SOLD PRICE',
    key: 'priceLowest',
    align: 'right',
    render: price => formatNumber(price)
  },
  {
    title: 'HIGHEST SOLD PRICE',
    key: 'priceHighest',
    align: 'right',
    render: price => formatNumber(price)
  }
];

const TABLE_ACTION = {
  APPEND_DATA: 0,
  NEW_DATA: 1
};

export default function SecondSection() {
  const [market, setMarket] = useState(0);
  const [highestSoldData, setHighestSoldData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [pageSize, setPageSize] = useState(50);
  const [apiLoading, setApiLoading] = useState(false);
  const [timeRange, setTimeRange] = useState(DEFAULT_RANGE_TIME);
  const {
    pageRef,
    setPageData
  } = useTableConfig();

  const fetchData = action => {
    action === TABLE_ACTION.NEW_DATA && setApiLoading(true);

    DataStudioApi
      .getHighestSoldPrice({
        type: market === 0 ? 'PRIMARY' : 'SECONDARY',
        start: timeRange.startDay,
        end: timeRange.endDay,
        pageSize: pageSize,
        page: pageRef.current.current
      })
      .then(res => {
        const { list, navigateLastPage } = res.data.data;

        const itemList = list.map(d => {
          return {
            name: d.itemName,
            img: d.itemImageThumbnail,
            content: d.priceHighest,
          };
        });

        if (action === TABLE_ACTION.APPEND_DATA) {
          setHighestSoldData(prev => [...prev, ...itemList]);
          setTableData(prev => [...prev, ...list]);
        } else {
          setHighestSoldData(itemList);
          setTableData(list);
        }

        setPageData({
          total: navigateLastPage
        });
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setApiLoading(false);
      });
  };

  useEffect(() => {
    setPageData({
      current: 1
    });
    fetchData(TABLE_ACTION.NEW_DATA);
  }, [market, timeRange, pageSize]);


  const handleSelectNumber = pageSize => {
    setPageSize(pageSize);
  };
  const handleSelectRange = range => {
    setTimeRange(range);
  };
  const handleNextPageAction = () => {
    const { current, total } = pageRef.current;
    if (current < total) {
      setPageData({
        current: current + 1
      });
      fetchData(TABLE_ACTION.APPEND_DATA);
    }
  };

  const renderDataRanking = () => {
    return (
      <div className={artistStyle.rankingWrapper}>
        <div className={artistStyle.innerRankingWrapper}>
          <GeneralRanking
            data={highestSoldData.slice(0, 5)}
            noPadding={true}
          />
        </div>
        {
          highestSoldData.length > 5 &&
            <>
              <div className={artistStyle.verticalSeperator} />
              <div className={artistStyle.innerRankingWrapper}>
                <GeneralRanking
                  startIndex={6}
                  data={
                    highestSoldData.length > 10
                      ? highestSoldData?.slice(5, 10)
                      : highestSoldData?.slice(5)
                  }
                  noPadding={true}
                />
              </div>
              <div className={artistStyle.verticalSeperator} />
            </>
        }
      </div>
    );
  };
  return (
    <>
    <div className={artistStyle.areaChartContent}>
      <span className={style.title}>
        Token Highest Sold Price
      </span>
      <div className={style.tabWrapper}>
        <MarketTab
          activeTab={market}
          setTab={setMarket}
        />
      </div>
      <div className={artistStyle.rightSection}>
        <NumberSelector onSelectChange={handleSelectNumber} />
        <DateRangeSelector
          handleSelectRange={handleSelectRange}
        />
      </div>
    </div>

    <div className={artistStyle.secondRow}>
      {
        apiLoading
          ? <Spinner size="medium" center={true}/>
          : (
            <>
              <div className={artistStyle.rankingContainer}>
                <div className={artistStyle.rankingTitle}>Top 10 Token Sales</div>
                {
                    highestSoldData.length !== 0
                      ? renderDataRanking()
                      : <div className={artistStyle.empty}>
                          No Data
                        </div>
                }
              </div>

              <div className={artistStyle.verticalSeperator} />

              <div className={`${artistStyle.tableWrapper} ${artistStyle.secondSection}`}>
                <Table
                  height="395px"
                  columns={highestSoldCols}
                  dataSource={tableData}
                  loading={apiLoading}
                  emptyContent={
                    <div>
                      <span>No Data</span>
                    </div>
                  }
                  fetchAction={handleNextPageAction}
                />
              </div>
            </>
          )
      }
    </div>
    </>
  );
}