import api from '../api';

const AuthApi = {};

AuthApi.login = ({ username, password }) => {
  return api.fire({
    url: '/login',
    method: 'POST',
    data: JSON.stringify({
      username,
      password
    })
  });
};

AuthApi.logout = () => {
  return api.fire({
    url: '/logout',
    method: 'POST'
  });
};

export default AuthApi;