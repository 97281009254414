import axios from 'axios';
import cookieUtils from './cookies';
import Store from '../store';

import { SET_MESSAGE } from '../consts/actionConsts';
import { SESSION } from '../consts/storageConsts';
import { 
  SERVER_ERROR,
  TOKEN_EXPIRED
} from '../consts/apiErrConsts';

import { setUserOnlineStatus } from 'reducers/userReducer';

const { dispatch } = Store;
const API_TIMEOUT_DUR = 30000;
const API_HOST = process.env.REACT_APP_API_DOMAIN;

axios.defaults.headers.common['X-XSRF-TOKEN'] =
  cookieUtils.get('XSRF-TOKEN') || 'fa462aea-3d96-4ae5-a535-755f41b3232d';
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
axios.defaults.headers.put['Content-Type'] = 'application/json;charset=UTF-8';
axios.defaults.headers.patch['Content-Type'] = 'application/json;charset=UTF-8';
axios.defaults.headers.delete['Content-Type'] = 'application/json;charset=UTF-8';

export const instance = axios.create({
  timeout: API_TIMEOUT_DUR,
  withCredentials: true,
  baseURL: API_HOST,
});

const handle400Error = errRes => {
  const { status } = errRes;

  switch(status){
    case 401:
      handleLogout();
      break;
    default:
      break;
  }
};
const handle500Error = () => {
  dispatch({
    type: SET_MESSAGE,
    payload: {
      text: SERVER_ERROR,
      status: 'ERROR'
    }
  });
};
const handleNetworkError = () => {
  dispatch({
    type: SET_MESSAGE,
    payload: {
      text: 'Network went wrong!',
      status: 'ERROR'
    }
  });
};

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      const { status } = error.response;
      if ([400, 401, 402, 403, 404].includes(status)) {
        handle400Error(error.response);
      } else {
        handle500Error(error.response);
      }
    } else {
      handleNetworkError(error.response);
    }
    return Promise.reject(error);
  }
);

const handleLogout = () => {
  localStorage.removeItem(SESSION);
  // localStorage.removeItem(EMAIL);
  // localStorage.removeItem(USER_PREF);
  dispatch({
    type: SET_MESSAGE,
    payload: {
      text: TOKEN_EXPIRED,
      status: 'ERROR'
    }
  });
  dispatch(setUserOnlineStatus(false));
};

const api = {};
// api.fire = async (options, API_PATH) => {
// // eslint-disable-next-line consistent-return
// return instance
//   .request({
//     ...options,
//     headers: {
//       ...options.headers,
//       // 'X-XSRF-TOKEN':
//       //   cookieUtils.get('XSRF-TOKEN') || 'fa462aea-3d96-4ae5-a535-755f41b3232d'
//     },
//     url: API_PATH ? `${API_PATH}${options.url}` : `${API_HOST}${options.url}`
//   });
// };

api.fire = instance.request;

api.handleError = code => {
  switch(code){
    case 400: 
      console.error('API-request parameter is mandatory');
      break;
    default:
      console.error('API-request parameter is mandatory');
  }
};

export default api;
