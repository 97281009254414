import _ from 'lodash';
import { useState, useEffect } from 'react';

export default function useResizeWidth() {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const handleResize = _.debounce(() => {
      setWidth(document.body.clientWidth);
    }, 300);
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return [width];
}