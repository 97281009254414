import styles from './style.module.scss';

export default function Button({
  type = 'normal',
  text,
  onClick,
  disabled = false,
  cClass,
}) {
  const handleOnClick = () => {
    if (!disabled) {
      onClick();
    }
  };
  return (
    <div
      className={
        `${styles.container} ${styles[type]} ${disabled && styles.disabled}`
      }
      style={cClass}
      onClick={handleOnClick}
    >
      { text }
    </div>
  );
}