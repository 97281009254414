import { useState } from 'react';

import Modal from 'components/common/NewModal';
import Spinner from 'components/common/Spinner';

import HeroBannerApi from 'utils/apicallers/herobannerapi';
import ArtistApi from 'utils/apicallers/artistapi';
import AttributeApi from 'utils/apicallers/attributesapi';
import EventApi from 'utils/apicallers/eventapi';
import ItemApi from 'utils/apicallers/itemapi';
import EditorialApi from 'utils/apicallers/editorial';

import { API_TYPE } from './const';

import styles from './styles.module.scss';

export default function DeleteModal({
  id,
  visible = false,
  apiType = '',
  title = '',
  errTitle = '',
  content = '',
  errContent = '',
  onComplete = () => {}
}) {
  const [isError, setIsError] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);

  const handleDelete = () => {
    if (apiLoading) {
      return;
    }
    let api = null;
    switch(apiType) {
      case API_TYPE.HEROBANNER:
        api = HeroBannerApi;
        break;
      case API_TYPE.ARTIST:
        api = ArtistApi;
        break;
      case API_TYPE.ITEM:
        api = ItemApi;
        break;
      case API_TYPE.ATTRIBUTE:
        api = AttributeApi;
        break;
      case API_TYPE.EVENT:
        api = EventApi;
        break;
      case API_TYPE.EDITORIAL:
        api = EditorialApi;
        break;
      default:
        break;
    }
    if (api) {
      setApiLoading(true);
      api
        .delete(id)
        .then(res => {
          handleDelCancel(true);
        })
        .catch(err => {
          console.error(err);
          setIsError(true);
        })
        .finally(() => {
          setApiLoading(false);
        });
    }
  };

  const handleDelCancel = (isSuccess = false) => {
    setIsError(false);
    onComplete(isSuccess === true);
  };

  return (
    <Modal
      visible={visible}
      title={ isError ? errTitle : title }
      okText={isError ? 'OK' : 'Delete'}
      onOk={isError ? handleDelCancel : handleDelete}
      onCancel={handleDelCancel}
      hideCancel={isError}
      disableOk={apiLoading}
    >
      {
        apiLoading
          ? <Spinner size="medium" />
          : <div className={styles.content}>
              { isError ? errContent : content }
            </div>
      }
    </Modal>
  );
}