import TrashIcon from '../../assets/imgs/trash-black.svg';

export const FETCH_USERS_RESULT = 'artist/FETCH_USERS_RESULT';

export const SET_DETAIL_ARTIST_ID = 'artist/SET_DETAIL_ARTIST_ID';

export const FETCH_ARTIST_RESULT = 'artist/FETCH_ARTIST_RESULT';

export const delList = [
  {
    key: 'delete',
    icon: TrashIcon,
    text: 'DELETE'
  }
];

export const VIDEO_SUPPORT_FILE_EXTENSIONS = 'video/mp4,video/quicktime';

export const VIDEO_FILE_SIZE_IN_MB = 100;