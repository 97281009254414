import { useState } from 'react';

import DashboardTab from 'components/common/DashboardTab';
import ThreeLabel from 'components/common/ThreeLabel';
import LineChart from 'components/common/LineChart';
import Spinner from 'components/common/Spinner';

import { formatPercentage } from 'utils/common';

import { TYPE } from 'components/common/LineChart/const';

import style from './style.module.scss';
import gstyle from 'components/App/style.module.scss';

const dailyRevenuMock = [
  {
    category: 'Primary Market',
  },
  {
    category: 'Secondary Market',
  },
];

const datasets = [
  {
    label: '',
    fill: true,
    type: TYPE.PRIMARY,
    yAxisID: 'y'
  },
  {
    label: '',
    fill: false,
    type: TYPE.SECONDARY,
    yAxisID: 'y1'
  },
];

export default function DailyRevenuSoldCount({ data, loading = false }) {
  const { primary, secondary } = data;
  const [activeTab, setActiveTab] = useState(0);

  const handleDailyRevenuTab = e => {
    const tabCategory = e.currentTarget.dataset.key;
    const tabIndex = dailyRevenuMock[0].category == tabCategory ? 0 : 1;
    setActiveTab(tabIndex);
  };

  const getLabelData = () => {
    const current = activeTab === 0
      ? primary.current
      : secondary.current;
    
    const compareToLast = activeTab === 0
      ? primary.compareToLast
      : secondary.compareToLast;


    return [
      {
        name: 'Total Revenue (USD)',
        color: 'primary',
        content: current.totalRevenue,
        trend: formatPercentage(compareToLast.totalRevenue)
      },
      {
        name: 'Token Sold Count',
        color: 'secondary',
        content: current.tokenSoldCount,
        trend: formatPercentage(compareToLast.tokenSoldCount)
      },
      {
        name: 'Average Token Revenue (USD)',
        color: '',
        content: current.averageRevenue,
        trend: formatPercentage(compareToLast.averageRevenue)
      },
    ];
  };

  const getRawTime = () => {
    const chartData = activeTab === 0
      ? primary?.chartData
      : secondary?.chartData;

    return chartData?.time;
  };

  const getRawData = () => {
    const chartData = activeTab === 0
      ? primary?.chartData
      : secondary?.chartData;

    return chartData?.data;
  };

  return (
    <>
      <div className={style.container}>
        <div className={style.title}>
          Daily Revenue & Token Sold Count
        </div>
        <DashboardTab
          data={ dailyRevenuMock }
          activeTab={ activeTab }
          handleChangeTab={handleDailyRevenuTab}
        />
      </div>

      {
        loading
          ? <div className={style['loading-wrapper']}>
              <Spinner size="medium" />
            </div>
          : <>
              <ThreeLabel data={ getLabelData() } fontSize="small" />

              <LineChart
                yTitle="REVENUE"
                y1Title="TOKEN"
                rawTimeList={getRawTime()}
                rawDataList={getRawData()}
                datasets={datasets}
              />
            </>
      }
    </>
  );
}
