import api from '../api';

const MintApi = {};

const prefix = '/mintings';

MintApi.getMinting = (params) => {
  return api.fire({
    url: prefix,
    method: 'GET',
    params
  });
};

MintApi.uploadArtwork = (params, onUploadProgress) => {
  return api.fire({
    url: `${prefix}/content`,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress,
    data: params
  }); 
};

MintApi.getMintingById = (mintingId) => {
  return api.fire({
    url: `${prefix}/${mintingId}`,
    method: 'GET'
  });
};

MintApi.update = (mintingId, params) => {
  return api.fire({
    url: `${prefix}/${mintingId}`,
    method: 'PATCH',
    data: JSON.stringify(params)
  });
};

export default MintApi;
