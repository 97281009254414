import Table from 'components/common/Table';
import { SORT } from 'components/common/Table/const';
import Pagination from 'components/common/Pagination';

import styles from './style.module.scss';

export default function ListingPage({
  addNewFn,
  onSortClick,
  sort = {
    key: '',
    type: SORT.DESC
  },
  data,
  cols,
  title,
  pagData = {
    totalPages: 0,
    pageNum: 0
  },
  pagFetchAction,
  messageComponent = null,
  emptyView,
  loading = false
}) {
  return (
    <>
      <div className={styles.titleWrapper}>
        <div className={styles.leftItem}>
          <img src={title.icon} loading="lazy" className={styles.titleLeftIcon} />
          <span className={styles.titleLeftText}>{title.text}</span>
        </div>
        {
          addNewFn && 
            <div className={styles.rightItem}>
              <div
                className={styles.titleRightbutton}
                onClick={addNewFn}
              >
                Add new
              </div>
            </div>
        }
      </div>

      {
        messageComponent && (
          <div className={styles.messageWrapper}>
            {messageComponent}
          </div>
        )
      }

      <Table
        dataSource={ data }
        columns={ cols }
        sort={ sort }
        onSortClick={ onSortClick }
        emptyContent={ emptyView }
        loading={ loading }
      />
      <Pagination data={pagData} fetchAction={pagFetchAction} />
    </>
  );
}