import { useState, useEffect } from 'react';

import TitleData from 'components/TitleData';
import DateRangeSelector from 'components/common/DateRangeSelector';
import Table from 'components/common/Table';
import LineChart from 'components/common/LineChart';
import Spinner from 'components/common/Spinner';

import { TYPE as LINE_TYPE } from 'components/common/LineChart/const';
import { DEFAULT_RANGE_TIME } from 'components/OverviewBoard/const';

import DataStudioApi from 'utils/apicallers/datastudioapi';
import { formatNumber, formatTime } from 'utils/common';

import style from 'components/ArtistBoard/style.module.scss';
import listingStyle from 'components/common/ListingPage/style.module.scss';
import userBoardStyle from 'components/UserBoard/style.module.scss';

const cols = [
  {
    title: 'DATE',
    key: 'date',
    render: time => formatTime(time, true)
  },
  {
    title: 'TOTAL FEE (USD)',
    key: 'fee',
    align: 'right',
    style: {
      fontWeight: 500,
      color: 'var(--color--gray-600)'
    }
  }
];

export default function FirstSection() {
  const [feeEarningData, setFeeEarningData] = useState({
    titleData: {
      title: 'Fee Earning',
      content: 0,
      trend: 0,
    }
  });
  const [apiLoading, setApiLoading] = useState(false);  

  useEffect(() => {
    handleSelectRange({
      startDay: DEFAULT_RANGE_TIME.startDay,
      endDay: DEFAULT_RANGE_TIME.endDay
    });
  }, []);

  const handleSelectRange = range => {
    setApiLoading(true);
    DataStudioApi
      .getRevenueFeeEarning({
        start: range.startDay, end: range.endDay
      })
      .then(res => {
        const { data } = res.data;
        const chartData = {
          time: [],
          data: [[]]
        };
        data.detailList.forEach(d => {
          chartData.time.push(d.date);
          chartData.data[0].push(d.fee);
        });
        setFeeEarningData({
          titleData: {
            title: 'Fee Earning',
            content: data.current,
            trend: data.compareToLast ? data.compareToLast*100 : 0,
          },
          chartData,
          tableData: data.detailList
        });
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setApiLoading(false);
      });
  };
  return (
    <>
    <div className={style.areaChartContent}>
      <TitleData data={feeEarningData} />
      <div className={style.rightSection}>
        <DateRangeSelector
          handleSelectRange={ handleSelectRange }
        />
      </div>
    </div>

    <div className={`${userBoardStyle.sectionRowWrapper} ${apiLoading && userBoardStyle.loading}`}>
      {
        apiLoading
          ? <Spinner size="medium" />
          : (
            <>
              <div className={userBoardStyle.threeForthSection}>
                <LineChart
                  height="223px"
                  datasets={[{
                    type: LINE_TYPE.PRIMARY,
                    fill: true
                  }]}
                  rawTimeList={feeEarningData?.chartData?.time}
                  rawDataList={feeEarningData?.chartData?.data}
                  yTitle="USD"
                />
              </div>

              <div className={userBoardStyle.verticalSeperator} />

              <div className={`${userBoardStyle.sectionItem} ${userBoardStyle.rightSectionItem}`}>
                <Table
                  height="227px"
                  columns={cols}
                  dataSource={feeEarningData?.tableData}
                  emptyContent={
                    <div>
                      <div>No Data</div>
                    </div>
                  }
                />
              </div>
            </>
          )
      }
    </div>
    </>
  );
}