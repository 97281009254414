import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useTableConfig from 'hooks/useTableConfig';

import { showBackIcon } from 'reducers/headerReducer';
import { setEventId } from 'reducers/eventReducer';

import EventDetail from './eventDetail';
import EventNew from './eventNew';
import ListingPage from 'components/common/ListingPage';
import StatusView from 'components/common/StatusView';
import DeleteModal from 'components/common/DeleteModal';
import ErrorView from 'components/common/ErrorView';

import { 
  SHOW_EVENT_DETAIL,
  SHOW_ADD_NEW_EVENT,
  SHOW_EVENT_UPDATE,
  SHOW_ERROR
} from 'components/universalheader/consts';
import { EVENT_STATUS } from './consts';
import { SORT } from 'components/common/Table/const';
import { API_TYPE } from 'components/common/DeleteModal/const';

import { shortenText } from 'utils';
import { formatTime } from 'utils/common';
import EventApi from 'utils/apicallers/eventapi';

import ViewIcon from 'assets/imgs/view.svg';
import ViewBlackIcon from 'assets/imgs/view-black.svg';
import EditIcon from 'assets/imgs/edit.svg';
import EditBlackIcon from 'assets/imgs/edit-black.svg';
import TrashIcon from 'assets/imgs/trash.svg';
import TrashBlackIcon from 'assets/imgs/trash-black.svg';
import titleLeftIcon from 'assets/imgs/eventTileleftIcon.svg';

import styles from 'components/common/ListingPage/style.module.scss';
import eventStyles from './style.module.scss';

export default function Event() {
  const dispatch = useDispatch();
  
  const [tableData, setTableData] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);
  const [delVisible, setDelVisible] = useState(false);
  const [id, setId] = useState('');
  
  const { backDesi } = useSelector(state => {
    return state.HeaderReducer;
  });
  const {
    currentPage,
    pagData,
    sort,
    pageRef,
    setSortData,
    setPageData
  } = useTableConfig();

  const goToDetail = id => {
    dispatch(setEventId(id));
    dispatch(showBackIcon(SHOW_EVENT_DETAIL));
  };

  const goToUpdate = (status, id) => {
    if (status === EVENT_STATUS.EXPIRED) {
      return;
    }
    dispatch(setEventId(id));
    dispatch(showBackIcon(SHOW_EVENT_UPDATE));
  };

  const handleShowAddNew = e => {
    dispatch(showBackIcon(SHOW_ADD_NEW_EVENT));
  };

  const fetchApi = () => {
    const sorting = sort.type === SORT.ASC ? 'ASC' : 'DESC';
    let orderBy = 'SCHEDULE_START_TIME';
    switch (sort.key) {
      case 'scheduleStartTime':
        orderBy = 'SCHEDULE_START_TIME';
        break;
      case 'scheduleEndTime':
        orderBy = 'SCHEDULE_END_TIME';
        break;
      case 'status':
        orderBy = 'STATUS';
        break;
      case 'updateTime':
        orderBy = 'UPDATE_TIME';
        break;
      case 'title':
        orderBy = 'TITLE';
        break;
      default:
        break;
    }
    
    setApiLoading(true);
    EventApi
      .getEvents({
        sorting,
        orderBy: orderBy,
        page: currentPage,
        pageSize: pageRef.current.size,
      })
      .then(res => {
        const { list, navigateLastPage } = res.data.data;
        setTableData(list);
        setPageData({
          total: navigateLastPage
        });
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setApiLoading(false);
      });
  };
  useEffect(() => {
    const dest = [SHOW_EVENT_DETAIL, SHOW_ADD_NEW_EVENT, SHOW_EVENT_UPDATE];
    if(!dest.includes(backDesi)){
      fetchApi();
    }
  }, [sort, currentPage, backDesi]);

  const handleSort = (key, type) => {
    setSortData({ key, type });
  };
  const handlePagAction = (page) => {
    setPageData({
      current: page
    });
  };
  const handleDelete = id => {
    setDelVisible(true);
    setId(id);
  };
  const handleDeleteComplete = isSuccess => {
    setDelVisible(false);
    isSuccess && fetchApi();
  };

  const cols = [
    {
      title: 'ID',
      key: 'eventId',
      fixed: 'left',
      render: text => (
        <div className={styles.id}>{shortenText(text, 6)}</div>
      )
    },
    {
      title: 'EVENT NAME',
      key: 'title',
      fixed: 'left',
      sorter: true
    },
    {
      title: 'START TIME',
      key: 'scheduleStartTime',
      align: 'left',
      sorter: true,
      render: text => formatTime(text)
    },
    {
      title: 'END TIME',
      key: 'scheduleEndTime',
      align: 'left',
      sorter: true,
      render: text => formatTime(text)
    },
    {
      title: 'LAST UPDATED TIME',
      key: 'updateTime',
      align: 'left',
      sorter: true,
      render: text => formatTime(text)
    },
    {
      title: 'STATUS',
      key: 'status',
      align: 'left',
      sorter: true,
      render: text => <StatusView status={text} />
    },
    {
      title: 'VIEW',
      key: 'view',
      align: 'center',
      width: '5%',
      clickable: true,
      render: (text, row, router, { isHover }) => {
        return (
          <img
            src={isHover ? ViewBlackIcon : ViewIcon}
            onClick={() => goToDetail(row.eventId)}
          />
        );
      }
    },
    {
      title: 'EDIT',
      key: 'edit',
      align: 'center',
      width: '5%',
      render: (text, row, router, { isHover }) => {
        return (
          <img
            className={row.status === EVENT_STATUS.EXPIRED
              ? eventStyles['edit-disable']
              : eventStyles.clickable
            }
            src={isHover ? EditBlackIcon : EditIcon}
            onClick={() => goToUpdate(row.status, row.eventId)}
          />
        );
      }
    },
    {
      title: 'DELETE',
      key: 'delete',
      align: 'center',
      width: '5%',
      clickable: true,
      render: (text, row, router, { isHover }) => {
        if (row.status === EVENT_STATUS.SCHEDULED) {
          return (
            <img
              src={isHover ? TrashBlackIcon : TrashIcon}
              onClick={() => handleDelete(row.eventId)}
            />
          );
        } else {
          return null;
        }
      }
    }
  ];

  const renderContent = () => {
    switch(backDesi){
      case SHOW_EVENT_DETAIL: 
        return <EventDetail />;
      case SHOW_ADD_NEW_EVENT:
      case SHOW_EVENT_UPDATE:
        return <EventNew />;
      case SHOW_ERROR:
        return <ErrorView />;
      default:
        return (
          <>
            <DeleteModal
              id={id}
              visible={delVisible}
              apiType={API_TYPE.EVENT}
              title="Delete Event"
              errTitle="No Able to Delete Event"
              content="Are you sure to delete this event?"
              errContent="This event cannot be deleted."
              onComplete={handleDeleteComplete}
            />
            <ListingPage
              addNewFn={handleShowAddNew}
              data={tableData}
              cols={cols}
              title={{
                text: 'Event',
                icon: titleLeftIcon
              }}
              sort={sort}
              onSortClick={handleSort}
              pagData={pagData}
              pagFetchAction={handlePagAction}
              loading={apiLoading}
            />
          </>
        );
    }
  };
  return (
    <div className={styles.eventContainer}>
      <div className={styles.contentArea}>
        { renderContent() }
      </div>
    </div>
  );
}