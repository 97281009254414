import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';

import Spinner from 'components/common/Spinner';
import { setUserOnlineStatus } from 'reducers/userReducer';

import { nanoid } from 'nanoid';
import { sha3_256 as Hash } from 'js-sha3';

import AuthApi from 'utils/apicallers/authapi';

import { USERNAME, SESSION } from 'consts/storageConsts';

import BTSELogo from 'assets/imgs/BTSE.png';
import NFTLogo from 'assets/imgs/NFT.png';
import visibleImg from 'assets/imgs/ic-visible.svg';
import invisibleImg from 'assets/imgs/ic-invisible.svg';

import styles from './style.module.scss';

export default function Login() {
  const [authData, setAuthData] = useState({account: '', pass: ''});
  const [readyToSend, setReadyToSend] = useState(false);
  const [passvisible, setPassvisible] = useState(false);
  const [showError, setShowError] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if(authData.account && authData.pass){
      setReadyToSend(true);
    }else{
      setReadyToSend(false);
    }
  }, [authData]);

  const handleInput = e => {
    let result = {};
    const value = e.target.value;
    const id = e.target.id;
    
    result[id] = value;
    result = {...authData, ...result};
    setAuthData(result);
  };
  const handleVisible = () => {
    let passEle = document.getElementById("pass");
    passEle.type === "password"
      ? passEle.type === 'text'
      : passEle.type === 'password';
    setPassvisible(!passvisible);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    const username = authData.account;
    const password = Hash(authData.pass);
    setApiLoading(true);
    setShowError(false);
    
    AuthApi
      .login({ username, password})
      .then(res => {
        localStorage.setItem(SESSION, nanoid());
        localStorage.setItem(USERNAME, username);
        dispatch(setUserOnlineStatus(true));
        // dispatch({
        //   type: SET_USER_ONLINESTATUS,
        //   payload: true
        // });
        history.push('/');
      })
      .catch(err => {
        console.log('err', err);
        setShowError(true);
      })
      .finally(() => {
        setApiLoading(false);
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.centerWrapper}>
        <div className={styles.titleSection}>
          <img src={BTSELogo} width="90px" height="28px" />
          <img src={NFTLogo} width="66px" height="28px" />
        </div>
        
        <form className={styles.inputArea}>
          <div className={styles.title}>
            Admin Sign in
          </div>
          <div className={styles.account}>
            <label htmlFor="account">username</label>
            <input
              id="account"
              type="text"
              name="account"
              placeholder="Enter User Name"
              onChange={handleInput}
            />
          </div>
          <div className={`${styles.account} ${styles.passInput}`}>
            <label htmlFor="pass">Password</label>
            <div className={styles.passWrapper}>
              <input
                id="pass"
                type="password"
                name="account"
                placeholder="Enter Password"
                onChange={handleInput}
              />
              <img
                src={passvisible ? visibleImg : invisibleImg }
                width={16}
                height={14}
                className={styles.visibleImg}
                onClick={handleVisible}
              />
            </div>
            
            {
              showError &&
              <div className={styles.errorMessage}>
                Please input valid account/pass
              </div>
            }
          </div>

          <button
            className={`${styles.btnArea} ${(readyToSend && !apiLoading) && styles.btnActive }`}
            type="submit"
            onClick={handleLogin}
            disabled={!readyToSend || apiLoading}
          >
            {
              apiLoading && (
                <div className={styles.loading}>
                  <Spinner size="small" />
                </div>
              )
            }
            Sign in
          </button>
        </form>
      </div>
    </div>
  );
}