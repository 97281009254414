import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';

import useInputVerify, { STATUS } from 'hooks/useInputVerify';

import { hideBackIcon, showBackIcon } from 'reducers/headerReducer';
import { fetchAttrDetailResult } from 'reducers/attributeReducer';

import Input from 'components/common/BorderInput';
import Button from 'components/common/Button';
import DropdownButton from 'components/common/DropdownButton';
import DeleteModal from 'components/common/DeleteModal';
import Spinner from 'components/common/Spinner';
import ErrorText from 'components/common/ErrorText';

import AttributeApi from 'utils/apicallers/attributesapi';

import {
  SHOW_ATTRIBUTE_DETAIL,
  SHOW_ERROR
} from 'components/universalheader/consts';
import { MODE, DEFAULT_DATA } from '../const';
import { DEL_LIST } from 'components/common/DropdownButton/const';
import { API_TYPE } from 'components/common/DeleteModal/const';

import TrashIcon from 'assets/imgs/trash-red.svg';

import styles from './styles.module.scss';
import baseStyles from '../base.styles.module.scss';

const DEFAULT_STATUS = {
  status: STATUS.BEFORE_CHECK,
  errMsg: ''
};

export default function AttributeNew({ mode = MODE.UPDATE }) {
  const dispatch = useDispatch();
  const subAttrStatusRef = useRef();

  const detailMainAttrId = useSelector(state => {
    return state.AttributeReducer.detailMainAttrId;
  });
  const {
    subAttributes,
    attributeName,
    attributeId
  } = useSelector(state => state.AttributeReducer.detailData);

  const [delVisible, setDelVisible] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [mainAttrName, setMainAttrName] = useState(
    mode === MODE.CREATE
      ? DEFAULT_DATA.attributeName
      : attributeName
  );
  const [subAttrList, setSubAttrList] = useState(
    mode === MODE.CREATE
      ? DEFAULT_DATA.subAttributes
      : subAttributes.map(d => d.subAttributeName)
  );
  const [subAttrStatusList, setSubAttrStatusList] = useState(
    mode === MODE.CREATE
      ? [{...DEFAULT_STATUS}]
      : subAttributes.map(d => ({...DEFAULT_STATUS}))
  );
  
  /**
   * NOTE: not one good implementation to use such data stucture.
   * Should be refactor later
   */
  const [
    mainAttrNameStatus,
    mainAttrNameErrMsg,
    mainAttrNameRef,
    setMainAttrNameStatus,
    resetMainAttrNameStatus
  ] = useInputVerify();

  useEffect(() => {
    if (
      detailMainAttrId && 
      mode === MODE.UPDATE &&
      (detailMainAttrId !== attributeId)
    ) {
      setApiLoading(true);
      setErrMsg('');
      AttributeApi
        .getAttributeById(detailMainAttrId)
        .then(res => {
          const { attributeName, subAttributes: subList } = res.data.data;
          dispatch(fetchAttrDetailResult({
              attributeId: detailMainAttrId,
              attributeName,
              subAttributes: subList
          }));
          const newSubNameList = subList.map(d => d.subAttributeName);
          const newSubStatusList = subList.map(d => ({...DEFAULT_STATUS}));
          setMainAttrName(attributeName);
          setSubAttrList(newSubNameList);
          setSubAttrStatusList(newSubStatusList);
        })
        .catch(err => {
          console.error(err);
          dispatch(showBackIcon(SHOW_ERROR));
        })
        .finally(() => {
          setApiLoading(false);
        });
    }
  }, [detailMainAttrId, attributeId, attributeName, subAttributes, dispatch, mode]);

  const _setSubAttrStatus = list => {
    setSubAttrStatusList(list);
    subAttrStatusRef.current = list;
  };

  const handleMainAttrName = e => {
    setMainAttrName(e);
  };

  const goToDetail = () => {
    dispatch(showBackIcon(SHOW_ATTRIBUTE_DETAIL));
  };

  const gotoSummary = () => {
    dispatch(hideBackIcon());
  };

  const handleAddSub = () => {
    setSubAttrList(prev => [...prev, '']);
    setSubAttrStatusList(prev => {
      return [...prev, {...DEFAULT_STATUS}];
    });
  };

  const handleDelete = index => {
    if (subAttrList.length <= 1) {
      return;
    }
    let prevList = [...subAttrList];
    let prevStatusList = [...subAttrStatusList];
    prevList.splice(index, 1);
    prevStatusList.splice(index, 1);
    setSubAttrList(prevList);
    setSubAttrStatusList(prevStatusList);
  };
  const handleCancel = () => {
    if (mode === "UPDATE") {
      goToDetail();
    } else {
      gotoSummary();
    }
  };

  const verifyMainAttrName = () => {
    if (!mainAttrName) {
      setMainAttrNameStatus(STATUS.INVALID, 'This field is required.');
      return false;
    } else {
      setMainAttrNameStatus(STATUS.VALID, '');
      return true;
    }
  };
  const verifySubAttrList = () => {
    if(!subAttrList.length){
      _setSubAttrStatus([{
        status: STATUS.INVALID,
        errMsg: 'At lease one sub attribute is required.'
      }]);
      return false;
    }
    return true;
  };

  const handleUpdate = () => {
    const isSubAttrPass = verifySubAttrList();
    const isMainAttrNamePass = verifyMainAttrName();

    if (isSubAttrPass && isMainAttrNamePass) {
      const filterOutBlank = subAttrList.filter(s => s.length !== 0);
      const params = {
        mainAttribute: mainAttrName,
        subAttributes: [...new Set(filterOutBlank)],
      };
      setApiLoading(true);
      if (mode === MODE.UPDATE) {
        AttributeApi
          .add(attributeId, params)
          .then(res => {
            goToDetail();
          })
          .catch(err => {
            console.error(err);
          })
          .finally(() => {
            setApiLoading(false);
          });
      } else {
        AttributeApi
          .create(params)
          .then(res => {
            gotoSummary();
          })
          .catch(err => {
            console.error(err);
          })
          .finally(() => {
            setApiLoading(false);
          });
      }
    }
  };

  const handleSubAttrChange = (e, index) => {
    let newList = [...subAttrList];
    newList[index] = e.toLowerCase();
    setSubAttrList(newList);
  };

  const handleDeleteComplete = isSuccess => {
    setDelVisible(false);
    isSuccess && gotoSummary();
  };

  if (apiLoading) {
    return (
      <Spinner size="medium" />
    );
  }
  if (errMsg) {
    return (
      <ErrorText text={errMsg} />
    );
  }

  const renderSubAttributes = (d = null, index = 0) => {
    return (
      <div key={index} className={styles['sub-attr-container']}>
        <div className={styles['sub-attr-wrapper']}>
          <div className={styles['input-wrapper']}>
            <Input
              value={d}
              onInputChange={e => handleSubAttrChange(e, index)}
              isError={subAttrStatusList[index]?.status === STATUS.INVALID}
              errMsg={subAttrStatusList[index]?.errMsg}
            />
          </div>
          {
            subAttrList.length > 1 &&
              <div
                className={styles.delete}
                onClick={() => handleDelete(index)}
              >
                <img
                  src={TrashIcon}
                  loading="lazy"
                />
              </div>
          }
        </div>
      </div>
    );
  };
  return (
    <>
      <Prompt message="" when={true} />
      <DeleteModal
        id={detailMainAttrId}
        visible={delVisible}
        apiType={API_TYPE.ATTRIBUTE}
        title="Delete Main Attribute"
        errTitle="Not Able to Delete Main Attribute"
        content="Are you sure to delete this main attribute and its all sub attributes?"
        errContent="This main attribute can not be deleted."
        onComplete={handleDeleteComplete}
      />
      <div className={styles['title-container']}>
        <div className={baseStyles.title}>
          {
            mode === "UPDATE"
              ? "Update Main Attribute"
              : "Add New Main Attribute"
          }
        </div>
        <div className={baseStyles['action-container']}>
          <Button 
            text="Cancel"
            type="simple"
            onClick={handleCancel}
          />
          <Button 
            text={mode === MODE.CREATE ? "Create" : "Save"}
            onClick={handleUpdate}
          />
          {
            mode === "UPDATE" && (
              <DropdownButton 
                list={DEL_LIST}
                onSelect={() => setDelVisible(true)}
              />
            )
          }
        </div>
      </div>

      <div className={styles.container}>
        {
          mode === MODE.UPDATE &&
            <div className={baseStyles.section}>
              <div className={baseStyles.title}>
                Attribute ID
              </div>
              <div className={baseStyles.content}>
                #{attributeId}
              </div>
            </div>
        }

        <div className={baseStyles.section}>
          <div className={baseStyles.title}>
            Main Attribute Name
          </div>
          <div className={styles['input-wrapper']}>
            <Input
              value={mainAttrName}
              enableClearBtn={false}
              onInputChange={handleMainAttrName}
              isError={mainAttrNameStatus === STATUS.INVALID}
              errMsg={mainAttrNameErrMsg}
            />
          </div>
        </div>

        <div className={`${baseStyles.section} ${styles.sectionSpace}`}>
          <div className={baseStyles.title}>Sub Attribute</div>
          <div className={baseStyles.content}>
            {
              subAttrList.length
                ? subAttrList.map((d, index) => {
                    return renderSubAttributes(d, index);
                  })
                : renderSubAttributes()
            }
            <div className={styles['add-sub-attr']}>
              <Button
                text="Another sub attribute"
                onClick={handleAddSub}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}