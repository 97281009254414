import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { nanoid } from 'nanoid';

import { addItemAttrs, removeListAddAttr, addListAttr } from 'reducers/itemReducer';

import AttributeApi from 'utils/apicallers/attributesapi';

import attributeicon from 'assets/imgs/attributeicon.svg';
import styles from './style.module.scss';

export default function GeneralAccordion({ data, type }) {
  const dispatch = useDispatch();
  
  const addAttributes = useSelector(state => {
    return state.ItemReducer.addAttributes;
  });
  const addListAttribute = useSelector(state => {
    return state.ItemReducer.addListAttribute;
  });
  const currentSingleItemDetail = useSelector(state => {
    return state.ItemReducer.singleItemDetail;
  });
  
  const [isActive, setIsActive] = useState(false);
  const [subAttributes, setSubAttributes] = useState([]);
  
  const fetchSubAttributes = () => {
    if (isActive) {
      setIsActive(!isActive);
    } else {
      AttributeApi
        .getAttributeById(data.attributeId)
        .then(res => {
          const dataR = res.data.data;
          let subAttributesFilterResult;
          if(type){
            const result = [];
            dataR.subAttributes.forEach(s => {
              console.log(data);
              addAttributes.forEach(d => {
                if(d.sub == s.subAttributeName) {
                  result.push(s);
                }
              });
              subAttributesFilterResult = result;
            });
          }else{
            subAttributesFilterResult = dataR.subAttributes.filter(s => {
              return s.items.findIndex(item => item.itemId == currentSingleItemDetail.id) == -1;
            });
          }
          setIsActive(!isActive);
          setSubAttributes(subAttributesFilterResult);
        })
        .catch(err => {
          console.error(err);
        });
    }
  };
  
  /**
   * 
   * payload likes:
    {
      main: 'color',
      sub: 'red'
    },
    {
      main: 'color',
      sub: 'blue'
    },
   */
  const handleAttributesChecked = sub => {
    const main = data.attributeName;
    const result = [...addAttributes];
    const subIndex = result.findIndex(d => (d.main === main && d.sub === sub));

    subIndex == -1
      ? result.push({ main, sub })
      : result.splice(subIndex, 1);

    dispatch(addItemAttrs(result));
    // dispatch({
    //   type: ADD_ITEM_ATTRIBUTES,
    //   payload: result,
    // });
  };
  const handleListAttributeChecked = sub => {
    if(addListAttribute && addListAttribute.main === data.attributeName 
        && addListAttribute.sub === sub) {
      dispatch(removeListAddAttr(null));
      // dispatch({
      //   type: REMOVE_LIST_ADD_ATTRIBUTE,
      //   payload: null,
      // });  
    }else{
      const main = data.attributeName;
      const result = {main, sub};
      dispatch(addListAttr(result));
      // dispatch({
      //   type: ADD_LIST_ATTRIBUTE,
      //   payload: result,
      // });
    }
  };
  const renderAttributes = s => {
    return addAttributes.findIndex(d => (
              d.main === data.attributeName && 
              d.sub === s.subAttributeName
            )) !== -1
              ? (
                  <svg className={styles.checkmark} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                    <circle className={styles.checkmark__circle} cx="26" cy="26" r="5" fill="none" />
                    <path className={styles.checkmark__check} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                  </svg>
                )
              : <span className={styles.uncheckedIcon} />;
  };
  const renderListAttribute = s => {
    return (addListAttribute && addListAttribute.main === data.attributeName && 
              addListAttribute.sub === s.subAttributeName)
              ? (
                  <svg className={styles.checkmark} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                    <circle className={styles.checkmark__circle} cx="26" cy="26" r="5" fill="none" />
                    <path className={styles.checkmark__check} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                  </svg>
                )
              : <span className={styles.uncheckedIcon} />;
  };
  return(
    <div className={styles['accordion-item']}>
      <div
        data-id={data.attributeId}
        className={styles['accordion-title']}
        onClick={fetchSubAttributes}
      >
          <div className={styles['accordion-title--leftSection']}>
            <img
              src={attributeicon}
              className={styles['leftSection--img']}
              width="48px"
              height="48px"
            />
            <span>{data.attributeName}</span>
          </div>
          <div className={styles.activeIcon}>
            <span
              className={`${styles.arrow} ${ isActive ? styles.active : ''}`}
            >
              <span></span>
              <span></span>
            </span>
          </div>
      </div>
      {
        isActive &&
        <div className={styles.subitemWrapper}>
          { 
            subAttributes
              ? subAttributes.map(s => {
                  return (
                    <div className={styles.rightSection} key={nanoid()}>
                      {s.subAttributeName}
                      <div
                        onClick={() => {
                          return (
                            type 
                              ? handleListAttributeChecked(s.subAttributeName)
                              : handleAttributesChecked(s.subAttributeName)
                          );
                        }}
                      >
                        {
                          type 
                            ? renderListAttribute(s)
                            : renderAttributes(s)
                        }
                      </div>
                    </div>
                  );
                })
              : <div> loading...</div>
           }
        </div>
      }
    </div>
  );
}