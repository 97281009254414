import api from '../api';

const UserApi = {};

const prefix = '/users';

UserApi.getUsers = params => {
  return api.fire({
    url: prefix,
    method: 'GET',
    params
  });
};

UserApi.setStatus = (userId, status) => {
  return api.fire({
    url: `${prefix}/${userId}`,
    method: 'PATCH',
    data: {
      status
    }
  });
};

export default UserApi;