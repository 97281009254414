export const tabs = [
  {
    key:'DashBoard',
  },
  {
    key: "User"
  },
  {
    key: "Artist"
  },
  {
    key: "Token"
  },
  {
    key: "Revenue"
  },
];

export const SET_DASHBOARD_TAB = 'DATABOARD/SET_DASHBOARD_TAB';