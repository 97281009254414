import { useState } from 'react';

import styles from './style.module.scss';

export default function Description({ 
  title, desc = '',
  editMode = false,
  onChange,
  limit,
  words,
 }) {
  const [overLimit, setOverLimit] = useState(false);

  const handleChange = e => {
    const value = e.target.value;
    (value.length > limit)
      ? setOverLimit(true)
      : setOverLimit(false);
    onChange(value, overLimit);
  };
  return editMode 
    ? (
        <div className={styles.container}>
          <div className={styles.textTitle}>{title}</div>
            <textarea
              className={`${styles.content} ${styles.editable} ${overLimit && styles.alertMessage}`}
              placeholder={
                limit == 200 
                  ? 'Short Description of NFT'
                  : 'Long Description of NFT'
                }
              value={desc}
              onChange={handleChange}
            />
            <div className={`${styles.wordlimit} ${overLimit && styles.alertMessage}`}>
              {words}/{limit}
            </div>
        </div>
      )
    : (
        <div className={styles.container}>
          <div className={styles.textTitle}>{title}</div>
          <div className={styles.content}>{desc}</div>
        </div>
      );
}