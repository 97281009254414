export const SHOW_BACK_ICON = 'HEADER/SHOW_BACK_ICON';
export const HIDE_BACK_ICON = 'HEADER/HIDE_BACK_ICON';

export const SHOW_EVENT_DETAIL = 'EVENT/SHOW_EVENT_DETAIL';
export const SHOW_ADD_NEW_EVENT = 'EVENT/SHOW_ADD_NEW_EVENT';
export const SHOW_EVENT_UPDATE = 'EVENT/SHOW_EVENT_UPDATE';

export const SHOW_ITEM_DETAIL = 'ITEM/SHOW_ITEM_DETAIL';
export const SHOW_ITEM_UPDATE = 'ITEM/SHOW_ITEM_UPDATE';

export const SHOW_ATTRIBUTE_SUMMARY = 'ATTRIBUTE/SHOW_ATTRIBUTE_SUMMARY';
export const SHOW_ATTRIBUTE_DETAIL = 'ATTRIBUTE/SHOW_ATTRIBUTE_DETAIL';
export const SHOW_ATTRIBUTE_DETAIL_UPDATE = 'ATTRIBUTE/SHOW_ATTRIBUTE_DETAIL_UPDATE';
export const SHOW_ATTRIBUTE_ADD_NEW_ATTR = 'ATTRIBUTE/SHOW_ATTRIBUTE_ADD_NEW_ATTR';

export const SHOW_ARTIST_SUMMARY = 'ARTIST/SHOW_ARTIST_SUMMARY';
export const SHOW_ARTIST_DETAIL = 'ARTIST/SHOW_ARTIST_DETAIL';
export const SHOW_ARTIST_UPDATE = 'ARTIST/SHOW_ARTIST_UPDATE';

export const SHOW_HERO_BANNER_SUMMARY = 'HEROBANNER/SHOW_HERO_BANNER_SUMMARY';
export const SHOW_HERO_BANNER_DETAIL = 'HEROBANNER/SHOW_HERO_BANNER_DETAIL';
export const SHOW_HERO_BANNER_UPDATE = 'HEROBANNER/SHOW_HERO_BANNER_UPDATE';
export const SHOW_HERO_BANNER_ADD = 'HEROBANNER/SHOW_HERO_BANNER_ADD';

export const SHOW_MINT_HOME = 'MINT/SHOW_MINT_HOME';

export const SHOW_EDITORIAL_DETAIL = 'EDITORIAL/SHOW_EDITORIAL_DETAIL';
export const SHOW_EDITORIAL_UPDATE = 'EDITORIAL/SHOW_EDITORIAL_UPDATE';
export const SHOW_EDITORIAL_ADD = 'EDITORIAL/SHOW_EDITORIAL_ADD';

export const SHOW_ERROR = 'SHOW_ERROR';
