import { createSlice } from '@reduxjs/toolkit';

/** payload likes:
 * [    
      {
        "userId": "trevorchang",
        "nickname": "Trevor Chang",
        "profileImage": "#imageLink",
        "createTime": 1617003117926
      },
      ...
    ]
 */
const initialState = {
  detailArtistId: '',
  detailData: {
    featured: false,
    imageBackground: "",
    itemCount: 0,
    linkDiscord: "",
    nickname: "",
    slogan: "",
    userId: ""
  },
  userList: []
};

export const artistSlice = createSlice({
  name: 'artistSlice',
  initialState,
  reducers: {
    setDetailArtistId: (state, action) => {
      state.detailArtistId = action.payload;
    },
    fetchArtistResult: (state, action) => {
      state.detailData = action.payload;
    },
    fetchUsersResult: (state, action) => {
      state.userList = action.payload;
    },
  },
});

export const { setDetailArtistId, fetchArtistResult, fetchUsersResult }
  = artistSlice.actions;
export default artistSlice.reducer;

// const artistReducer = (state = initialState, actions) =>
//   produce(state, draft => {
//     const { type, payload } = actions;
//     switch (type) {
//       case SET_DETAIL_ARTIST_ID:
//         draft.detailArtistId = payload;
//         return draft;
//       case FETCH_ARTIST_RESULT:
//         draft.detailData = payload;
//         return draft;
//       case FETCH_USERS_RESULT:
//         draft.userList = payload;
//         return draft;
//       default:
//         return draft;
//     }
//   });

// export default artistReducer;