export default 'ART-ETF';

export const SET_EVENT_ID = 'events/SET_EVENT_ID';

export const FETCH_EVENT_RESULT = 'events/FETCH_EVENT_RESULT';

export const CLEAR_EVENT_RESULT = 'events/CLEAR_EVENT_RESULT';

export const DEFAULT_DETAIL_DATA = {
  eventId: '',
  scheduleStartTime: 0,
  scheduleEndTime: 0,
  title: '',
  itemPreview: '',
  itemName: '',
  eventType: 'AUCTION',
  currency: 'USD',
  lowerBound: 0, // bid startPrice
  minBidDiff: 0, // bid min increment
  price: 0 // sale price
};

export const EVENT_STATUS = {
  SCHEDULED: 'SCHEDULED',
  ONLINE: 'ONLINE',
  EXPIRED: 'EXPIRED'
};

export const STATUS_ENUM = {
  [EVENT_STATUS.EXPIRED]: "5",
  [EVENT_STATUS.SCHEDULED]: "1",
  [EVENT_STATUS.ONLINE]: "2"
};

export const MODEL_TYPE = {
  AUCTION: 'BID',
  SALE: 'SALE'
};

export const MODEL_OPTIONS = [
  {
    value: MODEL_TYPE.AUCTION,
    label: 'Auction'
  },
  {
    value: MODEL_TYPE.SALE,
    label: 'Sale'
  },
];