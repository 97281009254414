import { useEffect, useState, useRef } from 'react';
import _ from 'lodash';

import useTableConfig from 'hooks/useTableConfig';

import Input from 'components/common/BorderInput';
import DropdownList from 'components/common/DropdownList';
import Table from 'components/common/Table';
import Pagination from 'components/common/Pagination';
import Button from 'components/common/Button';
import StatusView from 'components/common/StatusView';
import { DatePicker } from '@y0c/react-datepicker';

import { shortenText } from 'utils';
import { formatTime } from 'utils/common';
import UserApi from 'utils/apicallers/userapi';

import { SORT } from '../common/Table/const';

import UserIcon from 'assets/imgs/user.svg';
import SuspendOn from 'assets/imgs/suspend-on.svg';
import SuspendOff from 'assets/imgs/suspend-off.svg';

import styles from './styles.module.scss';
import listingStyle from 'components/common/ListingPage/style.module.scss';

const statusList = [
  {
    key: 'NORMAL',
    text: 'Normal'
  },
  {
    key: 'SUSPENDED',
    text: 'Suspend'
  }
];

export default function User() {
  const [tableData, setTableData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [time, setTime] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [apiLoading, setApiLoading] = useState(false);
  const [paramSend, setParamSend] = useState(false);
  
  const {
    currentPage,
    pagData,
    sort,
    pageRef,
    setSortData,
    setPageData
  } = useTableConfig();
  
  const searchRef = useRef({
    time,
    status: '',
    sort
  });

  const _setSearchText = text => {
    setSearchText(text);
    setParamSend(false);
  };

  const _setSelectedStatus = data => {
    setSelectedStatus(data);
    setParamSend(false);
    searchRef.current.status = data?.key;
  };

  const _setTime = timestamp => {
    setTime(timestamp);
    setParamSend(false);
    searchRef.current.time = timestamp;
  };

  const fetchUserApi = () => {
    const { time, status, sort } = searchRef.current;
    let orderBy = 'create_time';
    switch(sort.key) {
      case 'nickname':
      case 'role':
      case 'status':
        orderBy = sort.key;
        break;
      case 'createTime':
        orderBy = 'create_time';
        break;
      default:
        break;
    }
    
    const params = {
      search: searchText,
      listType: 'USER_MANAGEMENT',
      excludeArtist: false,
      ...(status && { status }),
      registeredDate: time ?? '',
      ...(orderBy && { orderBy }),
      page: currentPage,
      pageSize: pageRef.current.size,
      sorting: sort.type === SORT.ASC ? 'ASC' : 'DESC',
    };
    setApiLoading(true);
    UserApi
      .getUsers(params)
      .then(res => {
        const { list, navigateLastPage } = res.data.data;
        setTableData(list);
        setPageData({
          total: navigateLastPage
        });
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setParamSend(true);
        setApiLoading(false);
      });
  };

  useEffect(() => {
    fetchUserApi();
  }, [currentPage, sort]);

  const handleSearch = () => {
    if(!paramSend){
      fetchUserApi();
    }
  };

  const handleReset = () => {
    if(!searchText && !time && _.isEmpty(selectedStatus)){
      return;
    }else{
      _setSearchText('');
      _setTime('');
      _setSelectedStatus(null);
      _setSortData();

      const clearBtn = document.getElementsByClassName('picker-input__clear');
      clearBtn[0] && clearBtn[0].click();
    }
  };

  const _setSortData = (key, type) => {
    setSortData({
      key,
      type
    });
    searchRef.current.sort = {
      key,
      type
    };
  };

  const handleUserStatus = (id, currentStatus) => {
    const status = currentStatus === 'NORMAL' ? 'SUSPENDED' : 'NORMAL';
    UserApi
      .setStatus(id, status)
      .then(res => {
        fetchUserApi();
      })
      .catch(err => {
        console.error(err);
      });
  };

  const cols = [
    {
      title: 'ID',
      key: 'userId',
      width: '114px',
      render: id => (
        <div className={listingStyle.id}>{`#${shortenText(id, 10)}`}</div>
      )
    },
    {
      title: 'USER NAME',
      key: 'nickname',
      sorter: true,
      render: (text, row) => {
        return (
          <div className={styles.username}>
            {text}
            <div className={styles.mail}>
              {row.mail}
            </div>
          </div>
        );
      }
    },
    {
      title: 'ROLE',
      key: 'role',
      sorter: true,
      width: '122px',
      render: role => (
        <span className={styles.capitalText}>{role.toLowerCase()}</span>
      ),
    },
    {
      title: 'STATUS',
      key: 'status',
      sorter: true,
      width: '159px',
      render: status => (
        <StatusView 
          status={status}
        />
      )
    },
    {
      title: 'REGISTER TIME',
      key: 'createTime',
      sorter: true,
      width: '200px',
      render: unix => formatTime(unix)
    },
    {
      title: 'SUSPEND',
      key: 'suspend',
      align: 'center',
      width: '68px',
      clickable: true,
      render: (text, row) => (
        <img
          src={row.status === 'NORMAL' ? SuspendOff : SuspendOn}
          onClick={() => handleUserStatus(
            row.userId,
            row.status
          )}
        />
      )
    }
  ];

  return (
    <>
      <div className={styles.titleWrapper}>
        <img
          className={styles.titleLeftIcon}
          src={UserIcon}
        />
        <div className={styles.titleLeftText}>
          User
        </div>
      </div>

      <div className={styles.search}>
        
        <div className={styles.criteriaSection}>
          <div className={styles.titlebarWrapper}>
            <label htmlFor="search">Search User</label>
            <div className={styles.input}>
              <Input
                value={searchText}
                id="search"
                placeholder="Name / Email"
                onInputChange={_setSearchText}
              />
            </div>
          </div>

          <div className={styles.titlebarWrapper}>
            <label className={styles.status}>Status</label>
            <DropdownList
              list={statusList}
              placeholder="Select Status"
              selectAction={d => _setSelectedStatus(d)}
              content={selectedStatus?.text}
            />
          </div>
          
          <div className={styles.titlebarWrapper}>
            <label className={styles.register}>
              Registered Date
            </label>
            <div className={styles.date}>
              <DatePicker
                value={time}
                clear
                placeholder="Select Date"
                onChange={d => _setTime(d.valueOf())}
              />
            </div>
          </div>
        </div>
        
        <div className={styles['btn-container']}>
          <Button
            text="Search"
            onClick={handleSearch}
          />
          <Button
            text="Clear"
            type="simple"
            onClick={handleReset}
          />
        </div>
      </div>

      <div className={styles['table-container']}>
        <Table 
          columns={cols}
          dataSource={tableData}
          sort={sort}
          onSortClick={_setSortData}
          loading={apiLoading}
          emptyContent={
            <div className={styles.empty}>
              <div className={styles.main}>No User Found</div>
              <div className={styles.desc}>Please try another terms or filters</div>
            </div> 
          }
        />

        <div className={styles.pagination}>
          <Pagination 
            data={pagData}
            fetchAction={current => setPageData({ current })}
          />
        </div>
      </div>
    </>
  );
}