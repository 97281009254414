import api from '../api';

const HeroBannerApi = {};

const prefix = '/heroBanner';

/**
 * "list": [
      {
          "bannerId": "trevorchang",
          "bannerName": "Trevor Chang",
          "order": 5,
          "startTime": 1623130009,
          "endTime": 1683130009,
          "updateTime": 1653130009,
          "status": Online
      },
      ...
    ]
 */

HeroBannerApi.getHeroBanner = (params) => {
  return api.fire({
    url: prefix,
    method: 'GET',
    params
  });
};

HeroBannerApi.getHeroBannerById = (id) => {
  return api.fire({
    url: `${prefix}/${id}`,
    method: 'GET'
  });
};

HeroBannerApi.create = (params) => {
  return api.fire({
    url: prefix,
    method: 'POST',
    data: JSON.stringify(params)
  });
};

HeroBannerApi.update = (id, params) => {
  return api.fire({
    url: `${prefix}/${id}`,
    method: 'PATCH',
    data: JSON.stringify(params)
  });
};

HeroBannerApi.delete = (bannerId) => {
  return api.fire({
    url: `${prefix}/${bannerId}`,
    method: 'DELETE'
  });
};

HeroBannerApi.uploadBackground = (params) => {
  return api.fire({
    url: `${prefix}/background`,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: params
  });
};

export default HeroBannerApi;