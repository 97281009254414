import { createSlice } from '@reduxjs/toolkit';

import { tabs } from 'components/DataBoard/consts';

const initialState = {
  currentTab: tabs[0].key,
};

export const dashboardSlice = createSlice({
  name: 'dashboardSlice',
  initialState,
  reducers: {
    setDashboardTab: (state, action) => {
      state.currentTab = action.payload;
    },
  },
});

export const { setDashboardTab } = dashboardSlice.actions;
export default dashboardSlice.reducer;

// const dashboardReducer = (state = initialState, actions) =>
//   produce(state, draft => {
//     const { type, payload } = actions;
//     switch (type) {
//       case SET_DASHBOARD_TAB:
//         draft.currentTab = payload;
//         return draft;
//       default:
//         return draft;
//     }
//   });

// export default dashboardReducer;