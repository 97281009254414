import { useState, useRef } from 'react';

export const STATUS = {
  BEFORE_CHECK: 'BEFORE_CHECK',
  VALID: 'VALID',
  INVALID: 'INVALID'
};

export default function useInputVerify() {
  const [status, setStatus] = useState(STATUS.BEFORE_CHECK);
  const [errMsg, setErrMsg] = useState('');
  const inputRef = useRef({
    status,
    errMsg
  });

  const setInputStatus = (status, errMsg) => {
    setStatus(status);
    setErrMsg(errMsg);
    inputRef.current = {
      status,
      errMsg
    };
  };

  const resetStatus = () => {
    setStatus(STATUS.BEFORE_CHECK);
    setErrMsg('');
    inputRef.current = {
      status: STATUS.BEFORE_CHECK,
      errMsg: ''
    };
  };

  return [
    status,
    errMsg,
    inputRef,
    setInputStatus,
    resetStatus
  ];
}