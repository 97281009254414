import React, {useState, useRef } from 'react';

import useClickModal from 'hooks/useClickModal';

import styles from './styles.module.scss';

const DropdownList = ({
  list,
  selectAction,
  placeholder = '',
  content = '',
}) => {
  const [showList, setShowList] = useState(false);
  const [selectedEl, setSelectedEl] = useState();
  const ref = useRef(null);

  useClickModal(ref, null, () => setShowList(false));

  const handleSelect = ele => {
    setSelectedEl(ele);
    selectAction(ele);
  };

  return (
    <div
      ref={ref}
      className={`${styles.container} ${content ? styles.active : styles['no-select']}`}
      onClick={() => setShowList(prev => !prev)}
    >
      <div className={`${styles.placeholder} ${content && styles.active}`}>
        { content || placeholder }
      </div>
      <div className={`${styles.arrow} ${showList && styles.up}`}/>
      {
        showList && list.length > 0 && (
          <div className={styles.list}>
            <div className={styles.wrapper}>
              {
                list.map(ele => {
                  return (
                    <div
                      key={ele.key}
                      className={`${styles.item} ${ele.key === selectedEl?.key && styles['is-select']}`}
                      onClick={() => handleSelect(ele)}
                    >
                      {ele.text}
                    </div>
                  );
                })
              }
            </div>
          </div>
        )
      }
    </div>
  );
};

export default React.memo(DropdownList);