import TitleCard from 'components/TitleCard/index';
import style from './style.module.scss';

export default function TitleData({ data, cstyle, component = null }) {
  return data 
  ? (
      <div className={`${style.container} ${cstyle && style[cstyle]}`}>
        <TitleCard data={data.titleData} fontSet="bold" component={component} />
      </div>
    )
  : null;
}