import { useState, useRef } from 'react';
import _ from 'lodash';

import { SORT } from '../components/common/Table/const';

export default function useTableConfig() {
  const [currentPage, setCurrentPage] = useState(1);
  const [pagData, setPagData] = useState({
    totalPages: 1,
    pageNum: 1
  });
  const [sort, setSort] = useState({
    key: '',
    type: SORT.NONE
  });
  const pageRef = useRef({
    total: 1,
    current: 1,
    size: 10,
    sort: {
      key: '',
      type: SORT.NONE
    }
  });

  const setPageData = ({ current, total, size }) => {
    current && setCurrentPage(current);
    setPagData(prev => ({
      ...prev,
      ...(current && { pageNum: current }),
      ...(total && { totalPages: total }),
    }));
    const prev = pageRef.current;
    pageRef.current = {
      ...prev,
      ...(total && { total }),
      ...(current && { current }),
      ...(size && { size })
    };
  };

  const setSortData = sort => {
    if(_.isEmpty(sort)){
      sort = {
        key: '',
        type: SORT.NONE
      };
    } 
    setSort(sort);
    const tmp = {...pageRef.current};
    pageRef.current = {
      ...tmp,
      sort
    };
  };

  return {
    currentPage,
    pagData,
    sort,
    pageRef,
    setSortData,
    setPageData
  };
}