import EmptyImg from 'assets/imgs/ic-empty.svg';

import styles from './styles.module.scss';

export default function Empty({ content }) {
  return (
    <div className={styles['empty-container']}>
        {content || 'No Data' }
    </div>
  );
}