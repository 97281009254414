import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTableConfig from '../../hooks/useTableConfig';

import { showBackIcon } from 'reducers/headerReducer';
import { setDetailArtistId } from 'reducers/artistReducer';

import Detail from './Detail';
import ArtistUpdate from './ArtistUpdate';
import StatusView from 'components/common/StatusView';

import SelectList from 'components/common/SelectList';
import DeleteModal from 'components/common/DeleteModal';
import ListingPage from 'components/common/ListingPage';
import Modal from 'components/common/Modal';
import ErrorView from 'components/common/ErrorView';

import ArtistApi from 'utils/apicallers/artistapi';
import { formatNumber, copyShareLink } from 'utils/common';

import {
    SHOW_ARTIST_DETAIL,
    SHOW_ARTIST_UPDATE,
    SHOW_ERROR,
} from 'components/universalheader/consts';

import { SORT } from 'components/common/Table/const';
import { API_TYPE } from 'components/common/DeleteModal/const';
import { STATUS as VIEWSTATUS } from 'components/common/StatusView/const';

import ArtistLogo from 'assets/imgs/icon_artist.svg';
import TrashIcon from 'assets/imgs/trash.svg';
import TrashBlackIcon from 'assets/imgs/trash-black.svg';
import ViewIcon from 'assets/imgs/view.svg';
import ViewBlackIcon from 'assets/imgs/view-black.svg';
import EditIcon from 'assets/imgs/edit.svg';
import EditBlackIcon from 'assets/imgs/edit-black.svg';

import styles from './styles.module.scss';
import listingStyle from 'components/common/ListingPage/style.module.scss';
// import { STATUS } from 'hooks/useInputVerify';

export default function Artist() {
  const dispatch = useDispatch();

  const [tableData, setTableData] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);
  const [showArtistList, setShowArtistList] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [delVisible, setDelVisible] = useState(false);
  const {
    currentPage,
    pagData,
    sort,
    pageRef,
    setSortData,
    setPageData
  } = useTableConfig();
  
  const { backDesi } = useSelector(state => state.HeaderReducer);

  const fetchApi = () => {
    const sorting = sort.type === SORT.ASC ? 'ASC' : 'DESC';
    const sortKey = sort.key.toLowerCase();
    const sortKeys = {
      nickname: 'nickName',
      itemcount: 'item_count',
      featured: 'fetured',
    };
    const orderBy = sortKeys[sortKey] ?? 'create_time';

    const params = {
      orderBy: orderBy,
      page: currentPage,
      pageSize: pageRef.current.size,
      sorting
    };
    setApiLoading(true);
    ArtistApi
      .getArtist(params)
      .then(res => {
        const { data } = res.data;
        const { navigateLastPage, list } = data;
        setTableData(list);
        setPageData({
          total: navigateLastPage
        });
      })
      .catch(err => {
        console.error('err', err);
      })
      .finally(() => {
        setApiLoading(false);
      });
  };

  useEffect(() => {
    if (backDesi !== SHOW_ARTIST_DETAIL && backDesi !== SHOW_ARTIST_UPDATE) {
      fetchApi();
    }
  }, [sort, currentPage, backDesi]);

  const handleSelectArtist = data => {
    setShowArtistList(false);
    if (!data) {
      return;
    }
    
    ArtistApi
      .addUser(data.userId)
      .then(res => {
        fetchApi();
      })
      .catch(err => {
        console.error(err);
      });
  };
  const handleSort = (key, type) => {
    setSortData({
      key,
      type
    });
  };
  const handlePagAction = (page) => {
    setPageData({
      current: page
    });
  };

  const goToDetail = id => {
    dispatch(setDetailArtistId(id));
    dispatch(showBackIcon(SHOW_ARTIST_DETAIL));
  };

  const goToEdit = id => {
    dispatch(setDetailArtistId(id));
    dispatch(showBackIcon(SHOW_ARTIST_UPDATE));
  };

  const handlePreDelete = id => {
    setSelectedId(id);
    setDelVisible(true);
  };

  const handleDeleteComplete = isSuccess => {
    setDelVisible(false);
    isSuccess && fetchApi();
  };
  const addNew = useCallback(() => {
    setShowArtistList(true);
  }, []);

  const cols = [
    {
      title: 'ID',
      key: 'userId',
      width: '173px',
      render: text => {
        return (
          <div
            className={`${styles.id} ${listingStyle.id}`}
            onClick={() => copyShareLink(text)}
          >
            @{text}
          </div>
        );
      }
    },
    {
      title: 'ARTIST NAME',
      key: 'nickname',
      sorter: true
    },
    {
      title: 'ITEM COUNT',
      key: 'itemCount',
      align: 'left',
      width: '175px',
      sorter: true,
      render: (text) => formatNumber(text)
    },
    {
      title: 'FEATURED',
      key: 'featured',
      align: 'left',
      sorter: true,
      render: text => (
        <StatusView
          status={ text ? VIEWSTATUS.FETURED : VIEWSTATUS.NOTFEATURED } 
        />
      ),
    },
    {
      title: 'VIEW',
      key: 'view',
      align: 'center',
      width: '68px',
      clickable: true,
      render: (text, row, router, { isHover }) => {
        return (
          <img
            loading="lazy"
            className={styles.icon}
            src={isHover ? ViewBlackIcon : ViewIcon}
            onClick={() => goToDetail(row.userId)}
          />
        );
      }
    },
    {
      title: 'EDIT',
      key: 'details',
      align: 'center',
      width: '68px',
      clickable: true,
      render: (text, row, router, { isHover }) => {
        return (
          <img
            loading="lazy"
            className={styles.icon}
            src={isHover ? EditBlackIcon : EditIcon}
            onClick={() => goToEdit(row.userId)}
          />
        );
      }
    },
    {
      title: 'DELETE',
      key: 'delete',
      align: 'center',
      width: '68px',
      clickable: true,
      render: (text, row, _, { isHover }) => {
        return (
          <img
            loading="lazy"
            className={styles.icon}
            src={isHover ? TrashBlackIcon : TrashIcon}
            onClick={() => handlePreDelete(row.userId)}
          />
        );
      }
    }
  ];
  const renderContent = () => {
    switch (backDesi) {
      case SHOW_ARTIST_DETAIL:
        return <Detail />;
      case SHOW_ARTIST_UPDATE:
        return <ArtistUpdate />;
      case SHOW_ERROR:
        return <ErrorView />;
      default:
        return (
          <>
            <ListingPage
              data={ tableData }
              cols={ cols }
              title={{
                text: 'Artist',
                icon: ArtistLogo
              }}
              sort={ sort }
              pagData={ pagData }
              loading={apiLoading}
              addNewFn={ addNew }
              onSortClick={ handleSort }
              pagFetchAction={ handlePagAction }
            />

            {
              showArtistList &&
              <Modal>
                <SelectList
                  title={'Add New Artist'}
                  handleCloseModal={handleSelectArtist}
                />
              </Modal>
            }

            <DeleteModal
              id={selectedId}
              visible={delVisible}
              apiType={API_TYPE.ARTIST}
              title="Delete Artist"
              errTitle="Not Able to Delete Artist"
              content="Are you sure to delete this artist?"
              errContent="This artist can not be deleted because he/ she has items."
              onComplete={handleDeleteComplete}
            />
          </>
        );
    }
  };

  return (
    <div className={styles.container}>
      { renderContent() }
    </div>
  );
}