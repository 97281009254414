import ArrowUp from 'assets/imgs/arrow-up.svg';
import ArrowUpGrey from 'assets/imgs/arrow-up-grey.svg';
import ArrowDown from 'assets/imgs/arrow-down.svg';
import ArrowDownGrey from 'assets/imgs/arrow-down-grey.svg';

import { SORT } from '../const';

import styles from './styles.module.scss';

const ALIGN = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right'
};

export default function SortIcon({
  onSortClick = null,
  prefix = '',
  align = ALIGN.LEFT,
  sortType = SORT.DESC
}) {
  const handleSort = () => {
    switch(sortType) {
      case SORT.ASC:
        onSortClick(SORT.DESC);
        break;
      default:
        onSortClick(SORT.ASC);
        break;
    }
  };
  const setAlign = () => {
    switch (align) {
      case ALIGN.LEFT:
        return styles.left;
      case ALIGN.CENTER:
        return styles.center;
      case ALIGN.RIGHT:
      default:
        return styles.right;
    }
  };

  return (
    <div
      className={`${styles.container} ${setAlign()}`}
      onClick={handleSort}
    >
      <div className={styles.prefix}>{prefix}</div>
      <div className={styles.icon}>
        <div className={styles.up} onClick={handleSort}>
          <img src={sortType === SORT.ASC ? ArrowUp : ArrowUpGrey}
            height="8px"
            width="8px"
          />
        </div>
        <div className={styles.down} onClick={handleSort}>
          <img src={sortType === SORT.DESC ? ArrowDown : ArrowDownGrey}
            height="8px"
            width="8px"
          />
        </div>
      </div>
    </div>
  );
}