import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Prompt } from 'react-router-dom';
import _ from 'lodash';

import { hideBackIcon, showBackIcon } from 'reducers/headerReducer';
import { fetchArtistResult } from 'reducers/artistReducer';

import Button from "components/common/Button";
import Input from "components/common/BorderInput";
import DragDrop from 'components/common/DragDrop';
import Previewer from 'components/common/Previewer';
import Collapse from "components/common/Collapse";
import UploadButton from 'components/common/UploadButton';
import Spinner from 'components/common/Spinner';
import TextArea from 'components/common/TextArea';
import DropdownButton from 'components/common/DropdownButton';
import DeleteModal from 'components/common/DeleteModal';
import ErrorText from 'components/common/ErrorText';
import Switch from 'components/common/Switch';

import ArtistApi from 'utils/apicallers/artistapi';
import {
  fetchMatchPattern,
  checkFileSize,
  validator,
  checkMatchPattern,
} from 'utils/common';

import {
  SHOW_ARTIST_DETAIL,
  SHOW_ERROR
} from 'components/universalheader/consts';
import { CONTENT_TYPE } from 'components/common/Previewer/const';
import { API_TYPE } from 'components/common/DeleteModal/const';
import {
  delList,
  VIDEO_SUPPORT_FILE_EXTENSIONS,
  VIDEO_FILE_SIZE_IN_MB
} from '../const';
import { 
  wordLimitation,
  // shortLimitation,
  fileExtensions,
} from 'consts/appConsts';

import styles from "../Detail/styles.module.scss";
import updateStyles from './styles.module.scss';

const DEFAULT_DATA = {
  nickname: '',
  itemCount: '',
  imageBackground: '',
  slogan: '',
  linkDiscord: '',
  cvTitle: '',
  featured: false,
};
const patternMatch = fetchMatchPattern(/(?:\.([^.]+))?$/);
const checkPattern = checkMatchPattern(validator.htmlTag);

export default function ArtistUpdate() {
  const dispatch = useDispatch();
  const { detailArtistId, detailData } = useSelector(state => {
    return state.ArtistReducer;
  });

  const [artistInfo, setArtistInfo] = useState({
    ...DEFAULT_DATA,
    ...detailData
  });
  const { 
    itemCount,
    userId,
    imageThumbnail,
    imageBackground,
    cv,
    video
  } = detailData;

  const [originUrl, setOriginUrl] = useState({
    imageThumbnail,
    imageBackground,
    cv,
    video
  });
  const [apiLoading, setApiLoading] = useState(false);

  const [isUploadingBanner, setIsUploadingBanner] = useState(false);
  const [isUploadingThumbmail, setIsUploadingThumbnail] = useState(false);
  const [isUploadingCV, setIsUploadingCV] = useState(false);
  const [isUploadingVideo, setIsUploadingVideo] = useState(false);
  const [isSubmitting, setisSubmitting] = useState(false);
  const [delModalVisible, setDelModalVisible] = useState(false);

  const [thumbnailErrMsg, setThumbnailErrMsg] = useState('');
  const [backgroundErrMsg, setBackgroundErrMsg] = useState('');
  const [cvErrMsg, setCvErrMsg] = useState('');
  const [videoErrMsg, setVideoErrMsg] = useState('');

  const [validObj, setValidObj] = useState({});
  const [isSubmitDisable, setIsSubmitDisable] = useState(false);

  useEffect(() => {
    if (detailArtistId && (detailArtistId !== userId)) {
      setApiLoading(true);
      setValidObj({});
      ArtistApi
        .getArtistById(detailArtistId)
        .then(res => {
          const {
            imageThumbnail,
            imageBackground,
            cv,
            video
          } = res.data.data;
          dispatch(fetchArtistResult(res.data.data));
          setArtistInfo({
            ...DEFAULT_DATA,
            ...res.data.data
          });
          setOriginUrl({
            imageThumbnail,
            imageBackground,
            cv,
            video
          });
        })
        .catch(err => {
          console.error(err);
          dispatch(showBackIcon(SHOW_ERROR));
        })
        .finally(() => {
          setApiLoading(false);
        });
    }
  }, [detailArtistId, userId, dispatch]);

  useEffect(() => {
    const isSubmitDisable = (
      !_.isEmpty(validObj) ||
      isUploadingBanner ||
      isUploadingThumbmail ||
      isUploadingCV ||
      isUploadingVideo ||
      isSubmitting
    );

    setIsSubmitDisable(isSubmitDisable);
  });

  const handleInput = valueObj => {
    setArtistInfo(prevValue => {
      return {...prevValue, ...valueObj};
    });
  };

  const handleUpdate = () => {
    const params = {
      nickname: artistInfo.nickname || '',
      imageBackground: artistInfo.imageBackground || '',
      imageThumbnail: artistInfo.imageThumbnail || '',
      cv: artistInfo.cv || '',
      cvTitle: artistInfo.cvTitle || '',
      video: artistInfo.video || '',
      videoDescription: artistInfo.videoDescription || '',
      description: artistInfo.description || '',
      country: artistInfo.country || '',
      linkIg: artistInfo.linkIg || '',
      linkTwitter: artistInfo.linkTwitter || '',
      linkDiscord: artistInfo.linkDiscord || '',
      slogan: artistInfo.slogan || '',
      featured: artistInfo.featured,
    };
    setisSubmitting(true);
    ArtistApi
      .update(detailArtistId, params)
      .then(res => {
        // TODO: popup success msg
        gotoDetail();
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setisSubmitting(false);
      });
  };

  const gotoDetail = () => {
    dispatch(showBackIcon(SHOW_ARTIST_DETAIL));
  };

  const handleCancel = () => {
    gotoDetail();
  };

  const validateWithHtmlTag = (str, id) => {
    const result = checkPattern(str);
    result 
      ? validObj[id]= result
      : delete validObj[id];
    return result;
  };

  const handleUploadBanner = file => {
    setBackgroundErrMsg('');
    const fileExtension = patternMatch(file.name);
    if(!fileExtensions.img.includes(fileExtension)){
      setBackgroundErrMsg('file type not supported.');
      return;
    }

    const overHeight = 900;
    const overWidth = 1600;

    const img = new Image();
    const newUrl = URL.createObjectURL(file);
    img.src = newUrl;
    img.onload = () => {
      const { height, width } = img;
      URL.revokeObjectURL(newUrl);
      if (height < overHeight || width < overWidth) {
        setBackgroundErrMsg('Size should over 1600 * 900');
        return;
      }

      const params = new FormData();
      params.append('image', file);
      setIsUploadingBanner(true);
      setBackgroundErrMsg('');
      ArtistApi
        .uploadBgImg(detailArtistId, params)
        .then(res => {
          const background = res.data.data.imageBackground;
          setArtistInfo(prev => ({
            ...prev,
            imageBackground: background,
          }));
        })
        .catch(err => {
          setBackgroundErrMsg('Upload Fail');
          setArtistInfo(prev => ({
            ...prev,
            imageBackground: originUrl.imageBackground,
          }));
        })
        .finally(() => {
          setIsUploadingBanner(false);
        });
    };
  };

  const handleArtistImgUpload = file => {
    setThumbnailErrMsg('');
    const fileExtension = patternMatch(file.name);
    if(!fileExtensions.img.includes(fileExtension)){
      setThumbnailErrMsg('file type not supported.');
      return;
    }
    
    const size = 420;
    const img = new Image();
    const newUrl = URL.createObjectURL(file);
    img.src = newUrl;
    img.onload = function() {
      const { height, width } = img;
      URL.revokeObjectURL(newUrl);
      if (height < size || width < size) {
        setThumbnailErrMsg('Size should over 420 * 420');
        return;
      }

      const params = new FormData();
      params.append('image', file);
      setIsUploadingThumbnail(true);
      ArtistApi
        .uploadThumbnailImg(detailArtistId, params)
        .then(res => {
          const thumbnail = res.data.data.imageThumbnail;
          setArtistInfo(prev => ({
            ...prev,
            imageThumbnail: thumbnail,
          }));
        })
        .catch(err => {
          console.error(err);
          setThumbnailErrMsg('upload fail');
          setArtistInfo(prev => ({
            ...prev,
            imageThumbnail: originUrl.imageThumbnail,
          }));
        })
        .finally(() => {
          setIsUploadingThumbnail(false);
        });
    };
  };

  const handleUploadAttachment = file => {
    setCvErrMsg('');
    if (!file) {
      setCvErrMsg('please select file.');
      return;
    }

    if (!checkFileSize(file.size, 5)) {
      setCvErrMsg('File size should smaller than 5 MB.');
      return;
    }

    const formData = new FormData();
    formData.append('cv', file);
    setIsUploadingCV(true);
    ArtistApi
      .uploadCV(detailArtistId, formData)
      .then(res => {
        const { cv } = res.data.data;
        setArtistInfo(prev => ({
          ...prev,
          cv
        }));
      })
      .catch(err => {
        console.error(err);
        setCvErrMsg('upload fail');
        setArtistInfo(prev => ({
          ...prev,
          cv: originUrl.cv
        }));
      })
      .finally(() => {
        setIsUploadingCV(false);
      });
  };

  const handleUploadVideo = file => {
    setVideoErrMsg('');
    if (!file) {
      setVideoErrMsg('Please select file');
      return;
    }

    const fileExtension = patternMatch(file.name);
    if(!fileExtensions.video.includes(fileExtension)){
      setVideoErrMsg('file type not supported.');
      return;
    }

    if (!checkFileSize(file.size, VIDEO_FILE_SIZE_IN_MB)) {
      setVideoErrMsg(`File size should smaller than ${VIDEO_FILE_SIZE_IN_MB} MB.`);
      return;
    }

    const formData = new FormData();
    formData.append('video', file);
    setIsUploadingVideo(true);
    ArtistApi
      .uploadVideo(detailArtistId, formData)
      .then(res => {
        const { video } = res.data.data;
        setArtistInfo(prev => ({
          ...prev,
          video
        }));
      })
      .catch(err => {
        console.error(err);
        setVideoErrMsg('upload fail');
        setArtistInfo(prev => ({
          ...prev,
          video: origin.video
        }));
      })
      .finally(() => {
        setIsUploadingVideo(false);
      });
  };

  const openDelModal = () => {
    setDelModalVisible(true);
  };

  const handleDeleteComplete = isSuccess => {
    setDelModalVisible(false);
    isSuccess && dispatch(hideBackIcon());
  };
  const handleDeleteBackground = () => {
    handleInput({'imageBackground': ''});
    setBackgroundErrMsg('');
  };
  const handleDeleteCv = () => {
    handleInput({'cv': ''});
    setCvErrMsg('');
  };
  const handleDeleteVideo = () => {
    handleInput({'video': ''});
    setVideoErrMsg('');
  };

  const handleFeaturedCheck = valObj => {
    setArtistInfo(prevValue => {
      return {...prevValue, ...valObj};
    });
  };

  if (apiLoading) {
    return (
      <Spinner size="medium" />
    );
  }
  // if (errMsg) {
  //   return (
  //     <ErrorText text={errMsg} />
  //   );
  // }

  return (
    <div className={styles.container}>
      <Prompt message="" when={true} />
      <div className={styles["name-container"]}>
        <div className={styles.name}>{artistInfo.nickname}</div>

        <div className={styles["btn-container"]}>
          <Button type="simple" text="Cancel" onClick={handleCancel} />
          <Button
            type="normal"
            text="Save"
            disabled={isSubmitDisable}
            onClick={handleUpdate}
          />
          <DropdownButton
            list={delList}
            onSelect={openDelModal}
          />
        </div>
      </div>

      <div className={styles["profile-container"]}>
        <div className={styles["artist-image-wrapper"]}>
          <div className={styles["image-wrapper"]}>
            {
              isUploadingThumbmail
              ? <Spinner size="medium" />
              : <img
                  src={artistInfo.imageThumbnail || originUrl.imageThumbnail}
                  loading="lazy"
                />
            }
          </div>
          <div className={updateStyles["upload-btn"]}>
            <UploadButton
              text="Replace"
              handleUpload={handleArtistImgUpload}
            />
          </div>
          <div className={updateStyles.hint}>
            Recommended image is square with size no smaller than 420*420
            JPEG, PNG accepted, 5MB limit
          </div>
          {
            thumbnailErrMsg && (
              <ErrorText text={thumbnailErrMsg} />
            )
          }
        </div>

        <div className={styles["profile-wrapper"]}>
          <div className={styles.section}>
            <div className={styles.title}>Artist ID</div>
            <div className={styles.content}>
              <div>{detailArtistId}</div>
            </div>
          </div>

          <div className={styles.section}>
            <div className={styles.title}>Artist Name</div>
            <Input
              value={artistInfo.nickname}
              enableClearBtn={false}
              onInputChange={ value => handleInput({"nickname": value}) }
              // isError={validateWithHtmlTag(artistInfo.nickname)}
              errMsg={
                validateWithHtmlTag(artistInfo.nickname, "nickname") &&
                  'Invalid Input.'
              }
            />
          </div>

          <div className={styles.section}>
            <div className={styles.title}>Item count</div>
            <div className={styles.content}>{itemCount}</div>
          </div>

          <div className={styles.section}>
          <div className={styles.title}>Featured</div>
            <Switch
              inputkey="featured"
              checked={artistInfo.featured}
              onChange={handleFeaturedCheck}
            />
          </div>

          <div className={styles.section}>
            <div className={styles.title}>Artist banner image</div>
            {
              isUploadingBanner
              ? <Spinner size="medium" />
              : artistInfo.imageBackground
                ? (
                  <>
                    <Previewer
                      url={artistInfo.imageBackground}
                      type={CONTENT_TYPE.PHOTO_MEDIA}
                    />
                    <div className={updateStyles["upload-container"]}>
                      <UploadButton
                        text="Replace"
                        handleUpload={handleUploadBanner}
                      />
                      <div
                        className={updateStyles.delete}
                        onClick={handleDeleteBackground}
                      >
                        Delete
                      </div>
                    </div>
                  </>
                ) : (
                  <DragDrop
                    title="Drag and drop here or click to browse"
                    limitText="Recommended image size is no smaller than 1600*900 JPEG, PNG accepted, 5MB limit"
                    fileLimitSizeInMb={5}
                    submitImage={handleUploadBanner}
                  />
                )
            }
            {
              backgroundErrMsg && (
                <ErrorText text={backgroundErrMsg} />
              )
            }
          </div>

          <div className={styles.section}>
            <div className={styles.title}>Country</div>
            <Input
              value={artistInfo?.country}
              enableClearBtn={false}
              // isError={validateWithHtmlTag(artistInfo.country)}
              onInputChange={value => handleInput({"country": value})}
              errMsg={
                validateWithHtmlTag(artistInfo.country, "country") && 'Invalid Input.'
              }
            />
          </div>

          <div className={styles.section}>
            <div className={styles.title}>Twitter</div>
            <Input
              value={artistInfo?.linkTwitter}
              enableClearBtn={false}
              // isError={validateWithHtmlTag(artistInfo.linkTwitter)}
              onInputChange={value => handleInput({"linkTwitter": value})}
              errMsg={
                validateWithHtmlTag(artistInfo.linkTwitter, "linkTwitter") && 'Invalid Input.'
              }
            />
          </div>

          <div className={styles.section}>
            <div className={styles.title}>Instagram</div>
            <Input
              value={artistInfo?.linkIg}
              enableClearBtn={false}
              // isError={validateInstagram() !== ''}
              // isError={validateWithHtmlTag(artistInfo.linkIg)}
              onInputChange={value => handleInput({"linkIg": value})}
              errMsg={
                validateWithHtmlTag(artistInfo.linkIg, "linkIg") && 'Invalid Input.'
              }
            />
          </div>

          <div className={styles.section}>
            <div className={styles.title}>Discord</div>
            <Input
              value={artistInfo.linkDiscord}
              enableClearBtn={false}
              // isError={validateDiscord() !== ''}
              onInputChange={value => handleInput({"linkDiscord": value})}
              errMsg={
                validateWithHtmlTag(artistInfo.linkDiscord, "linkDiscord") && 
                  'Invalid Input.'
              }
            />
          </div>
        </div>
      </div>

      <Collapse header="Artist Description" defaultCollapse={false}>
        <div className={styles.section}>
          <div className={styles.title}>Short Description</div>
          <Input
            value={artistInfo.slogan}
            placeholder="Describe the Artist."
            enableClearBtn={false}
            maxLength={200}
            // isError={validateShortDesc() !== ''}
            onInputChange={value => handleInput({"slogan": value})}
            errMsg={validateWithHtmlTag(artistInfo.slogan, "slogan") &&
              'Invalid Input.'}
          />
        </div>

        <div className={styles.section}>
          <div className={styles.title}>Long Bio</div>
          <TextArea
            value={artistInfo.description}
            placeholder="Bio"
            maxLength={wordLimitation}
            // onInputChange={e => handleInput('description', e)}
            // isError={validateLongBio() !== ''}
            onInputChange={value => handleInput({"description": value})}
            errMsg={
              validateWithHtmlTag(artistInfo.description, "description") &&
                'Invalid Input.'
            }
          />
        </div>

        <div className={styles.section}>
          <div className={styles.title}>Attachment</div>
          
          <div className={updateStyles.attachmentTitle}>
            <Input
              value={artistInfo.cvTitle}
              placeholder="Attachment Title"
              enableClearBtn={false}
              maxLength={200}
              // isError={validateAttachmentTitle() !== ''}
              // errMsg={validateAttachmentTitle()}
              onInputChange={value => handleInput({"cvTitle": value})}
              errMsg={
                validateWithHtmlTag(artistInfo.cvTitle, "cvTitle") &&
                  'Invalid Input.'
              }
            />
          </div>

          {
            isUploadingCV
            ? <Spinner size="medium" />
            : artistInfo.cv
              ? <>
                  <Previewer
                    url={artistInfo.cv}
                    type={CONTENT_TYPE.PDF_MEDIA}
                  />
                  <div className={updateStyles["upload-container"]}>
                    <UploadButton
                      text="Replace"
                      accept=".pdf"
                      handleUpload={handleUploadAttachment}
                    />
                    <div
                      className={updateStyles.delete}
                      onClick={handleDeleteCv}
                    >
                      Delete
                    </div>
                  </div>
                  {
                    cvErrMsg && (
                      <ErrorText text={cvErrMsg} />
                    )
                  }
                </>
              : <>
                  <DragDrop
                    title="Drag and drop art works here"
                    limitText="PDF accepted, 5MB limit"
                    acceptType=".pdf"
                    fileLimitSizeInMb={5}
                    submitImage={handleUploadAttachment}
                  />
                  {
                    cvErrMsg && (
                      <ErrorText text={cvErrMsg} />
                    )
                  }
                </>
          }
        </div>
        
      </Collapse>

      <Collapse header="Video Promotion" defaultCollapse={false}>
        <div className={styles.section}>
          <div className={styles.title}>Video</div>
          {
            isUploadingVideo
            ? <Spinner size="medium" />
            : artistInfo.video
              ? <>
                  <Previewer
                    url={artistInfo.video}
                    type={CONTENT_TYPE.VEDIO_MEDIA}
                  />
                  <div className={updateStyles["upload-container"]}>
                    <UploadButton
                      text="Replace"
                      accept={VIDEO_SUPPORT_FILE_EXTENSIONS}
                      handleUpload={handleUploadVideo}
                    />
                    <div
                      className={updateStyles.delete}
                      onClick={handleDeleteVideo}
                    >
                      Delete
                    </div>
                  </div>
                  {
                    videoErrMsg && <ErrorText text={videoErrMsg} />
                  }
                </>
              : <>
                  <DragDrop
                    title="Drag and drop art works here"
                    limitText={`MP4 and MOV accepted, ${VIDEO_FILE_SIZE_IN_MB}MB limit`}
                    acceptType={VIDEO_SUPPORT_FILE_EXTENSIONS}
                    fileLimitSizeInMb={VIDEO_FILE_SIZE_IN_MB}
                    submitImage={handleUploadVideo}
                  />
                  {
                    videoErrMsg && (
                      <ErrorText text={videoErrMsg} />
                    )
                  }
                </>
          }
        </div>

        <div className={styles.section}>
          <div className={styles.title}>Video description</div>
          <TextArea 
            value={artistInfo.videoDescription}
            placeholder=""
            maxLength={wordLimitation}
            // isError={validateVideoDesc() !== ''}
            errMsg={
              validateWithHtmlTag(artistInfo.videoDescription, "videoDescription") 
                && 'Invalid Input.'
            }
            onInputChange={value => handleInput({"videoDescription": value})}
          />
        </div>
      </Collapse>
      
      <DeleteModal
        id={detailArtistId}
        visible={delModalVisible}
        apiType={API_TYPE.ARTIST}
        title="Delete Artist"
        errTitle="Not Able to Delete Artist"
        content="Are you sure to delete this artist?"
        errContent="This artist can not be deleted because he/ she has items."
        onComplete={handleDeleteComplete}
      />
    </div>
  );
}
