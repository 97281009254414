import { useState } from 'react';
import { handleMouseEnter } from 'utils/common';

import style from './style.module.scss';

import dowloadIcon from 'assets/imgs/download.svg';
import dowloadIcon_dark from 'assets/imgs/download_000.svg';

export default function ExportCSV({exportAction}) {
  const [hoverState, setHoverState] = useState(false);
  const handleMouseMove = handleMouseEnter(setHoverState);

  return (
    <div
      className={style.container}
      onClick={exportAction}
      onMouseEnter={handleMouseMove}
      onMouseLeave={handleMouseMove}
    >
      <img
        src={hoverState ? dowloadIcon_dark : dowloadIcon}
        loading="lazy"
        width="16"
        height="11"
      />
      <span>Export CSV</span>
    </div>
  );
}