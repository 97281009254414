export const UPTDATE_ITEM_ATTRIBUTES = 'item/UPTDATE_ITEM_ATTRIBUTES';
export const SET_ITEM_DETAIL_ID = 'item/SET_ITEM_DETAIL_ID';
export const FETCH_ITEM_DETAIL_RESULT = 'item/FETCH_ITEM_DETAIL_RESULT';
export const FETCH_ITEM_LIST_RESULT = 'item/FETCH_ITEM_LIST_RESULT';
export const ADD_ITEM_ATTRIBUTES = 'item/ADD_ITEM_ATTRIBUTES';
export const ADD_LIST_ATTRIBUTE = 'item/ADD_LIST_ATTRIBUTE';
export const REMOVE_LIST_ADD_ATTRIBUTE = 'item/REMOVE_LIST_ADD_ATTRIBUTE';
export const UPDATE_ITEM_ATTRIBUTES_LISTATTRIBUTES
  = 'item/UPDATE_ITEM_ATTRIBUTES_LISTATTRIBUTES';
export const CLEAN_ADDLISTATTRIBUTE = 'item/CLEAN_ADDLISTATTRIBUTE';

export const FETCH_TOKEN_LIST_RESULT = 'item/FETCH_TOKEN_LIST_RESULT';
export const CLEAR_TOKEN_LIST_RESULT = 'item/CLEAR_TOKEN_LIST_RESULT';

export const CLEAR_ITEM_DETAIL_RESULT = 'item/CLEAR_ITEM_DETAIL_RESULT';

export const SELECT_YEARS = 'item/SELECT_YEARS';

export const SET_ACTION = 'item/SET_ACTION';

export const ITEM_STATUS = {
  CMS_WIP: 'CMS_WIP',
  OPEN: 'OPEN'
};

export const ACTION = {
  DETAIL: 'DETAIL',
  UPDATE: 'UPDATE'
};
