import { useState } from 'react';

import { formatNumber, checkArrow } from 'utils/common';

import style from './style.module.scss';
import gstyle from 'components/App/style.module.scss';

export default function TitleCard({ data, fontSet, hasPadding = true, component = null }) {

  const formatPercentage = number => {
    return Math.floor(number * 100) / 100;
  };

  return (
    <div className={`${style.container} ${!hasPadding && style['no-padding']} ${component && style['auto-height']}`}>
      <div className={`${style.title} ${fontSet === 'bold' ? style.boldText : ''}`}>
        { data.title }
      </div>

      {component}

      <div className={style.content}>
        <span className={fontSet === 'bold' ? style.boldNumber : ''}>
          { formatNumber(data.content) }
        </span>

        <div className={ `${style.trend} ${data.trend !== 0 && (data.trend > 0 ? gstyle.greenColor : gstyle.redColor) }`}>
          <span>
            { data.trend !== 0 && checkArrow(data.trend) }
          </span>
          <span>{ Math.abs(formatPercentage(data.trend)) }%</span>
        </div>
      </div>
    
    </div>
  );
}