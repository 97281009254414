import { useEffect, useState, useRef } from 'react';
import useTableConfig from 'hooks/useTableConfig';

import DashboardTab from 'components/common/DashboardTab';
import DateRangeSelector from 'components/common/DateRangeSelector';
import Table from 'components/common/Table';

import { formatTime, formatNumber } from 'utils/common';
import { shortenText } from 'utils';
import { DEFAULT_RANGE_TIME } from 'components/OverviewBoard/const';
import DataStudioApi from 'utils/apicallers/datastudioapi';
import MarketTab from '../MarketTab';

import style from '../style.module.scss';

const cols = [
  {
    title: 'DATE',
    key: 'date',
    fixed: 'left',
    render: date => formatTime(date, true)
  },
  {
    title: 'TOKEN ID',
    key: 'tokenId',
    fixed: 'left',
    render: id => shortenText(id, 7)
  },
  {
    title: 'ITEM NAME',
    key: 'itemName'
  },
  {
    title: 'ARTIST NAME',
    key: 'artistNickname'
  },
  {
    title: 'SELLING',
    key: 'eventType'
  },
  {
    title: 'PRICE (USD)',
    key: 'price',
    align: 'right',
    render: price => formatNumber(price)
  },
  {
    title: 'BUYER USERNAME',
    key: 'buyerUserId'
  }
];

const TABLE_ACTION = {
  APPEND_DATA: 0,
  NEW_DATA: 1
};

export default function TokenSales() {
  const [market, setMarket] = useState(0);
  const [salesList, setSalesList] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);
  const [timeRange, setTimeRange] = useState(DEFAULT_RANGE_TIME);
  const {
    pageRef,
    setPageData
  } = useTableConfig();

  const fetchData = tableAction => {
    // to make scroll smooth, remove loading spinner
    if (tableAction === TABLE_ACTION.NEW_DATA) {
      setApiLoading(true);
    }

    DataStudioApi
      .getTokenSales({
        type: market === 0 ? 'PRIMARY' : 'SECONDARY',
        start: timeRange.startDay,
        end: timeRange.endDay,
        page: pageRef.current.current
      })
      .then(res => {
        const { list, navigateLastPage } = res.data.data;

        if (tableAction === TABLE_ACTION.APPEND_DATA) {
          setSalesList(prev => [...prev, ...list]);
        } else {
          setSalesList(list);
        }

        setPageData({
          total: navigateLastPage
        });
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => setApiLoading(false));
  };

  useEffect(() => {
    setPageData({
      current: 1
    });
    fetchData(TABLE_ACTION.NEW_DATA);
  }, [market, timeRange]);

  const handleNextPageAction = () => {
    const { current, total } = pageRef.current;
    if (current < total) {
      setPageData({
        current: current + 1
      });
      fetchData(TABLE_ACTION.APPEND_DATA);
    }
  };

  return (
    <div className={style.container}>
      <div className={style.titleWrapper}>
        <div className={style.title}>
          Token Sales
        </div>
      </div>
      <div className={style.titleWrapper}>
        <MarketTab
          activeTab={market}
          setTab={setMarket}
        />
        <DateRangeSelector
          handleSelectRange={setTimeRange}
        />
      </div>
      <div className={style.tableWrapper}>
        <Table
          height="276px"
          columns={cols}
          dataSource={salesList}
          loading={apiLoading}
          fetchAction={handleNextPageAction}
        />
      </div>
    </div>  
  );
}