import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { addHours, addMinutes } from 'date-fns';
import { Prompt } from 'react-router-dom';
import useInputVerify, { STATUS } from 'hooks/useInputVerify';

import { hideBackIcon, showBackIcon } from 'reducers/headerReducer';
import { fetchEventResult, clearEventResult } from 'reducers/eventReducer';

import { RangeDatePicker, DatePicker } from '@y0c/react-datepicker';
import dayjs from 'dayjs';

import BorderInput from 'components/common/BorderInput';
import Button from 'components/common/Button';
import Modal from 'components/common/Modal';
import SelectList from 'components/common/SelectList';
import Spinner from 'components/common/Spinner';
import DropdownButton from 'components/common/DropdownButton';
import DeleteModal from 'components/common/DeleteModal';
import ItemPreviewer from '../ItemPreviewer';
import ErrorText from 'components/common/ErrorText';

import EventApi from 'utils/apicallers/eventapi';

import { SELECTTYPE } from 'components/common/SelectList/consts';
import { 
  SHOW_ADD_NEW_EVENT,
  SHOW_ERROR
} from 'components/universalheader/consts';
import {
  CLEAR_EVENT_RESULT,
  DEFAULT_DETAIL_DATA,
  STATUS_ENUM,
  MODEL_OPTIONS,
  MODEL_TYPE
} from '../consts';
import { DEL_LIST } from 'components/common/DropdownButton/const';
import { API_TYPE } from 'components/common/DeleteModal/const';

import demo from 'assets/imgs/eventDemo.png';

import styles from './style.module.scss';
import commonStyles from '../eventDetail/style.module.scss';

export default function EventNew() {
  const dispatch = useDispatch();

  const backDesi = useSelector(state => {
    return state.HeaderReducer.backDesi;
  });
  const eventId = useSelector(state => {
    return state.EventReducer.eventId;
  });
  const { detailData } = useSelector(state => {
    return state.EventReducer;
  });
  
  const [apiLoading, setApiLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [showSelectModal, setShowSelectModal] = useState(false);
  const [itemData, setItemData] = useState({
    itemId: '',
    name: '',
    preview: '',
    tokenCounts: 0,
  });

  const isCreateMode = backDesi === SHOW_ADD_NEW_EVENT;
  const [event, setEvent] = useState({
    ...(isCreateMode ? {...DEFAULT_DETAIL_DATA} : detailData),
    ...(isCreateMode && 
      { scheduleStartTime: dayjs().startOf('day').valueOf() }),
    ...(isCreateMode && 
      { scheduleEndTime: dayjs().endOf('day').valueOf() }),
    tempStarthour: 0,
    tempStartmin: 0,
    tempEndhour: 0,
    tempEndmin: 0,
  });
  const [delVisible, setDelVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    isCreateMode && dispatch(clearEventResult());
    if (!isCreateMode && eventId) {
      setApiLoading(true);
      setErrMsg('');
      EventApi
        .getEventsById(eventId)
        .then(res => {
          dispatch(fetchEventResult(res.data.data));
          setEvent({
            ...res.data.data,
            tempStarthour: 0,
            tempStartmin: 0,
            tempEndhour: 0,
            tempEndmin: 0,
          });
          setApiLoading(false);
        })
        .catch(err => {
          // setErrMsg(err?.response?.data?.msg || 'fetch data fail');
          dispatch(showBackIcon(SHOW_ERROR));
          setApiLoading(false);
        });
    }
  }, [isCreateMode]);
  
  const [selectedOption, setSelectedOption] = useState(
    detailData.eventType !== MODEL_TYPE.AUCTION
      ? MODEL_OPTIONS[1]
      : MODEL_OPTIONS[0]
  );
  const [modelOption, setModelOption] = useState(MODEL_OPTIONS);
  
  const [
    titleStatus,
    titleErrMsg,
    titleRef,
    setTitleStatus,
    resetTitleStatus
   ] = useInputVerify();
  const [
    lowerBoundStatus,
    lowerBoundErrMsg,
    lowerBoundRef,
    setLowerBoundStatus,
    resetLowerBoundStatus
   ] = useInputVerify();
  const [
    minBidDiffStatus,
    minBidDiffErrMsg,
    minBidDiffRef,
    setMinBidDiffStatus,
    resetMinBidDiffStatus
  ] = useInputVerify();
  const [
    sellPriceStatus,
    sellPriceErrMsg,
    sellPriceRef,
    setSellPriceStatus,
    resetSellPriceStatus
  ] = useInputVerify();
  const [
    newItemStatus,
    newItemErrMsg,
    newItemRef,
    setNewItemStatus,
    resetNewItemStatus
  ] = useInputVerify();
  const [
    reservePriceStatus,
    reservePriceErrMsg,
    reservePriceRef,
    setReservePriceStatus,
    resetReservePriceStatus
  ] = useInputVerify();
  
  const handleTextInput = (key, e) => {
    setEvent(prev => ({
      ...prev,
      [key]: e
    }));
  };

  const verifyTitle = () => {
    if (!event.title) {
      setTitleStatus(STATUS.INVALID, 'This field is required.');
      return false;
    } else {
      setTitleStatus(STATUS.VALID, '');
      return true;
    }
  };
  const verifyLowerBound = () => {
    if (!event.lowerBound) {
      setLowerBoundStatus(STATUS.INVALID, 'This field is required.');
      return false;
    } else {
      setLowerBoundStatus(STATUS.VALID, '');
      return true;
    }
  };
  const verifyMinBidDiff = () => {
    if (!event.minBidDiff) {
      setMinBidDiffStatus(STATUS.INVALID, 'This field is required.');
      return false;
    } else {
      setMinBidDiffStatus(STATUS.VALID, '');
      return true;
    }
  };
  const verifySellPriceDiff = () => {
    if (!event.price) {
      setSellPriceStatus(STATUS.INVALID, 'This field is required.');
      return false;
    } else {
      setSellPriceStatus(STATUS.VALID, '');
      return true;
    }
  };
  const verifyNewItem = () => {
    if (!itemData.itemId) {
      setNewItemStatus(STATUS.INVALID, 'Please select one item.');
      return false;
    } else {
      setNewItemStatus(STATUS.VALID, '');
      return true;
    }
  };
  const verifyReservePrice = () => {
    if (!event.reservePrice) {
      return true;
    }

    const reservePrice = Number(event.reservePrice);
    const price = Number(event.price);
    const lowerBound = Number(event.lowerBound);

    if (selectedOption.value === MODEL_TYPE.SALE) {
      if (reservePrice < price) {
        setReservePriceStatus(STATUS.INVALID, 'should not smaller than sell price');
        return false;
      } else {
        setReservePriceStatus(STATUS.VALID, '');
        return true;
      }
    } else if (reservePrice < lowerBound) {
        setReservePriceStatus(STATUS.INVALID, 'should not smaller than start bidding price');
        return false;
      } else {
        setReservePriceStatus(STATUS.VALID, '');
        return true;
      }
  };

  const goToSummary = () => {
    dispatch(hideBackIcon());
  };

  const handleAction = () => {
    resetTitleStatus();
    resetLowerBoundStatus();
    resetMinBidDiffStatus();
    resetSellPriceStatus();
    resetNewItemStatus();
    resetReservePriceStatus();

    let pass = true;
    let verifyFns = [verifyTitle()];
    if (!isCreateMode) {
      if (selectedOption.value === MODEL_TYPE.AUCTION) {
        verifyFns = verifyFns.concat([
          verifyLowerBound(), verifyMinBidDiff(), verifyReservePrice(),
        ]);
      } else {
        verifyFns = verifyFns.concat([verifySellPriceDiff()]);
      }
    } else if (selectedOption.value === MODEL_TYPE.AUCTION) {
        verifyFns = verifyFns.concat([
          verifyLowerBound(),
          verifyMinBidDiff(),
          verifyNewItem(),
          verifyReservePrice(),
        ]);
      } else {
        verifyFns = verifyFns.concat([verifySellPriceDiff(), verifyNewItem()]);
      }
    pass = verifyFns.every(fn => fn === true);
    if (!pass) {
      return;
    }

    let params = {};
    if (isEditDisable) {
      params = {
        itemId: itemData.itemId ? itemData.itemId : event.itemId,
        scheduleEndTime: event.scheduleEndTime,
      };
    } else {
      params = {
        itemId: itemData.itemId ? itemData.itemId : event.itemId,
        title: event.title,
        scheduleStartTime: event.scheduleStartTime,
        scheduleEndTime: event.scheduleEndTime,
        eventType: selectedOption.value === MODEL_TYPE.AUCTION ? 'BID' : 'SALE',
        price: selectedOption.value === MODEL_TYPE.AUCTION ? event.lowerBound : event.price,
        ...(selectedOption.value === MODEL_TYPE.AUCTION && { minBidDiff: event.minBidDiff }),
        ...(event.reservePrice && { reservePrice: event.reservePrice })
      };
    }
    setIsSubmitting(true);
    if (!isCreateMode) {
      EventApi
        .update(event.eventId, params)
        .then(res => {
          goToSummary();
        })
        .catch(err => {
          setErrMsg(err?.response?.data?.msg || 'something went wrong, please contact system admin');
        })
        .finally(
          () => setIsSubmitting(false)
        );
    } else {
      const createParams = {
        ...params,
        itemId: itemData.itemId
      };
      EventApi
        .create(createParams)
        .then(res => {
          goToSummary();
        })
        .catch(err => {
          setErrMsg(err?.response?.data?.msg || 'something went wrong, please contact system admin');
        })
        .finally(
          () => setIsSubmitting(false)
        );
    }
  };

  const handleCancle = e => {
    goToSummary();
  };
  const triggerSelectItemModal = e => {
    setShowSelectModal(true);
  };
  const finishSelectItem = data => {
    data && setItemData(data);
    if(data?.tokenCounts > 1){
      setSelectedOption(MODEL_OPTIONS[1]);
      setModelOption([MODEL_OPTIONS[1]]);
    }else{
      setSelectedOption(MODEL_OPTIONS[0]);
    }
    setShowSelectModal(false);
    setNewItemStatus(STATUS.VALID, '');
  };
  const handleModelSelect = selectedOption => {
    const value = selectedOption.value;
    
    let result = {};
    result.model = value;
    result = {...event, ...result};
    setEvent(result);
    setSelectedOption(selectedOption);
    resetReservePriceStatus();
  };

  const handleRangeDate = (start, end) => {
    if(start && end){
      const startOnlyDateTimeStamp = 
        dayjs(start.valueOf()).startOf('day').valueOf();
      const endOnlyDateTimeStamp =
        dayjs(end.valueOf()).endOf('day').valueOf();

      let startResult = addHours(startOnlyDateTimeStamp, event.tempStarthour);
      startResult = addMinutes(startResult, event.tempStartmin);
      let endResult = addHours(endOnlyDateTimeStamp, event.tempEndhour);
      endResult = addMinutes(endResult, event.tempEndmin);

      console.log('after handleRangeDate startResult : ', startResult);
      console.log('after handleRangeDate endResult : ', endResult);

      setEvent(prev => ({
        ...prev,
        scheduleStartTime: startResult.valueOf(),
        scheduleEndTime: endResult.valueOf()
      }));
    }else{
      return;
    }
  };

  const handleEndDate = end => {
    const endOnlyDateTimeStamp =
      dayjs(end.valueOf()).startOf('day').valueOf();
    let endResult = addHours(endOnlyDateTimeStamp, event.tempEndhour);
    endResult = addMinutes(endResult, event.tempEndmin);

    setEvent(prev => ({
      ...prev,
      scheduleEndTime: endResult.valueOf(),
    }));
  };

  const calTime = (key, date) => {
    const hour = date.$H;
    const min = date.$m;

    if(key === 'start'){
      event.tempStarthour = hour;
      event.tempStartmin = min;
    }
    if(key === 'end'){
      event.tempEndhour = hour;
      event.tempEndmin = min;
    }

    const currentTime = key === 'start'
      ? event.scheduleStartTime
      : event.scheduleEndTime;
    
    const zeroScheduleTime = dayjs(currentTime).startOf('day').valueOf();
    let result = addHours(zeroScheduleTime, hour);
    result = addMinutes(result, min);
    return result.valueOf();
  };
  const handleTime = (key, date) => {
    const result = calTime(key, date);
    if(key === 'start'){
      setEvent(prev => ({
        ...prev,
        scheduleStartTime: result,
      }));
    }else{
      setEvent(prev => ({
        ...prev,
        scheduleEndTime: result,
      }));
    }
  };
  const initialPickTime = key => {
    if(key === 'start'){
      const dayjsObj = dayjs(event.scheduleStartTime);
      event.tempStarthour = dayjsObj.$H;
      event.tempStartmin = dayjsObj.$m;
    }else {
      const dayjsObj = dayjs(event.scheduleEndTime);
      event.tempEndhour = dayjsObj.$H;
      event.tempEndmin = dayjsObj.$m;
    }
    return dayjs(event.scheduleStartTime);
  };

  const handleDeleteComplete = isSuccess => {
    setDelVisible(false);
    isSuccess && goToSummary();
  };

  const clearItemData = () => {
    setItemData({
      itemId: '',
      name: '',
      preview: ''
    });
    setModelOption(MODEL_OPTIONS);
  };

  const isEditDisable =
    (detailData.status !== STATUS_ENUM.SCHEDULED) && (!isCreateMode);

  const renderNew = () => {
    return (
      <>
        <div className={`${commonStyles.textTitle} ${commonStyles.required}`}>
          Event Item
        </div>
        <div>
          {
            itemData.preview
            ? (
              <ItemPreviewer
                url={itemData.preview}
                name={itemData.name}
                onSelect={triggerSelectItemModal}
                onDelete={clearItemData}
              />
            )
            : (
              <Button
                cClass={{
                  width: '90px'
                }}
                text="Select"
                onClick={triggerSelectItemModal}
              />
            )
          }
        </div>
        {
          newItemStatus === STATUS.INVALID &&
          <div className={styles.err}>
            {newItemErrMsg}
          </div>
        }
      </>
    );
  };
  const renderUpdate = () => {
    return (
      <>
        <div className={`${commonStyles.textTitle} ${commonStyles.required}`}>
          Event Item
        </div>
        <ItemPreviewer
          url={itemData.preview ? itemData.preview : detailData.itemPreview}
          name={itemData.name || detailData.itemName}
          isEditDisable={isEditDisable}
          isDeleteDisable={!itemData.preview}
          onSelect={triggerSelectItemModal}
          onDelete={clearItemData}
        />
      </>
    );
  };

  // edit end date only
  const renderRangeDatePicker = () => {
    return (
      <div className={styles.timePicker}>
        <div className={`${commonStyles.dateRangeWrapper} ${styles.leftTimeItem}`}>
          <section className={`${commonStyles.rangeText} ${commonStyles.required}`}>
            Start Date
          </section>
          <DatePicker
            initialDate={initialPickTime('start')}
            disabled
          />
        </div>
        <div className={commonStyles.dateRangeWrapper}>
          <section className={`${commonStyles.rangeText} ${commonStyles.required}`}>
            End Date
          </section>
          <DatePicker
            initialDate={dayjs(event.scheduleEndTime)}
            onChange={date => handleEndDate(date)}
          />
        </div>
      </div>
    );
  };

  const renderWrapper = content => {
    return (
      <div className={styles.pickerWrapper}>
        {content}
      </div>
    );
  };
  
  if (apiLoading) {
    return (
      <Spinner size="medium" />
    );
  }
  if (errMsg) {
    return (
      <ErrorText text={errMsg} align="center" />
    );
  }
  return (
    <div className={commonStyles.detailCotainer}>
      <Prompt message="" when={true} />
      <DeleteModal 
        id={eventId}
        visible={delVisible}
        apiType={API_TYPE.EVENT}
        title="Delete Event"
        errTitle="Not Able to Delete Event"
        content="Are you sure to delete this event?"
        errContent="This event can not be deleted."
        onComplete={handleDeleteComplete}
      />
      <div className={styles.titleContainer}>
        <div className={styles.title}>
          {!isCreateMode ? event.title : "New Event"}
        </div>

        <div className={styles.btnArea}>
          <Button 
            text="Cancel"
            type="simple"
            onClick={handleCancle}
          />
          <Button 
            text={!isCreateMode ? 'Save' : 'Create'}
            onClick={handleAction}
            disabled={isSubmitting}
          />
          {
            !isEditDisable && !isCreateMode && (
              <DropdownButton 
                list={DEL_LIST}
                onSelect={() => setDelVisible(true)}
              />
            )
          }
        </div>
      </div>
      
      <div className={commonStyles.contentWrapper}>
        <div className={`${commonStyles.leftPart} ${commonStyles.leftPartEdit}`}>
          <img src={demo} className={commonStyles.image} />
          {/* <div className={commonStyles.uploadBtn}>Upload</div> */}
        </div>

        <div className={commonStyles.rightPart}>
          <div className={`${commonStyles.textTitle} ${isCreateMode && commonStyles.required}`}>
            Event ID
          </div>
          <div className={`${commonStyles.text} ${commonStyles.withIcon}`}>
            {event.eventId}
          </div>

          <div className={`${commonStyles.textTitle} ${commonStyles.required}`}>
            Event Title
          </div>
          <BorderInput 
            value={event.title}
            enableClearBtn={false}
            placeholder="Enter Event Title"
            onInputChange={e => handleTextInput('title', e)}
            isError={titleStatus === STATUS.INVALID}
            errMsg={titleErrMsg}
            isDisabled={isEditDisable}
          />
          {
            !isCreateMode
              ? renderUpdate()
              : renderNew()
          }

          {
            isEditDisable
              ? renderRangeDatePicker()
              : (
                <div className={commonStyles.dateRangeWrapper}>
                  <section className={`${commonStyles.rangeText} ${commonStyles.required}`}>
                    Start Date ~  End Date
                  </section>
                  <RangeDatePicker
                    initialStartDate={dayjs(event.scheduleStartTime)}
                    initialEndDate={dayjs(event.scheduleEndTime)}
                    onChange={handleRangeDate}
                    wrapper={renderWrapper}
                  />
                </div>
              )
          }

          <div className={styles.timePicker}>
            <div className={`${commonStyles.dateRangeWrapper} ${styles.leftTimeItem}`}>
              <section className={`${commonStyles.rangeText} ${commonStyles.required}`}>
                Start Time
              </section>
              <DatePicker
                showTimeOnly
                initialDate={initialPickTime('start')}
                onChange={date => handleTime('start', date)}
                disabled={isEditDisable}
              />
            </div>
            <div className={commonStyles.dateRangeWrapper}>
              <section className={`${commonStyles.rangeText} ${commonStyles.required}`}>
                End Time
              </section>
              <DatePicker
                showTimeOnly
                initialDate={dayjs(event.scheduleEndTime)}
                onChange={date => handleTime('end', date)}
              />
            </div>
          </div>
          
          <div className={`${commonStyles.textTitle} ${commonStyles.required}`}>
            Model
          </div>
          <Select
            value={selectedOption}
            defaultValue={selectedOption}
            isDisabled={isEditDisable}
            options={modelOption}
            onChange={handleModelSelect}
          />
          {
            selectedOption.value === MODEL_OPTIONS[0].value
            ? (
                <>
                  <div className={`${commonStyles.textTitle} ${commonStyles.required}`}>
                    Start Bidding Price
                  </div>
                  <BorderInput
                    value={event.lowerBound || ''}
                    type="number"
                    prefix={
                      <span className={commonStyles.dollarSign}>$</span>
                    }
                    postfix={
                      <span className={commonStyles.dollarPostfix}>USD</span>
                    }
                    enableClearBtn={false}
                    onInputChange={e => handleTextInput('lowerBound', e)}
                    isError={lowerBoundStatus === STATUS.INVALID}
                    errMsg={lowerBoundErrMsg}
                    isDisabled={isEditDisable}
                  />

                  <div className={`${commonStyles.textTitle} ${commonStyles.required}`}>
                    Bid Increment Price
                  </div>
                  <BorderInput
                    value={event.minBidDiff || ''}
                    type="number"
                    prefix={
                      <span className={commonStyles.dollarSign}>$</span>
                    }
                    postfix={
                      <span className={commonStyles.dollarPostfix}>USD</span>
                    }
                    enableClearBtn={false}
                    onInputChange={e => handleTextInput('minBidDiff', e)}
                    isError={minBidDiffStatus === STATUS.INVALID}
                    errMsg={minBidDiffErrMsg}
                    isDisabled={isEditDisable}
                  />

                  <div className={commonStyles.textTitle}>
                      Reserve Price (optional)
                  </div>
                  <BorderInput
                    value={event.reservePrice || ''}
                    type="number"
                    prefix={
                      <span className={commonStyles.dollarSign}>$</span>
                    }
                    postfix={
                      <span className={commonStyles.dollarPostfix}>USD</span>
                    }
                    enableClearBtn={false}
                    onInputChange={e => handleTextInput('reservePrice', e)}
                    isDisabled={isEditDisable}
                    isError={reservePriceStatus === STATUS.INVALID}
                    errMsg={reservePriceErrMsg}
                  />
                </>
              )
              : (
                <>
                  <div className={`${commonStyles.textTitle} ${commonStyles.required}`}>
                    Sell price
                  </div>
                  <BorderInput
                    value={event.price || ''}
                    type="number"
                    prefix={<span className={commonStyles.dollarSign}>$</span>}
                    postfix={<span className={commonStyles.dollarPostfix}>USD</span>}
                    enableClearBtn={false}
                    onInputChange={e => handleTextInput('price', e)}
                    isError={sellPriceStatus === STATUS.INVALID}
                    errMsg={sellPriceErrMsg}
                    isDisabled={isEditDisable}
                  />
                </>
              ) 
          }
        </div>
      </div>

      {
        showSelectModal &&
        <Modal>
          <SelectList
            title="Add Item"
            handleCloseModal={finishSelectItem}
            type={SELECTTYPE.ITEM}
          />
        </Modal>
      }
    </div>
  );
}