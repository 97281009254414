import { createSlice } from '@reduxjs/toolkit';

// import {
//   SET_HEROBANNER_ID,
//   FETCH_HEROBANNER_RESULT
// } from 'components/HeroBanner/const';

const initialState = {
  herobannerId: '',
  detailData: {
    bannerId: "",
    bannerName: "",
    order: 1,
    startTime: 0,
    endTime: 0,
    background: "",
    mainTitle: "",
    subTitle: [],
    cta: "Buy now",
    ctaType: "Event",
    eventId: "",
    artistId: "",
    url: ""
  }
};

export const herobannerSlice = createSlice({
  name: "herobannerSlice",
  initialState,
  reducers: {
    setHeroBannerId: (state, action) => {
      state.herobannerId = action.payload;
    },
    fetchHeroBanner: (state, action) => {
      state.detailData = action.payload;
    },
  },
});

export const { setHeroBannerId, fetchHeroBanner } = herobannerSlice.actions;
export default herobannerSlice.reducer;

// const heroBannerReducer = (state = initialState, actions) =>
//   produce(state, draft => {
//     const { type, payload } = actions;
//     switch (type) {
//       case SET_HEROBANNER_ID:
//         draft.herobannerId = payload;
//         return draft;
//       case FETCH_HEROBANNER_RESULT:
//         draft.detailData = payload;
//         return draft;
//       default:
//         return draft;
//     }
//   });

// export default heroBannerReducer;