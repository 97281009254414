import React from 'react';

import styles from './styles.module.scss';

export default function Spinner({ center, size, relativeCenter = false }) {
  let classIndicator = 'spinner';
  let wrapperIndicator = 'wrapper';

  if (center) {
    wrapperIndicator = `${wrapperIndicator}_center`;
  }
  if (relativeCenter) {
    wrapperIndicator = `${wrapperIndicator}_relative--center`;
  }

  if (size) {
    classIndicator = `${classIndicator}--${size}`;
  }
  return (
    <div className={styles[wrapperIndicator]}>
      <div className={styles[classIndicator]} />
    </div>
  );
}