import ErrorText from 'components/common/ErrorText';

import styles from './styles.module.scss';

export default function TextArea({
  value = '',
  maxLength = null,
  name = null,
  required = null,
  placeholder = '',
  showCount = true,
  onInputChange,
  isError = false,
  errMsg = ''
}) {

  const onChange = e => {
    onInputChange(e.target.value);
  };

  return (
    <div>
      <div className={`${styles["text-area-wrapper"]} ${isError && styles.err}`}>
        <textarea
          rows={7}
          value={value || ''}
          name={name}
          required={required}
          onChange={onChange}
          placeholder={placeholder}
          maxLength={maxLength || null}
        />
      </div>
      {
        (errMsg || showCount) && (
          <div className={`${styles.status} ${errMsg && styles.err}`}>
          {
            errMsg && <ErrorText text={errMsg} />
          }
          {
            showCount && (
              <div className={styles.limit}>
                {maxLength - (value?.length || 0)}
              </div>  
            )
          }
        </div>
        )
      }
    </div>
  );
}
