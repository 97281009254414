import { useRef } from 'react';

import UploadIcon from 'assets/imgs/ic-upload-grey.svg';

import styles from './styles.module.scss';

export default function DragDrop({
  acceptType = 'image/*',
  title = '',
  limitText = '',
  showClick = false,
  fileLimitSizeInMb = 50,
  submitImage = () => {}
}) {
  const inputRef = useRef();

  const handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = e => {
    e.stopPropagation();
    e.preventDefault();
    if (e.dataTransfer?.files.length > 0) {
      const file = e.dataTransfer?.files[0];
      submitImage(file);
    }
  };

  const uploadFile = e => {
    inputRef.current.value = null;
    inputRef.current.click();
  };

  const handleLoadFile = e => {
    const file = e.target.files[0];
    submitImage(file);
  };

  return (
    <div
      className={styles.container}
      onDragOver={handleDrag}
      onDrop={handleDrop}
    >
      <div className={styles.title}>
        {title}
        {
          showClick && (
            <div className={styles['click-text']}>
              or <span className={styles.link} onClick={uploadFile}>click</span> to browse
            </div>
          )
        }
      </div>
      <img
        className={styles.icon}
        src={UploadIcon} 
        loading="lazy"
        height="32px" 
        width="48px"
        onClick={uploadFile}
      />
      <div className={styles.limit}>{limitText}</div>
      <input
        ref={inputRef}
        className={styles['input-wrapper']}
        type="file"
        accept={acceptType}
        onChange={handleLoadFile}
      />
    </div>
  );
}